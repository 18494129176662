<!-- Page Sidebar Start-->
<div class="logo-wrapper">
  <a>
    <img class="img-fluid for-light" src="../../../../assets/images/logo/thi.png" alt="logo"  style="width: 100px; height: 35px"/>
    <img class="img-fluid for-dark" src="../../../../assets/bg/thi.png" alt="logo"  style="width: 100px; height: 35px" />
    <!-- <h6 style="color: #fe0000;">{{ curentLang === 'en' ? 'Fawtara' : (curentLang === 'ar' ? 'فوترة' : 'חיוב') }}</h6> -->
  </a>
  <div class="back-btn" (click)="sidebarToggle()">
    <i class="fa fa-angle-left"></i>
  </div>
  <div class="toggle-sidebar" (click)="sidebarToggle()">
    <i data-feather="grid" class="status_toggle middle sidebar-toggle"style="color: #fe0000;"></i>
    <!-- <app-feather-icons icon="'grid'" class="status_toggle middle sidebar-toggle"></app-feather-icons> -->
  </div>
</div>
<div class="logo-icon-wrapper">
  <a href="javascript:void(0)">
    <!-- <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" /> -->
  </a>
</div>
<nav class="sidebar-main">
  <div class="left-arrow" id="left-arrow" [class.d-none]="leftArrowNone" (click)="scrollToLeft()">
    <!-- <i data-feather="arrow-left" ></i> -->
    <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
  </div>



  <div id="sidebar-menu" [ngStyle]="{ marginLeft: this.layout.config.settings.layout == 'Rome' || 'Singapore' || 'Barcelona' ? margin + 'px' : '0px' }">
  <ul class="sidebar-links custom-scrollbar">
    <div class="simplebar-wrapper">
      <div class="simplebar-mask">
        <div class="simplebar-offset">
          <div class="simplebar-content-wrapper">
            <div class="simplebar-content">
              <li class="back-btn">
                <a href="javascript:void(0)">
                  <!-- <img class="img-fluid" src="assets/images/logo/logo-icon.png" alt="" /> -->
                  <h3 style="color: #141414;">{{ curentLang === 'en' ? 'Fawtara' : (curentLang === 'ar' ? 'فوترة' : 'חיוב') }}</h3>
                </a>
                <div class="mobile-back text-end" (click)="sidebarToggle()">
                  <span> {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'عودة' : 'חזרה')}} </span>
                  <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                </div>
              </li>

              <!-- Loop through menu items -->
              <ng-container *ngFor="let item of sidebarItems" >

                <li class="{{(currentHref == item.route) ? 'mm-active' : ''}}" *ngIf="!(userType_en === 'admin' && item.title === 'Users' )" >
                  <a [routerLink]="[item.route]" class="sidebar-link sidebar-title" routerLinkActive="active"
                    class="ai-icon {{(currentHref == item.route) ? 'mm-active' : ''}}" aria-expanded="false"
                    [class.link-nav]="!item.route" [ngClass]="{ active: item.active }">
                    <i [innerHTML]="item.iconClass" class=" mx-2 {{ item.active ? 'down' : 'right' }} " style="font-size: 18px; color: grey;"></i>
                      <span class="nav-text">{{ item.title  }}</span>                      
                  </a>
                  
              </li>
    
              
            </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ul>
</div>






  <div class="right-arrow" id="right-arrow" [class.d-none]="rightArrowNone" (click)="scrollToRight()">
    <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
  </div>
</nav>