import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from 'sweetalert2';
import { AddMeasurementEndPoient } from "../service/global.service";

@Component({
  selector: "app-measurement-units",
  templateUrl: "./measurement-units.component.html",
  styleUrls: ["./measurement-units.component.scss"]
})

export class MeasurementUnitsComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addMeasurement:FormGroup;
  updateMeasurement:FormGroup;
  productsData:any=[];
  customerDataMerchant:any=[];
  customerTypeData:any=[];
  measurementId: number;
  updatedData: any;
  viewData: any;
  categoriesData:any=[];
  unitMesauereData:any=[];
  public validate = false;
  public validateUpdate = false;

  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

   this.addMeasurement=new FormGroup({
    measurement_unit_name_ar :new FormControl('' , Validators.required),
    measurement_unit_name_en:new FormControl('' , Validators.required),
    measurement_unit_name_he :new FormControl('' , Validators.required),  
    created_by:new FormControl(this.userRegistId),
    });

    this.updateMeasurement=new FormGroup({
      measurement_unit_name_ar :new FormControl('' , Validators.required),
      measurement_unit_name_en:new FormControl('' , Validators.required),
      measurement_unit_name_he :new FormControl('' , Validators.required),  
      updated_by:new FormControl(this.userRegistId),
      });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  getAllMeasurementUnits() {
    this.spinner.show();
    this.service.getAllMeasurementUnits().subscribe({
      next: res => {
        this.unitMesauereData = res;
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };


  addedData() {

    const measurement_unit_name_ar = this.addMeasurement.value.measurement_unit_name_ar.trim();
    const measurement_unit_name_en = this.addMeasurement.value.measurement_unit_name_en.trim();
    const measurement_unit_name_he = this.addMeasurement.value.measurement_unit_name_he.trim();
   
    if (
      measurement_unit_name_ar === '' ||
      measurement_unit_name_en === '' ||
      measurement_unit_name_he === '' 
     ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.addMeasurement.markAllAsTouched();
      this.validate = !this.validate;
      return;
    }

    
      
    else
    {
      let model = {
        measurement_unit_name_ar: this.addMeasurement.value.measurement_unit_name_ar,
        measurement_unit_name_en: this.addMeasurement.value.measurement_unit_name_en,
        measurement_unit_name_he: this.addMeasurement.value.measurement_unit_name_he,
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddMeasurementEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllMeasurementUnits();
          this.addMeasurement.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }

  };

  setValue(measurementId: number) {
    for (let i = 0; i < this.unitMesauereData.length; i++) {
      if (this.unitMesauereData[i].measurement_unit_id == this.measurementId) {
        const selectedCategory = this.unitMesauereData.find(item => item.measurement_unit_id === measurementId);
        this.updateMeasurement.controls.measurement_unit_name_ar.setValue(this.unitMesauereData[i].measurement_unit_name_ar);
        this.updateMeasurement.controls.measurement_unit_name_en.setValue(this.unitMesauereData[i].measurement_unit_name_en);
        this.updateMeasurement.controls.measurement_unit_name_he.setValue(this.unitMesauereData[i].measurement_unit_name_he);
      }
    };
  };

  executeFunctions(mesauereID: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(mesauereID);
    this.setValue(mesauereID);
    this.updateMd(this.updatedData);
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  updateMeasurementById() {

    const measurement_unit_name_ar = this.updateMeasurement.value.measurement_unit_name_ar.trim();
    const measurement_unit_name_en = this.updateMeasurement.value.measurement_unit_name_en.trim();
    const measurement_unit_name_he = this.updateMeasurement.value.measurement_unit_name_he.trim();
   
    if (
      measurement_unit_name_ar === '' ||
      measurement_unit_name_en === '' ||
      measurement_unit_name_he === '' 
     ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.updateMeasurement.markAllAsTouched();
      this.validateUpdate = !this.validateUpdate;
      return;
    }

    else
    {
      let model = {
        measurement_unit_name_ar: this.updateMeasurement.value.measurement_unit_name_ar,
        measurement_unit_name_en: this.updateMeasurement.value.measurement_unit_name_en,
        measurement_unit_name_he: this.updateMeasurement.value.measurement_unit_name_he,
        updated_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.updateMeasurementById( this.measurementId, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllMeasurementUnits();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }

};

executeFunctionsView(ProductID: number, view: any) {
  this.viewData = view;
  this.getId(ProductID);
  this.setValue(ProductID);
  this.updateViewMd(this.viewData);
};

updateViewMd(paymentsUpdated: any) {
  this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
};

  getId(id: number) {
    this.measurementId = id;
  };

  filter(event:any)
  {
    let x = event.target.value;
   };

  openVerticallyCentered(dataDeleted:any) {
    this.modalService.open(dataDeleted, { centered: true });
  };
  
  deleteMeasurementUnitById() {
    this.spinner.show();
    this.service.deleteMeasurementUnitById(this.measurementId).subscribe({
      next: (res) => {
        if (res.status == 400) {
          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.spinner.hide();
          return;
        } else {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.getAllMeasurementUnits();
          this.modalService.dismissAll();
          this.spinner.hide();
        }
      },
      error: (err) => {
        Swal.fire(err.messageAr);
        this.spinner.hide(); 
      }
    });
  };

  changeStatus(id:number, status:any){
    this.spinner.show();
  
    let model =
    {
      "status": status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeMeasurementUnitsStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllMeasurementUnits();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  ngOnInit() 
  {
  this.getAllMeasurementUnits();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
}
