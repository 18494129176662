export const environment = {
  production: false,
  clientId: '41fc6eb8fe6b6061d3d2894441d3a119',
  clientSecret: '1187649eb778a975606209be6b52d8e5',
  redirectUri: 'https://sub.thinvoices.com/oauth/callback',
  scope: 'scope',
  authUrl: 'https://openapi.taxes.gov.il/shaam/tsandbox/longtimetoken/oauth2/authorize',
  tokenUrl: 'https://openapi.taxes.gov.il/shaam/tsandbox/longtimetoken/oauth2/token',
  apiUrl: 'https://intg.thinvoices.com/oauth-prod/authorization-code',
  // baseUrl: 'http://localhost:8000/'
     baseUrl: 'https://sub.thinvoices.com/'

};
