<nav class="navbar navbar-expand-lg fixed-top" *ngIf="isLogin" >
  <div class="container">
    <a class="navbar-brand" style="color: #141414;" routerLink="home">
    <img src="../../../assets/images/logo/thi.png" alt="" style="width: 100px; height: 35px;">  
    </a>
    <button class="navbar-toggler" type="button" (click)="toggleNavbar()" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <!-- <span class="navbar-toggler-icon" style="color: #141414;"></span> -->

      <span class="navbar-toggler-icon ">
        <i class="fa fa-bars p-2 text-dark"></i>
      </span>
    </button>

    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="navbar-nav m-auto">
        <li class="nav-item" routerLinkActive="active-2" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" routerLink="home">{{'brand.home'|translate}} <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item" routerLinkActive="active-2" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" routerLink="about">{{'brand.about'|translate}}</a>
        </li>
        <li class="nav-item" routerLinkActive="active-2" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" routerLink="pricing">{{'brand.Pricing'|translate}}</a>
        </li>
         <li class="nav-item" routerLinkActive="active-2" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" routerLink="terms">{{'brand.terms'|translate}}</a>
        </li>  
      </ul>
      <div class="navbar-auth" id="authBTNs">
        <button class="btn mx-3" [routerLinkActive]="['active2']" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="register" style="color: #141414;">{{'brand.register'|translate}}</a>
        </button>
        <button class="btn mx-3"  [routerLinkActive]="['active2']" [routerLinkActiveOptions]="{exact:true}">
          <a routerLink="login" style="color: #141414;">{{'brand.login'|translate}}</a>
        </button>
        <li class="nav-item dropdown" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">
        
          
      
              <!-- <div ngbDropdown class="lang">
                <div class="language">
                  <button class="mx-3" id="dropdownConfig" ngbDropdownToggle 
                  style="padding: 5px 12px; color: #141414; border-color: transparent;" >{{'brand.Language'|translate}}
                </button>
                </div>
                <div ngbDropdownMenu aria-labelledby="dropdownConfig" >
                  <a class="dropdown-item" (click)="changeLanguage('ar')" style="color: #141414;">Arabic</a>
                  <a class="dropdown-item" (click)="changeLanguage('en')"style="color: #141414;">English </a>
                  <a class="dropdown-item" (click)="changeLanguage('he')"style="color: #141414;">עִברִית</a>
               
            </div>
          </div> -->

        </li>


      </div>
    </div>
  </div>
</nav>
