<app-breadcrumb [title]="curentLang === 'en' ? 'Receipt Info' : (curentLang === 'ar' ? ' معلومات الايصالات' : ' פרטי הקבלות' )" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    הגדרות   '])" 
[active_item]="curentLang === 'en' ? 'Receipt Info' : (curentLang === 'ar' ? ' معلومات الايصالات ' : 'פרטי הקבלות')">
</app-breadcrumb>

<!--Receipt info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Receipt Info' : (curentLang === 'ar' ? 'معلومات الايصالات ' : '  פרטי הקבלות    ')}}
        </h5>

       <div>
        <!-- <button  class="btn" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add receipt' : (curentLang === 'ar' ? ' اضافة ايصال ' : 'מידע קבלה')}}
        </button> -->


        <button [hidden]="!isValidMerchant" type="button" class="btn" style="background-color: #141414; color: #fff;" aria-label="Close" [routerLink]="['/dashboard/add-receipts']">
          <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add receipt' : (curentLang === 'ar' ? ' اضافة ايصال ' : '  הוספת קבלה')}}
      </button>

     
      

        <!-- <button class="btn mx-1 btn-outline-warning"   >
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button> -->

         </div>
     
      </div>

      <div class="container-fluid basic_table" >
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group search px-1">
            <label for="seaarch" class="ml-2" style="color: grey;">
              {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
            </label>
            <div class="input-group">
              <input class="form-control w-100" type="text" name="search"
                     [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term"
                     placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"
                     />
              <!-- <div class="input-group-append m-auto">
                <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                        (click)="getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                  <i class="fa fa-search mx-1"></i>
                  {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                </button>
                <button class="btn btn-outline-secondary" type="button" (click)="term='';getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                  <i class="fa fa-times"></i>
                </button>
              </div> -->
            </div>
          </div>
        </div>
       
        <div class="col-sm-3 mt-2" >
          <label class="form-label px-1" style="color: grey;">
            {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה') }}
          </label>
          <ng-select [(ngModel)]="selectedCompany" (change)="filter($event)" class="px-1"
                     style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
            <ng-option [value]="allCompaniesOption">
              {{ curentLang === 'en' ? 'All Companies' : (curentLang === 'ar' ? 'الكل' : 'הכל') }}
            </ng-option>
            <ng-option *ngFor="let item of companyData" [value]="item">
              {{ curentLang === 'en' ? item?.company_name_en : (curentLang === 'ar' ? item?.company_name_ar : item?.company_name_he)}}
            </ng-option>
          </ng-select>
          <ng-template *ngIf="companyData.length === 0" class="px-1">
            <span style="color: red;">
              {{ curentLang === 'en' ? 'There are no invoices for this company yet' : (curentLang === 'ar' ? "لا توجد فواتير لهذه الشركة حتى الآن" : " עדין לא הופקו קבלות        ")}}
            </span>
          </ng-template>
        </div>
       
        <form [formGroup]="documentForm"   >

      
<div class="row d-flex justify-content-between ">
  <div class="col-md-3 mt-2 "  >
    <h6 class="form-label px-1 " style="color: grey;"> 
      {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : '  תקופה  ')}}
    </h6>
    <div class="px-1">
      <select class="form-control  px-1  form-select" style="cursor: pointer;" (change)="filterMonth($event)" (click)="confirmAction()">
        <option [value]="1">{{ curentLang === 'en' ? 'Current month' : (curentLang === 'ar' ? 'الشهر الحالي' : '  חודש נוכחי') }}</option>
        <option [value]="2">{{ curentLang === 'en' ? 'Last month' : (curentLang === 'ar' ? 'الشهر الماضي' : '  חודש קודם') }}</option>
        <option [value]="5">{{ curentLang === 'en' ? 'Last two month' : (curentLang === 'ar' ? ' أخر شهران ' : '  שני חודשים קודמים') }}</option>
        <option [value]="3">{{ curentLang === 'en' ? 'Start of the year' : (curentLang === 'ar' ? 'من بداية العام' : 'מתחילת שנה  ') }}</option>
        <option [value]="4">{{ curentLang === 'en' ? 'Last year' : (curentLang === 'ar' ? 'العام الماضي' : '  שנה קודמת') }}</option>
      </select>
    </div>
 
  </div>

  <div class="col-md-6 ">
    <div class="row d-flex justify-content-between">

    
      <div class="col-md-6 mt-2">
      <h6 for="fromDate" class="ml-2" style="color: grey;">
        {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : 'מתאריך')}}
      </h6>
      <div  >
        <p-calendar [(ngModel)]="fromDate" style="display: block; width: 100%;"
         showIcon="true" (ngModelChange)="onFromDateChange($event)"
            formControlName="startDate" 
            dateFormat="dd/mm/yy"
             placeholder="DD/MM/YYYY"
              >
            </p-calendar>

      </div>
    </div>

      <div class="col-md-6 " >
        <h6 for="toDate"  style="color: grey;">
         {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : ' עד תאריך  ')}}
       </h6>
       <div class="">
       <p-calendar 
       style="display: block; width: 100%;"
         [(ngModel)]="toDate" 
         showIcon="true" 
         (ngModelChange)="onToDateChange($event)" 
         formControlName="endDate" 
         dateFormat="dd/mm/yy" 
         placeholder="DD/MM/YYYY">
       </p-calendar>
      </div>
   </div>

    </div>
  </div>
  
 
  
   
  
  <div class="col-md-3 mt-4 " style="padding-top: 6px;">
    <div class="form-group search  ">
      <button class="btn btn-sm mx-1  mt-2 " style="background-color: #141414; color: #fff;" 
      [disabled]="!fromDate || !toDate"
      (click)="getInvoicesFilters(fromDate, toDate)">
        <i class="fa fa-refresh mx-1"></i>
        {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
      </button>
    </div>
  </div>
</div>
   
   
  
    </form>
  
    </div>
      </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12  ">


          <div class="table-responsive">
            <table class="table table-light d-none d-lg-table text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col>">{{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : 'ספר קבלה')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}} </th>
                   <th scope="col">  {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}</th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Cash Amount' : (curentLang === 'ar' ? 'مبلغ نقدي' : '   סכום מזומן  ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Checks Amount' : (curentLang === 'ar' ? 'مبلغ الشيكات' : '     סכום שקים ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Deduction From Source ' : (curentLang === 'ar' ? 'الخصم من المصدر' : '       ניכוי במקור  ')}}</th>
                   <th scope="col>">{{ curentLang === 'en' ? 'Final Total' : (curentLang === 'ar' ? 'المجموع النهائي' : '  סה"כ ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'PDF' : (curentLang === 'ar' ? '   PDF' : '  PDF   ')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th> -->

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of invoicesDataFilters | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total}; let i = index"
                [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }"
                (click)="getInvoiceId(item.invoice_uuid);executeFunctions(item.invoice_uuid , content4)" >
                  <td scope="row">{{i+1}}</td>
                  <td>{{item.document_number}}</td>

                  <td>{{item?.company?.company_name_en}} </td>
                   <td>{{item?.customer_name}}</td>
                   <td>{{ item?.invoice_date | date: 'dd/MM/yyyy' }}</td>
                   <td>{{item?.cash_amount}}</td>
                   <td>{{item?.checks_amount}}</td>
                   <td>{{item?.deduction_from_source}}</td>
                   <td>{{item?.total_amount}}</td>


                   <td>
                    <a   rel="noopener noreferrer">
                        <i class="fa fa-file-pdf-o" 
                        style="font-size: 15px; cursor: pointer; color: #213FA6;"></i>
                    </a>
                </td>
                


                  <!-- <td>
                    <button class=" btn" style="border: none;" (click)="executeFunctionsView(  item?.invoice_id,viewData ) ">
                        <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                    </button>
                  </td> 

                  <td >
                  <button  class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.invoice_id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td>

                   <td >
                    <button 
                    [ngStyle]="{ 'pointer-events': item.status == 1 ? 'none' : 'auto', 'color': item.status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.invoice_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
            
                     </td> -->

                </tr>
          
              </tbody>
            
            </table>


<!-- Receipt Info on phone -->
 

  <div class="container-fluid basic_table d-lg-none my-2" >

    


    <div class="row">
<div class="col-sm-12">
   
    <div class="card"  id="headingTwo"
     *ngFor="let item of invoicesDataFilters  | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ; let i = index ">
    
            <p   
            
            (click)="toggleCollapse(i)" 
            [attr.aria-expanded]="openedIndex === i" 
            aria-controls="collapse"
            style="cursor: pointer; background-color: #FB4438;" 
            class="py-1">
              <i class="fa fa-file mx-1"  style="color: #fff; font-size: 16px;" > </i>
              <span style="color: #fff; font-size: 16px;" class="mx-2">
                {{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : '  ספר קבלה')}} :
              </span>
              <span style="color: #fff; font-size: 16px;" class="mx-2">{{item.document_number}}</span>
            </p>
      
             
            <div class="row d-flex justify-content-between">

              <div class="col-md-12  col-sm-12 ">
                <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">

               
                <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}}
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>
                    <span class="col-sm-4   text-end ">
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item?.company?.company_name_en}}</span>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >
                 <!---->
      
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}
                  </span>

                  <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>

                  <span class="col-sm-4   text-end ">
                    <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item?.customer_name}}</span>
                  </span>
                  
                     
     
                 </div>
                 <hr style="border: solid 1px #141414;" >

                 <!---->
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                    {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}
                  </span>
                  
                  <div class="text-center col-sm-4">  </div>

                    <span style="color: #141414;   " class="col-sm-4   text-end "  >
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item?.invoice_date | date: 'dd/MM/yyyy' }}</span>
                  </span>

                
                 </div>
                 <hr style="border: solid 1px #141414;" >

                   <!---->

                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Cash Amount' : (curentLang === 'ar' ? 'مبلغ نقدي' : 'סכום מזומן')}} 

                    </span>
                    
                    <div class="text-center col-sm-4">  </div>
  
                      <span style="color: #141414;   " class="col-sm-4   text-end "  >
                        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{item?.cash_amount}}</span>
                    </span>
  
                  
                   </div>
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Checks Amount' : (curentLang === 'ar' ? 'مبلغ الشيكات' : 'סכום שקים ')}} 
  
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
    
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >  {{item?.checks_amount}}</span>
                      </span>
    
                    
                     </div>
         
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Deduction From Source ' : (curentLang === 'ar' ? 'الخصم من المصدر' : 'ניכוי במקור')}}
                
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
                
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item?.deduction_from_source}}</span>
                      </span>
                
                    
                     </div>
                 
 
                 <hr style="border: solid 1px #141414;" >


                    <!---->

    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
        {{ curentLang === 'en' ? 'Final Total' : (curentLang === 'ar' ? 'المجموع النهائي' : 'סה"כ')}}

      </span>
      
      <div class="text-center col-sm-4">  </div>

        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >   {{item?.total_amount}}</span>
      </span>

    
     </div>

                 
    
   <hr style="border: solid 1px #141414;" >

       <!---->

       <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? 'PDF' : (curentLang === 'ar' ? '   PDF' : '  PDF   ')}}
  
        </span>
        
        <div class="text-center col-sm-4">  </div>
  
          <span style="color: #141414;   " class="col-sm-4   text-end "  >

            <span style="color: #141414;   " class="text-end col-sm-4  " >
              <span   rel="noopener noreferrer" class="px-5">
                <i class="fa fa-file-pdf-o" (click)="executeFunctions(item.invoice_uuid , content4)" style="cursor: pointer;"
                [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }"
                style="color: grey; font-size: 15px; cursor: pointer; color: #213FA6;"></i>
            </span>
          </span>
        </span>
  
      
       </div>

                 
       
     <hr style="border: solid 1px #141414;" >

                </div>
              </div>

            </div>
            <hr style="color: #141414; border: solid 1px;">
    </div>

 



  </div>

    </div>
</div>
 



            <div *ngIf="invoicesDataFilters?.length == 0" class="alert text-danger mt-3">
              <span> {{ curentLang === 'en' ? 'There are no receipts for this company yet... ' : (curentLang === 'ar' ? ' لا توجد ايصالات لهذة الشركة حتي الأن ... ' : 'אין עדיין קבלות עבור חברה זו... ')}}</span>
          </div>
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
              <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">

            </div>
          </div>
          <div class=" d-flex justify-content-between"> 
            <div></div>

           <div *ngIf="user_type == 100 || user_type == 101">
            <a routerLink="/dashboard/merchant-info">
                <button class="btn btn-danger m-2 " *ngIf="!isInsideModal">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

           <div *ngIf="user_type == 200 || user_type == 201">
            <a routerLink="/dashboard/users">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          </div>

        </div>
      </div>

    </div>
  </div>

    </div>
</div>


<!--add Receipt  -->



<!--update Receipt  -->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Update receipt' : (curentLang === 'ar' ? ' تعديل الايصال ' : '  שנה את הקבלה')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateReceipt">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}} 
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="from" 
                                  />
                              </div>
                            <!---->
                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="to"
                                   />
                                </div>
                                  <!---->
                                <div class="mb-3  col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}
                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="date" 
                                     formControlName="date" 
                                     maxlength="80"
                                    />
                                     
                                  </div>
                           
                                  <!---->
                                  <div class="mb-3  col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : '   ספר קבלה')}}
                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="receipt_number" 
                                     maxlength="50"
                                    />
                                     
                                  </div>

                                   <!---->
                                   <div class="mb-3  col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Amount' : (curentLang === 'ar' ? 'الكمية' : '     כמות  ')}}                                   </label>
                                    <input style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="amount" 
                                     maxlength="50"
                                    />
                                     
                                  </div>
                                         <!---->

                                <div class="mb-3  col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Content' : (curentLang === 'ar' ? ' المحتوي ' : ' תוֹכֶן  ')}}
                                   </label>
                                 
                                    <textarea
                                    style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="content" 
                                    name="" id="" cols="30" rows="2">
  
                                    </textarea>
                                     
                                  </div>
                             
                                      <!---->
                                <div class="mb-3  col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? ' ملاحظة ' : ' הערות  ')}}
                                   </label>
                                 
                                    <textarea
                                    style="color: grey;" class="form-control"
                                     type="text" 
                                     formControlName="note" 
                                    name="" id="" cols="30" rows="2">
  
                                    </textarea>
                                     
                                  </div>

                          
                        </div>
                  
                          <div class="modal-footer">

                            <button type="button" 
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updateReceiptById()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form> 
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--view Receipt  -->

<ng-template #viewData let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : '     הצג מידע      ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
                    <form  [formGroup]="updateReceipt">
                      <div class="row">
                          <div class="mb-3 col-xl-6 col-md-6 ">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'From' : (curentLang === 'ar' ? ' من ' : ' מ')}} 
                             </label>
                              <input style="color: grey;" 
                               class="form-control" maxlength="80" 
                               type="text" formControlName="from" 
                                />
                            </div>
                          <!---->
                            <div class="mb-3  col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'To' : (curentLang === 'ar' ? '  الي  ' : ' ל ')}}
                               </label>
                                <input style="color: grey;" 
                                class="form-control"
                                 type="text"
                                 maxlength="80"
                                  formControlName="to"
                                 />
                              </div>
                                <!---->
                              <div class="mb-3  col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ ' : ' תאריך  ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="date" 
                                   formControlName="date" 
                                   maxlength="80"
                                  />
                                   
                                </div>
                         
                                <!---->
                                <div class="mb-3  col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? 'رقم الايصال' : 'ספר קבלה  ')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="receipt_number" 
                                   maxlength="50"
                                  />
                                   
                                </div>

                                 <!---->
                                 <div class="mb-3  col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Amount' : (curentLang === 'ar' ? 'الكمية' : '     כמות  ')}}                                   </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="amount" 
                                   maxlength="50"
                                  />
                                   
                                </div>
                                       <!---->

                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Content' : (curentLang === 'ar' ? ' المحتوي ' : ' תוֹכֶן  ')}}
                                 </label>
                               
                                  <textarea
                                  style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="content" 
                                  name="" id="" cols="30" rows="2">

                                  </textarea>
                                   
                                </div>
                           
                                    <!---->
                              <div class="mb-3  col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? ' ملاحظة ' : ' הערות  ')}}
                                 </label>
                               
                                  <textarea
                                  style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="note" 
                                  name="" id="" cols="30" rows="2">

                                  </textarea>
                                   
                                </div>

                        
                      </div>
                
                     
                    </form> 
            
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>


  <!--Delete Receipt-->

  <ng-template #dataDeleted let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : 'האם אתה בטוח שברצונך למחוק אותו ?') }}
        </p>
    </div>
    <div class="modal-footer">

      <button type="button"
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="deleteReceiptById()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>
</ng-template>




<ng-template #content4 let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
      {{curentLangDocument === 'en' ? 'Receipt Details' : (curentLangDocument === 'ar' ? 'تفاصيل الايصال ' : ' פרטי קבלה  ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <button type="button" (click)="downloadDocumentPdf();modal.close('Close click')" class="btn btn-sm my-3"
    style=" background-color: #141414; color: #fff;">
    <i class="fa fa-file-pdf-o mx-1"></i>
     {{ curentLangDocument === 'en' ? 'Export PDF' : (curentLangDocument === 'ar' ? 'تصدير PDF' : ' יְצוּא PDF')}}
</button>
<div class="container" [hidden]="receiptInfoData?.confirm == 0" >
    <div class="row m-auto">
        <div class="col-sm-12 ">

            <div class="page m-auto  invoice-content"   id="printDiv">

                <div class="card-2" style="background-color: #fff; box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.6);">

                  <div class="row d-flex justify-content-between  ">
           
                    <div class="col-md-6  pt-2">
                        <div class="mb-3">
                            <!-- <span style="font-weight: 500;"> {{ curentLangDocument === 'en' ? '  Name' : (curentLangDocument === 'ar' ? 'الاسم    ' : '   השם  ')}}</span>  : -->
                            <span style="font-weight: 500;" class="m-2" > {{receiptInfoData.customer_name}}</span>
                        </div>
        
                        <div>
                            <!-- <span style="font-weight: 500;">  {{ curentLangDocument === 'en' ? '  Address' : (curentLangDocument === 'ar' ? 'العنوان ' : ' הכתובת')}}</span> : -->
                            <span style="font-weight: 500;"class="m-2" > {{receiptInfoData.customer_address}}</span> 
                        </div>
        
                        <div>
                            <!-- {{ curentLangDocument === 'en' ? 'ID' : (curentLangDocument === 'ar' ? 'رقم الهوية  ' : 'תְעוּדַת זֶהוּת')}}: -->
                            <span  style="font-weight: 500" class="m-2">  {{receiptInfoData.customer_vat_number}}</span>
                            </div>
        
                        <div>
                            <!-- {{ curentLangDocument === 'en' ? 'ID' : (curentLangDocument === 'ar' ? 'رقم الهوية  ' : 'תְעוּדַת זֶהוּת')}}: -->
                            <span  style="font-weight: 500" class="m-2">  {{receiptInfoData.id}}</span>
                            </div>
        
        
                      
                            
        
        
                    </div>
        
                    <div class="col-md-6 pt-2 ">
                             <div class="invoice-number text-end ">
                                <span class="inv-title-1" style="font-weight: 500;" class="m-2">
                                    <!-- {{ curentLangDocument === 'en' ? 'Company Name:' : (curentLangDocument === 'ar' ? '  أسم الشركة:  ' : '    שם החברה:  ')}} -->
                                    {{receiptInfoData.company_name}} 
                                    
                                </span>
                                <div class="invo-addr-1">
                                    <div class="mt-2" style="font-weight: 500;" class="m-2">
                                        <!-- {{ curentLangDocument === 'en' ? 'Company City:' : (curentLangDocument === 'ar' ? ' مدينة الشركة:' : 'עיר חברה :')}} -->
                                        {{receiptInfoData.company_address_city}}
                                        
                                    </div>
                                    <div class="mt-2" style="font-weight: 500;" class="m-2">
                                        <!-- {{ curentLangDocument === 'en' ? 'Company Street Name:' : (curentLangDocument === 'ar' ? '  اسم شارع الشركة:' : 'שם רחוב של החברה:')}} -->
        
                                        {{receiptInfoData.company_address_str}}
        
                                    </div>
                                    <div class="mt-2" style="font-weight: 500;" class="m-2">
                                        <!-- {{ curentLangDocument === 'en' ? 'Company Building Number:' : (curentLangDocument === 'ar' ? '  رقم مبني الشركة:' : 'מספר בניין החברה: ')}} -->
                                        {{receiptInfoData.company_Building_number}}
                                    </div>
                                    <div class="mt-2" style="font-weight: 500;" class="m-2">
                                        <!-- {{ curentLangDocument === 'en' ? '    Post number: ' : (curentLangDocument === 'ar' ? ' الرقم البريدي:  ' : ' מיקוד דואר:')}} -->
                                        {{receiptInfoData.company_address_zip_code}}
                                        
                                    </div>
        
        
                                 
                                 </div>
                            </div>
                            
                         
                    </div>
        
        
                    <div class="m-auto text-center" style="font-weight: 500; font-size: 28px;">
                        <strong>{{ curentLangDocument === 'en' ? 'Receipt' : (curentLangDocument === 'ar' ? 'ايصال  ' : 'קַבָּלָה')}} {{receiptInfoData.receipt_number}}</strong>
                    </div>
         
        
                    <div class="row  ">
                        <div class="col-sm-6 mb-30">
                           
                                
                                <div>
                                    <span style="font-weight: 500;" class="mt-3 mx-2  text-center">{{receiptInfoData.copy_version}}</span>
                                </div>
                       
        
                        </div>
                        <div class="invoice text-end  col-sm-6">
                            <div >
                                <span style="font-weight: 500;"  >               
                                 <!-- {{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? 'تاريخ  ' : 'תַאֲרִיך')}}: -->
                                  {{receiptInfoData.date | date: 'yyyy-MM-dd'}}
                                </span>
                            </div>                                                     
                            <!-- <div style="font-weight: 500;" class="mx-5">{{ curentLangDocument === 'en' ? 'Account Key' : (curentLangDocument === 'ar' ? ' مفتاح الحساب ' : 'מפתח חשבון')}} : {{receiptInfoData.account_key}}</div>                                               -->
                            <!-- <div style="font-weight: 500;" class="mx-5">{{ curentLangDocument === 'en' ? 'ID' : (curentLangDocument === 'ar' ? 'رقم الهوية  ' : 'תְעוּדַת זֶהוּת')}}:  {{receiptInfoData.id}}</div> -->
                            <!-- <div style="font-weight: 500;" class="mx-5">{{ curentLangDocument === 'en' ? 'Currency' : (curentLangDocument === 'ar' ? 'العملة  ' : 'מַטְבֵּעַ')}}: {{receiptInfoData.currency}}</div>    -->
                           
                        </div>
                    </div>
        
        
        
                    <div class="order-summary  ">
        
                        <div class="col-lg-12 " >
                            <ng-container  >
                                <div class="mt-4 d-flex justify-content-between">
                                    <span style="font-weight: bold; " class="mx-2">{{ curentLangDocument === 'en' ? 'Payment Info' : (curentLangDocument === 'ar' ? 'بيانات الدفع  ' : ' נתוני תשלום')}} </span>
                                    <!-- <span style="font-weight: 500;" class="mx-3">{{ receiptInfoData.checks_amount | number:'1.0-0' }}</span> -->
                                </div>  
                        
                                <div class="table-responsive ">
                                    <table class="table invoice-table">
                                        <thead>
                                        
                                            <tr>
                                                <th
                                                    class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Payment Method ' : (curentLangDocument === 'ar' ? 'طرق الدفع  ' : 'אמצעי תשלום')}}
                                                </th>
                                                <!-- <th
                                                    class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Ref' : (curentLangDocument === 'ar' ? 'مرجع  ' : 'רפ')}}</th> -->
                                                <!-- <th
                                                    class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك  ' : 'בַּנק')}}
                                                </th> -->
                                                <!-- <th
                                                    class="text-center" style="font-weight: bold;" > {{ curentLangDocument === 'en' ? 'Branch' : (curentLangDocument === 'ar' ? 'فرع  ' : 'סניף')}}
                                                </th>
                                                
                                                <th
                                                    class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Account' : (curentLangDocument === 'ar' ? 'حساب  ' : 'חֶשְׁבּוֹן')}}
                                                </th> -->
        
                                                <th
                                                    class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Details' : (curentLangDocument === 'ar' ? 'تفاصيل  ' : 'פרטים')}}</th>
                                                    <th
                                                    class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' تاريخ  ' : ' תאריך')}}</th>
                                                <th class="text-center" style="font-weight: bold;" >{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع  ' : 'סכום')}}</th>
        
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor=" let item of receiptInfoData?.receiptsDetails">
                                                <td class="text-center" style="font-weight: 400; color: black;">
                                                  <span *ngIf=" item?.payment_type == 1"> {{ curentLangDocument === 'en' ? 'Cash' : (curentLangDocument === 'ar' ? 'كاش' : 'מְזוּמָנִים')}}</span>
                                                  <span *ngIf=" item?.payment_type == 2"> {{ curentLangDocument === 'en' ? 'Check' : (curentLangDocument === 'ar' ? 'شيك' : 'לִבדוֹק')}}</span>
                                                  <span *ngIf=" item?.payment_type == 3"> {{ curentLangDocument === 'en' ? 'Bank' : (curentLangDocument === 'ar' ? 'بنك' : 'בַּנק')}}</span>
                                                  <span *ngIf=" item?.payment_type == 4"> {{ curentLangDocument === 'en' ? 'Credit' : (curentLangDocument === 'ar' ? 'ائتمان' : 'אַשׁרַאי')}}</span>
                                                </td>
                                                <!-- <td class="text-center">{{ item.bank }}</td>
                                                <td class="text-center">{{ item.branch }}</td>
                                                <td class="text-center">{{ item.account }}</td>
                                                 -->
        
                                                <td class="text-center description-column" style="font-weight: 400; color: black;">{{ item.details }}</td>
                                                <td class="text-center" style="font-weight: 400; color: black;">{{ item.date | date: 'yyyy-MM-dd' }}</td>
                                                <td class="text-center" style="font-weight: 400; color: black;">{{ item.amount | number:'1.2-2'}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-container>
                        </div>
        
        
                      
        
                        <div class="small-table my-4">
                            <div class="row">
                                <div class="col-md-12 d-flex justify-content-between" >

                                  
                                    <div class="col-md-6 mt-5">
                                      <!-- <span>Weighted date: 05/05/24</span> -->
                                     </div>
                                     <div class="col-md-6">
                                        <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                                            <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                                <span class="py-1"> {{ curentLangDocument === 'en' ? 'Deduction at source' : (curentLangDocument === 'ar' ? 'الخصم من المصدر  ' : 'ניכוי במקור  ')}} :  </span>
                                            </div>
        
                                            <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                                <span class="mx-1">{{receiptInfoData.deduction_from_source | number:'1.0-0'}}</span>
                                            </div>
        
                                            <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                                <span>{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}  :  </span>
                                            </div>
        
                                            <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                                <span class="mx-1">{{ receiptInfoData.total_amount | number:'1.2-2' }}</span>
        
                                            </div>
        
        
                                        </div>
                                        
                                     </div>

                                  
        
                                     <div class="col-md-6">
        
                                     </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                        
                        
                    </div>
        
        
        
                  </div>
                    </div>
                </div>
            </div>


            
            <div class="modal-footer my-2">
              <button type="button" class="btn btn-sm m-auto mt-2"   hidden
                      style="background-color: #141414; color: #fff;" 
                       > 
                   <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
              </button>
              <button type="button" class="btn btn-sm mt-2 btn-danger" 
                      (click)="modal.close('Close click')">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                  <i class="fa fa-rotate-left mx-1"></i>
              </button>
          </div>

 
        </div>

        

    </div>




    
    </div>
  </div>
</ng-template>
