<app-breadcrumb [title]="curentLang === 'en' ? 'Upload Allocation Numbers' : (curentLang === 'ar' ? 'رفع أرقام التخصيص' : 'העלאת מספרי הקצאה')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Upload Allocation Numbers' : (curentLang === 'ar' ? 'رفع أرقام التخصيص' : 'העלאת מספרי הקצאה')">
</app-breadcrumb>

<div class="container">
  <h2>Upload Excel File</h2>
  <form (ngSubmit)="onSubmit()" #uploadForm="ngForm">
    <div class="form-group">
      <label for="file">Select Excel File:</label>
      <input type="file" id="file" (change)="onFileChange($event)" class="form-control" required />
    </div>
    <div class="d-flex justify-content-between mt-3">
      <button type="submit" class="btn btn-dark" [disabled]="!file">Upload</button>
      <button style="background-color: red; color: #fff;" class="btn" routerLink="/dashboard/upload-permanent-file">Back</button>
    </div>
  </form>

  <div *ngIf="companyInfo" class="mt-3">
    <h3>Company Information:</h3>
    <table class="table">
      <tbody>
        <tr>
          <td>Company Name</td>
          <td>{{ companyInfo.company_name_he }}</td>
        </tr>
        <tr>
          <td>Company Tax Number</td>
          <td>{{ companyInfo.tax_register }}</td>
        </tr>
        <tr>
          <td>Merchant Name</td>
          <td>{{ companyInfo.merchant.merchant_name_he }}</td>
        </tr>
        <tr>
          <td>Successful</td>
          <td>{{ successCount }}</td>
        </tr>
        <tr>
          <td>Failed</td>
          <td>{{ failureCount }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="responseMessages" class="mt-3">
    <!-- <h3>Response Summary:</h3>
    <p>Successful: {{ successCount }}, Failed: {{ failureCount }}</p> -->
    <table class="table">
      <thead>
        <tr>
          <th>Status</th>
          <th>Message</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let message of responseMessages; let i = index">
          <td [ngClass]="{'text-success': message.status === 'success', 'text-danger': message.status === 'failure'}">
            {{ message.status }}
          </td>
          <td>
            {{ message.message }}
          </td>
          <td *ngIf="message.status === 'success'">
            <button *ngIf="!message.reverted" class="btn btn-danger" (click)="revertInvoice(message.invoice_uuid, i)">تراجع</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
