import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DocumentPdfComponent } from "../../Accountant/document-pdf/document-pdf.component";
import Swal from "sweetalert2";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"]
})

export class DocumentsComponent implements OnInit {
  
  curentLang: string = "";
  layoutVersion: any;
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));

  term: any;
  p: any;
  companyDocumentsData: any = [];
  reportsIncomeForm: FormGroup;
  addCompanyByAdmin: FormGroup;
  id: any;
  reportsIncomeData: any = [];
  merchantInfoCompanyData: any = [];
  vatInfoData: any = [];
  currentPage: number = 1;
  limit: number = 400;
  offset: number = 0;
  total: number = 0;
  rowId: number;
  routeId: any;
  companyUUid: any;
  uuid: any;
  fromDate: string | null = null;
  toDate: string | null = null;
  invoicesDataFilters: any = [];
  DocumentsDataFilters: any = [];
  selectedCompany: any;
  customLimit: number = 400;

  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
  ) {
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
    this.uuid = this.activatedRoute.snapshot.params['id'];
    this.companyUUid = this.uuid;

    this.reportsIncomeForm = new FormGroup({
      companyId: new FormControl(this.id),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
    });
  }

  getCompanyIdByUuid(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.service.getCompanyIdByUuid(this.uuid).subscribe({
        next: res => {
          this.id = res.company_id;
          resolve();
        },
        error: error => {
          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? 'فشل في الحصول على معلومات الشركة' : (this.curentLang === 'en' ? 'Failed to get company information' : 'נכשל בהשגת מידע על החברה')
          });
          reject(error);
        }
      });
    });
  };

  getAllCompanyDocumentsFilters(fromDate: string, toDate: string, limit: number, offset: number, searchKeyword: string) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
  
    // إذا كانت الكلمة المبحوثة فارغة، استخدم البيانات المحفوظة محليًا
    // if (!searchKeyword) {
    //   this.companyDocumentsData = this.companyDocumentsData.slice(offset, offset + limit);
    //   return;
    // }
  
    // إذا كان هناك كلمة بحث، استدعاء دالة الفلتر
    // this.spinner.show(); // افتح التحميل حسب الحاجة
  
    this.service.getAllCompanyDocumentsComponent(this.id, limit, offset, fromDate, toDate, searchKeyword).subscribe({
      next: (res: any) => {
        this.companyDocumentsData = res.data;
        
        this.total = res.total;
        this.spinner.hide();
      },
      error: (err: any) => {
        this.spinner.hide();
      }
    });
  };
  

  getAllCompanyDocumentsComponent(fromDate?: string, toDate?: string, limit?: number, offset?: number , searchKeyword ?: string) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getAllCompanyDocumentsComponent(this.id, limit, offset, fromDate, toDate , searchKeyword).subscribe({
      next: res => {
        this.companyDocumentsData = res.data;
        this.total = res.total;
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  // getAllCompanyDocumentsToPrint(fromDate?: string, toDate?: string) {
  //   this.spinner.show();
  //   this.service.printAllInvoiceByCompanyId(this.id, fromDate, toDate).subscribe({
  //     next: res => {
  //       this.companyDocumentsData = res;
  //       if(this.user_type === 301){

  //       }
  //       const documentPdfComponent = new DocumentPdfComponent(this.spinner, this.service, this.activatedRoute, this.http);
  //       documentPdfComponent.generateAndDownloadAllInvoicesZip(this.companyDocumentsData);
  //       this.spinner.hide();
  //     },
  //     error: () => {
  //       this.spinner.hide();
  //     }
  //   });
  // }

  getAllCompanyDocumentsToPrint(fromDate?: string, toDate?: string) {
    this.spinner.show();
    this.service.printAllInvoiceByCompanyId(this.id, fromDate, toDate).subscribe({
      next: res => {
        this.companyDocumentsData = res;

       
        
        if (this.user_type === 301) {
          this.companyDocumentsData = this.companyDocumentsData.filter((document: any) => document.invoice_type !== 400);
        }
        
        const documentPdfComponent = new DocumentPdfComponent(this.spinner, this.service, this.activatedRoute, this.http);
        documentPdfComponent.generateAndDownloadAllInvoicesZip(this.companyDocumentsData);
       
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }
  

  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllCompanyDocumentsComponent(this.fromDate, this.toDate, this.limit, this.offset , this.term);
  }

  loadData() {
    this.getAllCompanyDocumentsComponent(this.fromDate, this.toDate);
  }

  onLimitChange() {
    this.limit = this.customLimit;
    this.offset = (this.currentPage - 1) * this.limit;
    this.getAllCompanyDocumentsComponent(this.fromDate, this.toDate, this.limit, this.offset);
  }

  getCompanyId(id: number) {
    this.routeId = id;
    localStorage.setItem('routeId', this.routeId);
  }

  getInvoiceTypeDescription(invoiceType: number): string {
    switch (invoiceType) {
      case 305:
        return this.curentLang === 'ar' ? 'فاتورة ضريبية' : (this.curentLang === 'en' ? 'Tax Invoice' : 'חשבונית מס');
      case 320:
        return this.curentLang === 'ar' ? 'فاتورة وسند قبض' : (this.curentLang === 'en' ? 'Invoice And Receipt' : 'חשבונית וקבלה');
      case 332:
        return this.curentLang === 'ar' ? 'مطالبة مالية' : (this.curentLang === 'en' ? 'Financial Request' : 'בקשה פינאלית');
      case 330:
        return this.curentLang === 'ar' ? 'فاتورة عكسية' : (this.curentLang === 'en' ? 'Invoice/Transaction Invoice' : 'חשבונית/חשבונית עסקה');
      case 400:
        return this.curentLang === 'ar' ? 'سند قبض' : (this.curentLang === 'en' ? 'Receipt' : 'קבלה');
      default:
        return this.curentLang === 'ar' ? 'نوع غير معروف' : (this.curentLang === 'en' ? 'Unknown Type' : 'סוג לא ידוע');
    }
  }

  openXl(content: any) {
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  ngOnInit() {
     this.layoutVersion = localStorage.getItem("layout_version");
    if (this.layoutVersion == null || this.layoutVersion == undefined) {
      localStorage.setItem("layout_version", "light-only");
      this.layoutVersion = "light-only";
    } else {
      this.layoutVersion = localStorage.getItem("layout_version");
      if (this.layoutVersion === "dark-only") {
        document.body.classList.add("dark-only");
      } else {
        document.body.classList.add("light-only");
      }
    }

    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));

    const currentDate = new Date();
    this.toDate = this.formatDate(currentDate);

    const oneMonthAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    this.fromDate = this.formatDate(oneMonthAgo);

    this.getCompanyIdByUuid().then(() => {
      this.getAllCompanyDocumentsComponent(this.fromDate, this.toDate);
    }).catch(error => {
    });
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  // Function to print all invoices
  async downloadAllInvoicesZip() {
    this.getAllCompanyDocumentsToPrint(this.fromDate, this.toDate);
  }
}
