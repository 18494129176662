import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup } from "@angular/forms";
import Swal from 'sweetalert2';
import { AddVatEndPoient } from "../service/global.service";

@Component({
  selector: "app-vat-list",
  templateUrl: "./vat-list.component.html",
  styleUrls: ["./vat-list.component.scss"]
})

export class VatListComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addVat:FormGroup;
  updateVat:FormGroup;
  vatInfoData:any=[];
  vatId: number;
  updatedData: any;
  viewData: any;
  public validate = false;
  public validateUpdate = false;
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));


   this.addVat=new FormGroup({
    vat_name_ar :new FormControl(''),
    vat_name_en:new FormControl(''),
    vat_name_he :new FormControl(''),  
     created_by:new FormControl(this.userRegistId),
    });

    this.updateVat=new FormGroup({
      vat_name_ar :new FormControl(''),
      vat_name_en:new FormControl(''),
      vat_name_he :new FormControl(''),  
      updated_by:new FormControl(this.userRegistId),
      });
  };

  getAllVatList() {
    this.spinner.show();
    this.service.getAllVatList().subscribe({
      next: res => {
        this.vatInfoData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  addedData() {

    const vat_name_ar = this.addVat.value.vat_name_ar.trim();
    const vat_name_en = this.addVat.value.vat_name_en.trim();
    const vat_name_he = this.addVat.value.vat_name_he.trim();
   
    if (
      vat_name_ar === '' ||
      vat_name_en === '' ||
      vat_name_he === '' 
     ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.addVat.markAllAsTouched();
      this.validate = !this.validate;
      return;
    }

    
      
    else
    {
      let model = {
        vat_name_ar: this.addVat.value.vat_name_ar,
        vat_name_en: this.addVat.value.vat_name_en,
        vat_name_he: this.addVat.value.vat_name_he,
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddVatEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllVatList();
          this.addVat.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }

  };
  changeStatus(id:number, status:any){
    this.spinner.show();
  
    let model =
    {
      "status": status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeVatStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllVatList();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  setValue(vatId: number) {
    for (let i = 0; i < this.vatInfoData.length; i++) {
      if (this.vatInfoData[i].vat_list_id == this.vatId) {
        const selectedCategory = this.vatInfoData.find(item => item.vat_list_id === vatId);

        this.updateVat.controls.vat_name_en.setValue(this.vatInfoData[i].vat_name_en);
        this.updateVat.controls.vat_name_ar.setValue(this.vatInfoData[i].vat_name_ar);
        this.updateVat.controls.vat_name_he.setValue(this.vatInfoData[i].vat_name_he);
      }
    };
  };

  executeFunctions(vatPerscentgeId: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(vatPerscentgeId);
    this.setValue(vatPerscentgeId);
    this.updateMd(this.updatedData);
 
  };

  executeFunctionsView(vatPerscentgeId: number, view: any) {
    this.viewData = view;
    this.getId(vatPerscentgeId);
    this.setValue(vatPerscentgeId);
    this.updateViewMd(this.viewData);
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  updateViewMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };
  getId(id: number) {
    this.vatId = id;
  };

  updatedVatData() {

    const vat_name_ar = this.updateVat.value.vat_name_ar.trim();
    const vat_name_en = this.updateVat.value.vat_name_en.trim();
    const vat_name_he = this.updateVat.value.vat_name_he.trim();
   
    if (
      vat_name_ar === '' ||
      vat_name_en === '' ||
      vat_name_he === '' 
     ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.updateVat.markAllAsTouched();
      this.validateUpdate = !this.validateUpdate;
      return;
    }

    else
    {
      let model = {
        vat_name_ar: this.updateVat.value.vat_name_ar,
        vat_name_en: this.updateVat.value.vat_name_en,
        vat_name_he: this.updateVat.value.vat_name_he,
        updated_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.updateVatById( this.vatId, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllVatList();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
 
  };

  ngOnInit() {

    this.getAllVatList();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));

  };
}
