import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "src/app/Data/service/http-help.service";

@Component({
  selector: "app-sub-plans",
  templateUrl: "./sub-plans.component.html",
  styleUrls: ["./sub-plans.component.scss"]
})

export class SubPlansComponent implements OnInit {
  curentLang:string="";
  allActivePlan:any=[];

  constructor
  (  private spinner: NgxSpinnerService,  
     public translate:TranslateService   ,
     private service:HttpHelpService  
  ) 
  {
    this.curentLang = localStorage.getItem('curentLang');
  }

  getAllActivePlans() {
    this.spinner.show();
    this.service.getAllActivePlans().subscribe({
      next: res => {
        this.allActivePlan = res;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  ngOnInit() 
  {
    this.getAllActivePlans();
  };
};
