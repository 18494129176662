// service: merchant-validation.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AddZipEndPoient, baseUrl,  } from "../service/global.service";
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MerchantValidationService {
  private cache: { [key: number]: any } = {}; // التخزين المؤقت للنتائج
  private cacheTime: number = 5 * 60 * 1000; // فترة التخزين المؤقت (5 دقائق)

  constructor(private http: HttpClient) {}

  validateMerchant(merchantId: number): Observable<any> {
    const cachedResult = this.cache[merchantId];
    if (cachedResult && (Date.now() - cachedResult.timestamp < this.cacheTime)) {
      // استخدام النتائج المخزنة إذا كانت صالحة
       
      return of(cachedResult.data);
    } else {
      // إرسال طلب جديد إذا لم تكن النتائج المخزنة صالحة
      return this.http.get(`${baseUrl}invoice/validate-documents/${merchantId}`).pipe(
        tap(result => {
          this.cache[merchantId] = { data: result, timestamp: Date.now() };
        })
      );
    }
  }
}
