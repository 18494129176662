import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"]
})

export class AboutComponent implements OnInit {
  curentLang:string="";

   
  constructor(private spinner: NgxSpinnerService,    public translate:TranslateService ,    @Inject(DOCUMENT) private document: Document) 
  {
    this.curentLang = localStorage.getItem('curentLang');
  }


  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
  }
}
