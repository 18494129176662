<div class="page-wrapper">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">     
          <div class="login-card">
            <div>
             
              <div class="login-main"> 
                <form class="theme-form" [formGroup]="resendForm">
                
                    <h4 style=" color: #141414; text-align: center; " class="mb-3"> {{'forget.otp'|translate}} </h4>

                  
                    <label class="col-form-label pt-0 m-auto text-center" style="color: #141414;">{{'forget.enterPhone'|translate}}</label>

                  <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <div class="input-group">
                          <span style="color: #141414;" class="input-group-text">+972</span>
                          <input class="form-control" type="text" maxlength="9" required="" placeholder="{{'Register.phone'|translate}}" formControlName="phone_number">
                        </div>
                        <div *ngIf="resendForm.get('phone_number')?.errors != null && resendForm.get('phone_number')?.touched" class="alert text-danger my-1">
                          <p *ngIf="resendForm.get('phone_number')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                          <p *ngIf="resendForm.get('phone_number')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{resendForm.get('phone_number')?.errors?.invalidMobilePrefix.message}}</p>
                          <p *ngIf="resendForm.get('phone_number')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{resendForm.get('phone_number')?.errors?.invalidPhoneNumber.message}}</p>
                        </div>                      </div>
          
                    
                    </div>
                  </div>
        
                  <button (click)="submitResendForm(resendForm)"
                   [disabled]="resendForm.invalid"
                    class="btn d-block w-100 m-t-10"
                     style="background-color: #141414; color: #fff;"
                      type="submit">{{'forget.submit'|translate}}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>