import { Component, OnInit } from "@angular/core";
import { resendOtpEndPoient } from "src/app/Data/service/global.service";
import { AuthService } from "../service/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoadingBarService } from "@ngx-loading-bar/core";
const Swal = require('sweetalert2')
import { israelPhoneNumberValidator } from './../../validators/israelPhoneNumberValidator';

@Component({
  selector: "app-resendotp",
  templateUrl: "./resendotp.component.html",
  styleUrls: ["./resendotp.component.scss"]
})

export class ResendotpComponent implements OnInit {
  
  public show: boolean = false;
  error:string = '';
  userRegistId :any;
  otpCode: number;
  userLanguage:string;
  userForgetPassword:any;
  resendForm = new FormGroup({
    'phone_number': new FormControl('', [Validators.required, israelPhoneNumberValidator()]),
  });
  constructor( private authService:AuthService ,
    private spinner: NgxSpinnerService , 
    private Router:Router,
    private loader: LoadingBarService
    ) { 

  }

  submitResendForm(RegisterForm: FormGroup) {
    let customerPhone = this.resendForm.value.phone_number;
    if (customerPhone.startsWith('0')) {
      customerPhone = customerPhone.substring(1);
    }

    let model = {
       "phone_number": '+972' + customerPhone,
    };
    this.spinner.show();
  
    this.authService.resendOtp(resendOtpEndPoient.POST, model).subscribe((res)=>{
      if ( res.status === 404) {        
        if(this.userLanguage == 'en') {
          Swal.fire({
            icon: 'error',
            title: res.messageEn,
          });
        } 

        if( this.userLanguage == 'ar' ) {
          Swal.fire({
            icon: 'error',
            title: res.messageAr,
          });
        }

        if( this.userLanguage == 'he' ) {
          Swal.fire({
            icon: 'error',
            title: res.messageHe,
          });
        }
      } 
      this.spinner.hide();
      if (res.status === 200 ) {
        if(this.userLanguage == 'en') {
          Swal.fire({
            icon: 'success',
            title: res.messageEn,
          });
        } 
        if( this.userLanguage == 'ar' ) {
          Swal.fire({
            icon: 'success',
            title: res.messageAr,
          });
        }
        if( this.userLanguage == 'he' ) {
          Swal.fire({
            icon: 'success',
            title: res.messageHe,
          });
        }
       this.spinner.hide();

     this.userForgetPassword = res.userId
    
     localStorage.setItem('userForgetPassword',  this.userForgetPassword);
     
        this.Router.navigate(['/forgot-password']);
      } 
    }
    );
  };

  

  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    this.userLanguage = localStorage.getItem('curentLang');

  } 
}
