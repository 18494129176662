<div class="cart-box" (click)="toggleCart()">
  <div class="media profile-media " >
    <img class="b-r-10" [src]="profileData?.image_url" alt="" style="width: 30px; height: 30px;">
    <div class="media-body "><span>
      {{ profileData.user_name}}
    </span>
      <p class="mb-0 font-roboto">
        {{curentLang === 'en' ? profileData.lookupDetailsUserType?.lookupDetailEnName : 
        (curentLang === 'ar' ? profileData.lookupDetailsUserType?.lookupDetailArName : profileData.lookupDetailsUserType?.lookupDetailHeName)}}
        <i class="middle fa fa-angle-down"></i></p>
    </div>
  </div>
</div>


<ul class="profile-dropdown onhover-show-div" [class.active]="openCart">
  <li>
    <a (click)="openXl(content)"><app-feather-icons [icon]="'lock'"></app-feather-icons>
    <span style="font-size: 12px;"> {{curentLang === 'en' ? ' Password' : (curentLang === 'ar' ? 'كلمة المرور' : '   סיסמה')}}</span></a>
  </li>


  <li>
    <a (click)="executeFunctions( profileData?.user_id, uploadData);">
    <app-feather-icons [icon]="'camera'"></app-feather-icons>
    <span>{{ curentLang === 'en' ? 'Image' : (curentLang === 'ar' ? ' الصورة' : ' התמונה')}}</span>
    </a>
  </li>





  <li (click)="isLogOut()" routerLink="/login" class="ml-2"><app-feather-icons [icon]="'log-in'"></app-feather-icons>
    <span style="font-size: 12px;">{{curentLang === 'en' ? 'Log Out' : (curentLang === 'ar' ? 'تسجيل الخروج ' : 'התנתק')}}</span>
  </li>
</ul>




<!--update password -->

<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
              {{ curentLang === 'en' ? 'Update Password ' : (curentLang === 'ar' ? '      تعديل كلمة المرور    ' : '  שנה סיסמה   ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
                    <form  [formGroup]="updatePassword" novalidate [ngClass]="{ 'was-validated': validate }">
                      <div class="row">


                        <div class="mb-3 col-xl-12 col-md-12 ">
                          <div class="form-group">
                            <label class="col-form-label"style="color: grey;">{{'Login.currentPassword'|translate}}</label>
                            <input class="form-control" [type]="show ? 'text' : 'password'" name="Login[password]" required="" onlyAlphabets
                             formControlName="currentPassword" required="" placeholder="*********">
                          
                            
                            <div *ngIf="updatePassword.get('currentPassword')?.errors != null && updatePassword.get('currentPassword')?.touched" class=" alert text-danger my-1">
                              <p  *ngIf="updatePassword.get('currentPassword')?.errors?.required" class=" "> {{'Login.error18'|translate}}</p>
                                      <ul  *ngIf="updatePassword.get('currentPassword')?.errors?.pattern" class="text-start"> 
                                          <li>{{'Login.error19'|translate}}</li>
                                          <li>{{'Login.error20'|translate}}</li>
                                          <li>{{'Login.error21'|translate}}</li>
                                      </ul>
                    </div>
                             <!-- <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                            <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>  -->
                          </div>
                          </div>



                          <div class="mb-3 col-xl-12 col-md-12 ">
                            <div class="form-group">
                              <label class="col-form-label"style="color: grey;">{{'Login.password'|translate}}</label>
                              <input class="form-control" [type]="show ? 'text' : 'password'" name="Login[password]" 
                              required="" onlyAlphabets formControlName="password" required="" placeholder="*********">
                            
                              
                              <div *ngIf="updatePassword.get('password')?.errors != null && updatePassword.get('password')?.touched" class=" alert text-danger my-1">
                                <p  *ngIf="updatePassword.get('password')?.errors?.required" class=" "> {{'Login.error18'|translate}}</p>
                                        <ul  *ngIf="updatePassword.get('password')?.errors?.pattern" class="text-start"> 
                                            <li>{{'Login.error19'|translate}}</li>
                                            <li>{{'Login.error20'|translate}}</li>
                                            <li>{{'Login.error21'|translate}}</li>
                                            <li>{{ curentLang === 'en' ? 'Password does not match the criteria' : (curentLang === 'ar' ? 'كلمة المرور غير مطابقة للمعايير' : 'הסיסמה אינה תואמת את הקריטריונים') }}

                                        </ul>
                                 </div>
                                 <!-- <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show-1"></span></div>
                                 <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide-1"></span></div>  -->
                               
                            </div>
                            </div>
 
                      </div>
                
                  
      
                        <div class="modal-footer">

                          <button type="button"  
                           class="btn btn-sm m-auto mt-2" 
                          style="background-color: #141414; color: #fff;"
                           (click)="ChangeUserPasswordByUserId()"
                           > 
                            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                            <i class="fa fa-check-circle mx-1 px-1" style="color: #fff;"></i>
                        </button>
                    
                            <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                <i class="fa fa-rotate-left mx-1 px-1"></i>
                            </button>
                    
                          
                    
                        </div>
                    </form>
            
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>



  <!-- upload image-->

  <ng-template #uploadData let-modal>
    <div class="modal-header">
    <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Update Profile Image ' : (curentLang === 'ar' ? ' تحديث الصورة الشخصية ' : '    עדכן תמונה אישית   ')}} 
      </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  
    </div>
    <div class="modal-body my-2">
     
      <div class="product-image text-center">
          <img *ngIf="currentImageUrl" [src]="currentImageUrl" alt="" class="my-2">
          <input type="file" class="form-control form-control-sm"  (change)="onFileChanged($event)" [accept]="'image/*'">
        </div>
        
              
    </div>
      <div class="modal-footer">
  
          <button type="button" 
           class="btn btn-sm m-auto mt-2" 
          style="background-color: #141414; color: #fff;"
          (click)="uploadFile()"
           > 
            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
            <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
        </button>
    
            <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
            </button>
    
          
    
        </div>
    </ng-template>