<app-breadcrumb [title]="curentLang === 'en' ? 'Add Invoice and Receipt' : (curentLang === 'ar' ? ' اضافة فاتورة وايصال ' : ' הוסף חשבונית וקבלה ')"
  [items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : [' לוח הבקרה '])"
  [active_item]="curentLang === 'en' ? 'Add Invoice and Receipt' : (curentLang === 'ar' ? ' اضافة فاتورة وايصال ' : ' הוסף חשבונית וקבלה ')">
</app-breadcrumb>

<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-block row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div>
              <form [formGroup]="invoiceReceiptForm" >
                <div class="row">
                  <div class="col-lg-12 row">
                    <div class="col-md-6">
                      <div class="form-group mx-2">
                        <label class="mt-2">{{ curentLang === 'en' ? 'Select Company Name' : (curentLang === 'ar' ? 'اختر اسم الشركة' : 'בחר את שם החברה ')}}</label>
                        <span class="text-danger">*</span>
                        <ng-select formControlName="company_id" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                          <ng-option *ngFor="let item of mastercompany" [value]="item.company_id">
                            {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group mx-2">
                        <label class="mt-2">{{ curentLang === 'en' ? 'Select Customer Name' : (curentLang === 'ar' ? 'اختر اسم العميل' : 'בחר את שם הלקוח ')}}</label>
                        <span class="text-danger">*</span>
                        <ng-select formControlName="customer_id" (change)="getCompanyId($event);getCustomerById();" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                          <ng-option *ngFor="let item of mastercustomer" [value]="item.customer_id">
                            {{ curentLang === 'en' ? item.customer_name_en : (curentLang === 'ar' ? item.customer_name_ar : item.customer_name_he) }}
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 row">
                    <div class="col-md-4">
                        <div class="col-lg-12 ">
                            <div class="form-group mx-2 ">
                                <label class="mt-2"> {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? 'تاريخ الفاتورة' : 'תאריך חשבונית ')}}</label>
                                <input type="date"  (change)="filter($event); getVatPercentageByVatListIdAndDate()"
                                 formControlName="invoice_date" class="form-control w-100">
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group mx-2">
                            <label class="mt-2"> {{ curentLang === 'en' ? 'Vat Type' : (curentLang === 'ar' ? 'نوع الضريبة' : 'סוג המס') }}</label>
                            <span class="text-danger">*</span>
          

                            <ng-select formControlName="vat_list_id"   (change)="getVatPercentageByVatListIdAndDate()"style="cursor: pointer;"
                             placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                <ng-option *ngFor="let item of vatTypeList; let i = index " [value]="item?.vat_list_id">
                                    {{ curentLang === 'en' ? item.vat_name_en : (curentLang === 'ar' ? item.vat_name_ar : item.vat_name_he) }}      
                                 </ng-option>
                              </ng-select>

                        </div> 
                    </div>

                    <div class="col-md-4">
                        <div class="form-group mx-2">
                            <label class="mt-2"> {{ curentLang === 'en' ? 'Document Language' : (curentLang === 'ar' ? 'لغة المستند  ' : 'שפת המסמך')}}</label>
                            <select formControlName="document_language" class="form-control form-select">
                                <option *ngFor="let lang of availableLanguages" [ngValue]="lang.code" [selected]="lang.code === curentLang">
                                    {{ lang.name }}
                                </option>
                            </select>

                            <!-- <ng-select formControlName="document_language"  style="cursor: pointer;"
                            placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                               <ng-option *ngFor="let lang of availableLanguages; let i = index "[ngValue]="lang.code" [selected]="lang.code === curentLang">
                                {{ lang.name }}     
                                </ng-option>
                             </ng-select> -->

                        </div>
                        </div>
                        
   
                </div>
                </div>

                <br>

                <div class="row">
                  <div class="col-lg-12">
                    <div class="table-responsive">
                      <table class="table table-bordered" formArrayName="lines">
                        <thead>
                          <tr>
                            <th>{{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם המוצר')}}</th>
                            <th  > {{ curentLang === 'en' ? 'Product Desc' : (curentLang === 'ar' ? ' وصف المنتج' : 'תיאור מוצר')}}</th>

                            <th>{{ curentLang === 'en' ? 'Price per unit' : (curentLang === 'ar' ? 'سعر الوحدة' : 'מחיר ליחידה')}}</th>
                            <th>{{ curentLang === 'en' ? 'Quantity' : (curentLang === 'ar' ? 'الكمية' : 'כמות')}}</th>
                            <th>{{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'خصم' : 'הנחה')}}</th>
                            <th>{{ curentLang === 'en' ? 'Sub Total' : (curentLang === 'ar' ? 'المجموع الفرعي' : 'סכום כולל')}}</th>
                            <th>{{ curentLang === 'en' ? 'Vat Rate' : (curentLang === 'ar' ? 'نسبة الضريبة' : 'שיעור מס  ')}}</th>
                            <th>{{ curentLang === 'en' ? 'Vat Amount' : (curentLang === 'ar' ? 'قيمة الضريبة' : 'סכום מס  ')}}</th>
                            <th>{{ curentLang === 'en' ? 'Total Amount' : (curentLang === 'ar' ? 'المجموع' : 'סכום כולל')}}</th>
                            <th>{{ curentLang === 'en' ? 'Remove' : (curentLang === 'ar' ? 'حذف' : 'מחק')}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of invproducts.controls;let i=index" [formGroupName]="i">
                              <td style="width:20%">
                                

                                  <ng-select formControlName="product_id"  (change)="productchange(i)"  style="cursor: pointer; "
                                  placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                     <ng-option *ngFor="let item of masterproduct; let i = index " [value]="item.product_id">
                                      {{ curentLang === 'en' ? item.product_name_en : (curentLang === 'ar' ? item.product_name_ar : item.product_name_he) }}
                                  </ng-option>
                                   </ng-select>

                              </td>

                              <td style="width:20%">
 
                                <textarea
                                maxlength="250" name=""
                                 id="des_en" cols="10" rows="1" type="text"
                                 class="form-control form-control-sm"
                                 formControlName="description" >


                          </textarea>
                            </td>

                              <td style="width:10%">
                                  <input class="form-control" type="number" (change)="Itemcalculation(i)" formControlName="price_per_unit" />
                              </td>

                              <td style="width:7%">
                                  <input class="form-control" type="number" (change)="Itemcalculation(i)" formControlName="quantity" />
                              </td>

                              <td style="width:7%">
                                  <input class="form-control" type="number" (change)="Itemcalculation(i)" formControlName="discount" />
                              </td>

                            
                              <td style="width:10%">
                                  <input class="form-control" formControlName="sub_total" />
                              </td>

                              <td style="width:5%">
                                  <input class="form-control" formControlName="vat_rate" />
                              </td>

                              <td style="width:8%">
                                  <input class="form-control" formControlName="vat_amount" />
                              </td>

                              <td style="width:8%">
                                  <input class="form-control" formControlName="total_amount" />
                              </td>
                              <td style="width:5%">
                                  <i (click)="Removeproduct(i)" class="fa fa-trash"
                                  style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true">
                                 </i>
                              </td>

                          </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                            <td colspan="6">
                                <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addnewproduct()">
                                    <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                 {{ curentLang === 'en' ? 'Add Product' : (curentLang === 'ar' ? 'اضافة منتج  ' : 'הוסף מוצר')}}    </button>
                            </td>
                        </tr>
                    </tfoot>
                      </table>
                    </div>

                    <div class="row d-flex justify-content-between">
                   
                          <div class="col-md-6 col-sm-12" style="color: black;">
                            <div class="row">
                              <div class="col-sm-12">
                                <div class="d-flex flex-column">
                                  <div class="d-flex justify-content-between px-4 my-2">
                                    <div style="width: 200px;">{{ curentLang === 'en' ? 'Amount Before Discount' : (curentLang === 'ar' ? ' المبلغ قبل الخصم' : ' סכום לפני הנחה')}}</div>
                                    <input formControlName="amount_before_discount" class="form-control">
                                  </div>
                                  <div class="d-flex justify-content-between px-4 my-2">
                                    <div style="width: 200px;">{{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                    <input formControlName="all_discount" class="form-control">
                                  </div>
                                  <div class="d-flex justify-content-between px-4 my-2">
                                    <div style="width: 200px;">{{ curentLang === 'en' ? 'Payment Amount' : (curentLang === 'ar' ? ' قيمة الدفع ' : 'ערך תשלום')}}</div>
                                    <input formControlName="payment_amount" class="form-control">
                                  </div>
                                  <div class="d-flex justify-content بين px-4 my-2">
                                    <div style="width: 200px;">{{ curentLang === 'en' ? 'VAT Amount' : (curentLang === 'ar' ? ' قيمة الضريبة' : ' ערך מס ')}} ( {{VatPercentage }} %)</div>
                                    <input formControlName="all_vat_amount" class="form-control">
                                  </div>
                                  <div class="d-flex justify-content بين px-4 my-2">
                                    <div style="width: 200px;">{{ curentLang === 'en' ? 'Payment Amount Including VAT' : (curentLang === 'ar' ? 'قيمة المبلغ شاملة الضريبة' : 'הסכום כולל מס')}}</div>
                                    <input formControlName="payment_amount_including_vat" class="form-control">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6 col-sm-12" style="color: black;">
                            <div class="mt-8 px-4">
                              <div>
                                <label for="notes">{{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? 'ملاحظة' : 'הערה')}}</label>
                                <textarea formControlName="invoice_note" id="notes" name="notes" class="form-textarea form-control my-2" placeholder="{{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? 'ملاحظة' : 'הערה')}}"></textarea>
                              </div>
                            </div>
                          </div>
                      
                    </div>

                  </div>
                </div>

                <br>

            
                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                        <table class="table table-bordered" formArrayName="linesChecks">
                            <!-- Table header -->
                            <thead class="text-black">
                                <!-- Table row -->
                                <tr class="">
                                    <!-- Table headers -->
                                    <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ?
                                        "التاريخ" : "תאריך") }}</th>
                                    <th>{{ curentLang === "en" ? "Reference" : (curentLang === "ar" ?
                                        "المرجع" : "הפניה") }}</th>
                                    <th>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك"
                                        : "בנק") }}</th>
                                    <th>{{ curentLang === "en" ? "Branch" : (curentLang === "ar" ?
                                        "الفرع" : "סניף") }}</th>
                                    <th>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ?
                                        "الحساب" : "חשבון") }}</th>
                                    <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ?
                                        "التفاصيل" : "פרטים") }}</th>
                                    <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ?
                                        "المبلغ" : "סכום") }}</th>
                                    <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف"
                                        : "מחק") }}</th>
                                </tr>
                            </thead>
                            <!-- Table body -->
                            <tbody>
                                <!-- Iterate over the linesChecks FormArray -->
                                <tr *ngFor="let item of linesChecks.controls; let i = index"
                                    [formGroupName]="i">
                                    <!-- Table cells for each control in the form group -->

                                    <td>
                                        <input class="form-control" formControlName="date"
                                            type="date" />
                                    </td>
                                    <td>
                                        <input class="form-control" formControlName="reference" />
                                    </td>
                                    <td>
                          

                                        <ng-select formControlName="bank"  (change)="getBranches($event ,i)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                            <ng-option *ngFor="let item of masterBank " [value]="item?.bank_code">
                                                {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}                                                            </ng-option>
                                          </ng-select>

                                    </td>
                                    
                                    <td>
                            

                                        <ng-select formControlName="branch"    style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                            <ng-option *ngFor="let item of getBranchesForIndex(i)  " [value]="item?.bank_branch_code">
                                                {{ curentLang === 'en' ? item.bank_branch_name_en : (curentLang === 'ar' ? item.bank_branch_name_ar : item.bank_branch_name_he) }}                                                         </ng-option>
                                          </ng-select>

                                    </td>
                                    
                                    

                                    <td>
                                        <input class="form-control" formControlName="account" type="number" />
                                    </td>
                                    <td>
                                        <input class="form-control" formControlName="details" type="text" />
                                    </td>
                                    <td>
                                        <input class="form-control" formControlName="amount" type="number" />
                                    </td>
                                    
                                    <td>
                                        <i (click)="removeLineFromChecks(i)" class="fa fa-trash"
                                            style="font-size: 22px; cursor: pointer; color: red;"
                                            aria-hidden="true"></i>
                                    </td>
                                </tr>
                            </tbody>
                            <!-- Table footer -->
                            <tfoot>
                                <!-- Table row -->
                                <tr>
                                    <!-- Table cells -->
                                
                                
                                    <td>
                                        <!-- Add button to add new line -->
                                        <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToChecks()">
                                            <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === 'en' ? 'Add Check' : (curentLang === 'ar' ? 'اضافة دفعة شيك' : 'הוסף מוצר') }}
                                        </button>
                                    </td>

                                    <td>
                                        <input type="text" [value]="getTotalAmountChecksArray()" class="form-control" formControlName="checks_amount">
                                    </td>
                                </tr>
                                
                            </tfoot>
                        </table>
                    </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                        <table class="table table-bordered" formArrayName="linesBankPayment">
                            <!-- Table header -->
                            <thead class="text-black">
                                <!-- Table row -->
                                <tr class="">
                                    <!-- Table headers -->
                                    <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ?
                                        "التاريخ" : "תאריך") }}</th>
                                 
                                    <th>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك"
                                        : "בנק") }}</th>
                              
                                    <th>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ?
                                        "الحساب" : "חשבון") }}</th>
                                    <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ?
                                        "التفاصيل" : "פרטים") }}</th>
                                    <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ?
                                        "المبلغ" : "סכום") }}</th>
                                    <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف"
                                        : "מחק") }}</th>
                                </tr>
                            </thead>
                            <!-- Table body -->
                            <tbody>
                                <!-- Iterate over the linesChecks FormArray -->
                                <tr *ngFor="let item of linesBankPayment.controls; let i = index"
                                    [formGroupName]="i">
                                    <!-- Table cells for each control in the form group -->

                                    <td>
                                        <input class="form-control" formControlName="date"
                                            type="date" />
                                    </td>
                                  
                                    <td>
                                        <ng-select formControlName="bank"  (change)="getBranches($event ,i)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                            <ng-option *ngFor="let item of masterBank; let i = index " [value]="item?.bank_code">
                                                {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}                                                            </ng-option>
                                          </ng-select>
                                    </td>
                                     
                                    
                                    

                                    <td>
                                        <input class="form-control" formControlName="account" type="number" />
                                    </td>
                                    <td>
                                        <input class="form-control" formControlName="details" type="text" />
                                    </td>
                                    <td>
                                        <input class="form-control" formControlName="amount" type="number" />
                                    </td>
                                    
                                    <td>
                                        <i (click)="removeLineFromBankPayment(i)" class="fa fa-trash"
                                            style="font-size: 22px; cursor: pointer; color: red;"
                                            aria-hidden="true"></i>
                                    </td>
                                </tr>
                            </tbody>
                            <!-- Table footer -->
                            <tfoot>
                                <!-- Table row -->
                                <tr>
                                    <!-- Table cells -->
                                
                                
                                    <td>
                                        <!-- Add button to add new line -->
                                        <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToBankPayment()">
                                            <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === 'en' ? 'Add Transfer Bank' : (curentLang === 'ar' ? 'اضافة تحويل بنكي' : 'הוסף מוצר') }}
                                        </button>
                                    </td>

                                    <td>
                                        <input type="text" [value]="getTotalAmountBankPaymentArray()" class="form-control" formControlName="bank_payment_amount">
                                    </td>
                                </tr>
                                
                            </tfoot>
                        </table>
                    </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                        <table class="table table-bordered" formArrayName="linesCredit">
                            <!-- Table header -->
                            <thead class="text-black">
                                <!-- Table row -->
                                <tr class="">
                                    <!-- Table headers -->
                                    <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ?
                                        "التاريخ" : "תאריך") }}</th> 
                                 
                                  

                                    <th>{{ curentLang === "en" ? "Payment Type" : (curentLang === "ar" ? "نوع الدفع"
                                        : "  סוג תשלום") }}</th>
                              
                              <th>{{ curentLang === "en" ? "Credit Type" : (curentLang === "ar" ? "نوع الأتمان"
                                : "סוג אשראי") }}</th>

                                     <th>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ?
                                        "الحساب" : "חשבון") }}</th> 

                        

                                    <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ?
                                        "التفاصيل" : "פרטים") }}</th>
                                    <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ?
                                        "المبلغ" : "סכום") }}</th>
                                    <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف"
                                        : "מחק") }}</th>
                                </tr>
                            </thead>
                            <!-- Table body -->
                            <tbody>
                                <!-- Iterate over the linesChecks FormArray -->
                                <tr *ngFor="let item of linesCredit.controls; let i = index"
                                    [formGroupName]="i">
                                    <!-- Table cells for each control in the form group -->

                                    <td>
                                        <input class="form-control" formControlName="date"
                                            type="date" />
                                    </td>
                                  
                                    <td>
                                        <select formControlName="credit_card_name" class="form-control form-select" (change)="filterPayment($event)">
                                            <option [value]="1" > Normal </option>
                                            <option [value]="2" > Payments </option>
                                            <option [value]="3" > Credit </option>
                                            <option [value]="4" > Deferred Payment </option>
                                            <option [value]="5" > Others  </option>
                                            <option [value]="0" > not Credit Card </option>
                                        </select>
                                    </td>
                                     
                                    <td>
                                        <select formControlName="credit_type" class="form-control form-select" (change)="filterCreidt($event, i)">
                                            <option [value]="1">Isracard</option>
                                            <option [value]="2">Cal</option>
                                            <option [value]="3">Diners</option>
                                            <option [value]="4">American Express</option>
                                            <option [value]="6">Leumi Card</option>
                                            <option [value]="0">Not Credit Card</option>
                                        </select>
                                        
                                    </td>
                                    

                                    <td>
                                        <input class="form-control" formControlName="account" type="number" />
                                    </td>

                                    <!-- <td>
                                        <input class="form-control" formControlName="credit_card_code" type="text" />
                                    </td> -->

                                    
                                    <td>
                                        <input class="form-control" formControlName="details" type="text" />
                                    </td>
                                    <td>
                                        <input class="form-control" formControlName="amount" type="number" />
                                    </td>
                                    
                                    <td>
                                        <i (click)="removeLineFromCreidtPayment(i)" class="fa fa-trash"
                                            style="font-size: 22px; cursor: pointer; color: red;"
                                            aria-hidden="true"></i>
                                    </td>
                                </tr>
                            </tbody>
                            <!-- Table footer -->
                            <tfoot>
                                <!-- Table row -->
                                <tr>
                                    <!-- Table cells -->
                                
                                
                                    <td>
                                        <!-- Add button to add new line -->
                                        <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToCredit()">
                                            <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === 'en' ? 'Add Credit' : (curentLang === 'ar' ? '  إضافة رصيد' : '  הוסף אשראי') }}
                                        </button>
                                    </td>

                                    <td>
                                        <input type="text" [value]="getTotalAmountCreidtArray()" class="form-control" formControlName="credit_amount">
                                    </td>
                                </tr>
                                
                            </tfoot>
                        </table>
                    </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="table-responsive">
                        <table class="table table-bordered" formArrayName="linesCash">
                            <!-- Table header -->
                            <thead class="text-black">
                                <!-- Table row -->
                                <tr>
                                    <!-- Table headers -->
                                    <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ?
                                        "التاريخ" : "תאריך") }}</th>
                                    <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ?
                                        "التفاصيل" : "פרטים") }}</th>
                                    <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ?
                                        "المبلغ" : "סכום") }}</th>
                                    <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف"
                                        : "מחק") }}</th>
                                </tr>
                            </thead>
                            <!-- Table body -->
                            <tbody>
                                <!-- Iterate over the linesCash FormArray -->
                                <tr *ngFor="let item of linesCash.controls; let i = index"
                                    [formGroupName]="i">
                                    <!-- Table cells for each control in the form group -->
                                    <td>
                                        <input class="form-control" formControlName="date"
                                            type="date" />
                                    </td>

                                    <td>
                                        <input class="form-control" formControlName="details"
                                            type="text" />
                                    </td>
                                    <td>
                                        <input class="form-control" formControlName="amount"
                                            type="number" />
                                    </td>
                                    <td>
                                        <i (click)="removeLineFromCash(i)" class="fa fa-trash"
                                            style="font-size: 22px; cursor: pointer; color: red;"
                                            aria-hidden="true"></i>
                                    </td>
                                </tr>
                            </tbody>
                            <!-- Table footer -->
                            <tfoot>
                                <!-- Table row -->
                                <tr>
                                    <!-- Table cells -->
                                    <td>
                                        <!-- Add button to add new line -->
                                        <button type="button" class="btn my-2"
                                            style="background-color: #141414; color: #fff;"
                                            (click)="addNewLineToCash()">
                                            <i class="fa fa-plus-circle mx-1"
                                                style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === 'en' ? 'Add Cash' : (curentLang === 'ar' ?
                                            'اضافة دفعة كاش' : 'הוסף מוצר') }}
                                        </button>
                                    </td>

                                    <td>
                                        <input type="text" [value]="getTotalAmountCashArray()" class="form-control" formControlName="cash_amount">
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                        </div>
                    </div>
                </div>

                <div class="row d-flex justify-content-center">
                    <div class="col-md-3"></div>
                  <div class="col-md-3">
                   
                    <div class="col-lg-12 p-2">
                      <label for="" style="font-weight: 800;">
                        {{ curentLang === 'en' ? 'Deduction From Source' : curentLang === 'ar' ? ' الخصم من المصدر' : ' ניכוי מהמקור ' }}
                      </label>
                      <input type="text" formControlName="deduction_from_source" class="form-control m-auto w-25">
                    </div>
                  </div>
                  <div class="col-md-3">
                    <br />
                    <div class="col-lg-12 p-2">
                      <label for="" style="font-weight: 800;">
                        {{ curentLang === 'en' ? 'Final Amount' : curentLang === 'ar' ? 'القيمة النهائية ' : ' סכום סופי ' }}
                      </label>
                      <input class="form-control m-auto w-25" formControlName="total_amount" type="number">
                    </div>
                  </div>
                  <div class="col-md-3"></div>
                </div>

                <div class="row d-flex justify-content-between text-center">

                    <div class="col-4 col-lg-4 p-1">
                        <button class="btn  " style="background-color: #141414; color: #fff;" type="submit" (click)="confirmInvoice()">
                            <i class="fa fa-check mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                            {{ curentLang === 'en' ? 'Confirm ' : (curentLang === 'ar' ? 'تأكيد    ' : 'כדי להיות בטוח') }}  
                        </button> 
                    </div>

                  <div class="col-4 col-lg-4 p-1">
                    <button class="btn  " style="background-color: #277d29; color: #fff" type="submit" (click)="saveInvoiceAndReceipt()">
                      <i class="fa fa-save m-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                      {{ curentLang === 'en' ? 'Save ' : (curentLang === 'ar' ? 'حفظ ' : ' שמור ') }}
                    </button>
                  </div>
                  <div class="col-4 col-lg-4 p-1">
                    <a class="btn   btn-danger" routerLink="/dashboard/invoice-with-receipt">
                      <i class="fa fa-rotate-left mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                    </a>
                  </div>
                </div> 
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
