import { Component, PLATFORM_ID, Inject } from '@angular/core';
// import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
// import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './Auth/service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from './Data/service/Activity.Service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  title = 'Fawtara';
  isLogin = true;
  lang:string= 'en'
  curentLang: any;
  
  constructor(
   @Inject(PLATFORM_ID) private platformId: Object,
   @Inject(DOCUMENT) private document: Document ,
   private  authService:AuthService ,
   public translate:TranslateService,
    private loader: LoadingBarService,
    private activityService: ActivityService
    ) {

      this.authService.isLogin.subscribe(value => {
        this.isLogin = value;
      })
  }

  ngOnInit() {
    this.activityService.checkInactivity();

    AOS.init();
  
    // Change Lang:
    const storedLang = localStorage.getItem('curentLang');
    if (!storedLang) {
      this.curentLang = 'ar';
      localStorage.setItem('curentLang', this.curentLang);
    } else {
      this.curentLang = storedLang;
    }
    
    this.translate.use(this.curentLang);
  
    if (this.curentLang === "en") {
      this.document.getElementsByTagName('html')[0].lang = 'en';
      this.document.getElementsByTagName('html')[0].dir = 'ltr';
      this.document.getElementById('Appcontainer')?.classList.remove('is-rtl');
    } else if (this.curentLang === "ar") {
      this.document.getElementsByTagName('html')[0].lang = 'ar';
      this.document.getElementsByTagName('html')[0].dir = 'rtl';
      this.document.getElementById('Appcontainer')?.classList.add('is-rtl');
    } else if (this.curentLang === "he") {
      this.document.getElementsByTagName('html')[0].lang = 'he';
      this.document.getElementsByTagName('html')[0].dir = 'rtl';
      this.document.getElementById('Appcontainer')?.classList.add('is-rtl');
    }
  }
  
   };


