import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { AddTermsConditionPoient } from "../service/global.service";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"]
})

export class TermsComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addTerms:FormGroup;
  updateTerms:FormGroup;
   updatedData: any;
  viewData: any;
   termsData:any=[];
 
   termsId:number;
   public validate = false;
   public validateUpdate = false;
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));


   this.addTerms=new FormGroup({
   
    question_ar:new FormControl('' , Validators.required),
    answer_ar :new FormControl('' , Validators.required),  
    question_en: new FormControl('' , Validators.required),
    answer_en :new FormControl('' , Validators.required),
    question_he: new FormControl('' , Validators.required),
    answer_he :new FormControl('' , Validators.required),
    created_by:new FormControl(this.userRegistId),
    });

    this.updateTerms=new FormGroup({
   
      question_ar:new FormControl('' , Validators.required),
      answer_ar :new FormControl('' , Validators.required),  
      question_en: new FormControl('' , Validators.required),
      answer_en :new FormControl('' , Validators.required),
      question_he: new FormControl('' , Validators.required),
      answer_he :new FormControl('' , Validators.required),
      created_by:new FormControl(this.userRegistId),
      });

   
  };

  getAllTermsAndConditions() {
    this.spinner.show();
    this.service.getAllTermsAndConditions().subscribe({
      next: res => {
        this.termsData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'lg'});
  };
  addedData() {

    const question_ar = this.addTerms.value.question_ar.trim();
    const answer_ar = this.addTerms.value.answer_ar.trim();
    const question_en = this.addTerms.value.question_en.trim();
    const answer_en = this.addTerms.value.answer_en.trim();
    const question_he = this.addTerms.value.question_he.trim();
    const answer_he = this.addTerms.value.answer_he.trim();
  
    if (
      question_ar === '' ||
      answer_ar === '' ||
      question_en === '' ||
      answer_en === '' ||
      question_he === '' ||
      answer_he === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.addTerms.markAllAsTouched();
      this.validate = !this.validate;
      return;
    }
 
    else
    {
      let model = {
        question_ar: this.addTerms.value.question_ar,
        answer_ar: this.addTerms.value.answer_ar,
        question_en: this.addTerms.value.question_en,
        answer_en: this.addTerms.value.answer_en,
        question_he: this.addTerms.value.question_he,
        answer_he: this.addTerms.value.answer_he,
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddTermsConditionPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
           this.getAllTermsAndConditions();
          this.addTerms.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
      
    }

  };

  getId(id: number) {
    this.termsId = id;
  };

  executeFunctions(AppID: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(AppID);
     
    this.setValue(AppID);
    this.updateMd(this.updatedData);
 
  };

  setValue(termsId: number) {
    for (let i = 0; i < this.termsData.length; i++) {
      if (this.termsData[i].id == this.termsId) {
        const selectedCategory = this.termsData.find(item => item.id === termsId);

        this.updateTerms.controls.question_ar.setValue(this.termsData[i].question_ar);
        this.updateTerms.controls.answer_ar.setValue(this.termsData[i].answer_ar);
        this.updateTerms.controls.question_en.setValue(this.termsData[i].question_en);
        this.updateTerms.controls.answer_en.setValue(this.termsData[i].answer_en);
        this.updateTerms.controls.question_he.setValue(this.termsData[i].question_he);
        this.updateTerms.controls.answer_he.setValue(this.termsData[i].answer_he);

      }
    };
  };
 
  updateMd(termsUpdated: any) {
    this.modalService.open(termsUpdated, { size: 'lg' , centered:true});
  };

  updateTermsData() {

    const question_ar = this.updateTerms.value.question_ar.trim();
    const answer_ar = this.updateTerms.value.answer_ar.trim();
    const question_en = this.updateTerms.value.question_en.trim();
    const answer_en = this.updateTerms.value.answer_en.trim();
    const question_he = this.updateTerms.value.question_he.trim();
    const answer_he = this.updateTerms.value.answer_he.trim();
  
    if (
      question_ar === '' ||
      answer_ar === '' ||
      question_en === '' ||
      answer_en === '' ||
      question_he === '' ||
      answer_he === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.updateTerms.markAllAsTouched();
      this.validateUpdate = !this.validateUpdate;
      return;
    }

    else
    {
      let model = {
        question_ar: this.updateTerms.value.question_ar,
        answer_ar: this.updateTerms.value.answer_ar,
        question_en: this.updateTerms.value.question_en,
        answer_en: this.updateTerms.value.answer_en,
        question_he: this.updateTerms.value.question_he,
        answer_he: this.updateTerms.value.answer_he,
        updated_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.updateTermsData( this.termsId, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllTermsAndConditions();
         } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
 

};

  changeTermsStatus(id:number, status:any){
    this.spinner.show();
  
    let model =
    {
      "status": status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeTermsStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllTermsAndConditions();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  openVerticallyCentered(dataDeleted:any) {
    this.modalService.open(dataDeleted, { centered: true });
  };
  
  deleteTermsById() {
    this.spinner.show();
    this.service.deleteTermsById(this.termsId).subscribe({
      next: (res) => {
        if (res.status == 400) {
          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.spinner.hide();
          return;
        } else {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
           this.getAllTermsAndConditions();
          this.modalService.dismissAll();
          this.spinner.hide();
        }
      },
      error: (err) => {
        Swal.fire(err.messageAr);
        this.spinner.hide(); 
      }
    });
  };
 

  ngOnInit() 
  {
   this.getAllTermsAndConditions();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
}
