import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SubscribeEndPoient } from "../../service/global.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
const Swal = require('sweetalert2')
@Component({
  selector: "app-plane-details",
  templateUrl: "./plane-details.component.html",
  styleUrls: ["./plane-details.component.scss"]
})

export class PlaneDetailsComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  merchantId:any;
  id:number;
  planDetails:any;
  tokenDetails:any;

  newOrders = {
    colorClass: "primary",
    icon: "new-order",
    colorClass2: "warning",
    icon2: "customers",
    colorClass3: "success",
    icon4: "invoice",
    colorClass4: "secondary",
    icon3: "profit"
  };

  addPlan:FormGroup;



  constructor
  ( private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService ,
    activatedRoute:ActivatedRoute,
    private modalService: NgbModal,
    private router: Router,
  ) 

  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.id = activatedRoute.snapshot.params['id'];
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

   this.addPlan=new FormGroup({
      merchant_id:new FormControl(this.merchantId),
      plan_id:new FormControl(this.id),
      payment_method:new FormControl('عن طريق العميل - مجاني'),
      user_id:new FormControl(this.userRegistId),
      created_by:new FormControl(this.userRegistId), 
    });
 
  };

  getPlanById(){

    this.spinner.show()
    this.service.getPlanById(this.id  ).subscribe(({
      next:res => {
        this.planDetails = res;  
         
        this.spinner.hide();
      },
      error:err => {
      }
    }));
  };

  addedData() {
    let model = {
      merchant_id:Number (this.merchantId),
      plan_id: Number(this.id),
      payment_method: this.addPlan.value.payment_method,
      user_id: Number(this.userRegistId),
      created_by: Number(this.userRegistId),
    };
    this.spinner.show();
    this.service.Post(SubscribeEndPoient.POST, model).subscribe((res =>{
      if ( res.status === 400) {        
        if(this.curentLang == 'en') {
          Swal.fire({
            icon: 'error',
            title: res.messageEn,
          });
        } 

        if( this.curentLang == 'ar' ) {
          Swal.fire({
            icon: 'error',
            title: res.messageAr,
          });
        }

        if( this.curentLang == 'he' ) {
          Swal.fire({
            icon: 'error',
            title: res.messageHe,
          });
        }
      } 
      this.spinner.hide();
      if (res.status === 200 ) {
        if(this.curentLang == 'en') {
          Swal.fire({
            icon: 'success',
            title: res.messageEn,
          });
        } 
        if( this.curentLang == 'ar' ) {
          Swal.fire({
            icon: 'success',
            title: res.messageAr,
          });
        }
        if( this.curentLang == 'he' ) {
          Swal.fire({
            icon: 'success',
            title: res.messageHe,
          });
        }
        this.modalService.dismissAll();
        this.getPlanById();
        this.updateUserToken();
       this.spinner.hide();
       this.router.navigate(['/dashboard/dashboard']);

      } 
    }));
  };

  updateUserToken() {
    this.spinner.show();
    this.service.updateUserToken().subscribe({
      next: res => {
        this.tokenDetails = res;  
        localStorage.setItem('token', this.tokenDetails.token); 
        this.spinner.hide();
        window.location.reload();
      },
      error: err => {
         this.spinner.hide();
      }
    });
  };
  

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  ngOnInit() {
    this.getPlanById();
    this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));

  };

}
