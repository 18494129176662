import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HttpHelpService } from "../../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { FormControl, FormGroup } from "@angular/forms";
import Swal from 'sweetalert2';

@Component({
  selector: "app-office",
  templateUrl: "./office.component.html",
  styleUrls: ["./office.component.scss"]
})

export class OfficeComponent implements OnInit {
  public show: boolean = false;
  curentLang:string="";
  layoutVersion:any;
  updateOfficeInfo:FormGroup;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeData:any;
  officeId: number;
  term:any;
  OfficeName:string=''
  OfficeAddress:string=''
  OfficePhone:string=''
  Email:string=''
  TaxNumber:string=''
 
  newOrders = {
    colorClass: "primary",
    icon: "new-order",
    colorClass2: "warning",
    icon2: "customers",
    colorClass3: "success",
    icon4: "invoice",
    colorClass4: "secondary",
    icon3: "profit"
  };
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));    

    this.updateOfficeInfo=new FormGroup({
      office_name :new FormControl(''),  
      office_address:new FormControl(''),
      office_phone:new FormControl(''),
      email :new FormControl(''),
      tax_number :new FormControl(''),
      updated_by:new FormControl(this.officeIdNumber),
    });
  };

  getAllOfficeInfo() {
    this.spinner.show();
    this.service.getAllOfficeInfo(this.officeIdNumber).subscribe({
      next: res => {
        this.officeData = res;
 
        this.OfficeName = this.officeData.office_name
        this.OfficeAddress = this.officeData.office_address
        this.OfficePhone = this.officeData.office_phone
        this.Email = this.officeData.email
        this.TaxNumber = this.officeData.tax_number
        
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };


  updateOfficeById() {

    let model = {
      office_name: this.updateOfficeInfo.value.office_name,
        office_address: this.updateOfficeInfo.value.office_address,
        office_phone: this.updateOfficeInfo.value.office_phone,
        email:this.updateOfficeInfo.value.email,
        tax_number:this.updateOfficeInfo.value.tax_number,
        updated_by: Number(this.officeIdNumber),
    }; 
  
    this.spinner.show();
    this.service.updateOfficeById( this.officeIdNumber, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getAllOfficeInfo();
      } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });


};


  ngOnInit() 
  {
    this.getAllOfficeInfo();

    this.layoutVersion = localStorage.getItem("layout_version");
    if (this.layoutVersion == null || this.layoutVersion == undefined) {
      localStorage.setItem("layout_version", "light-only");
      this.layoutVersion= "light-only"; 
    } else {
      this.layoutVersion = localStorage.getItem("layout_version"); 
      if (this.layoutVersion === "dark-only") {
        document.body.classList.add("dark-only");
      } else {
        document.body.classList.add("light-only");
 
      }
    }
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));    

  }
}
