import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { AddAccountantUsersCompanyEndPoient } from "../../service/global.service";

@Component({
  selector: "app-accountant-user-companies",
  templateUrl: "./accountant-user-companies.component.html",
  styleUrls: ["./accountant-user-companies.component.scss"]
})

export class AccountantUserCompaniesComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addAccountingOffice:FormGroup;
  updateAccountingOffice:FormGroup;
  customersData:any=[];
  customerDataMerchant:any=[];
  accountingUserCompanyData:any=[];
  officeId: number;
  updatedData: any;
  viewData: any;
  currentPage: number = 1;
  limit: number = 400;
  offset: number = 0;
  total: number = 0; 
  id:any;
  addAccountantUserCompanyForm:FormGroup;
  activeCompanyData:any

  rowId:number;

  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    private router: Router,
    activatedRoute:ActivatedRoute,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
    this.id = activatedRoute.snapshot.params['id'];

    this.addAccountantUserCompanyForm = new FormGroup({
      companyUser: new FormControl([] , Validators.required),   
      office_id: new FormControl(this.officeIdNumber),
      user_id: new FormControl(this.id),
      created_by: new FormControl(this.userRegistId),
    });
  };

  getAllActiveAgreedPermissionsByOfficeId( ) {
   
    this.spinner.show();
    this.service.getAllActiveAgreedPermissionsByOfficeId(this.officeIdNumber).subscribe({
      next: res => {
        this.activeCompanyData = res;
        this.spinner.hide();
         this.getAllAccessByUserId(); 

      },
      error: () => {
        this.spinner.hide();
      }
    });
  };

  openXl2(content2:any) {
    this.modalService.open(content2, { size: 'lg'   });
  };

  onCompanyMerchantChange(event) {
    this.addAccountantUserCompanyForm.patchValue({
      companyUser: event
    });
  };

  addedAccountingUserCompany() {
    if (this.addAccountantUserCompanyForm.invalid) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      return;
    } else {
      const companyUsers = this.addAccountantUserCompanyForm.value.companyUser;
       
      const models = companyUsers.map(companyUser => ({
        office_id: Number(this.officeIdNumber),
        company_id: Number(companyUser.company_id),
        permission_id: Number(companyUser.permission_id),
        user_id: Number(this.id),
        created_by: Number(this.userRegistId),
      }));
  
      this.spinner.show();
      this.service.Post(AddAccountantUsersCompanyEndPoient.POST, models).subscribe((res) => {
        this.spinner.hide();
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllAccessByUserId();
          
          this.addAccountantUserCompanyForm.reset();
        } else {
          let errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
      });
    }
  }
  // getAllPermissionsByOfficeId() {
  //   this.spinner.show();
  //   this.service.getAllPermissionsByOfficeId(this.id).subscribe({
  //     next: res => {
  //       this.accountingOfficeCompanyData = res;
          
  //       this.spinner.hide();
  //     },
  //     error: error => {
  //       this.spinner.hide();
  //     }
  //   });
  // };

  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllAccessByUserId(this.limit, offset);
  }

  getAllAccessByUserId(limit?: number, offset?: number) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getAllAccessByUserId(this.id  ,limit, offset).subscribe({
      next: res => {
        this.accountingUserCompanyData = res.data;
        this.total = res.total;
        this.spinner.hide();
         
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }


  openVerticallyCentered(dataDeleted:any) {
    this.modalService.open(dataDeleted, { centered: true });
  };

  getId(id:number)
{
   this.rowId = id ;
 
};

deleteAccessCompanyForUser() {

    this.service.deleteOnAccess(this.rowId).subscribe(({
      next:res=> {
        
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? 'تم حذف الشركة الموجودة لدي المستخدم بنجاح ' : (this.curentLang === 'en' ? ' The user is existing company has been successfully deleted' : 'החברה הקיימת של המשתמש נמחקה בהצלחה'),
        })
            this.getAllAccessByUserId();
            this.accountingUserCompanyData = res;
            this.modalService.dismissAll()
      },
      error:err =>{
        Swal.fire(err)
      }
    }))
  };
  
  
  ngOnInit() 
  {
    this.getAllActiveAgreedPermissionsByOfficeId();

    this.getAllAccessByUserId();
   this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
   this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
   
  };
}
