import { AbstractControl, ValidatorFn } from '@angular/forms';

export function israelPhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let value = control.value;

    // إزالة بادئة +972 إذا كانت موجودة
    if (value?.startsWith('+972')) {
      value = value?.substring(4);
    }

    const mobilePrefixes = ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
    const phoneNumberPattern = /^[0-9]{7}$/; // تحقق من أن الرقم يحتوي على 7 أرقام بعد البادئة

    // إزالة الصفر الأولي إذا كان موجودًا للأغراض الداخلية فقط
    let internalValue = value;
    if (internalValue?.startsWith('0')) {
      internalValue = internalValue?.substring(1);
    }

    // تحقق من أن الرقم الداخلي يبدأ ببادئة صحيحة
    const prefix = internalValue?.substring(0, 2);
    if (!mobilePrefixes.includes(prefix)) {
      return { 'invalidMobilePrefix': { message: 'Invalid mobile prefix. Use one of the following: ' + mobilePrefixes.join(', ') } };
    }

    // تحقق من أن بقية الرقم يتبع التنسيق الصحيح (7 أرقام بعد البادئة)
    const mainNumber = internalValue?.substring(2);
    if (mainNumber.length !== 7 || !phoneNumberPattern.test(mainNumber)) {
      return { 'invalidPhoneNumber': { message: 'Phone number must be 7 digits long after the prefix.' } };
    }

    return null;
  };
}