import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpHelpService } from '../../service/http-help.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, catchError, concatMap, of, throwError } from 'rxjs';

@Component({
  selector: 'app-update-invoice-with-receipt',
  templateUrl: './update-invoice-with-receipt.component.html',
  styleUrls: ['./update-invoice-with-receipt.component.scss']
})
export class UpdateInvoiceWithReceiptComponent implements OnInit {
  invoiceReceiptForm: FormGroup;
  masterBank: any;
  masterproduct: any;
  vatTypeList: any;
  VatPercentage: any;
  curentLang: string;
  invoiceproduct: any;
  invoicedetail: any;
  invoiceId: number;
  mastercustomer: any;
  id: number;
  oneCustomerData: any;
  CustomerNameEn: any;
  CustomerNameHe: any;
  companyId: number;
  CustomerNameAr: any;
  mastercompany: any;
  masterInvoiceTypeList: Object;
  vatPersentageData: any;
  cashType = 1;
  checkType = 2;
  bankTransferType = 4;
  creditCardType = 3;
   allBranchesData: any;
  masterBranches: any = []
  uuId:any;
  availableLanguages: any = [
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'Arabic' },
    { code: 'he', name: 'Hebrew' },
  ];
  invoiceData: any;
  creditFilter: any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  constructor(
    private builder: FormBuilder, 
    private service: HttpHelpService, 
    private router: Router, 
    private alert: ToastrService,
    private activeroute: ActivatedRoute,
    private modalService: NgbModal, 
    private spinner: NgxSpinnerService,  
    private datePipe: DatePipe,
    private toastr: ToastrService ,
  ) { 
    this.curentLang = localStorage.getItem('curentLang');
    this.id = activeroute.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.curentLang = localStorage.getItem('curentLang') || 'en';

    this.invoiceReceiptForm = this.builder.group({
      invoice_type: [320],
      document_language: [this.curentLang || 'en'],
      invoice_date: ['', Validators.required],
      amount_before_discount: [{ value: 0, disabled: true }],
      all_discount: [{ value: 0, disabled: true }],
      payment_amount: [{ value: 0, disabled: true }],
      all_vat_amount: [{ value: 0, disabled: true }],
      payment_amount_including_vat: [{ value: 0, disabled: true }],
      invoice_note: ['', Validators.maxLength(255)],
      customer_id: ['', Validators.required],
      company_id: ['', Validators.required],
      vat_list_id: [null, Validators.required],
      vat_percentage: [{ value: 0, disabled: true }],
      confirm: [{ value: 0, disabled: true }],
      cash_amount: [0],
      checks_amount: [0],
      deduction_from_source: [0],
      bank_payment_amount: [0],
      credit_amount: [0],
      total_amount: [0]  ,
      customer_name: this.builder.control('', Validators.required),
      customer_id_number : this.builder.control(''),
      customer_vat_number : this.builder.control(''),
      merchant_id: Number(localStorage.getItem('merchant_id')),
      updated_by: Number(localStorage.getItem('user_id')),
      lines: this.builder.array([] , Validators.required),
      linesCash: this.builder.array([]),
      linesChecks: this.builder.array([]),
      linesBankPayment: this.builder.array([]),
      linesCredit: this.builder.array([]),
   
    });

  this.addFormArraySubscriptions(this.invoiceReceiptForm.get('lines') as FormArray);
  this.addFormArraySubscriptions(this.invoiceReceiptForm.get('linesCash') as FormArray);
  this.addFormArraySubscriptions(this.invoiceReceiptForm.get('linesChecks') as FormArray);
  this.addFormArraySubscriptions(this.invoiceReceiptForm.get('linesBankPayment') as FormArray);
  this.addFormArraySubscriptions(this.invoiceReceiptForm.get('linesCredit') as FormArray);

    
    this.GetProducts();
    this.vatTypechange();
    this.GetCompany();
    this.GetInvoiceTypeList();
    // this.getCustomerById();
    this.GetCustomers();
    this.GetBankList();
    this.getAllBankBranch();

    this.loadInvoiceData();

    this.invoiceReceiptForm.get('deduction_from_source').valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });

    this.stopVatPercentageSubscription();

  }

  addFormArraySubscriptions(formArray: FormArray) {
    formArray.controls.forEach((control: FormGroup) => {
      control.valueChanges.subscribe(() => {
        this.calculateTotalAmount();
      });
    });
  
    formArray.valueChanges.subscribe(() => {
      this.calculateTotalAmount();
    });
  }

  loadInvoiceData() {
    this.spinner.show(); // عرض رمز التحميل قبل استدعاء البيانات
    this.invoiceId = this.activeroute.snapshot.params['id'];
    this.service.getInvoicesAndReceiptsByInvoiceId(this.invoiceId).subscribe(res => {
      this.invoiceData = res;
  
      // تحديث النموذج بالقيم المستلمة
      this.invoiceReceiptForm.patchValue({
        invoice_type: this.invoiceData.invoice_type,
        all_discount: this.invoiceData.all_discount,
        customer_id: this.invoiceData.customer_id,
        company_id: this.invoiceData.company_id,
        invoice_date: this.invoiceData.invoice_date,
        customer_id_number : this.invoiceData?.customer_id_number,
        customer_name : this.invoiceData.customer_name,
        customer_vat_number: this.invoiceData.customer_vat_number,
        vat_list_id: this.invoiceData.vat_list_id,
        document_language: this.invoiceData.document_language,
        vat_percentage: this.invoiceData.vat_percentage,
        invoice_note: this.invoiceData.invoice_note,
        total_amount: this.invoiceData.total_amount,
        deduction_from_source : this.invoiceData.deduction_from_source,
        credit_amount: parseFloat(this.invoiceData.credit_amount) || 0,
        bank_payment_amount: parseFloat(this.invoiceData.bank_payment_amount) || 0,
        cash_amount: parseFloat(this.invoiceData.cash_amount) || 0,
        checks_amount: parseFloat(this.invoiceData.checks_amount) || 0
      });
  
      // Populate lines array
      this.populateFormArray(this.invoiceData.soldItems, 'lines');
      this.populateFormArray(this.invoiceData.linesCash, 'linesCash');
      this.populateFormArray(this.invoiceData.linesChecks, 'linesChecks');
      this.populateFormArray(this.invoiceData.linesBankPayment, 'linesBankPayment');
      this.populateFormArray(this.invoiceData.linesCredit, 'linesCredit')
  
      this.getVatPercentageByVatListIdAndDate();
      this.calculateTotalAmount();

      this.spinner.hide();
    });
  }
  
  // populateReceiptDetailsArray(details: any[], formArrayName: string) {
  //   const formArray = this.invoiceReceiptForm.get(formArrayName) as FormArray;
  //   formArray.clear();
  //   details.forEach(detail => {
  //     const formGroup = this.builder.group({
  //       date: detail.date,
  //       reference: detail.reference,
  //       bank: detail.bank,
  //       branch: detail.branch,
  //       account: detail.account,
  //       details: detail.details,
  //       amount: detail.amount,
  //       payment_type: detail.payment_type
  //     });
  //     formArray.push(formGroup);
  //   });
  // }

  populateFormArray(data: any[], formArrayName: string) {
    const formArray = this.invoiceReceiptForm.get(formArrayName) as FormArray;
    formArray.clear();
    data.forEach(detail => {
        const formGroup = this.builder.group(detail);
        formArray.push(formGroup);
    });
}
  

  confirmInvoice() {
    this.spinner.show();   
    const linesCash = this.invoiceReceiptForm.get('linesCash') as FormArray;
    const linesChecks = this.invoiceReceiptForm.get('linesChecks') as FormArray;
    const linesBankPayment = this.invoiceReceiptForm.get('linesBankPayment') as FormArray;
    const linesCredit = this.invoiceReceiptForm.get('linesCredit') as FormArray;
    if (linesCash.length === 0 && linesChecks.length === 0 && linesBankPayment.length === 0 && linesCredit.length === 0) {
      Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يجب أن يكون هناك بيانات على الأقل في واحدة من وسائل الدفع' :
                  this.curentLang === 'he' ? '   חייבים להיות נתונים לפחות באחד מאמצעי התשלום    ' :
                  'There must be data in at least one of the payment methods'
      });
      this.spinner.hide();
      return; // Stop the execution if the condition is not met
    }
const cashAmount = this.getTotalAmountCashArray();
const checksAmount = this.getTotalAmountChecksArray();
const bankPaymentAmount = this.getTotalAmountBankPaymentArray();
const creditAmount = this.getTotalAmountCreidtArray();

const totalLinesPayment = (cashAmount + checksAmount + bankPaymentAmount + creditAmount);

if (totalLinesPayment !== this.invoiceReceiptForm.get('payment_amount_including_vat').value) {
  Swal.fire({
    icon: 'warning',
    title: this.curentLang === 'ar' ? 'المبلغ الأجمالي للفاتوره ليس مطابق لمبلغ الإجمالي للدفع' :
      this.curentLang === 'he' ? '   הסכום הכולל של הזמנות לא מתאים לסכום הכולל של התשלום    ' :
        'The total amount of the orders does not match the total amount of the payment',
  })
  this.spinner.hide();
  return;
}
    if (this.invoiceReceiptForm.valid) {
      const formData = this.invoiceReceiptForm.getRawValue();

        this.spinner.show();
        return this.service.updateInvoiceAndReceipt(this.invoiceId, formData).pipe(
          concatMap((res: any) => {
            let result: any = res;
            this.invoiceId = result?.data;
            this.uuId = result?.data?.invoice_uuid;  
            if (result.status === 200) {
                // Return an observable here
                return of(null);
            } else if (result.status === 400 || result.status === 403 || result.status === 500) {
                this.spinner.hide();
                let errorMessage;
                switch (result.status) {
                    case 400:
                    case 403:
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                Swal.fire({
                    icon: 'error',
                    title: errorMessage
                });
                // Return an observable here
                return throwError(errorMessage);
            }
            // Return an observable here
            return of(null);
          }),
          catchError(error => {
            this.spinner.hide();
             return throwError(error);
          }),
          concatMap(() => this.service.confirmInvoice(this.invoiceId))
        ).subscribe((res) => {
            if (res.status === 200) {
                 
              this.router.navigate([`/dashboard/invoice-with-receipt`]);

                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  color: 'green',
                  backdrop:true,
                   
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                
                Toast.fire({
                  icon: 'success',
                  title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
                })


                this.modalService.dismissAll();
            } else if (res.status === 400 || res.status === 403 || res.status === 500) {
                let errorMessage;
                switch (res.status) {
                    case 400:
                    case 403:
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        // this.router.navigate(['/dashboard/invoices']);

                        break;
                        
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
               

                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  color: 'red',
                  backdrop:true,
                   
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                
                Toast.fire({
                  icon: 'error',
                  title: errorMessage
                })
            }
            this.spinner.hide();
        });
    } else {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
        });
        this.spinner.hide();
        return EMPTY;
    }
    
  }
  

  getBranchesForIndex(index: number) {
    // Return the branches data for the corresponding row index
    return this.masterBranches[index] || [];
}
  saveInvoiceAndReceipt() {
    this.spinner.show();
    const linesCash = this.invoiceReceiptForm.get('linesCash') as FormArray;
    const linesChecks = this.invoiceReceiptForm.get('linesChecks') as FormArray;
    const linesBankPayment = this.invoiceReceiptForm.get('linesBankPayment') as FormArray;
    const linesCredit = this.invoiceReceiptForm.get('linesCredit') as FormArray;
    if (linesCash.length === 0 && linesChecks.length === 0 && linesBankPayment.length === 0 && linesCredit.length === 0) {
      Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يجب أن يكون هناك بيانات على الأقل في واحدة من وسائل الدفع' :
                  this.curentLang === 'he' ? '   חייבים להיות נתונים לפחות באחד מאמצעי התשלום    ' :
                  'There must be data in at least one of the payment methods'
      });
      this.spinner.hide();
      return; // Stop the execution if the condition is not met
    }

const cashAmount = this.getTotalAmountCashArray();
const checksAmount = this.getTotalAmountChecksArray();
const bankPaymentAmount = this.getTotalAmountBankPaymentArray();
const creditAmount = this.getTotalAmountCreidtArray();

const totalLinesPayment = (cashAmount + checksAmount + bankPaymentAmount + creditAmount);

if (totalLinesPayment !== this.invoiceReceiptForm.get('payment_amount_including_vat').value) {
  Swal.fire({
    icon: 'warning',
    title: this.curentLang === 'ar' ? 'المبلغ الأجمالي للفاتوره ليس مطابق لمبلغ الإجمالي للدفع' :
      this.curentLang === 'he' ? '   הסכום הכולל של הזמנות לא מתאים לסכום הכולל של התשלום    ' :
        'The total amount of the orders does not match the total amount of the payment',
  })
  this.spinner.hide();
  return;
}

    if (this.invoiceReceiptForm.valid) {
    // Ensure cash_amount and checks_amount are set correctly
    const cashAmount = this.getTotalAmountCashArray();
    const checksAmount = this.getTotalAmountChecksArray();
    const bankPaymentAmount = this.getTotalAmountBankPaymentArray();
    const creditAmount = this.getTotalAmountCreidtArray();
  
     
    this.invoiceReceiptForm.get('cash_amount')?.setValue(cashAmount);
    this.invoiceReceiptForm.get('checks_amount')?.setValue(checksAmount);
    this.invoiceReceiptForm.get('bank_payment_amount')?.setValue(bankPaymentAmount);
    this.invoiceReceiptForm.get('credit_amount')?.setValue(creditAmount);
  
    const formData = this.invoiceReceiptForm.getRawValue();
  
    // تحويل بيانات النموذج إلى سلسلة نصية وعرضها في وحدة التحكم
    
    this.service.updateInvoiceAndReceipt(this.invoiceId,formData).subscribe({
      next: response => {
        let result: any;
        result = response;      
        this.invoiceId = result?.data;
        this.uuId = result?.data?.invoice_uuid;  
        if (result.status === 200) {
          this.router.navigate(['/dashboard/invoice-with-receipt']);

          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'green',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe)
          })


          this.invoiceReceiptForm.reset();
      } else if (result.status === 400 || result.status === 403 || result.status === 500) {
          let errorMessage;
          switch (result.status) {
              case 400:
                  errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                  break;
              case 403:
                  errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                  break;
              case 500:
                  errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                  break;
              default:
                  errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
         
          const Toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            color: 'red',
            backdrop:true,
             
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          })
          
          Toast.fire({
            icon: 'error',
            title: errorMessage
          })

      }
        this.spinner.hide();
      },
      error: error => {
        

        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          color: 'red',
          backdrop:true,
           
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        
        Toast.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'خطأ في حفظ الفاتورة والإيصال' : 
                 this.curentLang === 'he' ? 'שגיאה בשמירת החשבונית והקבלה' : 
                 'Error saving Invoice and Receipt'
        })


        this.spinner.hide();
      }
    });
  }
  else {
    Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
    });
    this.spinner.hide();
    return EMPTY;
}
this.spinner.hide()


  }

  
  // Remaining methods are the same...

  GetBankList() {
    this.spinner.show();
    this.service.GetBankList().subscribe(res => {
      this.masterBank = res;
      this.spinner.hide();
    });
  }

  getBranches(bankCode: string, index: number) {
    this.spinner.show();
    if (bankCode) {
        const selectedBank = this.masterBank.find(bank => bank.bank_code === bankCode);
        if (selectedBank) {
            const bankId = selectedBank.bank_id;
            this.service.getAllBranchById(bankId).subscribe({
                next: res => {
                    // Store the branches data for this particular row index
                    this.masterBranches[index] = res;
                    this.spinner.hide();
                    // If the branch control has a value, check if it exists in the fetched branches
                    const branchControl = (this.invoiceReceiptForm.get('linesChecks') as FormArray).at(index).get('branch');
                    const branchValue = branchControl.value;
                    const branchExists = res.some(branch => branch.bank_branch_code === branchValue);
                    if (!branchExists) {
                        // If the current value doesn't exist in the fetched branches, reset the branch control
                        branchControl.reset();
                    }
                }, 
                error: error => {
                    this.spinner.hide();
                }
            });
        }
    }
  }

  getAllBankBranch() {
    this.spinner.show();
    this.service.getAllBankBranch().subscribe({
      next: res => {
        this.allBranchesData = res;
        this.spinner.hide();
      },
      error: error => {
         this.spinner.hide();
      }
    });
  }




  
  Itemcalculation(index: any) {
    this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    
    let quantity = this.invoiceproduct.get("quantity")?.value;
    let price = this.invoiceproduct.get("price_per_unit")?.value;
    let discount = this.invoiceproduct.get("discount")?.value;
    let vat_rate = this.invoiceproduct.get("vat_rate")?.value;
  
    let sub_total = (quantity * price) - discount; // الحساب الفرعي للعنصر بعد الخصم
    let vat_amount = parseFloat((sub_total * (vat_rate / 100)).toFixed(2)); // حساب مبلغ الضريبة المضافة
    let total_amount = parseFloat((sub_total + vat_amount).toFixed(2)); // حساب السعر النهائي بإضافة مبلغ الضريبة المضافة إلى الحساب الفرعي
    
    this.invoiceproduct.get("sub_total")?.setValue(sub_total); // تعيين قيمة الحساب الفرعي
    this.invoiceproduct.get("vat_amount")?.setValue(vat_amount); // تعيين قيمة مبلغ الضريبة المضافة
    this.invoiceproduct.get("total_amount")?.setValue(total_amount); // تعيين السعر النهائي للعنصر
    
    this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
  };

  summarycalculation() {
    let array = this.invoiceReceiptForm.getRawValue().lines;
    
    let amount_before_discount = 0;
    let all_discount = 0;
    let all_vat_amount = 0;
  
    array.forEach((x: any) => {
      amount_before_discount += parseFloat(x.price_per_unit) * parseFloat(x.quantity);
      all_discount += parseFloat(x.discount);
      all_vat_amount += parseFloat(x.vat_amount);
    });
  
    let payment_amount = amount_before_discount - all_discount; // المبلغ المطلوب للدفع
    let payment_amount_including_vat = payment_amount + all_vat_amount; // المبلغ المطلوب للدفع بما فيه ضريبة القيمة المضافة
  
    this.invoiceReceiptForm.get("amount_before_discount")?.setValue(parseFloat(amount_before_discount.toFixed(2)));
    this.invoiceReceiptForm.get("all_discount")?.setValue(parseFloat(all_discount.toFixed(2)));
    this.invoiceReceiptForm.get("payment_amount")?.setValue(parseFloat(payment_amount.toFixed(2)));
    this.invoiceReceiptForm.get("all_vat_amount")?.setValue(parseFloat(all_vat_amount.toFixed(2)));
    this.invoiceReceiptForm.get("payment_amount_including_vat")?.setValue(parseFloat(payment_amount_including_vat.toFixed(2)));
}



addnewproduct() {
  
  this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;

  let customercode = this.invoiceReceiptForm.get("customer_id")?.value;
  let vatListId = this.invoiceReceiptForm.get("vat_list_id")?.value;
  let invoiceTypeCode = this.invoiceReceiptForm.get("invoice_type")?.value;
  let invoiceDate = this.invoiceReceiptForm.get("invoice_date")?.value;
  let companyid = this.invoiceReceiptForm.get("company_id")?.value;
  

  if (
    (customercode != null && customercode != '') &&
    (vatListId != null && vatListId != '') &&
    (invoiceTypeCode != null && invoiceTypeCode != '') &&
    (invoiceDate != null && invoiceDate != '') &&
    (companyid != null && companyid != '') 
  ) {
    this.invoicedetail.push(this.Generaterow());
    
  } else {
    
    Swal.fire({
      icon: 'warning',
      title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
    });
  }
};

addNewLineToCash() {
  let linesCashArray = this.invoiceReceiptForm.get("linesCash") as FormArray;

  let customerId = this.invoiceReceiptForm.get("customer_id")?.value;
  let companyId = this.invoiceReceiptForm.get("company_id")?.value;
  
  if (
    (customerId != null && customerId != '') &&
    (companyId != null && companyId != '') ) {
    linesCashArray.push(this.GenerateRowCash());

    // Update cash_amount
    this.invoiceReceiptForm.get('cash_amount').setValue(this.getTotalAmountCashArray());
    this.calculateTotalAmount(); // Update total_amount
  } else {
    Swal.fire({
      icon: 'warning',
      title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
    });
  }
}

addNewLineToBankPayment() {
let linesBankPaymentArray = this.invoiceReceiptForm.get("linesBankPayment") as FormArray;

let customerId = this.invoiceReceiptForm.get("customer_id")?.value;
let companyId = this.invoiceReceiptForm.get("company_id")?.value;

if (
  (customerId != null && customerId != '') &&
  (companyId != null && companyId != '') ) {
    linesBankPaymentArray.push(this.GenerateRowBankPayment());

  // Update cash_amount
  this.invoiceReceiptForm.get('cash_amount').setValue(this.getTotalAmountBankPaymentArray());
  this.calculateTotalAmount(); // Update total_amount
} else {
  Swal.fire({
    icon: 'warning',
    title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
  });
}
}

addNewLineToChecks() {
let linesChecksArray = this.invoiceReceiptForm.get("linesChecks") as FormArray;

let customerId = this.invoiceReceiptForm.get("customer_id")?.value;
let companyId = this.invoiceReceiptForm.get("company_id")?.value;

if (
    (customerId != null && customerId != '') &&
    (companyId != null && companyId != '') ) {
    linesChecksArray.push(this.GenerateRowCheck());

    // Update checks_amount
    this.invoiceReceiptForm.get('checks_amount').setValue(this.getTotalAmountChecksArray());
    this.calculateTotalAmount(); // Update total_amount
} else {
    Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
    });
}
}

addNewLineToCredit() {
let linesChecksArray = this.invoiceReceiptForm.get("linesCredit") as FormArray;

let customerId = this.invoiceReceiptForm.get("customer_id")?.value;
let companyId = this.invoiceReceiptForm.get("company_id")?.value;

if (
    (customerId != null && customerId != '') &&
    (companyId != null && companyId != '') ) {
    linesChecksArray.push(this.GenerateRowCreidt());

    // Update checks_amount
    this.invoiceReceiptForm.get('checks_amount').setValue(this.getTotalAmountCreidtArray());
    this.calculateTotalAmount(); // Update total_amount
} else {
    Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
    });
}
}

// GENERATE ROWS
GenerateRowCheck() {
  return this.builder.group({
      date: this.builder.control('', Validators.required),
      reference: this.builder.control(1 , Validators.required),
      bank: this.builder.control( '' , Validators.required),
      branch: this.builder.control('' , Validators.required),
      account: this.builder.control({ value: 0, disabled: false } , Validators.required),
      details: this.builder.control(''),
      amount: this.builder.control({ value: 0, disabled: false } , Validators.required),
      payment_type: this.builder.control({ value: this.checkType, disabled: true }),
  });

  

}

GenerateRowCash() {
  return this.builder.group({
      date: this.builder.control('', Validators.required),
      details: this.builder.control('', ),
      amount: this.builder.control('', Validators.required),
      payment_type: this.builder.control({ value: this.cashType, disabled: true }),
  });
}

GenerateRowBankPayment() {
  return this.builder.group({
      date: this.builder.control('', Validators.required),
      details: this.builder.control('', ),
      amount: this.builder.control('', Validators.required),
      account: this.builder.control({ value: 0, disabled: false } , Validators.required),
      bank: this.builder.control('' , Validators.required),
      payment_type: this.builder.control({ value: this.bankTransferType, disabled: true }),
  });
}



GenerateRowCreidt() {
  return this.builder.group({
      date: this.builder.control('', Validators.required),
      details: this.builder.control(''),
      amount: this.builder.control('', Validators.required),
      credit_card_code: this.builder.control('', Validators.required),
      credit_card_name: this.builder.control('', Validators.required),
      credit_type: this.builder.control('', Validators.required),
      account: this.builder.control({ value: 0, disabled: false } , Validators.required),

      payment_type: this.builder.control({ value: this.creditCardType, disabled: true }),
  });
}


 // Remove Cash Row
 removeLineFromCash(index: number) {
  const linesCashArray = this.invoiceReceiptForm.get("linesCash") as FormArray;
  linesCashArray.removeAt(index);
  this.calculateTotalAmount();
}

// Remove Check Row
removeLineFromChecks(index: number) {
  const linesChecksArray = this.invoiceReceiptForm.get("linesChecks") as FormArray;
  linesChecksArray.removeAt(index);
  this.calculateTotalAmount();
}

removeLineFromBankPayment(index: number) {
  const linesChecksArray = this.invoiceReceiptForm.get("linesBankPayment") as FormArray;
  linesChecksArray.removeAt(index);
  this.calculateTotalAmount();
}

removeLineFromCreidtPayment(index: number) {
  const linesChecksArray = this.invoiceReceiptForm.get("linesCredit") as FormArray;
  linesChecksArray.removeAt(index);
  this.calculateTotalAmount();
}



getTotalAmountCashArray(): number {
  let totalAmount = 0;
  const linesCashArray = this.invoiceReceiptForm.get("linesCash") as FormArray;
  linesCashArray.controls.forEach((control: FormGroup) => {
      const amount = parseFloat(control.get('amount').value) || 0;
      totalAmount += amount;
  });
   // this.calculateTotalAmount();

  return totalAmount;
}


getTotalAmountChecksArray(): number {
  let totalAmount = 0;
  const linesChecksArray = this.invoiceReceiptForm.get("linesChecks") as FormArray;
  linesChecksArray.controls.forEach((control: FormGroup) => {
      const amount = parseFloat(control.get('amount').value) || 0;
      totalAmount += amount;
  });
  // this.calculateTotalAmount();

  return totalAmount;
}

getTotalAmountBankPaymentArray(): number {
  let totalAmount = 0;
  const linesBankPaymentArray = this.invoiceReceiptForm.get("linesBankPayment") as FormArray;
  linesBankPaymentArray.controls.forEach((control: FormGroup) => {
      const amount = parseFloat(control.get('amount').value) || 0;
      totalAmount += amount;
  });
  // this.calculateTotalAmount();

  return totalAmount;
}

getTotalAmountCreidtArray(): number {
  let totalAmount = 0;
  const linesChecksArray = this.invoiceReceiptForm.get("linesCredit") as FormArray;
  linesChecksArray.controls.forEach((control: FormGroup) => {
      const amount = parseFloat(control.get('amount').value) || 0;
      totalAmount += amount;
  });
    // this.calculateTotalAmount();
  return totalAmount;
}
alAmountCreidtArray(): number {
  let totalAmount = 0;
  const linesChecksArray = this.invoiceReceiptForm.get("linesCredit") as FormArray;
  linesChecksArray.controls.forEach((control: FormGroup) => {
      const amount = parseFloat(control.get('amount').value) || 0;
      totalAmount += amount;
  });

  // إضافة القيمة الحالية في الحقل credit_amount
  const creditAmount = parseFloat(this.invoiceReceiptForm.get('credit_amount').value) || 0;
  totalAmount += creditAmount;

  return totalAmount;
}


 filterPayment(event:any)
{
  let x = event.target.value ;
   
 };

 filterCreidt(event: any, index: number) {
  const selectedValue = event.target.value;
  this.creditFilter = selectedValue;
 
  // Update the form control for credit_card_code at the specified index
  (this.invoiceReceiptForm.get('linesCredit') as FormArray).at(index).get('credit_card_code')?.setValue(this.creditFilter);
}

calculateTotalAmount() {
  const cashAmount = this.getTotalAmountCashArray();
     
  const checksAmount = this.getTotalAmountChecksArray();

  const bankPayment = this.getTotalAmountBankPaymentArray();

  const creditAmount = this.getTotalAmountCreidtArray();

  const deductionFromSource = parseFloat(this.invoiceReceiptForm.get('deduction_from_source').value) || 0;
     
  const oldTotalAmount = parseFloat(this.invoiceReceiptForm.get('total_amount').value) || 0; // قيمة total_amount السابقة

  const newTotalAmount = (cashAmount + checksAmount + bankPayment + creditAmount) - deductionFromSource;

  if (oldTotalAmount !== newTotalAmount) { // فقط في حالة تغير القيمة
    this.invoiceReceiptForm.get('total_amount').setValue(newTotalAmount);
  }
}




getTotalAmount(): number {
return this.invoiceReceiptForm.get('total_amount').value;
}

  get invproducts() {
    return this.invoiceReceiptForm.get("lines") as FormArray;
  };

  get linesChecks() {
    return this.invoiceReceiptForm.get("linesChecks") as FormArray;
  }
  
  get linesCash() {
    return this.invoiceReceiptForm.get("linesCash") as FormArray;
  }

  get linesBankPayment() {
    return this.invoiceReceiptForm.get("linesBankPayment") as FormArray;
  }
  
  get linesCredit() {
    return this.invoiceReceiptForm.get("linesCredit") as FormArray;
  }

  Generaterow() {

    this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;

    const newIndex = this.invoicedetail.length + 1;  
    
     
    this.stopVatPercentageSubscription();


    return this.builder.group({
        index: this.builder.control(newIndex),
        product_id: this.builder.control('', Validators.required),
        category: this.builder.control('', Validators.required),
        measure_unit_description: this.builder.control('', Validators.required),
        measurement_unit_id: this.builder.control('', Validators.required),
        description: this.builder.control('', Validators.required),
        catalog_id: this.builder.control('', Validators.required),
        item_name: this.builder.control('', Validators.required),
        quantity: this.builder.control(1),
        price_per_unit: this.builder.control(0),
        discount: this.builder.control(0),
        sub_total: this.builder.control({ value: 0, disabled: true }),
        vat_rate: this.builder.control({ value: this.VatPercentage, disabled: true }),
        vat_amount: this.builder.control({ value: 0, disabled: true }),
        total_amount: this.builder.control({ value: 0, disabled: true }),
    });
};

  stopVatPercentageSubscription()
{
    this.invoiceReceiptForm.get('vat_list_id')?.disable();
    this.invoiceReceiptForm.get('invoice_date')?.disable();
};

resumeVatPercentageSubscription()
{
  this.invoiceReceiptForm.get('vat_list_id')?.enable();
  this.invoiceReceiptForm.get('invoice_date')?.enable();
};

  GetCustomers()
   {
    this.spinner.show();
    this.service.getAllActiveCustomersBasedOnMerchantId().subscribe(res => {
      this.mastercustomer = res;
      this.spinner.hide();
    })
  };


  getIdd(id: number) {
    this.id = id;
  };
  getCustomerById() {
    const customerId = this.invoiceReceiptForm.get("customer_id")?.value;
    if (customerId != null && customerId !== '') {
      this.spinner.show();
      this.service.getCustomerById(Number(customerId)).subscribe({
        next: res => {
          this.oneCustomerData = res;
          console.log(this.oneCustomerData, 'this.oneCustomerData');
  
          const customerType = this.oneCustomerData.customer_type;
          const customerId = this.oneCustomerData.customer_id;
          if (customerType === 6) {
            const customerVatNumber = Number(this.oneCustomerData.customer_vat_number);
            console.log(customerVatNumber, '6');
  
            if (customerVatNumber === 0 || customerVatNumber === null) {
              this.spinner.hide();
  
              // رسالة تحذير مع زري أكشن
              Swal.fire({
                icon: 'warning',
                title: this.curentLang === 'ar' ? 'الرقم الضريبي للعميل غير صحيح' : (this.curentLang === 'en' ? 'The customer tax number is incorrect' : (this.curentLang === 'he' ? 'מספר המס של הלקוח שגוי' : '')),
                text: this.curentLang === 'ar' ? 'القيمه الافتراضيه : 999999998' : (this.curentLang === 'en' ? 'Default Value : 999999998' : (this.curentLang === 'he' ? 'ערך ברירת מחדל : 999999998' : '')),
                showCancelButton: true,
                confirmButtonText: this.curentLang === 'ar' ? 'ارسال القيمة الافتراضية' : (this.curentLang === 'en' ? 'Send Default Value' : 'קבל ערך ברירת מחדל'),
                cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : (this.curentLang === 'en' ? 'Edit Customer Data' : 'ערוך נתוני לקוח'),
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                  // إذا تم الضغط على زر "ارسال القيمة الافتراضية"
                  this.invoiceReceiptForm.patchValue({
                    customer_vat_number: '999999998',
                    customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                    customer_id_number: this.oneCustomerData?.id_number,
                  });
                  console.log(this.invoiceReceiptForm.value, 'this.invoiceReceiptForm.value');
                  
  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  // إذا تم الضغط على زر "تعديل بيانات العميل" نفتح نافذة إدخال SweetAlert
                  Swal.fire({
                    title: this.curentLang === 'ar' ? 'تحديث الرقم الضريبي للعميل' : 
                           (this.curentLang === 'en' ? 'Update Customer VAT Number' : 'עדכון מספר מס של הלקוח'),
                    input: 'text',
                    inputLabel: this.curentLang === 'ar' ? 'أدخل الرقم الضريبي الجديد' : 
                                (this.curentLang === 'en' ? 'Enter new VAT number' : 'הזן מספר מס חדש'),
                    inputValue: this.oneCustomerData.customer_vat_number, // القيم الحالية
                    showCancelButton: true,
                    confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 
                                       (this.curentLang === 'en' ? 'Update' : 'עדכון'),
                    cancelButtonText: this.curentLang === 'ar' ? 'إلغاء' : 
                                      (this.curentLang === 'en' ? 'Cancel' : 'בטל'),
                                      inputValidator: (value) => {
                                        // التأكد من أن القيمة ليست فارغة، لا تبدأ بـ 0، لا تحتوي على أصفار فقط، ولا تقل عن 9 أرقام
                                        if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                                          return this.curentLang === 'ar' ? 'الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 
                                                 (this.curentLang === 'en' ? 'Please enter a valid VAT number with at least 9 digits and does not start with 0' : 'הזן מספר מס תקין עם לפחות 9 ספרות ושלא מתחיל ב-0');
                                        }
                                      }
                                      
                                      
                  }).then((inputResult) => {
                    if (inputResult.isConfirmed) {
                      this.invoiceReceiptForm.patchValue({
                        customer_vat_number: inputResult.value,
                        customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                        customer_id_number: this.oneCustomerData?.id_number
                      });
  
                      // تحديث بيانات العميل في الـ API
                      const model = {
                        customer_vat_number: inputResult.value,
                        updated_by: this.userRegistId
                      };
                      this.service.updateCustomersById(customerId, model).subscribe((res) => {
                        console.log('Customer VAT updated with new value.');
                      });
                    }
                  });
                }
              });
              return;
            }
          }
  
          if (customerType === 5) {
            const customerVatNumber = Number(this.oneCustomerData.id_number);
            console.log(customerVatNumber, '5');
  
            if (customerVatNumber === 0 || customerVatNumber === null) {
              this.spinner.hide();
              Swal.fire({
                icon: 'warning',
                title: this.curentLang === 'ar' ? 'رقم الهوية للعميل غير صحيح' : (this.curentLang === 'en' ? 'The customer ID number is incorrect' : (this.curentLang === 'he' ? 'מספר הזהות של הלקוח שגוי' : '')),
                text: this.curentLang === 'ar' ? 'القيمه الافتراضيه : 999999998' : (this.curentLang === 'en' ? 'Default Value : 999999998' : (this.curentLang === 'he' ? 'ערך ברירת מחדל : 999999998' : '')),
                showCancelButton: true,
                confirmButtonText: this.curentLang === 'ar' ? 'ارسال القيمة الافتراضية' : (this.curentLang === 'en' ? 'Send Default Value' : 'קבל ערך ברירת מחדל'),
                cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : (this.curentLang === 'en' ? 'Edit Customer Data' : 'ערוך נתוני לקוח'),
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                  // إذا تم الضغط على زر "ارسال القيمة الافتراضية"
                  this.invoiceReceiptForm.patchValue({
                    customer_id_number: '999999998',
                    customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                    customer_vat_number: this.oneCustomerData?.customer_vat_number,
                  });
                  console.log(this.invoiceReceiptForm.value, 'this.invoiceform.value');
  
                  this.spinner.hide();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  // إذا تم الضغط على زر "تعديل بيانات العميل" نفتح نافذة إدخال SweetAlert
                  Swal.fire({
                    title: this.curentLang === 'ar' ? 'تحديث رقم الهوية للعميل' : 
                           (this.curentLang === 'en' ? 'Update Customer ID Number' : 'עדכון מספר זהות של הלקוח'),
                    input: 'text',
                    inputLabel: this.curentLang === 'ar' ? 'أدخل رقم الهوية الجديد' : 
                                (this.curentLang === 'en' ? 'Enter new ID number' : 'הזן מספר זהות חדש'),
                    inputValue: this.oneCustomerData.id_number, // القيم الحالية
                    showCancelButton: true,
                    confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 
                                       (this.curentLang === 'en' ? 'Update' : 'עדכון'),
                    cancelButtonText: this.curentLang === 'ar' ? 'إلغاء' : 
                                      (this.curentLang === 'en' ? 'Cancel' : 'בטל'),
                    inputValidator: (value) => {
                                        // التأكد من أن القيمة ليست فارغة، لا تبدأ بـ 0، لا تحتوي على أصفار فقط، ولا تقل عن 9 أرقام
                                        if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                                          return this.curentLang === 'ar' ? 'الرجاء إدخال رقم هوية صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 
                                                 (this.curentLang === 'en' ? 'Please enter a valid ID number with at least 9 digits and does not start with 0' : 'הזן מספר מס תקין עם לפחות 9 ספרות ושלא מתחיל ב-0');
                                        }
                                      }
                                      
                                      
                  }).then((inputResult) => {
                    if (inputResult.isConfirmed) {
                      this.invoiceReceiptForm.patchValue({
                        customer_id_number: inputResult.value,
                        customer_vat_number : this.oneCustomerData.customer_vat_number,
                        customer_name : this.oneCustomerData['customer_name_' + this.curentLang].substring(0, 25),
                      });
                      console.log(this.invoiceReceiptForm.value, 'updated invoiceform');
  
                      // تحديث بيانات العميل في الـ API
                      const model = {
                        id_number: inputResult.value,
                        updated_by: this.userRegistId
                      };
                      this.service.updateCustomersById(customerId, model).subscribe((res) => {
                        console.log('Customer ID updated with new value.');
                      });
                    }
                  });
                }
              });
              return;
            }
          }
  
          // تحديث النموذج ببيانات العميل المستلمة
          this.invoiceReceiptForm.patchValue({
            customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
            customer_id_number: this.oneCustomerData?.id_number,
            customer_vat_number: this.oneCustomerData?.customer_vat_number,
          });
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    }
  }

  GetCompany() 
{
    this.service.GetCompany().subscribe(res => {
      this.mastercompany = res;
    })
  };

  GetProducts() 
  {
    this.service.getAllActiveProductsBasedOnMerchantId().subscribe(res => {
      this.masterproduct = res;

    })
   };

  GetInvoiceTypeList()
  {
    this.spinner.show();

    this.service.GetInvoiceTypeList().subscribe(res => {
      this.masterInvoiceTypeList = res;
      this.spinner.hide();
    })
  };

  customerchange()
 {
    let customer_id = this.invoiceReceiptForm.get("customer_id")?.value;
    this.service.GetCustomerbyId(customer_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  };

  companychange()
  {
    let company_id = this.invoiceReceiptForm.get("company_id")?.value;
    this.service.GetCompanybyId(company_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  };
  getCompanyId(id: any) {
    this.spinner.show();
    this.companyId =  id;
    this.spinner.hide();    
  };

  getVatPercentageByVatListIdAndDate()
  {
    const vatListId = this.invoiceReceiptForm.get("vat_list_id")?.value;
    const invoiceDate = this.invoiceReceiptForm.get("invoice_date")?.value;
  
    
    if (vatListId && invoiceDate) {
      this.spinner.show();
      this.service.getVatPercentageByVatListIdAndDate(vatListId, invoiceDate).subscribe({
        next: res => {
          this.vatPersentageData = res;
          if (this.vatPersentageData && this.vatPersentageData.length > 0) {
            this.VatPercentage = this.vatPersentageData[0].vat_percentage;
            this.invoiceReceiptForm.patchValue({
              vat_percentage: this.VatPercentage // تحديث القيمة في النموذج
            });
            this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
          } else {
            // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
            this.invoiceReceiptForm.patchValue({
              vat_percentage: 0
            });
          }
        },
        error: error => {
           // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
          this.invoiceReceiptForm.patchValue({
            vat_percentage: 0
          });
        },
        complete: () => {
          this.spinner.hide(); // إخفاء المحمل بعد الحصول على البيانات بنجاح أو بعد حدوث خطأ
        }
      });
    } else {
      // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
      this.invoiceReceiptForm.patchValue({
        vat_percentage: 0
      });
      this.spinner.hide(); // إخفاء المحمل إذا لم يتم تحديد كلا من vatListId و invoiceDate
    }

    
  };
  
  filter(event:any)
  {
    let x = event;    
    
  };

  getId(id: number) {
    this.invoiceId = id;
  };
  vatTypechange() {
    this.spinner.show();

    this.service.GetVatTypeList().subscribe(res => {
      this.vatTypeList = res;
    });

    this.spinner.hide();
  };

  productchange(index: any) {
    this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

    // استماع للتغيرات على Total Amount
    this.invoiceproduct.get('total_amount').valueChanges.subscribe(newTotalAmount => {
        this.calculateReverse(index, newTotalAmount);
    });

    let product_id = this.invoiceproduct.get("product_id")?.value;

    this.service.GetProductbycode(product_id).subscribe(res => {
        let proddata: any;
        proddata = res;

        // التحقق من إذا كانت بيانات المنتج غير مكتملة
        if (proddata?.product_code === '' || proddata?.product_code === null ||
            proddata?.categories_id === null || proddata?.measurement_unit_id === null) {
            
            // عرض الرسالة التحذيرية وإدخال للمستخدم لإضافة الكود
            Swal.fire({
              icon: 'warning',
              title: this.curentLang === 'ar' ? ' برجاء استكمال معلومات المنتج والمحاولة مرة اخري' : 
                     (this.curentLang === 'en' ? 'Please complete the product information and try again' : 
                     (this.curentLang === 'he' ? 'אנא מלא את פרטי המוצר ונסה שוב' : '')),
              input: 'text', // إدخال نص للمستخدم
              inputPlaceholder: this.curentLang === 'ar' ? 'أدخل كود المنتج' : 
                                 (this.curentLang === 'en' ? 'Enter product code' : 
                                 (this.curentLang === 'he' ? 'הזן קוד מוצר' : '')),
              showCancelButton: true,
              confirmButtonText: this.curentLang === 'ar' ? 'إرسال' : 
                                 (this.curentLang === 'en' ? 'Submit' : 
                                 (this.curentLang === 'he' ? 'שלח' : '')),
              preConfirm: (value) => {
                if (!value || value.trim() === '') {
                    Swal.showValidationMessage(
                        this.curentLang === 'ar' ? 'لا يمكن إدخال قيمة فارغة أو مسافة فقط' : 
                        (this.curentLang === 'en' ? 'Empty or whitespace-only values are not allowed' : 
                        (this.curentLang === 'he' ? 'אין להזין ערך ריק או הכולל רק רווחים' : ''))
                    );
                    return false;
                }
                return value.trim();
              }
            }).then(result => {
                if (result.isConfirmed && result.value) {
                    let newProductCode = result.value;
                    this.invoiceproduct.get("catalog_id")?.setValue(newProductCode);

                    // إرسال الكود الجديد إلى الخادم
                    const model = {
                      product_code: newProductCode,
                      updated_by: this.userRegistId
                    };

                    this.spinner.show();
                    this.service.updateProductsById(product_id, model).subscribe((res) => {
                        console.log('Product ID updated with new value.');

                        // جلب البيانات المحدثة بعد التعديل
                        this.service.GetProductbycode(product_id).subscribe(updatedProdData => {
                            this.updateFormWithProductData(updatedProdData, index);
                        });
                    });
                    this.spinner.hide();
                }
            });

            return;
        }

        if (proddata != null) {
            this.updateFormWithProductData(proddata, index);
        }
    });
}

// دالة لتحديث حقول النموذج بقيم المنتج المحدثة
updateFormWithProductData(proddata: any, index: number) {
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    this.invoiceproduct.get("productName")?.setValue(proddata.name);
    this.invoiceproduct.get("price_per_unit")?.setValue(proddata.price);
    this.invoiceproduct.get("category")?.setValue(proddata.categories.categories_code);
    this.invoiceproduct.get("measure_unit_description")?.setValue(proddata.measurementUnits['measurement_unit_name_' + this.curentLang].substring(0, 20));
    this.invoiceproduct.get("measurement_unit_id")?.setValue(proddata.measurementUnits.measurement_unit_id);
    this.invoiceproduct.get("description")?.setValue(proddata['des_' + this.curentLang].substring(0, 30));
    this.invoiceproduct.get("catalog_id")?.setValue(proddata.product_code.substring(0, 13));
    this.invoiceproduct.get("item_name")?.setValue(proddata['product_name_' + this.curentLang]);
    this.Itemcalculation(index);
}

//new
onTotalAmountChange(index: number) {
  this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  const newTotalAmount = parseFloat(this.invoiceproduct.get("total_amount")?.value);
  if (!isNaN(newTotalAmount)) {
      this.calculateReverse(index, newTotalAmount);
  }
}


/// new
calculateReverse(index: any, newTotalAmount: number) {
  this.invoicedetail = this.invoiceReceiptForm.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  let quantity = this.invoiceproduct.get("quantity")?.value || 1; // التأكد من أن quantity ليس صفرًا
  let vat_rate = this.invoiceproduct.get("vat_rate")?.value || 0;
  let discount = this.invoiceproduct.get("discount")?.value || 0;

  // حساب Sub Total قبل تطبيق الضريبة
  let sub_total_before_vat = newTotalAmount / (1 + (vat_rate / 100));
  
  // حساب الخصم الكامل (يتم تطبيق الخصم على السعر الإجمالي للمنتجات)
  let total_without_discount = sub_total_before_vat + discount;

  // حساب السعر لكل وحدة
  let price_per_unit = total_without_discount / quantity;

  // حساب مبلغ الضريبة
  let vat_amount = parseFloat((sub_total_before_vat * (vat_rate / 100)).toFixed(2));

  // تعيين القيم المحسوبة إلى النموذج
  this.invoiceproduct.get("sub_total")?.setValue(sub_total_before_vat.toFixed(2));
  this.invoiceproduct.get("price_per_unit")?.setValue(price_per_unit.toFixed(2));
  this.invoiceproduct.get("vat_amount")?.setValue(vat_amount.toFixed(2));
  
  // تحديث القيم الإجمالية للفاتورة
  this.summarycalculation();
}
  

  
  Removeproduct(index: any)
  {
      this.invproducts.removeAt(index);
      this.summarycalculation();
      if( this.invproducts.length == 0){
        this.resumeVatPercentageSubscription();
      }
    
  };


}
