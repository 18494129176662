<app-homeheader></app-homeheader>
<app-about></app-about>
<app-sub-plans></app-sub-plans>

<app-terms-and-conditions></app-terms-and-conditions>



<div class="footer-area footer-style-two bg-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <!-- <img src="assets/img/logo.png" alt="logo"> -->
                        <h4 style="color: #141414;">{{ curentLang === 'en' ? 'THI INVOICES   ' : (curentLang === 'ar' ? '      THI INVOICES  '  : '    THI INVOICES   ')}}</h4>
                    </a>
                    <p>
                        
                    </p>
                    <ul class="social-links" style="color: #E62E2D;">
                        <li><a   href="https://www.facebook.com/THIOfficesJR/" target="_blank"><i class="fa fa fa-facebook-square" style="font-size: 20px;"></i></a></li>
                        <!-- <li><a   target="_blank"><i class="fa fa-twitter-square"></i></a></li>
                        <li><a   target="_blank"><i class="fa fa-linkedin-square"></i></a></li>
                        <li><a   target="_blank"><i class="fa fa-github-square"></i></a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-2">
                    <h3>{{ curentLang === 'en' ? 'Company' : (curentLang === 'ar' ? 'الشركة' : 'חֶברָה')}}</h3>
                    <ul class="links-list" style="color: #E62E2D;">
                        <li><a routerLink="/about"> {{ curentLang === 'en' ? 'About Us' : (curentLang === 'ar' ? 'من نحن' : 'מי אנחנו')}}</a></li>
                        <li><a  >{{ curentLang === 'en' ? 'Services  ' : (curentLang === 'ar' ? '  الخدمات' : '  שירותים')}}  </a></li>
               
                        <li><a >{{ curentLang === 'en' ? 'Reviews  ' : (curentLang === 'ar' ? '  الأراء' : '  דעות')}}</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{ curentLang === 'en' ? 'Support  ' : (curentLang === 'ar' ? '  الدعم' : '  התמיכה')}}</h3>
                    <ul class="links-list"  style="color: #E62E2D;">
                        <li><a  >{{ curentLang === 'en' ? 'Services  ' : (curentLang === 'ar' ? '  الخدمات' : '  שירותים')}}</a></li>
                        <li><a >{{ curentLang === 'en' ? 'Support  ' : (curentLang === 'ar' ? '  الدعم' : '  התמיכה')}}</a></li>
                        <li><a >{{ curentLang === 'en' ? 'Privacy Policy  ' : (curentLang === 'ar' ? 'سياسة الخصوصية' : '  מדיניות הפרטיות')}}</a></li>
                   
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{ curentLang === 'en' ? '   Company Address  ' : (curentLang === 'ar' ? ' عنوان الشركة   ' : '  כתובת החברה')}}</h3>
                    <ul class="links-list"  style="color: #E62E2D;">
                        <li><a >{{ curentLang === 'en' ? ' 22 Al-Asmai Street, Al-Quds Towers Building, Jerusalem ' : (curentLang === 'ar' ? '  شارع الأصمعي . عمارة 22 أبراج القدس، القدس ' : ' רחוב אל-אסמאי 22, בניין מגדלי אלקודס, ירושלים    ')}}</a></li>
                        
                      </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>{{ curentLang === 'en' ? 'Contact Us  ' : (curentLang === 'ar' ? '  تواصل معنا  ' : '  התחבר אלינו')}}</h3>
                    <p  style="color: #E62E2D;">{{ curentLang === 'en' ? 'For more information or to try out the billing management software, call:' : (curentLang === 'ar' ? 'للمزيد من المعلومات أو لتجربة برنامج إدارة الفواتير، قم بالإتصال على:'  : ' למידע נוסף או להתנסות בתוכנת ניהול החיובים, התקשר  :')}}</p>
                    <p  style="color: #E62E2D;"> 0547078885  <i class="fa fa-phone"></i></p>
                    <!-- <p  style="color: #E62E2D;"> <a   href="https://www.facebook.com/THIOfficesJR/" target="_blank" style="color: #E62E2D;">https://www.facebook.com/THIOfficesJR/ </a></p> -->
                    
                    <p  style="color: #E62E2D;">{{ curentLang === 'en' ? ' Or contact us via WhatsApp:' : (curentLang === 'ar' ? ' أو تواصل معنا عبر واتساب:    :  '  : '   או צור איתנו קשר דרך וואטסאפ : ')}}</p>
                   
                    
                        <a href="http://wa.me/+0547078885" target="_blank" rel="noopener noreferrer" action="share/whatsapp/share"
                        class="d-inline-flex justify-content-center align-items-center rounded-circle">
                        <span style="color: #E62E2D;">0547078885</span>
                        <i class="fa fa-whatsapp mx-1" style="color: #E62E2D;"></i>
                      </a>
                  

                </div>
                

            </div>
        </div>
        <div class="copyright-area">
            <p>{{ curentLang === 'en' ? 'Copyright' : (curentLang === 'ar' ? ' حقوق النشر ' : ' זכויות יוצרים')}} @{{year}} <strong>{{ curentLang === 'en' ? 'THI Offices' : (curentLang === 'ar' ? 'THI Offices '  : 'THI Offices  ')}}</strong>{{ curentLang === 'en' ? 'All Rights Reserved by' : (curentLang === 'ar' ? 'جميع الحقوق محفوظة بواسطة ' : 'כל הזכויות שמורות על ידי')}} </p>
        </div>
    </div>
</div>