<div class="gradient-main-banner">
    <div class="container-fluid">
        <div class="gradient-main-banner-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="content mt-4">
                      <h1 class="text-white">{{ curentLang === 'en' ? 'THI INVOICES' : (curentLang === 'ar' ? 'THI INVOICES'  : 'THI INVOICES')}}</h1>
                        <h2 style="color: #fff;">{{ curentLang === 'en' ? 'Discover THI INVOICES Management Software' : (curentLang === 'ar' ? 'اكتشف برنامج إدارة الفواتير من THI INVOICES'  : 'גלה תוכנת ניהול חשבוניות מ-THI INVOICES')}}</h2>
                        <p style="color: #fff;">{{ curentLang === 'en' ? 'the perfect solution for your business' : (curentLang === 'ar' ? 'الحل الأمثل لعملك'  : 'הפתרון המושלם לעסק שלך')}}</p> 
                        <a *ngIf="!Token" routerLink="/login" class="default-btn" style="background-color: #fff;">{{ curentLang === 'en' ? 'Get Started For Free' : (curentLang === 'ar' ? 'ابدأ مجاناً'  : 'התחל עכשיו')}}</a>
                        <a *ngIf="Token" routerLink="/dashboard/dashboard" class="default-btn" style="background-color: #fff;">{{ curentLang === 'en' ? 'Dashboard' : (curentLang === 'ar' ? 'لوحة التحكم'  : ' לוח הבקרה')}}</a>

                        <div *ngIf="showInstallButton" class="install-section">
                            <p style="color: #fff; font-size: 18px; margin: 10px;">
                              {{ curentLang === 'en' ? 'You can install this application as a desktop app.' : 
                              (curentLang === 'ar' ? 'يمكنك تثبيت هذا التطبيق كتطبيق سطح مكتب.' : 
                              'אתה יכול להתקין יישום זה כאפליקציית שולחן עבודה.')}}
                            </p>
                            <button class="default-btn" style="background-color: #fff;" (click)="installPWA()">
                              {{ curentLang === 'en' ? 'Install App' : (curentLang === 'ar' ? 'تثبيت التطبيق' : 'התקן אפליקציה')}}
                            </button>
                          </div>
                    </div>

                 
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="image">
                        <img src="../../../assets/bg/4023504.png" alt="banner-img" class="100">
                    </div>
                </div>
            </div>
            <div class="banner-shape2"><img src="../../../assets/shape/shape7.png" alt="image"></div>
            <div class="banner-shape9"><img src="../../../assets/shape/shape21.png" alt="image"></div>
            <div class="banner-shape10"><img src="../../../assets/shape/shape21.png" alt="image"></div>
            <div class="banner-shape11"><img src="../../../assets/shape/shape22.png" alt="image"></div>
            <div class="banner-shape3"><img src="../../../assets/shape2.png" alt="image"></div>
        </div>
    </div>
  </div>
  