<app-breadcrumb [title]="curentLang === 'en' ? 'Vat Info' : (curentLang === 'ar' ? ' معلومات ضريبة القيمة المضافة  ' : '   מס ערך מוסף ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Vat Info' : (curentLang === 'ar' ? ' معلومات ضريبة القيمة المضافة  ' : '   מס ערך מוסף')">
</app-breadcrumb>

<!--vat info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Vat Info' : (curentLang === 'ar' ? '  معلومات ضريبة القيمة المضافة ' : '  מס ערך מוסף')}}
        </h5>

       <div>
        <button class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add Vat' : (curentLang === 'ar' ? '  اضافة ضريبة    ' : '     הוסף מס ')}}
        </button>

       

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllVatList()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Vat Name En' : (curentLang === 'ar' ? '  أسم الضريبة عربي  ' : '    שם המס הוא ערבי ')}} </th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>

                   <th scope="col"> {{ curentLang === 'en' ? 'Vat Details' : (curentLang === 'ar' ? ' تفاصيل الضريبة  ' : '    פרטי מס    ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of vatInfoData | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> 
                    {{curentLang === 'en' ? item.vat_name_en : (curentLang === 'ar' ? item.vat_name_ar : item.vat_name_he)}}
                  </td>

                  <td>{{ item.createdDate | date:'dd/MM/yyyy'}}</td>

                  <td >
                    <i 
                    routerLink="/dashboard/vat-list-details/{{item?.vat_list_id}}"
                     class="fa fa-info-circle info cursor" style=" cursor: pointer; color: grey;"
                     title="Details" data-toggle="tooltip" data-placement="top" >
                   
                    </i>
                     </td>

                   <td  >
                    <button *ngIf="item.status==1" class=" btn" (click)="changeStatus(item?.vat_list_id, item.status)">
                        <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                    </button> 

                    <button *ngIf="item.status==59" class=" btn" (click)="changeStatus(item?.vat_list_id, item.status)">
                        <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                    </button> 

                  </td>

                   

                  <td>
                    <i class="fa fa-eye" style="color: grey;   cursor: pointer;"
                    (click)="executeFunctionsView(  item?.vat_list_id,viewData ) "></i>
                  </td>

                   <td>
                     <i class="fa fa-edit" style=" cursor: pointer; color: green;"
                     (click)="executeFunctions(  item?.vat_list_id,updatedData ) ">
                    </i>
                   </td>

                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>

<!--add vat-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Add Vat ' : (curentLang === 'ar' ? ' اضافة ضريبة ' : '     הוספת שיעור ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]=" addVat" novalidate [ngClass]="{ 'was-validated': validate }">
                        <div class="row">
                            <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Vat Name Ar' : (curentLang === 'ar' ? '  اسم الضريبة عربي   ' : '  שם החברה בערבית    ')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;"  onlyAlphabets required=""
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="vat_name_ar" 
                                  />
                           <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter vat name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الضريبة  ' : ' נא להזין את שם המס')}}  </div>
                              </div>
              
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Vat Name En' : (curentLang === 'ar' ? '  اسم الضريبة انجليزي   ' : '    שם מס באנגלית     ')}}
                                 </label>
                                  <input style="color: grey;"  onlyAlphabets required="" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="vat_name_en"
                                   />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter vat name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الضريبة  ' : ' נא להזין את שם המס')}}  </div>
                                </div>
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Vat Name He' : (curentLang === 'ar' ? '  اسم الضريبة عبري   ' : ' שם המס הוא עברי')}}
                                 </label>
                                  <input style="color: grey;"  onlyAlphabets required="" class="form-control"
                                   type="text" 
                                   formControlName="vat_name_he"  
                                   maxlength="80"
                                  />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter vat name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الضريبة  ' : ' נא להזין את שם המס')}}  </div>
                                   
                                </div>
              
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" 
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>

<!--update vat-->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '     ערוך מידע    ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]=" updateVat"  novalidate [ngClass]="{ 'was-validated': validateUpdate }">
                        <div class="row">
                          <div class="mb-3 col-xl-4 col-md-4 ">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Vat Name Ar' : (curentLang === 'ar' ? '  اسم الضريبة عربي   ' : '  שם החברה בערבית    ')}}
                              <span class="text-danger">*</span>
                           </label>
                            <input style="color: grey;"  onlyAlphabets required=""
                             class="form-control" maxlength="80" 
                             type="text" formControlName="vat_name_ar" 
                              />
                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter vat name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الضريبة  ' : ' נא להזין את שם המס')}}  </div>
                          </div>
          
                          <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Vat Name En' : (curentLang === 'ar' ? '  اسم الضريبة انجليزي   ' : '    שם מס באנגלית     ')}}
                             </label>
                              <input style="color: grey;"  onlyAlphabets required="" 
                              class="form-control"
                               type="text"
                               maxlength="80"
                                formControlName="vat_name_en"
                               />
                          <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter vat name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الضريبة  ' : ' נא להזין את שם המס')}}  </div>
                            </div>
          
                            <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Vat Name He' : (curentLang === 'ar' ? '  اسم الضريبة عبري   ' : ' שם המס הוא עברי')}}
                             </label>
                              <input style="color: grey;"  onlyAlphabets required="" class="form-control"
                               type="text" 
                               formControlName="vat_name_he"  
                               maxlength="80"
                              />
                          <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter vat name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الضريبة  ' : ' נא להזין את שם המס')}}  </div>
                               
                            </div>
              
                             

              
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button"  
                             class="btn btn-sm m-auto my-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updatedVatData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto my-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--view vat-->

<ng-template #viewData let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : '     הצג מידע      ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateVat" >
                        <div class="row">
                            <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Vat Name Ar' : (curentLang === 'ar' ? '  اسم الضريبة عربي   ' : '  שם החברה בערבית    ')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" readonly formControlName="vat_name_ar"
                                  />
                              </div>
              
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Vat Name En' : (curentLang === 'ar' ? '  اسم الضريبة انجليزي   ' : '    שם המס הוא ערבי   ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                    readonly
                                    formControlName="vat_name_en"
                                   />
                                </div>
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'vat Name He' : (curentLang === 'ar' ? '  اسم الضريبة عبري   ' : ' שם המס הוא עברי')}}
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="vat_name_he"  
                                  readonly
                                  />
                                   
                                </div>
              
                             

              
                        </div>
                  
                    
        
                       
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>