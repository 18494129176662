import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoginEndPoient } from "src/app/Data/service/global.service";
import { AuthService } from "../service/auth.service";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { israelPhoneNumberValidator } from "src/app/validators/israelPhoneNumberValidator";
const Swal = require('sweetalert2')

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})

export class LoginComponent implements OnInit {
  public show: boolean = false;
  userLanguage:string;
  curentLang:string="";
  LoginForm:FormGroup;
  
  constructor(@Inject(DOCUMENT) private document: Document  , 
    private spinner: NgxSpinnerService ,
    private authService:AuthService ,
    private Router:Router,
    private ToastrService:ToastrService ,
    public translate:TranslateService ,
    private loader: LoadingBarService
   ) 
   { 
     //Change Lang:
    this.curentLang = localStorage.getItem('curentLang')||'ar';
    this.changeLanguage(this.curentLang);
    this.LoginForm = new FormGroup({
      phone_number: new FormControl('' ,[Validators.required , israelPhoneNumberValidator()]),
      password: new FormControl('' , [Validators.required , Validators.pattern(/^(?=.*[!@#$%^&*()_\-+=<>?])[A-Z][A-Za-z0-9!@#$%^&*()_\-+=<>?]{3,20}$/)]),
  
    });    
 };


 

  submitLoginForm(RegisterForm: FormGroup) {
    if (
      this.LoginForm.value.phone_number == ''||
      this.LoginForm.value.password == ''
       ) 
      {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      
      return; 
    }
      
    else
    {
      let password = this.LoginForm.value.password;
      if (password.length < 8 || password.length > 20) {
          Swal.fire({
              icon: 'error',
              title: this.curentLang === 'ar' ? 'يجب أن تكون كلمة المرور أطول من أو تساوي 8 أحرف وأقل من أو تساوي 20 حرفًا' : 
                     (this.curentLang === 'en' ? 'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters' : 
                     (this.curentLang === 'he' ? 'הסיסמה חייבת להיות ארוכה מ-8 תווים וקצרה מ-20 תווים' : ''))
          });
          return;
      }

      let customerPhone = this.LoginForm.value.phone_number;
      if (customerPhone.startsWith('0')) {
        customerPhone = customerPhone.substring(1);
      }

      let model = {
        phone_number: '+972' + customerPhone,
        password: this.LoginForm.value.password,
      };
    
      this.spinner.show();
      this.authService.Login(LoginEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {          
    
            localStorage.setItem('token', res.accessToken);
         const Type =   localStorage.setItem('user_type', res.userData.user_type);
            localStorage.setItem('userType_en',res.userData.userType_en) ;  
            localStorage.setItem('user_id', res.userData.user_id);
            localStorage.setItem('office_id', res.userData.office_id);
            localStorage.setItem('merchant_id', res.userData.merchant_id);
            localStorage.setItem('companyDefault',res.userData.company_default);
            localStorage.setItem('curentLang', res.userData.language);
            this.authService.saveCurrentUser();
            

            if(res.userData.user_type === 100)
              {
                this.Router.navigate(['/dashboard/merchant-info']);
              }

              else if (res.userData.user_type === 300 )
                {
                  this.Router.navigate(['/dashboard/office-companies']);
                }
              else
              {
                this.Router.navigate(['/dashboard/dashboard']);
              }
            this.authService.isLogin.next(true)
         
       //Not Verified
      //  if (res.userData?.mverified === 0) {
      //   Swal.fire({
      //     icon: 'info',
      //     title:'Please verify your account',
      //   })
      //   this.Router.navigate(['/otp'])
      //   localStorage.setItem('user_id', res.userData.user_id);
      //   localStorage.setItem('merchant_id', res.userData.merchant_id);
      // }
        }
         else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }

  };

  changeLanguage(lang:string)
	{ 
	   
	  localStorage.setItem('curentLang', lang)
	  this.translate.use(lang);
	  if(lang == "en") {
		this.switchDirection('en', 'ltr');
	  }
	   else if(lang == "ar" ) 
	  {
		this.switchDirection('ar', 'rtl'); 
	  }

    else if( lang == "he") 
    {
      this.switchDirection('he', 'rtl');
    }
	};
  
  switchDirection(lang:string , dir:string)
	{
		document.body.setAttribute('direction', dir);
		this.document.getElementsByTagName('html')[0].lang = lang; 
        document.getElementsByTagName('html')[0].setAttribute('dir', dir);
		document.getElementsByTagName('html')[0].setAttribute('class',dir);
	}

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  } 

  showPassword() {
    this.show = !this.show;
  }
  }
