import { Component, OnInit } from "@angular/core";
import { HttpHelpService } from "../../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SubscribeEndPoient } from "../../service/global.service";

@Component({
  selector: "app-update-merchant",
  templateUrl: "./update-merchant.component.html",
  styleUrls: ["./update-merchant.component.scss"]
})

export class UpdateMerchantComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));

  merchantInfoData:any=[];
  id:number;
  planNameAr:string='';
  planNameEn:string='';
  planNamehE:string='';
  Price:string='';
  numberOfDocuments:number;
  numberOfCompany:number;
  numberOfUsers:number;
  status:number;
  amountPaid:number;
  paymentType:string='';
  SubscribeStatusAr:string='';
  SubscribeStatusEn:string='';
  SubscribeStatushE:string='';
  lookup_detail_id:number;
  MerchantNameEn:string;
  MerchantNameAr:string;
  MerchantNameHe:string;
  MerchantAddress:string;
  IdNumber:string;
  public validate = false;
  planData:any=[];
  addPlan:FormGroup;
  findUserId:any;
  userID:any;
  updateMerchant:FormGroup;
  increasePlan :FormGroup;
  public updatevalidate = false;

   newOrders = {
    colorClass: "primary",
    icon: "new-order",
    colorClass2: "warning",
    icon2: "customers",
    colorClass3: "success",
    icon4: "invoice",
    colorClass4: "secondary",
    icon3: "profit"
  };

  // updateMerchant=new FormGroup({
  //   merchant_name_en:new FormControl('' ),
  //   merchant_name_ar :new FormControl('' ),  
  //   merchant_name_he:new FormControl('', Validators.required),
  //   merchant_address:new FormControl(''),
  //   id_number:new FormControl(''),
  //   updated_by:new FormControl(''),
  // });

  
  constructor
  (  
     private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    activatedRoute:ActivatedRoute,
    private modalService: NgbModal, 
    private fb:FormBuilder
    ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.id = activatedRoute.snapshot.params['id'];
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));

    
   this.addPlan=new FormGroup({
    merchant_id:new FormControl(this.id),
    plan_id:new FormControl('' , Validators.required),
    payment_method:new FormControl('عن طريق العميل - مجاني'),
    user_id:new FormControl(this.userID),
    created_by:new FormControl(this.userRegistId), 
  });
  
  this.increasePlan=new FormGroup({
    numberOfDocuments:new FormControl(0),
    numberOfCompanies:new FormControl(0),
    numberOfUsers:new FormControl(0),
    numberOfDays:new FormControl(0),
    created_by:new FormControl(this.userRegistId), 
  });

  this.updateMerchant = this.fb.group({
    merchant_name_ar: ['', [this.noWhitespaceValidator()]],
    merchant_name_en: ['', [this.noWhitespaceValidator()]],
    merchant_name_he: ['', [Validators.required, this.noWhitespaceValidator()]],
    merchant_address: ['', [this.noWhitespaceValidator()]],
    id_number: ['',[this.noWhitespaceValidator() , Validators.required] ]
  });

  if (this.user_type === 100 || this.user_type === 101) {
    this.updateMerchant.controls['id_number'].disable();
  } else if (this.user_type === 200 || this.user_type === 201) {
    // Enable all controls if user type is 200 or 201
    this.updateMerchant.controls['id_number'].enable();
    this.updateMerchant.controls['merchant_name_ar'].enable();
    this.updateMerchant.controls['merchant_name_en'].enable();
    this.updateMerchant.controls['merchant_name_he'].enable();
    this.updateMerchant.controls['merchant_address'].enable();
  }
}

  getMerchantInfoById() {
    this.spinner.show();
    this.service.getMerchantInfoById(this.id).subscribe({
      next: res => {
        this.merchantInfoData = res;
   
        
  
        this.planNameEn = this.merchantInfoData[0]?.subscribe_plan_details[0]?.plan_name_en;
        this.planNameAr = this.merchantInfoData[0]?.subscribe_plan_details[0]?.plan_name_ar;
        this.planNamehE = this.merchantInfoData[0]?.subscribe_plan_details[0]?.plan_name_he;
        this.Price = this.merchantInfoData[0]?.subscribe_plan_details[0]?.price;
        this.numberOfDocuments = this.merchantInfoData[0]?.subscribe_to_plans[0]?.number_of_documents;
        this.numberOfCompany = this.merchantInfoData[0]?.subscribe_to_plans[0]?.number_of_company;
        this.numberOfUsers = this.merchantInfoData[0]?.subscribe_to_plans[0]?.number_of_users;
        this.status = this.merchantInfoData[0]?.subscribe_plan_details[0]?.status;
        this.amountPaid = this.merchantInfoData[0]?.subscribe_plan_details[0]?.amount_paid;
        this.paymentType = this.merchantInfoData[0]?.subscribe_plan_details[0]?.payment_method;
        this.lookup_detail_id = this.merchantInfoData[0]?.lookupDetailsSubscribe?.lookup_detail_id;
        this.SubscribeStatusAr = this.merchantInfoData[0]?.subscribe_plan_details[0]?.lookupDetailArName;
        this.SubscribeStatusEn = this.merchantInfoData[0]?.subscribe_plan_details[0]?.lookupDetailEnName;
        this.SubscribeStatushE = this.merchantInfoData[0]?.subscribe_plan_details[0]?.lookupDetailHeName;
        this.MerchantNameEn = this.merchantInfoData[0]?.merchant_name_en;
        this.MerchantNameAr = this.merchantInfoData[0]?.merchant_name_ar;
        this.MerchantNameHe = this.merchantInfoData[0]?.merchant_name_he;
        this.MerchantAddress = this.merchantInfoData[0]?.merchant_address;
        this.IdNumber = this.merchantInfoData[0]?.id_number

        // Loop through userId array to find user_id with user_type = 100
        this.findUserId = this.merchantInfoData[0]?.users;
        this. userID = null;
        this.findUserId?.forEach(user => {
          if (user.user_type === 100) {
          this.userID = user.user_id;
          }
        });
  
   
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };
  
  openXl(content:any) {
    this.modalService.open(content, { size: 'lg' });
  };
  getAllPans() {
    this.spinner.show();
    this.service.getAllPans().subscribe({
      next: res => {
        this.planData = res;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }

    noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    };
  }

  updateMerchantById() {
    const merchant_name_he = this.updateMerchant.value.merchant_name_he ? this.updateMerchant.value.merchant_name_he.trim() : '';
    const merchant_name_en = this.updateMerchant.value.merchant_name_en ? this.updateMerchant.value.merchant_name_en.trim() : '';
    const merchant_name_ar = this.updateMerchant.value.merchant_name_ar ? this.updateMerchant.value.merchant_name_ar.trim() : '';
    const merchant_address = this.updateMerchant.value.merchant_address ? this.updateMerchant.value.merchant_address.trim() : '';
    const id_number = this.updateMerchant.get('id_number')?.value ? this.updateMerchant.get('id_number')?.value.trim() : '';
  
    // Ensure the required fields are filled based on the user type
    if (!merchant_name_he) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' :
               (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' :
               (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
      this.updateMerchant.markAllAsTouched();
      this.validate = !this.validate;
      return;
    }
  
    // Ensure `id_number` is not empty for users of type 200 or 201
    if ((this.user_type === 200 || this.user_type === 201) && !id_number) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى إدخال رقم الهوية وعدم تركه فارغاً' :
               (this.curentLang === 'en' ? 'Please enter the ID number and do not leave it empty' :
               (this.curentLang === 'he' ? 'אנא הכנס את מספר הזהות ואל תשאיר אותו ריק' : ''))
      });
      this.updateMerchant.markAllAsTouched();
      this.validate = !this.validate;
      return;
    }
  
    let model: any = {
      merchant_name_en: merchant_name_en,
      merchant_name_ar: merchant_name_ar,
      merchant_name_he: merchant_name_he,
      merchant_address: merchant_address,
      updated_by: Number(this.userRegistId),
    };
  
    // Conditionally add id_number based on user type
    if (this.user_type !== 100 && this.user_type !== 101) {
      model.id_number = id_number ? Number(id_number) : null;
    }

  
  
    this.spinner.show();
    this.service.updateMerchantById(this.id, model).subscribe((res) => {
      if (res.status == 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang == 'ar' ? 'تم التعديل بنجاح' :
                 (this.curentLang == 'en' ? 'Updated successfully' : 'עודכן בהצלחה')
        });
        this.spinner.hide();
      } else if (res.status === 400 && res.res.message && res.res.message.length > 0) {
        const errorMessages = res.res.message;
        errorMessages.forEach(errorMessage => {
          Swal.fire({
            icon: 'error',
            title: this.curentLang == 'ar' ? 'خطأ' :
                   (this.curentLang == 'en' ? 'Error' : 'שְׁגִיאָה'),
          });
        });
        this.spinner.hide();
      }
    });
  }
  



  
  

filter(event:any)
{
  let x = event;
};

addedData() {
  let model = {
    merchant_id:Number (this.id),
    plan_id: Number(this.addPlan.value.plan_id),
    payment_method: this.addPlan.value.payment_method,
    user_id: Number(this.userID),
    created_by: Number(this.userRegistId),
  };
  this.spinner.show();
  this.service.Post(SubscribeEndPoient.POST, model).subscribe((res =>{
    if ( res.status === 400) {        
      if(this.curentLang == 'en') {
        Swal.fire({
          icon: 'error',
          title: res.messageEn,
        });
      } 

      if( this.curentLang == 'ar' ) {
        Swal.fire({
          icon: 'error',
          title: res.messageAr,
        });
      }

      if( this.curentLang == 'he' ) {
        Swal.fire({
          icon: 'error',
          title: res.messageHe,
        });
      }
    } 
    this.spinner.hide();
    if (res.status === 200 ) {
      if(this.curentLang == 'en') {
        Swal.fire({
          icon: 'success',
          title: res.messageEn,
        });
      } 
      if( this.curentLang == 'ar' ) {
        Swal.fire({
          icon: 'success',
          title: res.messageAr,
        });
      }
      if( this.curentLang == 'he' ) {
        Swal.fire({
          icon: 'success',
          title: res.messageHe,
        });
      }
      this.modalService.dismissAll();
      this.getAllPans();
      this.getMerchantInfoById();
     this.spinner.hide();
    //  this.router.navigate([`/dashboard/dashboard`]);

    } 
  }));
};

openXl2(content2:any) {
  this.modalService.open(content2, { size: 'lg' });
};


increasePlanDetailsByAdmin() {
  this.updatevalidate = true; // Always set to true to validate every time
  
  if (
    this.increasePlan.controls.numberOfDocuments.value == null && 
    this.increasePlan.controls.numberOfCompanies.value == null &&
    this.increasePlan.controls.numberOfUsers.value == null &&
    this.increasePlan.controls.numberOfDays.value == null
  ) {
    Swal.fire({
      icon: 'warning',
      title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة' : (this.curentLang === 'en' ? 'Please fill out the required fields' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים' : ''))
    });
    return;
  }
  
  let model = {
    numberOfDocuments: Number(this.increasePlan.value.numberOfDocuments),
    numberOfCompanies: Number(this.increasePlan.value.numberOfCompanies),
    numberOfUsers: Number(this.increasePlan.value.numberOfUsers),
    numberOfDays: Number(this.increasePlan.value.numberOfDays),
    created_by: Number(this.userRegistId),
  };

  this.spinner.show();
  this.service.increasePlanDetailsByAdmin(this.id, model).subscribe((res) => {
    this.spinner.hide();
    if (res.status === 200) {
      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
      });
      this.modalService.dismissAll();
      this.getAllPans();
      this.getMerchantInfoById();
      this.increasePlan.reset();
      this.increasePlan.patchValue({
        numberOfDocuments: 0,
        numberOfCompanies: 0,
        numberOfUsers: 0,
        numberOfDays: 0
      });
    } else {
      let errorMessage;
      switch (res.status) {
        case 400:
        case 403:
        case 404:
        case 500:
          errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          break;
        default:
          errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
      }
      Swal.fire({
        icon: 'error',
        title: errorMessage
      });
    }
  });
};





  ngOnInit() {
    this.getMerchantInfoById();
    this.getAllPans();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));

  };
};
