<div class="page-title-area bg-color">
  <div class="container">
      <div class="page-title-content">
          <h2 class="text-white">{{ curentLang === 'en' ? 'Subscription plans' : (curentLang === 'ar' ? 'خطط الاشتراك' : 'תוכניות מנוי')}}</h2>
          <ul>
              <li class="text-white"><a routerLink="/">{{ curentLang === 'en' ? 'Home/' : (curentLang === 'ar' ? 'الرئيسية/'  : 'רָאשִׁי/')}}</a></li>
              <li class="text-white">{{ curentLang === 'en' ? 'Subscription plans' : (curentLang === 'ar' ? 'خطط الاشتراك' : 'תוכניות מנוי')}}</li>
          </ul>
      </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
  </div>
  <div class="banner-shape1"><img src="../../../assets/shape/shape9.png" alt="image"></div>
</div>

<div class="container-fluid ">
  <div class="row ">
    <div class="col-sm-12">
      <div class="card">
          <div class="card-header ">
             </div>
        <div class="card-body">
          <div class="row pricingcontent">
            <div class="col-lg-4 col-md-6 my-2" *ngFor="let item of allActivePlan">
              <div class="pricingtable">
                <div class="pricingtable-header">
                  <h3 class="title" style="color:  #141414;"> {{ curentLang === 'en' ? item.plan_name_en : (curentLang === 'ar' ? item.plan_name_ar : item.plan_name_he) }}</h3>
                </div>
                <div class="price-value" style="background-color: #141414;">
                  <span class="currency">$</span>
                  <span class="h3">{{ item.price.substring(0, item.price.length - 3) }}</span>
                  <span class="duration"> / {{ curentLang === 'en' ? 'mo' : (curentLang === 'ar' ? 'ش' : 'חודש') }}</span>
              </div>
              
                <ul class="pricing-content text-center" style="color: #E62E2D;">
                  <li>{{item.number_of_documents}} {{ curentLang === 'en' ? 'Documents' : (curentLang === 'ar' ? 'مستندات' : 'מסמכים') }}</li>
                  <li>{{item.number_of_company}} {{ curentLang === 'en' ? 'Companies' : (curentLang === 'ar' ? 'شركات' : 'חברות') }}</li>
                  <li>{{item.number_of_users}} {{ curentLang === 'en' ? 'Users' : (curentLang === 'ar' ? 'مستخدمين' : 'משתמשים') }}</li>
                  <li>{{curentLang === 'en' ? item.description_en.substring(0, 20) : (curentLang === 'ar' ? item.description_ar.substring(0, 20) : item.description_he.substring(0, 20))}} </li>
                </ul>
                <div class="pricingtable-signup">
                  <a class="btn  btn-lg" style="background-color: #141414;" href="javascript:void(0)" routerLink="/register"> 
                  {{ curentLang === 'en' ? 'subscription' : (curentLang === 'ar' ? '  اشتراك' : '  מִנוּי') }} 
                  </a>
              </div>
              </div>
            </div>
    
          </div>
        </div>
      </div>
   
    </div>
  </div>
</div>