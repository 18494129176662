import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})

export class HomeComponent implements OnInit {
  curentLang:string="";
  year: number = new Date().getFullYear();
  constructor( private spinner: NgxSpinnerService , public translate:TranslateService) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
  }

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 1000);
  } 
}
