<ng-container *ngIf="!isInsideModal">
  <app-breadcrumb
    [title]="curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? ' معلومات المستخدمين' : 'מידע על משתמשים')"
    [items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['לוח הבקרה'])"
    [active_item]="curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? ' معلومات المستخدمين ' : 'מידע על משתמשים')">
  </app-breadcrumb>
</ng-container>

<!-- بقية الكود هنا -->




<!--Users info -->
<ng-container *ngIf="!isInsideModal">

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? 'معلومات المستخدمين ' : 'מידע על סוגי חשבוניות')}}
        </h5>

       <div>
        <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)" *ngIf="user_type == 200">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add User' : (curentLang === 'ar' ? ' اضافة مستخدم ' : 'הוסף משתמש  ')}}
        </button>

        <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl2(content2)" *ngIf="user_type == 100">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add User' : (curentLang === 'ar' ? ' اضافة مستخدم ' : 'הוסף משתמש  ')}}
        </button>
        

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getALLUsersByBasedToken()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'User Name' : (curentLang === 'ar' ? ' اسم المستخدم ' : ' שם המשתמש')}} </th>
                   <th scope="col">  {{ curentLang === 'en' ? 'Phone Number' : (curentLang === 'ar' ? '  رقم الهاتف  ' : ' מספר הטלפון')}}</th>
                   <th scope="col">  {{ curentLang === 'en' ? 'User Type' : (curentLang === 'ar' ? '  نوع المستخدم ' : ' סוג המשתמש')}}</th> 
                   <th scope="col">  {{ curentLang === 'en' ? 'Mverified' : (curentLang === 'ar' ? ' تم التحقق ' : ' מְאוּמָת  ')}}</th>
               
                   <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Privileges  ' : (curentLang === 'ar' ? '   الصلاحيات  ' : '  סמכויות ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of userData  | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td>{{item?.user_name}} </td>
                   <td>{{ formatPhoneNumber(item?.phone_number) }}</td>

                   <td *ngIf="item?.user_type==200" class="text-success" style="font-weight: 600;">
                    {{curentLang === 'en' ? 'Admin' : (curentLang === 'ar' ? 'مدير' : 'מנהל ')}}
                  </td>

                  <td *ngIf="item?.user_type==201" class="text-danger" style="font-weight: 600;">
                    {{curentLang === 'en' ? 'Admin Assistant' : (curentLang === 'ar' ? 'مساعد المدير' : 'עוזר במאי ')}}
                  </td>

                   <td *ngIf="item?.user_type==100" class="text-danger" style="font-weight: 600;">
                    {{curentLang === 'en' ? 'Merchant' : (curentLang === 'ar' ? 'تاجر' : 'סוֹחֵר ')}}
                  </td>

                   <td *ngIf="item?.user_type==101" class="text-danger" style="font-weight: 600;">
                    {{curentLang === 'en' ? 'Merchant User' : (curentLang === 'ar' ? 'مستخدم لدي التاجر ' : 'אני משתמש סוחר ')}}
                   </td>

                   

                   <td *ngIf="item?.mverified == 1">
                      <i class="fa fa-check-circle" style="font-size: 20px; color: green;"></i>
                   </td>
                   
                   <td *ngIf="item?.mverified == 0">
                    <i class="fa fa-close" style="font-size: 20px; color: red;"></i>
                 </td>
                

                 <td>
                    <button [disabled]="item.user_type === 200 || item.user_type === 100" *ngIf="item.user_status == 1" 
                    class="btn"
                     style="border: none;" 
                    (click)="changeUserStatus(item?.user_id, item.user_status)">
                      <i class="fa solid fa-solid fa-toggle-on" style=" color: #213FA6;"></i>
                    </button> 
                    
                    <button *ngIf="item.user_status == 59" class="btn" style="border: none;" (click)="changeUserStatus(item?.user_id, item.user_status)">
                      <i class="fa solid fa-solid fa-toggle-off" style=" color: red;"></i>
                    </button> 
                  </td>
                  


                   <td>{{ item.createdDate | date: 'dd/MM/yyyy' }}</td>



                   <td >
                    <i *ngIf="item.user_type == 101 || item.user_type == 201" 
                    routerLink="/dashboard/user-privileges/{{item?.user_id}}"
                     class="fa fa-file-archive-o"
                      style="font-size: 18px; cursor: pointer;"></i>

                      <i *ngIf="item.user_type == 100 || item.user_type == 200" 
                      
                       class="fa fa-lock"
                        style=" cursor: pointer; color: grey;"></i>

                     </td>


                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>
</ng-container>


<!--add user by admin  -->

<ng-template #content let-modal >
    <div class="modal-header d-flex justify-content-between" >
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Add user' : (curentLang === 'ar' ? ' اضافة مستخدم ' : '  הוסף משתמש')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                   
                <form  [formGroup]="addUser" novalidate [ngClass]="{ 'was-validated': validate }">
                    <div class="row">
                        <div class="mb-3 col-xl-6 col-md-6 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'User Name' : (curentLang === 'ar' ? ' اسم المستخدم ' : ' שם המשתמש')}} 
                           </label>
                            <input style="color: grey;" required="" onlyAlphabets
                             class="form-control" maxlength="80" 
                             type="text" formControlName="user_name" 
                              />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter user name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم المستخدم  ' : '      אנא הזן שם משתמש ')}}  </div>

                          </div>
                        <!---->
                          <div class="mb-3  col-xl-6 col-md-6 ">
                            <label class="col-form-label pt-0" style="color: #141414;">{{'Register.phone'|translate}}</label>
                            <div class="input-group">
                              <span style="color: #141414;" class="input-group-text">+972</span>
                              <input class="form-control" type="text" maxlength="10" required="" onlyAlphabets
                               placeholder="{{'Register.phone'|translate}}" formControlName="phone_number">
                            </div>
                            <div *ngIf="addUser.get('phone_number')?.errors != null && addUser.get('phone_number')?.touched" class="alert text-danger my-1">
                              <p *ngIf="addUser.get('phone_number')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                              <p *ngIf="addUser.get('phone_number')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{addUser.get('phone_number')?.errors?.invalidMobilePrefix.message}}</p>
                              <p *ngIf="addUser.get('phone_number')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{addUser.get('phone_number')?.errors?.invalidPhoneNumber.message}}</p>
                            </div>
                            <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter phone number' : (curentLang === 'ar' ? '   الرجاء ادخال رقم الهاتف  ' : '      אנא הזן מספר טלפון')}}  </div>

                            </div>

                                     <!---->
                                     <div class="mb-3 col-xl-6 col-md-6 ">
                                        <label class="form-label" style="color: grey;"> 
                                          {{ curentLang === 'en' ? 'User Type' : (curentLang === 'ar' ? 'نوع المستخدم ' : 'סוג משתמש')}}
                                       </label>
                                        <select class="js-example-basic-single col-xl-12 job-select2 form-select-sm" required="" onlyAlphabets
                                         style="border: solid 1px grey; "
                                        (change)="filter($event)" formControlName="user_type">
                                    
                                        <option [value]="200">
                                            {{curentLang === 'en' ? 'Admin' : (curentLang === 'ar' ? 'مدير' : 'מנהל')}}
                                        </option>
                                        <option [value]="201">
                                        {{curentLang === 'en' ? 'Admin Assistant' : (curentLang === 'ar' ? 'مساعد المدير' : 'עוזר במאי')}}
                                        </option>
                                       </select>
                                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please choose user type ' : (curentLang === 'ar' ? '      الرجاء اختيار نوع المستخدم        ' : '         אנא בחר סוג משתמש      ')}}  </div>

                                      </div>

                                      
                               <!---->
                               <div class="mb-3  col-xl-6 col-md-6 ">
                                <div class="form-group">
                                    <label class="form-label"style="color: grey;">{{'Login.password'|translate}}</label>
                                    <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" 
                                     formControlName="password" required="" onlyAlphabets
                                     placeholder="*********">
                                    <div *ngIf="addUser.get('password')?.errors != null && addUser.get('password')?.touched" class=" alert text-danger my-1">
                                      <p  *ngIf="addUser.get('password')?.errors?.required" class=" "> {{'Login.error18'|translate}}</p>
                                              <ul  *ngIf="addUser.get('password')?.errors?.pattern && addUser.get('password')?.touched" class="text-start"> 
                                                  <li>{{'Login.error19'|translate}}</li>
                                                  <li>{{'Login.error20'|translate}}</li>
                                                  <li>{{'Login.error21'|translate}}</li>
                                                  <li>{{ curentLang === 'en' ? 'Password does not match the criteria' : (curentLang === 'ar' ? 'كلمة المرور غير مطابقة للمعايير' : 'הסיסמה אינה תואמת את הקריטריונים') }}

                                              </ul>
                                       </div>
                                  <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show-1"></span></div>
                                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide-1"></span></div> 
                                  </div>
                                 
                              </div>


                      
                    </div>
              
                      <div class="modal-footer">

                        <button type="button" 
                         class="btn btn-sm m-auto mt-2" 
                        style="background-color: #141414; color: #fff;"
                        [disabled]="addUser.get('password').invalid"
                         (click)="addedData()"
                         > 
                          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                      </button>
                  
                          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                              <i class="fa fa-rotate-left mx-1"></i>
                          </button>
                  
                        
                  
                      </div>
                  </form> 
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--add user by merchant  -->

<ng-template #content2 let-modal >
    <div class="modal-header d-flex justify-content-between" >
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Add user' : (curentLang === 'ar' ? ' اضافة مستخدم ' : '  הוסף משתמש')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                   
                <form  [formGroup]="merchantUser" novalidate [ngClass]="{ 'was-validated': validate }">
                    <div class="row">
                        <div class="mb-3 col-xl-12 col-md-12 ">
                            <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'User Name' : (curentLang === 'ar' ? ' اسم المستخدم ' : ' שם המשתמש')}} 
                           </label>
                            <input style="color: grey;" required="" onlyAlphabets
                             class="form-control" maxlength="80" 
                             type="text" formControlName="user_name" 
                              />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter user name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم المستخدم  ' : '      אנא הזן שם משתמש ')}}  </div>

                          </div>
                        <!---->
                          <div class="mb-3  col-xl-12 col-md-12 ">
                            <label class="col-form-label pt-0" style="color: #141414;">{{'Register.phone'|translate}}</label>
                            <div class="input-group">
                              <span style="color: #141414;" class="input-group-text">+972</span>
                              <input class="form-control" type="text" maxlength="10" required="" onlyAlphabets placeholder="{{'Register.phone'|translate}}" formControlName="phone_number">
                            </div>
                            <div *ngIf="merchantUser.get('phone_number')?.errors != null && merchantUser.get('phone_number')?.touched" class="alert text-danger my-1">
                              <p *ngIf="merchantUser.get('phone_number')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                              <p *ngIf="merchantUser.get('phone_number')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{merchantUser.get('phone_number')?.errors?.invalidMobilePrefix.message}}</p>
                              <p *ngIf="merchantUser.get('phone_number')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{merchantUser.get('phone_number')?.errors?.invalidPhoneNumber.message}}</p>
                            </div>
                            <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter phone number' : (curentLang === 'ar' ? '   الرجاء ادخال رقم الهاتف  ' : '      אנא הזן מספר טלפון')}}  </div>

                            </div>

                               <!---->
                               <div class="mb-3  col-xl-12 col-md-12 ">
                                <div class="form-group">
                                    <label class="form-label"style="color: grey;">{{'Login.password'|translate}}</label>
                                    <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"  formControlName="password" required="" onlyAlphabets placeholder="*********">
                                    <div *ngIf="merchantUser.get('password')?.errors != null && merchantUser.get('password')?.touched" class=" alert text-danger my-1">
                                      <p  *ngIf="merchantUser.get('password')?.errors?.required" class=" "> {{'Login.error18'|translate}}</p>
                                              <ul  *ngIf="merchantUser.get('password')?.errors?.pattern && addUser.get('password')?.touched" class="text-start"> 
                                                  <li>{{'Login.error19'|translate}}</li>
                                                  <li>{{'Login.error20'|translate}}</li>
                                                  <li>{{'Login.error21'|translate}}</li>
                                                  <li>{{ curentLang === 'en' ? 'Password does not match the criteria' : (curentLang === 'ar' ? 'كلمة المرور غير مطابقة للمعايير' : 'הסיסמה אינה תואמת את הקריטריונים') }}

                                              </ul>
                                       </div>
                                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div> 
                                  </div>
                                 
                              </div>


                      
                    </div>
              
                      <div class="modal-footer">

                        <button type="button" 
                         class="btn btn-sm m-auto mt-2" 
                        style="background-color: #141414; color: #fff;"
                        [disabled]="merchantUser.get('password').invalid"
                         (click)="addMerchantUser()"
                         > 
                          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                      </button>
                  
                          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                              <i class="fa fa-rotate-left mx-1"></i>
                          </button>
                  
                        
                  
                      </div>
                  </form> 
              
            </div>
            
          </div>
        
        </div>
    </div>
   

</ng-template>







<!-- Users modal Info -->
<ng-container *ngIf="isInsideModal">

  <div class="container-fluid basic_table" >

    <div class="card-header d-flex justify-content-between">
      <h5 style="color: grey;"> 
          {{ curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? 'معلومات المستخدمين ' : 'מידע על סוגי חשבוניות')}}
      </h5>

     <div>
      <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)" *ngIf="user_type == 200">
          <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add User' : (curentLang === 'ar' ? ' اضافة مستخدم ' : 'הוסף משתמש  ')}}
      </button>

      <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl2(content2)" *ngIf="user_type == 100">
          <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add User' : (curentLang === 'ar' ? ' اضافة مستخدم ' : 'הוסף משתמש  ')}}
      </button>
      

      <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getALLUsersByBasedToken()">
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
    </button>

       </div>
   
    </div>
    <div class="row">
      <div class="col-sm-5">
          <div class="form-group search  my-3">
            <label for="seaarch"   style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
               <input class="form-control  w-100" type="text" name="search"
                [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
          </div>
      </div>
  </div>


    <div class="row">
<div class="col-sm-12">
   
    <div class="card"  id="headingTwo"
     *ngFor="let item of userData  | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
    
            <p   
            
            (click)="toggleCollapse(i)" 
            [attr.aria-expanded]="openedIndex === i" 
            aria-controls="collapse"
            style="cursor: pointer; background-color: #FB4438;" 
            class="py-1">
              <i class="fa fa-user-circle mx-1"  style="color: #fff; font-size: 16px;" > </i>
              <span style="color: #fff; font-size: 16px;" class="mx-2">{{item?.user_name}}</span>
            </p>
      
             
            <div class="row d-flex justify-content-between">

              <div class="col-md-12  col-sm-12 ">
                <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">

               
                <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Phone Number' : (curentLang === 'ar' ? '  رقم الهاتف  ' : ' מספר הטלפון')}}
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >{{ formatPhoneNumber(item?.phone_number) }}</span>
                    <span class="col-sm-4   text-end ">
                      <i class="fa fa-phone-square px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >
                 <!---->
      
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'User Type' : (curentLang === 'ar' ? '  نوع المستخدم ' : ' סוג המשתמש')}}
                  </span>
                  

                      <span *ngIf="item?.user_type==200" class="text-success text-center col-sm-4" style="font-weight: 600;">
                        {{curentLang === 'en' ? 'Admin' : (curentLang === 'ar' ? 'مدير' : 'מנהל ')}}
                      </span>
    
                      <span *ngIf="item?.user_type==201" class="text-danger text-center col-sm-4" style="font-weight: 600;">
                        {{curentLang === 'en' ? 'Admin Assistant' : (curentLang === 'ar' ? 'مساعد المدير' : 'עוזר במאי ')}}
                      </span>
    
                       <span *ngIf="item?.user_type==100" class="text-danger text-center col-sm-4" style="font-weight: 600;">
                        {{curentLang === 'en' ? 'Merchant' : (curentLang === 'ar' ? 'تاجر' : 'סוֹחֵר ')}}
                      </span>
    
                       <span *ngIf="item?.user_type==101" class="text-danger text-center col-sm-4" style="font-weight: 600;">
                        {{curentLang === 'en' ? 'Merchant User' : (curentLang === 'ar' ? 'مستخدم لدي التاجر ' : 'אני משתמש סוחר ')}}
                       </span>
                       <span class="col-sm-4   text-end  ">
                        <i class="fa fa-user px-5 "  style="color: #141414; font-size: 16px ;" ></i>
                      </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >

                 <!---->
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                    {{ curentLang === 'en' ? 'Mverified' : (curentLang === 'ar' ? ' تم التحقق ' : ' מְאוּמָת  ')}}
                  </span>
                  
                  <div class="text-center col-sm-4   ">  
                    
                  </div>
                    <span style="color: #141414;   " class="text-end col-sm-4  " *ngIf="item?.mverified == 1" >
                      <i class="fa fa-check-circle px-5" style="font-size: 16px; color: green;"></i>
                  </span>

                    <span class="col-sm-4    text-end " *ngIf="item?.mverified == 0" >
                      <i class="fa fa-close px-5" style="font-size: 16px; color: red;"></i>   
                   </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >

                   <!---->
                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}
                    </span>
                    
                    <div class="text-center col-sm-4  ">  
                      
                    </div>
                      <span style="color: #141414;   " class="text-end col-sm-4" *ngIf="item?.mverified == 1" >
                        
                        <span class="col-sm-4   text-end  ">
                          <button [disabled]="item.user_type === 200 || item.user_type === 100" *ngIf="item.user_status == 1" 
                          class="btn px-5"
                           style="border: none;" 
                          (click)="changeUserStatus(item?.user_id, item.user_status)">
                            <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6; "></i>
                          </button> 
                          
                          <button *ngIf="item.user_status == 59" class="btn px-5" style="border: none;" (click)="changeUserStatus(item?.user_id, item.user_status)">
                            <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                          </button> 
                        </span>
                          
                        
                    </span>
  
                 
                   </div>
                   <hr style="border: solid 1px #141414;" > 
                       <!---->
                   
                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>

                    </span>
                    
                    <span  style="color: #141414; font-size: 14px; " class="text-center col-sm-4" >
                      {{ item.createdDate | date: 'dd/MM/yyyy' }}
                    </span>
                
                      <span style="color: #141414;   " class="text-end col-sm-4  " >
                        <i class="fa fa-history px-5" style="font-size: 16px; color: #141414;"></i>
                    </span>
  
                   
                   </div>
                   <hr style="border: solid 1px #141414;" >
                 
                    <!---->
                 
                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      <th scope="col"> {{ curentLang === 'en' ? 'Privileges  ' : (curentLang === 'ar' ? '   الصلاحيات  ' : '  סמכויות ')}}</th>

                    </span>
                    
                    <div   class="text-center col-sm-4" >
                      
                    </div>
                
                      <span style="color: #141414;   " class="text-end col-sm-4  " >
                        <i *ngIf="item.user_type == 101 || item.user_type == 201" 
                        
                        routerLink="/dashboard/user-privileges/{{item?.user_id}}"
                         class="fa fa-file-archive-o px-5"
                          style="font-size: 16px; cursor: pointer;"></i>
    
                          <i *ngIf="item.user_type == 100 || item.user_type == 200" 
                          
                           class="fa fa-lock px-5"
                            style=" cursor: pointer; font-size: 16px; color: grey;"></i>
                    </span>
  
                   
                   </div>
                   <hr style="border: solid 1px #141414;" >






                </div>
              </div>

            </div>
            <hr style="color: #141414; border: solid 1px;">
    </div>

    <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>    




  </div>

    </div>
</div>
</ng-container>





