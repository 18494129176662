// import { Router } from '@angular/router';
// //httpConfig.interceptor.ts
// import {
//   HttpRequest,
//   HttpHandler,
//   HttpEvent,
//   HttpInterceptor,
//   HttpResponse,
//   HttpErrorResponse
// } from '@angular/common/http';
// import { Observable, of, throwError } from 'rxjs';
// import { map, catchError } from 'rxjs/operators';
// import { Injectable } from '@angular/core';


// @Injectable()
// export class HttpConfigInterceptor implements HttpInterceptor {
//   loaderToShow: any;
//   constructor(
//     private _Router:Router
//     ) { }

//     private handleAuthError(err: HttpErrorResponse): Observable<any> {
//       //handle your auth error or rethrow
//       if(err.status === 403) {
//           this._Router.navigateByUrl(`/login`);
//           return of(err); // or EMPTY may be appropriate here
//       }
//       return throwError(err);
//   }



//   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

//     const token = localStorage.getItem('token');
//     // const companyId =  localStorage.getItem("companyId");
//     const userId = localStorage.getItem("user_id");

//     //Authentication by setting header with token value
//     if(token) {
//       request = request.clone({
//         setHeaders: {
//           'Authorization':'Bearer '+ token,
//           // 'companyId': companyId,
//            'user_id': userId, 
//           // 'Content-Type': 'application/json'
//         }
//       });
//     }

//     // if (!request.headers.has('Content-Type')) {
//     //   request = request.clone({
//     //     setHeaders: {
//     //       'content-type': 'application/json'
//     //     }
//     //   });
//     // }

//     // request = request.clone({
//     //   headers: request.headers.set('Accept', 'application/json')
//     // });
//       // this.ngxLoader.start();
//       return next.handle(request).pipe(
//         map((event: HttpEvent<any>) => {
//           if(event instanceof HttpResponse) {

//           }
//           // this.ngxLoader.stop();
//           return event;
//         }),
//         catchError((error: HttpErrorResponse) => {

//        //  this.ngxLoader.stop();
//        this.handleAuthError(error);

//        return throwError(error);
//       }));

//   } 





// }



import { Router } from '@angular/router';
// httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr'; // إذا كنت تستخدم Toastr لإظهار الرسائل

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  loaderToShow: any;
  curentLang: string = '';

  constructor(
    private _Router: Router,
    private toastr: ToastrService
  ) {
    this.curentLang = localStorage.getItem('curentLang');
  }

  // دالة لمعالجة خطأ الجلسة المنتهية
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
      // استدعاء Toast لعرض رسالة انتهاء الجلسة
      this.showSessionExpiredToast();
      
      // مسح بيانات الجلسة وتوجيه المستخدم إلى صفحة تسجيل الدخول
      this.clearSessionData();
      this._Router.navigateByUrl(`/login`);

      return of(err);
    }
    return throwError(err);
  }

  // دالة لمسح بيانات الجلسة
  private clearSessionData() {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    // يمكنك مسح المزيد من البيانات إذا لزم الأمر
  }

  // دالة لإظهار رسالة انتهاء الجلسة باستخدام Toast
  private showSessionExpiredToast() {
    this.toastr.warning(
      this.curentLang === 'ar'
        ? 'لقد انتهت جلستك. يرجى تسجيل الدخول مرة أخرى لمتابعة استخدام النظام.'
        : this.curentLang === 'en'
          ? 'Your session has expired. Please log in again to continue using the system.'
          : 'המושב שלך פג תוקף. אנא התחבר מחדש כדי להמשיך להשתמש במערכת.',
      'Warning'
    );
  }
  

  // دالة لإضافة التوكن إلى الهيدر
  private addTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('user_id');

    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + token,
          'user_id': userId
        }
      });
    }
    return request;
  }

  // دالة interceptor لمعالجة الطلبات
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addTokenToRequest(request);

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // التعامل مع الاستجابة الناجحة هنا إذا لزم الأمر
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // استدعاء الدالة لمعالجة الأخطاء
        return this.handleAuthError(error);
      })
    );
  }
}
