import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { FormControl, FormGroup } from "@angular/forms";
import Swal from 'sweetalert2';
import { AddInoviceTypeEndPoient } from "../service/global.service";

@Component({
  selector: "app-invoices-types",
  templateUrl: "./invoices-types.component.html",
  styleUrls: ["./invoices-types.component.scss"]
})

export class InvoicesTypesComponent implements OnInit {
    
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addInvoiceType:FormGroup;
  updateInvoiceType:FormGroup;
  customerDataMerchant:any=[];
  invoiceTypeId: number;
  updatedData: any;
  viewData: any;
  invoicesListData:any=[];


  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

   this.addInvoiceType=new FormGroup({
    invoice_type_name_ar :new FormControl(''),
    invoice_type_name_en:new FormControl(''),
    invoice_type_name_he :new FormControl(''),  
    invoice_type_code: new FormControl(null),
    created_by:new FormControl(this.userRegistId),
    });

    this.updateInvoiceType=new FormGroup({
      invoice_type_name_ar :new FormControl(''),
      invoice_type_name_en:new FormControl(''),
      invoice_type_name_he :new FormControl(''),  
      invoice_type_code: new FormControl(null),
      updated_by:new FormControl(this.userRegistId),
      });
  };

  addedData() {
    if (
      this.addInvoiceType.value.invoice_type_name_ar == ''||
      this.addInvoiceType.value.invoice_type_name_en == ''||
      this.addInvoiceType.value.invoice_type_name_he == ''||
      this.addInvoiceType.value.invoice_type_code == null
              ) 
      {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      
      return; 
    }
      
    else
    {
      let model = {
        invoice_type_name_ar: this.addInvoiceType.value.invoice_type_name_ar,
        invoice_type_name_en: this.addInvoiceType.value.invoice_type_name_en,
        invoice_type_name_he: this.addInvoiceType.value.invoice_type_name_he,
        invoice_type_code: Number(this.addInvoiceType.value.invoice_type_code),
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddInoviceTypeEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllInvoicesTypes();
          this.addInvoiceType.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }

  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  setValue(invoiceTypeId: number) {
    for (let i = 0; i < this.invoicesListData.length; i++) {
      if (this.invoicesListData[i].invoice_type_id == this.invoiceTypeId) {
        const selectedCategory = this.invoicesListData.find(item => item.invoice_type_id === invoiceTypeId);
        this.updateInvoiceType.controls.invoice_type_name_ar.setValue(this.invoicesListData[i].invoice_type_name_ar);
        this.updateInvoiceType.controls.invoice_type_name_en.setValue(this.invoicesListData[i].invoice_type_name_en);
        this.updateInvoiceType.controls.invoice_type_name_he.setValue(this.invoicesListData[i].invoice_type_name_he);
        this.updateInvoiceType.controls.invoice_type_code.setValue(this.invoicesListData[i].invoice_type_code);
      }
    };
  };

  executeFunctions(invoiceID: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(invoiceID);
    this.setValue(invoiceID);
    this.updateMd(this.updatedData);
  };

  updateInvoicesTypesById() {

    let model = {
      invoice_type_name_ar: this.updateInvoiceType.value.invoice_type_name_ar,
      invoice_type_name_en: this.updateInvoiceType.value.invoice_type_name_en,
      invoice_type_name_he: this.updateInvoiceType.value.invoice_type_name_he,
      invoice_type_code: Number(this.updateInvoiceType.value.invoice_type_code),
      updated_by: Number(this.userRegistId),
    };
  
    this.spinner.show();
    this.service.updateInvoicesTypesById( this.invoiceTypeId, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getAllInvoicesTypes();
      } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });


};
  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  executeFunctionsView(ProductID: number, view: any) {
    this.viewData = view;
    this.getId(ProductID);
    this.setValue(ProductID);
    this.updateViewMd(this.viewData);
  };
  
  updateViewMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  getAllInvoicesTypes() {
    this.spinner.show();
    this.service.getAllInvoicesTypes().subscribe({
      next: res => {
        this.invoicesListData = res;
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  changeStatus(id:number, status:any){
    this.spinner.show();
  
    let model =
    {
      "status": status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeInvoicesTypesStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllInvoicesTypes();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  getId(id: number) {
    this.invoiceTypeId = id;
  };

  filter(event:any)
  {
    let x = event.target.value;
   };
  openVerticallyCentered(dataDeleted:any) {
    this.modalService.open(dataDeleted, { centered: true });
  };
  
  deleteInvoicesTypesById() {
    this.spinner.show();
    this.service.deleteInvoicesTypesById(this.invoiceTypeId).subscribe({
      next: (res) => {
        if (res.status == 400) {
          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.spinner.hide();
          return;
        } else {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.getAllInvoicesTypes();
          this.modalService.dismissAll();
          this.spinner.hide();
        }
      },
      error: (err) => {
        Swal.fire(err.messageAr);
        this.spinner.hide(); 
      }
    });
  };

  ngOnInit() 
  {
  this.getAllInvoicesTypes();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
}
