<app-breadcrumb [title]="curentLang === 'en' ? 'Accounting Office Info' : (curentLang === 'ar' ? ' معلومات مكاتب المحاسبة  ' : '  מידע משרד רואי חשבון  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Accounting Office Info' : (curentLang === 'ar' ? ' معلومات مكاتب المحاسبة  ' : '    מידע משרד רואי חשבון     ')">
</app-breadcrumb>

<!--Accounting Office info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Accounting Office Info' : (curentLang === 'ar' ? '  معلومات مكاتب المحاسبة ' : '  מידע משרד רואי חשבון  ')}}
        </h5>

       <div>
        <button   class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add Accounting Office' : (curentLang === 'ar' ? '  اضافة مكتب محاسبة' : '   הוסף משרד רואי חשבון ')}}
        </button>

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllAccountingOffice()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Office Name ' : (curentLang === 'ar' ? '  أسم المكتب   ' : ' שם המשרד  ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Office Address' : (curentLang === 'ar' ? '   عنوان المكتب  ' : '  כתובת משרד  ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Office Phone' : (curentLang === 'ar' ? '   هاتف المكتب  ' : 'טלפון במשרד  ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Email' : (curentLang === 'ar' ? '   البريد الإلكتروني ' : ' דואר אלקטרוני  ')}}</th>
                   <th scope="col">                            
                      {{ curentLang === 'en' ? '  Tax Number' : (curentLang === 'ar' ? 'الرقم الضريبي   ' : ' מספר מס')}}  
                    </th>

                    <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                    <th scope="col"> {{ curentLang === 'en' ? 'Office Users' : (curentLang === 'ar' ? ' مستخدمين المكتب ' : '    משתמשי השולחן')}}</th>

                    <th scope="col"> {{ curentLang === 'en' ? 'Add Company  ' : (curentLang === 'ar' ? ' اضافة شركة ' : 'הוסף חברה ')}}</th>
                    <th scope="col"> {{ curentLang === 'en' ? 'View Companies  ' : (curentLang === 'ar' ? 'عرض الشركات ' : ' לצפות בחברות ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th> -->

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of accountingOfficeData | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ; let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td>{{item.office_name.substring(0, 20)}}</td>

                   <td>{{item.office_address}}</td>
                   <td>{{item.office_phone}}</td>
                   <td>{{item.email}}</td>
                   <td>{{item.tax_number}}</td>
                   
                    <td>{{ item.createdDate | date: 'dd/MM/yyyy' }}</td>

                    <td>
                      <i class="fa fa-user-circle-o" style="color: rgb(0, 0, 0);  font-size: 15px; cursor: pointer;"
                      routerLink="/dashboard/all-office-users/{{item.office_id}}" ></i>
                    </td>

                    <td>
                        <i class="fa fa-plus-circle" style="color: rgb(0, 0, 0);  font-size: 15px; cursor: pointer;"
                        (click)="getId(item.office_id);openXl2(content2)"></i>
                      </td>

                      <td>
                        <i class="fa fa-building-o" style="color: rgb(0, 0, 0);  font-size: 15px; cursor: pointer;"
                        routerLink="/dashboard/accounting-offices-companies/{{item.office_id}}"></i>
                      </td>

                   <td>

                 
                    <button  *ngIf="item.office_status==1" class=" btn" style="border: none;" (click)="changeStatus(item?.office_id, item.office_status)">
                        <i class="fa solid fa-solid fa-toggle-on " style="font-size: 15px; color: #213FA6;" ></i>
                    </button> 

                    <button  *ngIf="item.office_status==59" class=" btn"  style="border: none;" (click)="changeStatus(item?.office_id, item.office_status)">
                        <i class="fa solid fa-solid fa-toggle-off " style="font-size: 15px; color: red;"></i>
                    </button> 

                  </td>

                   

                  <td>
                    <button class=" btn" style="border: none;" (click)="executeFunctionsView(  item?.office_id,viewData ) ">
                        <i class="fa fa-eye" style="color: grey;  font-size: 15px; cursor: pointer;"></i>
                    </button>
                  </td> 

                  <td >
                  <button  class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.office_id,updatedData ) ">
                    <i class="fa fa-edit" style="font-size: 15px; cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td>

                   <!-- <td >
                    <button [disabled]="user_type == 200" 
                    [ngStyle]="{ 'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.office_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style="font-size: 22px; cursor: pointer;"></i>
                      </button>
                     </td> -->

                </tr>
          
              </tbody>
            </table>
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>



<!--add Accounting Office-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Add Accounting Office' : (curentLang === 'ar' ? '  اضافة مكتب محاسبة' : '   הוסף משרד רואי חשבון ')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                       <form  [formGroup]="addAccountingOffice" novalidate [ngClass]="{ 'was-validated': validate }">
                        <div class="row">

                              <div class="mb-3 col-xl-6 col-md-6">
                                <label class="col-form-label pt-0" style="color: #141414;">{{'Register.name'|translate}}
                                  <span class="text-danger" style="color: red;">*</span>
                                </label>
                                <input class="form-control" type="text" maxlength="20"  required="" onlyAlphabets   formControlName="user_name">
                                <div *ngIf="addAccountingOffice.get('user_name')?.errors != null && addAccountingOffice.get('user_name')?.touched" class=" alert text-danger  my-1">
                                  <!-- <p  *ngIf="addAccountingOffice.get('user_name')?.errors?.required" class=" my-1 text-danger"> {{'Register.nameuserRequierd'|translate}}</p> -->
                                  <p *ngIf="addAccountingOffice.get('user_name')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                                  <p *ngIf="addAccountingOffice.get('user_name')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                                 </div>
                                 <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter user name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم المستخدم ' : ' אנא הזן שם משתמש ')}}  </div>

                              </div>


              
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="col-form-label pt-0" style="color: #141414;">{{'Register.phone'|translate}} 
                                  <span class="text-danger" style="color: red;">*</span>
                                </label>
                                <div class="input-group">
                                  <span style="color: #141414;" class="input-group-text">+972</span>
                                  <input class="form-control" type="text" maxlength="10" onlyAlphabets required=""  placeholder="{{'Register.phone'|translate}}" formControlName="phone_number">
                                </div>
                                <div *ngIf="addAccountingOffice.get('phone_number')?.errors != null && addAccountingOffice.get('phone_number')?.touched" class="alert text-danger my-1">
                                  <p *ngIf="addAccountingOffice.get('phone_number')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p> 
                                  <p *ngIf="addAccountingOffice.get('phone_number')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{addAccountingOffice.get('phone_number')?.errors?.invalidMobilePrefix.message}}</p>
                                  <p *ngIf="addAccountingOffice.get('phone_number')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{addAccountingOffice.get('phone_number')?.errors?.invalidPhoneNumber.message}}</p>
                                </div>
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter phone number' : (curentLang === 'ar' ? '   الرجاء ادخال رقم الهاتف ' : ' אנא הזן מספר טלפון ')}}  </div>
                              </div>
              
                              <div class="mb-3 col-xl-6 col-md-6">
                                <div class="form-group">
                                  <label class="col-form-label"style="color: #141414;">
                                    {{ curentLang === 'en' ? 'Office Name ' : (curentLang === 'ar' ? '  أسم المكتب   ' : ' שם המשרד  ')}}    
                                    <span class="text-danger" style="color: red;">*</span>
                                </label>
                                  <input class="form-control" type="text" maxlength="80"  required="" onlyAlphabets   formControlName="office_name">
                                  <div *ngIf="addAccountingOffice.get('office_name')?.errors != null && addAccountingOffice.get('office_name')?.touched" class=" alert text-danger  my-1">
                                    <p  *ngIf="addAccountingOffice.get('office_name')?.errors?.required" class=" my-1 text-danger"> 
                                        {{ curentLang === 'en' ? 'This input is requierd ' : (curentLang === 'ar' ? '    هذا الحقل مطلوب  ' : '  זהו שדה חובה')}}
                                    </p>
                                       <p *ngIf="addAccountingOffice.get('office_name')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                                    <p *ngIf="addAccountingOffice.get('office_name')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                                   </div>
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter office name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم المكتب ' : ' נא להזין את שם המשרד')}}  </div>

                                </div>
                                </div>

                              
                                <div class="mb-3 col-xl-6 col-md-6">
                                    <div class="form-group">
                                      <label class="col-form-label"style="color: #141414;">
                                        {{ curentLang === 'en' ? 'Office Address ' : (curentLang === 'ar' ? '  عنوان المكتب ' : 'כתובת משרד')}}
                                       
                                    </label>
                                      <input class="form-control" type="text" maxlength="80"     formControlName="office_address">
                                      <div *ngIf="addAccountingOffice.get('office_address')?.errors != null && addAccountingOffice.get('office_address')?.touched" class=" alert text-danger  my-1">
                                        <!-- <p  *ngIf="addAccountingOffice.get('office_address')?.errors?.required" class=" my-1 text-danger"> 
                                            {{ curentLang === 'en' ? 'This input is requierd ' : (curentLang === 'ar' ? '    هذا الحقل مطلوب  ' : '  זהו שדה חובה')}}

                                        </p> -->
                                        <p *ngIf="addAccountingOffice.get('office_address')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                                        <p *ngIf="addAccountingOffice.get('office_address')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                                       </div>
                                    </div>
                                    </div>

                                    <div class="mb-3 col-xl-6 col-md-6">
                                        <label class="form-label" style="color: #141414;"> 
                                          {{ curentLang === 'en' ? 'Tax Number' : (curentLang === 'ar' ? 'رقم السجل الضريبي' : 'מספר תעודת הצילום') }}
                                          <span class="text-danger" style="color: red;">*</span>
                                        </label>
                                        <input style="color: #141414;" 
                                               class="form-control" 
                                               onlyAlphabets required=""
                                               type="text"
                                               (keypress)="isNumber($event)"
                                               maxlength="9"
                                               max="9"
                                               min="0"
                                               inputmode="numeric" 
                                               formControlName="tax_number" 
                                               pattern="[0-9]{9}"
                                               title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}" />
                                        <div *ngIf="addAccountingOffice?.get('tax_number').invalid && (addAccountingOffice?.get('tax_number').dirty || addAccountingOffice?.get('tax_number').touched)" class="text-danger">
                                          {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                        </div>
                                      </div>


                                    <div class="mb-3 col-xl-6 col-md-6 ">
                                        <label class="col-form-label pt-0" style="color: #141414;">
                                            {{ curentLang === 'en' ? 'Office Phone ' : (curentLang === 'ar' ? '  هاتف المكتب ' : ' טלפון במשרד  ')}}
                                         </label>
                                         <div class="input-group">
                                          <!-- <span style="color: #141414;" class="input-group-text">+972</span> -->
                                          <input class="form-control" type="text" maxlength="9"   placeholder="{{'Register.phone'|translate}}" formControlName="office_phone">
                                        </div>
                                        <div *ngIf="addAccountingOffice.get('office_phone')?.errors != null && addAccountingOffice.get('office_phone')?.touched" class="alert text-danger my-1">
                                          <!-- <p *ngIf="addAccountingOffice.get('office_phone')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p> -->
                                          <p *ngIf="addAccountingOffice.get('office_phone')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{addAccountingOffice.get('office_phone')?.errors?.invalidMobilePrefix.message}}</p>
                                          <p *ngIf="addAccountingOffice.get('office_phone')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{addAccountingOffice.get('office_phone')?.errors?.invalidPhoneNumber.message}}</p>
                                        </div>
                                      </div>

                                      <div class="mb-3 col-xl-6 col-md-6 ">
                                        <label class="col-form-label pt-0" style="color: #141414;">
                                          {{ curentLang === 'en' ? 'Email' : (curentLang === 'ar' ? 'البريد الإلكتروني ' : ' דואר אלקטרוני  ')}}
                                          <span class="text-danger" style="color: red;">*</span>
                                        </label>
                                        <input
                                          class="form-control"
                                          type="email"
                                          required
                                          formControlName="email"
                                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        />
                                        <div *ngIf="addAccountingOffice.get('email')?.errors != null && addAccountingOffice.get('email')?.touched" class="alert text-danger my-1">
                                          <p *ngIf="addAccountingOffice.get('email')?.errors?.required" class="my-1 text-danger">
                                            {{ curentLang === 'en' ? 'This input is required' : (curentLang === 'ar' ? 'هذا الحقل مطلوب' : 'זהו שדה חובה') }}
                                          </p>
                                          <p *ngIf="addAccountingOffice.get('email')?.errors?.pattern" class="my-1 text-danger">
                                            {{ curentLang === 'en' ? 'Please enter a valid email address' : (curentLang === 'ar' ? 'يرجى إدخال عنوان بريد إلكتروني صالح' : 'אנא הזן כתובת דוא"ל חוקית') }}
                                          </p>
                                        </div>
                                        <div class="invalid-feedback text-danger">
                                          {{ curentLang === 'en' ? 'Please enter email' : (curentLang === 'ar' ? 'الرجاء ادخال البريد الإلكتروني' : 'נא להזין את המייל שלך') }}
                                        </div>


                                      </div>

                                      <div class="mb-3 col-xl-6 col-md-6">
                                        <div class="form-group">
                                         <label class="col-form-label"style="color: #141414;">{{'Register.password'|translate}}</label>
                                         <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"
                                          required=""
                                          minlength="8"
                                          maxlength="20"
                                          placeholder="*********" 
                                         formControlName="password">
                                         <div *ngIf="addAccountingOffice.get('password')?.errors != null && addAccountingOffice.get('password')?.touched" class=" alert text-danger my-1">
                                           <p  *ngIf="addAccountingOffice.get('password')?.errors?.required" class=" "> {{'Login.error18'|translate}}</p>
                                                   <ul  *ngIf="addAccountingOffice.get('password')?.errors?.pattern && addAccountingOffice.get('password')?.touched" class="text-start"> 
                                                       <li>{{'Login.error19'|translate}}</li>
                                                       <li>{{'Login.error20'|translate}}</li>
                                                       <li>{{'Login.error21'|translate}}</li>
                                                       <li>{{ curentLang === 'en' ? 'Password does not match the criteria' : (curentLang === 'ar' ? 'كلمة المرور غير مطابقة للمعايير' : 'הסיסמה אינה תואמת את הקריטריונים') }}
                                                      </li>
                                                   </ul>

                                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter password' : (curentLang === 'ar' ? '  الرجاء ادخال الرقم السري' : 'נא להזין את הסיסמה')}}  </div>

                                            </div>
                                       
                                            
                                             <div class="show-hideAr" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                             <div class="show-hideAr" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                                      
                                             <!-- <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                             <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div> -->
                                         
                                        
                 
                                       </div>
                                       </div>

                               
                        </div>
                  
                          <div class="modal-footer">
                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                            [disabled]="addAccountingOffice.get('password').invalid"
                             (click)="addedData()"
                             
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                          </div>
                      </form> 
                      
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>


<!--View Accounting Office-->

<ng-template #viewData let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
              {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : '     הצג מידע      ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                <form  [formGroup]="updateAccountingOffice">
                    <div class="row">

                        <div class="mb-3 col-xl-6 col-md-6">
                            <div class="form-group">
                              <label class="col-form-label"style="color: #141414;">
                                {{ curentLang === 'en' ? 'Office Name ' : (curentLang === 'ar' ? '  أسم المكتب   ' : ' שם המשרד  ')}}    
                            </label>
                              <input class="form-control" readonly type="text" maxlength="80" required=""   formControlName="office_name">
                              <div *ngIf="updateAccountingOffice.get('office_name')?.errors != null && updateAccountingOffice.get('office_name')?.touched" class=" alert text-danger  my-1">
                                <p  *ngIf="updateAccountingOffice.get('office_name')?.errors?.required" class=" my-1 text-danger"> 
                                    {{ curentLang === 'en' ? 'This input is requierd ' : (curentLang === 'ar' ? '    هذا الحقل مطلوب  ' : '  זהו שדה חובה')}}
                                </p>
                                   <p *ngIf="updateAccountingOffice.get('office_name')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                                <p *ngIf="updateAccountingOffice.get('office_name')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                               </div>
                            </div>
                            </div>

          
                            <div class="mb-3 col-xl-6 col-md-6">
                                <div class="form-group">
                                  <label class="col-form-label"style="color: #141414;">
                                    {{ curentLang === 'en' ? 'Office Address ' : (curentLang === 'ar' ? '  عنوان المكتب ' : 'כתובת משרד')}}
                                </label>
                                  <input class="form-control" readonly type="text" maxlength="80" required=""   formControlName="office_address">
                                  <div *ngIf="updateAccountingOffice.get('office_address')?.errors != null && updateAccountingOffice.get('office_address')?.touched" class=" alert text-danger  my-1">
                                    <p  *ngIf="updateAccountingOffice.get('office_address')?.errors?.required" class=" my-1 text-danger"> 
                                        {{ curentLang === 'en' ? 'This input is requierd ' : (curentLang === 'ar' ? '    هذا الحقل مطلوب  ' : '  זהו שדה חובה')}}

                                    </p>
                                    <p *ngIf="updateAccountingOffice.get('office_address')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                                    <p *ngIf="updateAccountingOffice.get('office_address')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                                   </div>
                                </div>
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="col-form-label pt-0" style="color: #141414;">
                                        {{ curentLang === 'en' ? 'Office Phone ' : (curentLang === 'ar' ? '  هاتف المكتب ' : ' טלפון במשרד  ')}}
                                     </label>
                                    <input class="form-control" type="text" maxlength="20" required="" readonly  formControlName="office_phone">
                                    <div *ngIf="updateAccountingOffice.get('office_phone')?.errors != null && updateAccountingOffice.get('office_phone')?.touched" class=" alert text-danger  my-1">
                                      <p  *ngIf="updateAccountingOffice.get('office_phone')?.errors?.required" class=" my-1 text-danger"> {{'Register.phoneRequierd'|translate}}</p>
                                      <p *ngIf="updateAccountingOffice.get('office_phone')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.phoneerror'|translate}}</p>
              
                                     </div>
                                  </div>

                                <div class="mb-3 col-xl-6 col-md-6">
                                    <label class="form-label" style="color: #141414;"> 
                                      {{ curentLang === 'en' ? 'Tax Number' : (curentLang === 'ar' ? 'رقم السجل الضريبي' : 'מספר תעודת הצילום') }}
                                    </label>
                                    <input style="color: #141414;" readonly
                                           class="form-control" 
                                           type="text"
                                           inputmode="numeric" 
                                           formControlName="tax_number" 
                                           pattern="[0-9]{9}"
                                           title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}" />
                                    <div *ngIf="updateAccountingOffice?.get('tax_number').invalid && (updateAccountingOffice?.get('tax_number').dirty || updateAccountingOffice?.get('tax_number').touched)" class="text-danger">
                                      {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                    </div>
                                  </div>


                             

                                  <div class="mb-3 col-xl-12 col-md-12 ">
                                    <label class="col-form-label pt-0" style="color: #141414;">
                                        {{ curentLang === 'en' ? 'Email' : (curentLang === 'ar' ? 'البريد الإلكتروني ' : ' דואר אלקטרוני  ')}}
                                     </label>
                                    <input class="form-control" type="email"  required="" readonly   formControlName="email">
                                    <div *ngIf="updateAccountingOffice.get('email')?.errors != null && updateAccountingOffice.get('email')?.touched" class=" alert text-danger  my-1">
                                      <p  *ngIf="updateAccountingOffice.get('email')?.errors?.required" class=" my-1 text-danger"> 
                                        {{ curentLang === 'en' ? 'This input is requierd ' : (curentLang === 'ar' ? '    هذا الحقل مطلوب  ' : '  זהו שדה חובה')}}
                                    </p>
               
                                     </div>
                                  </div>

                                

                           
                    </div>
              
                     
                  </form> 
                
              
            </div>
            
          </div>
        
        </div>
    </div>
   
  </ng-template>

<!--update Accounting Office-->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
              {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '    ערוך מידע ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
      <div class="modal-body">
          <div class="container-fluid ">
              <div class="edit-profile">
                <div class="row d-flex justify-content-between">
                    <form  [formGroup]="updateAccountingOffice" novalidate [ngClass]="{ 'was-validated': updatevalidate }">
                        <div class="row">

                            <div class="mb-3 col-xl-6 col-md-6">
                                <div class="form-group">
                                  <label class="col-form-label"style="color: #141414;">
                                    {{ curentLang === 'en' ? 'Office Name ' : (curentLang === 'ar' ? '  أسم المكتب   ' : ' שם המשרד  ')}}  
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input class="form-control" type="text" maxlength="80" onlyAlphabets required=""    formControlName="office_name">
                                  <div *ngIf="updateAccountingOffice.get('office_name')?.errors != null && updateAccountingOffice.get('office_name')?.touched" class=" alert text-danger  my-1">
                                    <p  *ngIf="updateAccountingOffice.get('office_name')?.errors?.required" class=" my-1 text-danger"> 
                                        {{ curentLang === 'en' ? 'This input is requierd ' : (curentLang === 'ar' ? '    هذا الحقل مطلوب  ' : '  זהו שדה חובה')}}
                                    </p>
                                       <p *ngIf="updateAccountingOffice.get('office_name')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                                    <p *ngIf="updateAccountingOffice.get('office_name')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                                   </div>
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter office name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم المكتب ' : 'נא להזין את שם המשרד')}}  </div>

                                </div>
                                </div>

              
                                <div class="mb-3 col-xl-6 col-md-6">
                                    <div class="form-group">
                                      <label class="col-form-label"style="color: #141414;">
                                        {{ curentLang === 'en' ? 'Office Address ' : (curentLang === 'ar' ? '  عنوان المكتب ' : 'כתובת משרד')}}
                                    </label>
                                      <input class="form-control" type="text" maxlength="80" required=""   formControlName="office_address">
                                      <div *ngIf="updateAccountingOffice.get('office_address')?.errors != null && updateAccountingOffice.get('office_address')?.touched" class=" alert text-danger  my-1">
                                        <!-- <p  *ngIf="updateAccountingOffice.get('office_address')?.errors?.required" class=" my-1 text-danger"> 
                                            {{ curentLang === 'en' ? 'This input is requierd ' : (curentLang === 'ar' ? '    هذا الحقل مطلوب  ' : '  זהו שדה חובה')}}

                                        </p> -->
                                        <p *ngIf="updateAccountingOffice.get('office_address')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                                        <p *ngIf="updateAccountingOffice.get('office_address')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                                       </div>
                                    </div>
                                    </div>

                                    <div class="mb-3 col-xl-6 col-md-6 ">
                                        <label class="col-form-label pt-0" style="color: #141414;">
                                            {{ curentLang === 'en' ? 'Office Phone ' : (curentLang === 'ar' ? '  هاتف المكتب ' : ' טלפון במשרד  ')}}
                                         </label>
                                    
                                         <div class="input-group">
                                          <!-- <span style="color: #141414;" class="input-group-text">+972</span> -->
                                          <input class="form-control" type="text" maxlength="9"   placeholder="{{'Register.phone'|translate}}" formControlName="office_phone">
                                        </div>
                                        <div *ngIf="updateAccountingOffice.get('office_phone')?.errors != null && updateAccountingOffice.get('office_phone')?.touched" class="alert text-danger my-1">
                                          <!-- <p *ngIf="updateAccountingOffice.get('office_phone')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p> -->
                                          <p *ngIf="updateAccountingOffice.get('office_phone')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{updateAccountingOffice.get('office_phone')?.errors?.invalidMobilePrefix.message}}</p>
                                          <p *ngIf="updateAccountingOffice.get('office_phone')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{updateAccountingOffice.get('office_phone')?.errors?.invalidPhoneNumber.message}}</p>
                                        </div>
                                      </div>

                                    <div class="mb-3 col-xl-6 col-md-6">
                                        <label class="form-label" style="color: #141414;"> 
                                          {{ curentLang === 'en' ? 'Tax Number' : (curentLang === 'ar' ? 'رقم السجل الضريبي' : 'מספר תעודת הצילום') }}
                                          <span class="text-danger" style="color: red;">*</span>
                                        </label>
                                        <input style="color: #141414;" 
                                        class="form-control" 
                                        onlyAlphabets required=""
                                        type="text"
                                        (keypress)="isNumber($event)"
                                        maxlength="9"
                                        max="9"
                                        min="0"
                                        inputmode="numeric" 
                                        formControlName="tax_number" 
                                        pattern="[0-9]{9}"
                                        title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}" />
                                        <div *ngIf="updateAccountingOffice?.get('tax_number').invalid && (updateAccountingOffice?.get('tax_number').dirty || updateAccountingOffice?.get('tax_number').touched)" class="text-danger">
                                          {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                        </div>
                                      </div>


                                 

                                      <div class="mb-3 col-xl-12 col-md-12 ">
                                        <label class="col-form-label pt-0" style="color: #141414;">
                                          {{ curentLang === 'en' ? 'Email' : (curentLang === 'ar' ? 'البريد الإلكتروني ' : ' דואר אלקטרוני  ')}}
                                          <span class="text-danger" style="color: red;">*</span>
                                        </label>
                                        <input
                                          class="form-control"
                                          onlyAlphabets required=""
                                          type="email"
                                          formControlName="email"
                                          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        />
                                        <div *ngIf="updateAccountingOffice.get('email')?.errors != null && updateAccountingOffice.get('email')?.touched" class="alert text-danger my-1">
                                          <p *ngIf="updateAccountingOffice.get('email')?.errors?.required" class="my-1 text-danger">
                                            {{ curentLang === 'en' ? 'This input is required' : (curentLang === 'ar' ? 'هذا الحقل مطلوب' : 'זהו שדה חובה') }}
                                          </p>
                                          <p *ngIf="updateAccountingOffice.get('email')?.errors?.pattern" class="my-1 text-danger">
                                            {{ curentLang === 'en' ? 'Please enter a valid email address' : (curentLang === 'ar' ? 'يرجى إدخال عنوان بريد إلكتروني صالح' : 'אנא הזן כתובת דוא"ל חוקית') }}
                                          </p>
                                        </div>
                                        <div class="invalid-feedback text-danger">
                                          {{ curentLang === 'en' ? 'Please enter email' : (curentLang === 'ar' ? 'الرجاء ادخال البريد الإلكتروني' : 'נא להזין את המייל שלך') }}
                                        </div>


                                      </div>

                                    

                               
                        </div>
                  
                          <div class="modal-footer">
                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updatedOfficeData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                          </div>
                      </form> 
                  
                 
                 
              
                
              </div>
              
            </div>
          
          </div>
      </div>
     
  </ng-template>





  
<!--add Accounting Office Company-->

<ng-template #content2 let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Adding a company to an accounting office' : (curentLang === 'ar' ? '  اضافة شركة لمكتب المحاسبة' : 'הוספת חברה למשרד רואי חשבון ')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
              <form [formGroup]="addCompanyAccountingOffice">
                <div class="row">
                  <div class="mb-3 col-xl-12 col-md-12">
                    <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה') }}
                    </label>
                    <ng-select formControlName="companyMerchant" (change)="onCompanyMerchantChange($event)" style="cursor: pointer;" [multiple]="true"
                               placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                      <ng-option *ngFor="let item of activeCompanyData" [value]="item" >
                        {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }} /
                        {{ curentLang === 'en' ? item.merchant.merchant_name_en : (curentLang === 'ar' ? item.merchant.merchant_name_ar : item.merchant.merchant_name_he) }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                
                <div class="modal-footer">
                  <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" 
                       [disabled]="addCompanyAccountingOffice.invalid"   (click)="addedAccountingOfficeCompany()">
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                  </button>
                  
                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </div>
              </form>
              
              
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>