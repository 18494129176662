<app-breadcrumb [title]="curentLang === 'en' ? 'Finaical Requests Info' : (curentLang === 'ar' ? ' معلومات المطالبات المالية  ' : ' מידע על תביעות כספיות')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Finaical Requests Info' : (curentLang === 'ar' ? ' معلومات المطالبات المالية' : 'מידע על תביעות כספיות')">
</app-breadcrumb>

<!--Finaical Requests Info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Finaical Requests Info' : (curentLang === 'ar' ? 'معلومات المطالبات المالية ' : 'מידע על תביעות כספיות')}}
        </h5>
           
       <div>
        
        <button [hidden]="!isValidMerchant" class="btn" style="background-color: #141414; color: #fff;" 
        routerLink="/dashboard/add-finaical-request">
  <i class="fa fa-plus-circle mx-1"></i>
  {{ curentLang === 'en' ? 'Add Finaical Request' : (curentLang === 'ar' ? '  اضافة مطالبة مالية  ' : 'הוסף תביעה כספית')}}
</button>



      <!-- <button class="btn mx-1 btn-outline-warning"  >
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button> -->
       </div>
     
      </div>

      <div class="row">
        <div class="col-sm-2  ">
          <div class="form-group search px-1">
            <label for="seaarch" class="ml-2" style="color: grey;">
              {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
            </label>
            <div class="input-group">
              <input class="form-control w-100" type="text" name="search"
                     [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term"
                     placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"
                     />
              <!-- <div class="input-group-append m-auto">
                <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                        (click)="getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                  <i class="fa fa-search mx-1"></i>
                  {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                </button>
                <button class="btn btn-outline-secondary" type="button" (click)="term='';getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                  <i class="fa fa-times"></i>
                </button>
              </div> -->
            </div>
          </div>
        </div>

      <div class="col-sm-3 mt-1">
        <label class="form-label px-1" style="color: grey;">
          {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה') }}
        </label>
        <ng-select [(ngModel)]="selectedCompany" (change)="filter($event)" class="px-1"
                   style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
          <ng-option [value]="allCompaniesOption">
            {{ curentLang === 'en' ? 'All Companies' : (curentLang === 'ar' ? 'الكل' : 'הכל') }}
          </ng-option>
          <ng-option *ngFor="let item of companyData" [value]="item">
            {{ curentLang === 'en' ? item?.company_name_en : (curentLang === 'ar' ? item?.company_name_ar : item?.company_name_he)}}
          </ng-option>
        </ng-select>
        <ng-template *ngIf="companyData.length === 0" class="px-1">
          <span style="color: red;">
            {{ curentLang === 'en' ? 'There are no invoices for this company yet' : (curentLang === 'ar' ? "لا توجد فواتير لهذه الشركة حتى الآن" : "אין עדיין חשבוניות לחברה זו")}}
          </span>
        </ng-template>
      </div>
      
      <div class="col-sm-2 mt-1">
      <label class="form-label px-1" style="color: grey;"> 
        {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : 'פרק זמן')}}
     </label>
     <select class="form-control form-select px-1"
     style="cursor: pointer; "
     (change)="filterMonth($event)"  >
     <option [value]="1" > {{ curentLang === 'en' ?  'month' :(curentLang === 'ar' ?'شهر'  :  'חודש' )}} </option>
     <option [value]="2" > {{ curentLang === 'en' ?  'two months' :(curentLang === 'ar' ?'   شهرين'  :  ' שני חודשים ' )}} </option>
     <option [value]="3" > {{ curentLang === 'en' ?  'three months' :(curentLang === 'ar' ?'   ثلاثة أشهر'  :  ' שלוש חודשים ' )}} </option>
     <option [value]="4" > {{ curentLang === 'en' ?  'four months' :(curentLang === 'ar' ?'   أربعة أشهر'  :  '  ארבעה חודשים   ' )}} </option>
     <option [value]="5" > {{ curentLang === 'en' ?  'five months' :(curentLang === 'ar' ?'  خمسة أشهر' :  '  חמישה חודשים   ' )}} </option>
     <option [value]="6" > {{ curentLang === 'en' ?  'six months' :(curentLang === 'ar' ?'   ستة أشهر'  :  '  רבעה חודשים   ' )}} </option>
     <option [value]="7" > {{ curentLang === 'en' ?  'seven months' :(curentLang === 'ar' ?'   سبعة أشهر'  :  '  חמשה חודשים   ' )}} </option>
     <option [value]="8" > {{ curentLang === 'en' ?  'eight months' :(curentLang === 'ar' ?'    ثمانية أشهر'  :  '  ששה חודשים   ' )}} </option>
     <option [value]="9" > {{ curentLang === 'en' ?  'nine months' :(curentLang === 'ar' ?'   عشرة أشهر'  :  '  שבעה חודשים   ' )}} </option>
     <option [value]="10" > {{ curentLang === 'en' ?  'ten months' :(curentLang === 'ar' ?'   عشرة أشهر'  :  '  שמונה חודשים   ' )}} </option>
     <option [value]="11" > {{ curentLang === 'en' ?  'eleven months' :(curentLang === 'ar' ?'   احدي عشر شهر'  :  '  ספינה חודשים   ' )}} </option>
     <option [value]="12" > {{ curentLang === 'en' ?  'twelve months' :(curentLang === 'ar' ?'   اثني عشر شهر '  :  '  עשרה חודשים   ' )}} </option>

     </select>
      </div>

      <div class="col-sm-2">
        <div class="form-group search px-1">
          <label for="fromDate" class="ml-2" style="color: grey;">
            {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : 'מהתאריך של')}}
          </label>
          <input class="form-control w-100" type="date" [(ngModel)]="fromDate" id="fromDate" />
        </div>
      </div>
      
      <div class="col-sm-2">
        <div class="form-group search px-1">
          <label for="toDate" class="ml-2" style="color: grey;">
            {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : ' עד היום')}}
          </label>
          <input class="form-control w-100" type="date" [(ngModel)]="toDate" id="toDate" />
        </div>
      </div>
      
      <div class="col-sm-1 mt-4" style="padding-top: 6px;">
        <div class="form-group search ">
          <button class="btn btn-sm mx-1" style="background-color: #141414; color: #fff;" 
          [disabled]="!fromDate || !toDate"
          (click)="getInvoicesFilters(fromDate, toDate)">
            <i class="fa fa-refresh mx-1"></i>
            {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
          </button>
        </div>
      </div>
    </div>

    <div class="card-block row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-light text-center table-hover d-none d-lg-table">
            <thead>
              <tr>
                <th scope="col">{{ curentLang === 'en' ? 'Index' : (curentLang === 'ar' ? 'م' : 'מ') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Confirmation Number' : (curentLang === 'ar' ? 'رقم التأكيد' : 'מספר אישור') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? 'رقم المستند' : 'מספר מסמך') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي' : 'מספר מס') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? 'تاريخ الفاتورة' : 'תאריך חשבונית') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'أسم الشركة' : 'שם החברה') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Customer Name' : (curentLang === 'ar' ? 'أسم العميل' : 'שם הסוכן') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي العميل' : 'מספר מס לקוח') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Amount Before Discount' : (curentLang === 'ar' ? 'المبلغ قبل الخصم' : 'סכום לפני הנחה') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'الخصم' : 'הנחה') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Payment Amount' : (curentLang === 'ar' ? 'المبلغ المدفوع' : 'סכום תשלום') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Vat Amount' : (curentLang === 'ar' ? 'قيمة الضريبة' : 'סכום המס') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Confirm Financial' : (curentLang === 'ar' ? 'تأكيد المطالبة' : 'אשר את הטענה') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Convert To Invoice' : (curentLang === 'ar' ? 'تحويل الي فاتورة' : 'המר לחשבונית') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? 'تعديل' : 'תיקון') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Export Pdf' : (curentLang === 'ar' ? 'تصدير Pdf' : 'ייצוא PDF') }}</th>
                <th scope="col">{{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? 'حذف' : 'מחק') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of invoicesDataFilters | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total}; let i = index"
              [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }"
              >
                <td scope="row">{{ i + 1 }}</td>
                <td>{{ item.confirmation_number }}</td>
                <td>{{ item.document_number }}</td>
                <td>{{ item.vat_number }}</td>
                <td>{{ item.invoice_date | date: 'dd/MM/yyyy' }}</td>
                <td>{{ curentLang === 'en' ? item.company?.company_name_en.substring(0, 10) : (curentLang === 'ar' ? item.company?.company_name_ar.substring(0, 10) : item.company?.company_name_he.substring(0, 10)) }}</td>
                <td>{{ curentLang === 'en' ? item?.customer_name.substring(0, 10) : (curentLang === 'ar' ? item?.customer_name.substring(0, 10) : item?.customer_name.substring(0, 10)) }}</td>
                <td>{{ item?.customer_vat_number }}</td>
                <td>{{ item.amount_before_discount }}</td>
                <td>{{ item.all_discount }}</td>
                <td>{{ item.payment_amount }}</td>
                <td>{{ item.vat_percentage }} %</td>
    
                <!-- Confirm Financial Column -->
                <td *ngIf="item.confirm == 0">
                  <button [disabled]="item.invoice_category == 14" style="background-color: #213FA6; color: white;" (click)="getId(item?.invoice_id);openVerticallyCenteredConfirmed2(dataConfirm2)" class="btn btn-xs">
                    <i class="fa fa-check-circle-o text-white mx-1"></i>{{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                  </button>
                </td>
                <td *ngIf="item.confirm == 1">
                  <span class="text-success" style="font-weight: 700;"><i class="fa fa-check-circle"></i></span>
                </td>
    
                <!-- Convert To Invoice Column -->
                <td>
                  <button [disabled]="item.confirm == 0 || item.invoice_category == 14" style="background-color: #213FA6; color: white;" (click)="getId(item?.invoice_id);openVerticallyCenteredConfirmed(dataConfirm)" class="btn btn-xs">
                    <i class="fa fa-exchange text-white mx-1"></i>{{ curentLang === 'en' ? 'Convert' : (curentLang === 'ar' ? 'تحويل' : 'המר') }}
                  </button>
                </td>
    
                <!-- Update and Delete Column -->
                <td *ngIf="item.confirm == 0">
                  <i [hidden]="user_type == 200" class="fa fa-edit" style=" cursor: pointer; color: green;" routerLink="/dashboard/update-finaical-request/{{item.invoice_uuid}}"></i>
                  <i [hidden]="user_type == 100" class="fa fa-close" style=" color: grey;"></i>
                </td>
                <td *ngIf="item.confirm == 1">
                  <i class="fa fa-close" style=" color: grey;"></i>
                </td>
    
                <!-- Export PDF Column -->
                <td *ngIf="item.confirm == 1">
                  <i  class="fa fa-file-pdf-o" style="font-size: 15px; cursor: pointer; color: #213FA6;"
                  (click)="executeFunctions(item.invoice_uuid , content4)"></i>
                </td>

                <td *ngIf="item.confirm == 0">
                  <i class="fa fa-close" style=" color: grey;"></i>
                </td>
    
                <!-- Delete Column -->
                <td>
                  <button [disabled]="user_type == 200" [ngStyle]="{ 'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red' }" class="btn" style="border: none;" (click)="getId(item?.invoice_id); openVerticallyCentered(dataDeleted)">
                    <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>


<!-- invoices Info on phone -->
 

<div class="container-fluid basic_table d-lg-none my-2" >

  <div class="row">
<div class="col-sm-12">
 
  <div class="card"  id="headingTwo"
   *ngFor="let item of invoicesDataFilters  | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ; let i = index ">
  
          <p   
          
          (click)="toggleCollapse(i)" 
          [attr.aria-expanded]="openedIndex === i" 
          aria-controls="collapse"
          style="cursor: pointer; background-color: #FB4438;" 
          class="py-1">
            <i class="fa fa-file mx-1"  style="color: #fff; font-size: 16px;" > </i>
            <span style="color: #fff; font-size: 16px;" class="mx-2">
              {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : 'מספר מסמך')}} :
            </span>
            <span style="color: #fff; font-size: 16px;" class="mx-2">{{item.document_number}}</span>
          </p>
    
           
          <div class="row d-flex justify-content-between">

            <div class="col-md-12  col-sm-12 ">
              <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">
              <!---->
             
              <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                  {{ curentLang === 'en' ? 'Confirmation Number' : (curentLang === 'ar' ? '  رقم التأكيد  ' : '      מספר אישור  ')}} 
                </span>
                  <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>
                  <span class="col-sm-4   text-end ">
                    <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.confirmation_number}}</span>
                  </span>
               </div>
               <hr style="border: solid 1px #141414;" >
               <!---->
    
               <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                  {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : 'מספר מסמך')}}
                </span>

                <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>

                <span class="col-sm-4   text-end ">
                  <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.document_number}}</span>
                </span>
                
                   
   
               </div>
               <hr style="border: solid 1px #141414;" >

               <!---->
               <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                  {{ curentLang === 'en' ? 'Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي' : 'מספר מס') }}
                </span>
                
                <div class="text-center col-sm-4">  </div>

                  <span style="color: #141414;   " class="col-sm-4   text-end "  >
                    <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item.vat_number }}</span>
                </span>

              
               </div>
               <hr style="border: solid 1px #141414;" >

                 <!---->

                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                    {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة ' : '    תאריך חשבונית')}}

                  </span>
                  
                  <div class="text-center col-sm-4">  </div>

                    <span style="color: #141414;   " class="col-sm-4   text-end "  >
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{ item.invoice_date | date: 'dd/MM/yyyy' }}</span>
                  </span>

                
                 </div>
                 <hr style="border: solid 1px #141414;" >
                  <!---->

                  <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Company Name  ' : (curentLang === 'ar' ? ' أسم الشركة ' : 'שם החברה')}}  
                    </span>
                    
                    <div class="text-center col-sm-4">  </div>
  
                      <span style="color: #141414;   " class="col-sm-4   text-end "  >
                        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > 
                          {{ curentLang === 'en' ? item.company?.company_name_en.substring(0, 10) : (curentLang === 'ar' ? item.company?.company_name_ar.substring(0, 10) : item.company?.company_name_he.substring(0, 10)) }}
                        </span>
                    </span>
  
                  
                   </div>
       
                 <hr style="border: solid 1px #141414;" >
                  <!---->

                  <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Customer Name  ' : (curentLang === 'ar' ? '  أسم العميل   ' : ' שם הסוכן')}}                
                    </span>
                    
                    <div class="text-center col-sm-4">  </div>
              
                      <span style="color: #141414;   " class="col-sm-4   text-end "  >
                        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ curentLang === 'en' ? item?.customer_name.substring(0, 10) : (curentLang === 'ar' ? item?.customer_name.substring(0, 10) : item?.customer_name.substring(0, 10)) }}</span>
                    </span>
              
                  
                   </div>
               <hr style="border: solid 1px #141414;" >
                  <!---->

                  <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي العميل' : 'מספר מס לקוח') }}              
                    </span>
                    
                    <div class="text-center col-sm-4">  </div>
              
                      <span style="color: #141414;   " class="col-sm-4   text-end "  >
                        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >
                          {{ item?.customer_vat_number }}
                        </span>
                    </span>
              
                  
                   </div>
               <hr style="border: solid 1px #141414;" >
                  <!---->


  <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
      {{ curentLang === 'en' ? 'Amount Before Discount  ' : (curentLang === 'ar' ? ' المبلغ قبل الخصم   ' : 'סכום לפני הנחה ')}}
    </span>
    
    <div class="text-center col-sm-4">  </div>

      <span style="color: #141414;   " class="col-sm-4   text-end "  >
        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{ item.amount_before_discount }}</span>
    </span>

  
   </div>
  
 <hr style="border: solid 1px #141414;" >

     <!---->

     <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
        {{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'الخصم' : 'הנחה') }}
      </span>
      
      <div class="text-center col-sm-4">  </div>
  
        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{ item.all_discount }}</span>
      </span>
  
    
     </div>
    
   <hr style="border: solid 1px #141414;" >
  
       <!---->

     <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
        {{ curentLang === 'en' ? ' Payment Amount  ' : (curentLang === 'ar' ? 'المبلغ المدفوع  ' : '  סכום תשלום   ')}}  
      </span>
      
      <div class="text-center col-sm-4">  </div>
      <span style="color: #141414;   " class="col-sm-4   text-end "  >
        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item.payment_amount }}</span>
    </span>

    
     </div>
   <hr style="border: solid 1px #141414;" >
    <!---->

      <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? ' Vat Amount ' : (curentLang === 'ar' ? ' قيمة الضريبة  ' : '    סכום המס       ')}}
        </span>
        
        <div class="text-center col-sm-4">  </div>
        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item.vat_percentage }}</span>
      </span>
  
      
       </div>
     <hr style="border: solid 1px #141414;" >
      <!---->


      <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? 'Confirm Financial' : (curentLang === 'ar' ? 'تأكيد المطالبة' : 'אשר את הטענה') }}
        </span>
        
        <div class="text-center col-sm-4">  </div>
        <span style="color: #141414;   " class="col-sm-4    text-end "  >
          <span *ngIf="item.confirm == 0" class="px-5">
            <button [disabled]="item.invoice_category == 14"
             style="background-color: #213FA6; color: white;" 
             (click)="getId(item?.invoice_id);openVerticallyCenteredConfirmed2(dataConfirm2)" class="btn btn-xs mb-3  ">
              <i class="fa fa-check-circle-o text-white mx-1"></i>{{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
            </button>
          </span>
          <span *ngIf="item.confirm == 1" class=" px-5 ">
            <span class="text-success  " style="font-weight: 700;"><i class="fa fa-check-circle"></i></span>
          </span>
      </span>
  
      
       </div>
     <hr style="border: solid 1px #141414;" >
      <!---->

      <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? 'Convert To Invoice' : (curentLang === 'ar' ? 'تحويل الي فاتورة' : 'המר לחשבונית') }}
        </span>
        
        <div class="text-center col-sm-4">  </div>
        <span class="px-5">
          <button [disabled]="item.confirm == 0 || item.invoice_category == 14" 
          style="background-color: #213FA6; color: white;" (click)="getId(item?.invoice_id);openVerticallyCenteredConfirmed(dataConfirm)" class="btn btn-xs mb-3">
            <i class="fa fa-exchange text-white mx-1"></i>{{ curentLang === 'en' ? 'Convert' : (curentLang === 'ar' ? 'تحويل' : 'המר') }}
          </button>
        </span>
  
      
       </div>
     <hr style="border: solid 1px #141414;" >
      <!---->


      <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}
        </span>
        
        <div class="text-center col-sm-4">  </div>
        
        <span style="color: #141414;   " class="col-sm-4    text-end "  >
          <span *ngIf="item.confirm == 0" class="px-5">
            <i [hidden]="user_type == 200" class="fa fa-edit" style="cursor: pointer; color: green;" routerLink="/dashboard/update-finaical-request/{{item.invoice_uuid}}"></i>
            <i [hidden]="user_type == 100" class="fa fa-close" style="color: grey;"></i>
          </span>

          <span *ngIf="item.confirm == 1"class="px-5">
            <i class="fa fa-close" style="color: grey;"></i>
          </span>
      </span>
  
      
       </div>
     <hr style="border: solid 1px #141414;" >
      <!---->

      <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? 'Export Pdf  ' : (curentLang === 'ar' ? '   تصدير Pdf  ' : '  ייצוא PDF ')}}
        </span>
        
        <div class="text-center col-sm-4">  </div>
        
        <span style="color: #141414;   " class="col-sm-4    text-end "  >
          <span class="px-5" >
            <i class="fa fa-file-pdf-o" 
            (click)="executeFunctions(item.invoice_uuid , content4)" style="cursor: pointer;"
            [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }" 
             ></i>
          </span >
      </span>
  
      
       </div>
     <hr style="border: solid 1px #141414;" >
      <!---->

      <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}

        </span>
        
        <div class="text-center col-sm-4">  </div>
        
        <span style="color: #141414;   " class="col-sm-4    text-end "  >
          <span   >
            <button [disabled]="user_type == 200" [ngStyle]="{ 'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red' }"
             class="btn px-5 " style="border: none;" (click)="getId(item?.invoice_id); openVerticallyCentered(dataDeleted)">
              <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
            </button>
          </span>
      </span>
  
      
       </div>
     <hr style="border: solid 1px #141414;" >
      <!---->


              </div>
            </div>

          </div>
          <hr style="color: #141414; border: solid 1px;">
  </div>




</div>

  </div>
</div>











          <div *ngIf="invoicesDataFilters?.length == 0" class="alert text-danger mt-3">
            <span> {{ curentLang === 'en' ? 'There are no invoices for this company yet... ' : (curentLang === 'ar' ? ' لا توجد فواتير لهذة الشركة حتي الأن ... ' : 'אין עדיין חשבוניות לחברה זו... ')}}</span>
        </div>
          <div class="pagination-container">
            <pagination-controls 
              (pageChange)="onPageChange($event)" 
              (totalItems)="total" 
              (currentPage)="currentPage">
            </pagination-controls>
            <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">

          </div>     
           </div>
      </div>
    </div>
    
    </div>
  </div>

    </div>
</div>




   <!--Delete Finaical Request-->

   <ng-template #dataDeleted let-modal>
    <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
          {{curentLang === 'en' ? 'Are you sure you want to delete this Finaical Request?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذف هذة المطالبة المالية ؟' : 'האם אתה בטוח שאתה רוצה למחוק את התביעה הכספית הזו?') }}
        </p>
    </div>
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="deleteInvoiceById()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>
</ng-template>



  <!--confirm-->

  <ng-template #dataConfirm let-modal>
    <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body text-center">
      <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
        {{curentLang === 'en' ? 'Do you want to convert this financial request into an invoice ?' : (curentLang === 'ar' ? '  هل تريد تحويل هذة المطالبة المالية الي فاتورة ؟' : '  האם ברצונך להמיר את התביעה הכספית הזו לחשבונית ? ') }}
      </p>
    </div>
  
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="convrtFinaicalRequestToInvoice()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>


  </ng-template>


   <!--confirm finincial-->

   <ng-template #dataConfirm2 let-modal>
    <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body text-center">
      <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
        {{curentLang === 'en' ? 'Do you want to confirm this financial request ?' : (curentLang === 'ar' ? '  هل تريد تأكيد هذة المطالبة المالية ؟' : ' האם ברצונך לאשר את הבקשה הכספית הזו? ') }}
      </p>
    </div>
  
    <div class="modal-footer">

      <button type="button" 
       class="btn btn-sm m-auto mt-2" 
      style="background-color: #141414; color: #fff;"
       (click)="confirmInvoice()"
       > 
        {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>

        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
        </button>

      

    </div>


  </ng-template>







  <ng-template #content4 let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
        {{ curentLangDocument === 'en' ? 'Finaical Requests Info' : (curentLangDocument === 'ar' ? 'معلومات المطالبات المالية ' : 'מידע על תביעות כספיות')}}

      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="container-fluid">
        <button type="button" (click)="generatePDF2()" class="btn btn-sm my-3"
      style=" background-color: #141414; color: #fff;">
      <i class="fa fa-file-pdf-o mx-1"></i>
       {{ curentLangDocumentDocument === 'en' ? 'Export PDF' : (curentLangDocumentDocument === 'ar' ? 'تصدير PDF' : ' יְצוּא PDF')}}
  </button>
  <div class="container" [hidden]="receiptInfoData?.confirm == 0" >
    <div class="row m-auto">
      <div class="col-sm-12 ">

          <div class="page m-auto  invoice-content"  id="printDiv">

              <div class="card-2">

                  <div class="row m-2 ">
                      <div class="col-lg-12">
                          <div class="invoice-inner" id="invoice_wrapper">
                              <div class="invoice-top">
                                  <div class="row">
                                      <div class="col-sm-4">
                                          <div class="logo my-2"  *ngIf="invoiceInfoData?.logo" >
                                              <img
                                                  [src]="invoiceInfoData?.logo"
                                                  alt="logo"
                                                  style="width: 100px;height: 100px; border-radius: 5%;">
                                          </div>
                                      </div>

                                      <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4">
                                          <h2
                                              class="text-center water-mark ">{{invoiceInfoData?.copy_version}}</h2>
                                      </div>

                                      <div class="col-sm-4">
                                   
                                      </div>
                                  </div>
                              </div>
                              <div class="invoice-info">
                                  <div class="row">
                                     
                                      <div class="col-sm-6 mb-30">
                                          <div class="invoice-number ">
                                              <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">{{ curentLangDocument === 'en' ? 'For Hon' : (curentLangDocument === 'ar' ? '   لحضرة' : 'עבור כבודו')}}</h4>
                                              <div class="invo-addr-1">
                                                  <div style="font-weight: 400;">
                                                      <!-- <strong> {{ curentLangDocument === 'en' ? 'Customer Name' : (curentLangDocument === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} / </strong> -->
                                                      <strong>{{ invoiceInfoData?.customer_name }}</strong>
                                                  </div>
                                                  
                                                  <div *ngIf="invoiceInfoData?.customer_address; else noAddress" style="font-weight: 300;" class="m-0">
                                                      <strong>{{ invoiceInfoData?.customer_address }}</strong>
                                                  </div>
                                                  
                                                  <ng-template #noAddress>
                                                      <div style="font-weight: 300;">
                                                          <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מספר זיהוי עובד')}} </strong>
                                                          <div style="font-weight: 300;">
                                                              <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                          </div>
                                                      </div>
                                                  </ng-template>
                                                  
                                                  <div *ngIf="invoiceInfoData?.customer_address" style="font-weight: 300;">
                                                      <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : 'מספר זיהוי עובד')}} </strong>
                                                      <div style="font-weight: 300;">
                                                          <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                      </div>
                                                  </div>
                                                  

                                                  
                                                  <div style="font-weight: 300;">
                                                      <!-- <strong>{{ curentLangDocument === 'en' ? 'Customer Id Number' : (curentLangDocument === 'ar' ? '  رقم الهوية للعميل' : '  מספר זיהוי לקוח ')}} / </strong> -->
                                                      <strong>{{ invoiceInfoData?.customer_id_number }}</strong>
                                                  </div>
                                                  
                                              </div>
                                          </div>
                                          
                                      </div>

                                      <div class="col-sm-6 mb-30">
                                          <div class="invoice-number text-end">
                                              <!-- <h4 class="inv-title-1"> {{ curentLangDocument === 'en' ? 'Invoice From' : (curentLangDocument === 'ar' ? 'فاتورة من' : 'חשבונית מ')}} </h4> -->
                                              <div class="invo-addr-1">
                                                  <div style="font-weight: bold;">
                                                      <!-- <strong>{{ curentLangDocument === 'en' ? 'Company Name' : (curentLangDocument === 'ar' ? 'اسم الشركة' : 'שם החברה')}} / </strong>  -->
                                                      <strong>{{ invoiceInfoData?.company_name }}</strong>
                                                  </div>
                                                  
                                                  <div style="font-weight: 300;">
                                                      <!-- <strong> {{ curentLangDocument === 'en' ? 'Company Address' : (curentLangDocument === 'ar' ? 'عنوان الشركة' : 'כתובת החברה')}} / </strong> -->
                                                      <strong>{{ invoiceInfoData?.company_address_str }} -</strong>
                                                      <strong>{{ invoiceInfoData?.company_Building_number }} -</strong>

                                                      
                                                      <strong>{{ invoiceInfoData?.company_address_city }}</strong>
                                                  </div>
                                                  
                                                
                                                  <!-- <div style="font-weight: 400;">{{ invoiceInfoData?.company_address_zip_code }}</div> -->
                                                  <!-- <div style="font-weight: 300;">
                                                      <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? '  الرقم الضريبي للشركة' : 'מספר מס חברה')}} / </strong>
                                                      <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                  
                                                  </div> -->

                                                  <div  style="font-weight: 300;"> 
                                                      <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : ' מספר מס חברה    ')}}  </strong> 
                                                 </div>
                                                 <div  style="font-weight: 300;"> 
                                                 <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                 </div>
                                                

                                                
                                                  
                                              </div>
                                          </div>
                                          
                                          
                                          
                                      </div>
                                  </div>
                                  <div class="row  ">
                             
                                      <div class="col-sm-4"></div>
                                      <div class="invoice text-center   col-sm-4">
                                          <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة' : ' פרטי החשבונית  ')}}</h4> -->

                                          <p class="mb-1"
                                              *ngIf="invoiceInfoData?.invoice_reference_number !== null">
                                              <!-- {{ curentLangDocument === 'en' ? 'Invoice Reference Number' : (curentLangDocument === 'ar' ? 'الرقم المرجعي للفاتورة' : ' מספר הפנייה לחשבונית ')}} -->
                                              <span>{{invoiceInfoData?.invoice_reference_number}}</span>
                                          </p>

                                          <div class="mb-1"*ngIf="invoiceInfoData?.document_number !== null" style="color: black;">
                                        <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Finaical Request Number' : (curentLangDocument === 'ar' ? ' مطالبة مالية رقم    ' : '  תביעה כספית מס ')}} :</strong>   
                                        <strong style="font-weight: 400; color: black;">{{invoiceInfoData?.document_number}}</strong>
                                      </div>
                                    

                                      <div [hidden]="invoiceInfoData?.confirmation_number == null"  style="font-weight: 300;color: black;">
                                          <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Confirmation Number' : (curentLangDocument === 'ar' ? ' رقم تخصيص' : 'מספר הקצאה')}} : </strong> 
                                         <strong>{{ invoiceInfoData?.confirmation_number }}  </strong>
                                     </div>
                                         
                                      </div>

                                      <div class="col-sm-4  text-end  ">
                                          <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' التاريخ  ' : ' תאריך  ')}}</h4> -->
                                         
                                                <div class="mb-0" style="font-weight: bold;color: black;">
                                                  {{ curentLangDocument === 'en' ? '  Date' : (curentLangDocument === 'ar' ? 'تاريخ  ' : 'היסטוריה')}}
                                              </div>  
                                              <div style="font-weight: 400; color: black;">{{ invoiceInfoData?.invoice_date  }}</div>
                                      </div>
                                  </div>
                              </div>
                              <div class="order-summary">
                                  <div class="table-responsive">
                                      <table class="table invoice-table">
                                          <thead class="bg-active" style="font-weight: bold;">
                                              <tr>
                                                  <th
                                                      class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Item Name' : (curentLangDocument === 'ar' ? 'اسم الصنف' : 'שם הפריט')}}</th>
                                                  <!-- <th
                                                      class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Description' : (curentLangDocument === 'ar' ? 'الوصف' : 'תיאור')}}</th> -->
                                                  <!-- <th
                                                      class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Measure Unit' : (curentLangDocument === 'ar' ? 'وحدة القياس' : 'יחידת מידה')}}</th> -->
                                                  <th
                                                      class="text-center" style="font-weight: bold;"> {{ curentLangDocument === 'en' ? 'Price' : (curentLangDocument === 'ar' ? 'السعر  ' : ' מחיר  ')}}</th>
                                                  <th
                                                      class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Quantity' : (curentLangDocument === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                  <th
                                                      class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Discount' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</th>
                                                  <th class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ')}}</th>

                                              </tr>
                                          </thead>
                                          <tbody>
                                              <tr
                                                  *ngFor=" let item of invoiceInfoData?.lines">
                                                  <td class="text-center" style="font-weight: 400; color: black;" >{{item?.item_name}}</td>  
                                                  
                                                  <!-- <td  class="text-center  description-column"style="font-weight: 400; color: black;">{{item?.description}}</td>

                                                  <td class="text-center  description-column" style="font-weight: 400; color: black;">{{item?.measure_unit_description}}</td> -->

                                                  <td class="text-center" style="font-weight: 400; color: black;" >{{item?.price_per_unit | number:'1.2-2'}}</td>

                                                  <td class="text-center" style="font-weight: 400; color: black;" >{{item?.quantity}}</td>

                                                  <td class="text-center" style="font-weight: 400; color: black;" >{{item?.discount}}</td>

                                                  <td class="text-center" style="font-weight: 400; color: black;" >{{item?.sub_total | number:'1.2-2'}}</td>

                                              </tr>

                                          </tbody>
                                      </table>

                                    

                                  </div>
                                  <div class="small-table my-4">
                                      <div class="row">
                                          <div class="col-md-12  ">
                                            <div class="tabe table-responsive" style="background-color: #F1F1F1;">

                                              <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                                                  <div class="col-md-6  p-2 ">
                                                      <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Amount Before Discount:' : (curentLangDocument === 'ar' ? 'المبلغ قبل الخصم' : '   סכום לפני הנחה')}}</div>
                                                      <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Discount:' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                                      <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Payment Amount:' : (curentLangDocument === 'ar' ? '   المبلغ المدفوع    ' : '      סכום תשלום    ')}}</div>
                                                      <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'VAT Amount:' : (curentLangDocument === 'ar' ? '       المبلغ الضريبي    ' : '           סכום עסקה   ')}}</div>
                                                      <div style="width: 300px; font-weight: bold;" class="m-1"> {{ curentLangDocument === 'en' ? 'Payment Amount Including VAT:' : (curentLangDocument === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : '  סכום תשלום כולל עסקה  ')}}</div>

                                                  </div>

                                               

                                                  <div class="col-md-6 text-end px-3 p-2">
                                                      <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.amount_before_discount | number:'1.2-2'}}</div>
                                                      <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.all_discount | number:'1.2-2'}}</div>
                                                      <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount | number:'1.2-2'}}</div>
                                                      <div style="font-weight: 400; color: black;" class="my-1">({{invoiceInfoData?.vat_percentage}} %)   {{invoiceInfoData?.all_vat_amount | number:'1.2-2'}}</div>
                                                      <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount_including_vat | number:'1.2-2'}}</div>

                                                  </div>
                                                 
                                               
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                  
                                  
                                  
                                  
                                  
                              </div>

                          </div>

                      </div>
                  </div>
              </div>
          </div>

      </div>
  </div>
  
          
  
      </div>
  
  
  
  
    
      </div>
    </div>
  </ng-template>