import { HttpHelpService } from './../../Data/service/http-help.service';
import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/Auth/service/auth.service';

@Component({
  selector: "app-navigathion",
  templateUrl: "./navigathion.component.html",
  styleUrls: ["./navigathion.component.scss"]
})

export class NavigathionComponent implements OnInit {
  isNavbarCollapsed = true;
  isLogin : boolean = true ;
  curentLang:string="";
  constructor(@Inject(DOCUMENT) private document: Document  , 
  private service:HttpHelpService ,
   public translate:TranslateService ,
   private _AuthService:AuthService ,   private router: Router)
   { 
    //Change Lang:
    this.curentLang = localStorage.getItem('curentLang');
    this.changeLanguage(this.curentLang)
  }

  switchDirection(lang:string , dir:string)
	{
		document.body.setAttribute('direction', dir);
		this.document.getElementsByTagName('html')[0].lang = lang; 
        document.getElementsByTagName('html')[0].setAttribute('dir', dir);
		document.getElementsByTagName('html')[0].setAttribute('class',dir);
	}

  changeLanguage(lang: string) {
    localStorage.setItem('curentLang', lang);
    this.translate.use(lang);
    
    if (lang == "en") {
        this.switchDirection('en', 'ltr');
    } else if (lang == "ar") {
        this.switchDirection('ar', 'rtl');
    } else if (lang == "he") {
        this.switchDirection('he', 'rtl');
    }

    // Navigate to the current URL without full refresh
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/dashboard', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
    });
}
  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  };
  ngOnInit() {
    this._AuthService.isLogin.next(false)

  };
};
