import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-homeheader",
  templateUrl: "./homeheader.component.html",
  styleUrls: ["./homeheader.component.scss"]
})

export class HomeheaderComponent implements OnInit {
  curentLang:string="";
  deferredPrompt: any;
  showInstallButton: boolean = false;
  Token:any
  constructor( public translate:TranslateService )
   { 
    this.curentLang = localStorage.getItem('curentLang');
    this.Token = localStorage.getItem('token');
  }

  installPWA() {
    if (this.deferredPrompt) {
      // عرض نافذة التثبيت
      this.deferredPrompt.prompt();
      // انتظار اختيار المستخدم
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
         } else {
         }
        this.deferredPrompt = null;
      });
    }
  }
  ngOnInit() {
    window.addEventListener('beforeinstallprompt', (e) => {
      // منع ظهور مطالبة التثبيت الافتراضية
      e.preventDefault();
      // حفظ الحدث
      this.deferredPrompt = e;
      // عرض الزر للتثبيت
      this.showInstallButton = true;
    });
  }


}
