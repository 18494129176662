import { Component, OnInit } from "@angular/core";
import { HttpHelpService } from "../service/http-help.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AddPlanEndPoient } from "../service/global.service";
import Swal from 'sweetalert2';

@Component({
  selector: "app-plans-info",
  templateUrl: "./plans-info.component.html",
  styleUrls: ["./plans-info.component.scss"]
})

export class PlansInfoComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addPlan:FormGroup;
  updatePlan:FormGroup;
  planData:any=[];
  planId: number;
  updatedData: any;
  viewData: any;
  lookupData:any = [] ;
  isUnlimitedDocuments:any
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));


    this.addPlan = new FormGroup({
      plan_name_en: new FormControl('', [Validators.required]),
      plan_name_ar: new FormControl('', [Validators.required]),
      plan_name_he: new FormControl('', [Validators.required]),
      description_en: new FormControl('', [Validators.required]),
      description_ar: new FormControl('', [Validators.required]),
      description_he: new FormControl('', [Validators.required]),
      duration: new FormControl('', [Validators.required]),
      is_unlimited_documents: new FormControl('', [Validators.required]),
      price: new FormControl(null, [Validators.required]),
      number_of_documents: new FormControl(0, ),
      number_of_company: new FormControl(null, [Validators.required]),
      number_of_users: new FormControl(null, [Validators.required]),
      repeatable: new FormControl(null, [Validators.required]),
      created_by: new FormControl(this.userRegistId),
    });
    

    this.updatePlan=new FormGroup({
      plan_name_en: new FormControl('', [Validators.required]),
      plan_name_ar: new FormControl('', [Validators.required]),
      plan_name_he: new FormControl('', [Validators.required]),
      description_en: new FormControl('', [Validators.required]),
      description_ar: new FormControl('', [Validators.required]),
      description_he: new FormControl('', [Validators.required]),
      duration: new FormControl('', [Validators.required]),
      is_unlimited_documents: new FormControl('', [Validators.required]),
      price: new FormControl(null, [Validators.required]),
      number_of_documents: new FormControl(0,),
      number_of_company: new FormControl(null, [Validators.required]),
      number_of_users: new FormControl(null, [Validators.required]),
      repeatable: new FormControl(null, [Validators.required]),
      updated_by:new FormControl(this.userRegistId),
      });
  };

  getAllPans() {
    this.spinner.show();
    this.service.getAllPans().subscribe({
      next: res => {
        this.planData = res;
            
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getPlanTypeLabel(isUnlimited: any): string {
    // تأكد من أن isUnlimited هو boolean
    const isUnlimitedBoolean = isUnlimited === true || isUnlimited === 'true';
    
    if (isUnlimitedBoolean) {
      return this.curentLang === 'en' ? 'Unlimited' : (this.curentLang === 'ar' ? 'غير محدود' : 'ללא מספר מסמכים');
    } else {
      return this.curentLang === 'en' ? 'Limited' : (this.curentLang === 'ar' ? 'محدود' : 'מספר מסמכים');
    }
  }
  
  
  getAllStatus() {
    this.spinner.show();
    this.service.getAllStatus().subscribe({
      next: res => {
        this.lookupData = res;
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  addedData() {
    const plan_name_ar = this.addPlan.value.plan_name_ar?.trim();
    const plan_name_en = this.addPlan.value.plan_name_en?.trim();
    const plan_name_he = this.addPlan.value.plan_name_he?.trim();
    const description_ar = this.addPlan.value.description_ar?.trim();
    const description_en = this.addPlan.value.description_en?.trim();
    const description_he = this.addPlan.value.description_he?.trim();
    
    // تأكد من أن القيم العددية موجودة وليست null
    const duration = this.addPlan.value.duration !== null ? this.addPlan.value.duration : '';
    const price = this.addPlan.value.price !== null ? this.addPlan.value.price : '';
    const number_of_documents = this.addPlan.value.number_of_documents !== null ? this.addPlan.value.number_of_documents : '';
    const number_of_company = this.addPlan.value.number_of_company !== null ? this.addPlan.value.number_of_company : '';
    const number_of_users = this.addPlan.value.number_of_users !== null ? this.addPlan.value.number_of_users : '';
    const repeatable = this.addPlan.value.repeatable !== null ? this.addPlan.value.repeatable : '';
  
    // التحقق من صلاحية الفورم بالكامل
    if (
      plan_name_ar === '' ||
      plan_name_en === '' ||
      plan_name_he === '' ||
      description_ar === '' ||
      description_en === '' ||
      description_he === '' ||
      duration === '' ||
      price === '' ||
      number_of_documents === '' ||
      number_of_company === '' ||
      number_of_users === '' ||
      repeatable === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill all fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
      this.addPlan.markAllAsTouched();
      return;
    }
  
    else
    {
      let model = {
        plan_name_ar: plan_name_ar,
        plan_name_en: plan_name_en,
        plan_name_he: plan_name_he,
        description_ar: description_ar,
        description_en: description_en,
        description_he: description_he,
        duration: Number(duration),
        is_unlimited_documents: this.addPlan.value.is_unlimited_documents,
        price: Number(price),
        number_of_documents: Number(number_of_documents),
        number_of_company: Number(number_of_company),
        number_of_users: Number(number_of_users),
        repeatable: Number(repeatable),
        created_by: Number(this.userRegistId),
      };
  
      this.spinner.show();
      this.service.Post(AddPlanEndPoient.POST, model).subscribe((res) => {
        if (res.status === 201) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllPans();
          this.addPlan.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
  };
  
 
  
  onPlanTypeChange(event: any) {
    const value = event.target.value === 'true';
    this.addPlan.get('is_unlimited_documents')?.setValue(value);
  };
  setValue(planId: number) {
    const selectedPlan = this.planData.find(item => item.plan_id === planId);
    
    if (selectedPlan) {
      this.updatePlan.controls.plan_name_en.setValue(selectedPlan.plan_name_en);
      this.updatePlan.controls.plan_name_ar.setValue(selectedPlan.plan_name_ar);
      this.updatePlan.controls.plan_name_he.setValue(selectedPlan.plan_name_he);
      this.updatePlan.controls.description_en.setValue(selectedPlan.description_en);
      this.updatePlan.controls.description_ar.setValue(selectedPlan.description_ar);
      this.updatePlan.controls.description_he.setValue(selectedPlan.description_he);
      this.updatePlan.controls.duration.setValue(selectedPlan.duration);
      this.updatePlan.controls.is_unlimited_documents.setValue(selectedPlan.is_unlimited_documents); // تأكد من أن القيمة هنا boolean
      this.updatePlan.controls.price.setValue(selectedPlan.price);
      this.updatePlan.controls.number_of_documents.setValue(selectedPlan.number_of_documents);
      this.updatePlan.controls.number_of_company.setValue(selectedPlan.number_of_company);
      this.updatePlan.controls.number_of_users.setValue(selectedPlan.number_of_users);
      this.updatePlan.controls.repeatable.setValue(selectedPlan.repeatable);
  
      // طباعة القيم للتحقق
     }
  };
  
  executeFunctions(vatPerscentgeId: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(vatPerscentgeId);
    this.setValue(vatPerscentgeId);
    this.updateMd(this.updatedData);
 
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  onUpdatePlanTypeChange(event: any , planId: number) {
    const selectedPlan = this.planData.find(item => item.plan_id === planId);

    const value = event.target.value === selectedPlan;
     
    this.updatePlan.get('is_unlimited_documents')?.setValue(value);
  }
  updatedPlanData() {
    // تأكد من أن القيم النصية ليست null قبل تطبيق trim
    const plan_name_en = this.updatePlan.value.plan_name_en?.trim();
    const plan_name_ar = this.updatePlan.value.plan_name_ar?.trim();
    const plan_name_he = this.updatePlan.value.plan_name_he?.trim();
    const description_en = this.updatePlan.value.description_en?.trim();
    const description_ar = this.updatePlan.value.description_ar?.trim();
    const description_he = this.updatePlan.value.description_he?.trim();
    
    // تأكد من أن القيم العددية موجودة وليست null
    const duration = this.updatePlan.value.duration !== null ? this.updatePlan.value.duration : '';
    const price = this.updatePlan.value.price !== null ? this.updatePlan.value.price : '';
    const number_of_documents = this.updatePlan.value.number_of_documents !== null ? this.updatePlan.value.number_of_documents : '';
    const number_of_company = this.updatePlan.value.number_of_company !== null ? this.updatePlan.value.number_of_company : '';
    const number_of_users = this.updatePlan.value.number_of_users !== null ? this.updatePlan.value.number_of_users : '';
    const repeatable = this.updatePlan.value.repeatable !== null ? this.updatePlan.value.repeatable : '';
  
    // التحقق من صلاحية المدخلات
    if (
      plan_name_en === '' ||
      plan_name_ar === '' ||
      plan_name_he === '' ||
      description_en === '' ||
      description_ar === '' ||
      description_he === '' ||
      duration === '' ||
      price === '' ||
      number_of_documents === '' ||
      number_of_company === '' ||
      number_of_users === '' ||
      repeatable === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill all fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      return;
    }
  
    let model = {
      plan_name_en: plan_name_en,
      plan_name_ar: plan_name_ar,
      plan_name_he: plan_name_he,
      description_en: description_en,
      description_ar: description_ar,
      description_he: description_he,
      duration: Number(duration),
      is_unlimited_documents: this.updatePlan.value.is_unlimited_documents,
      price: Number(price),
      number_of_documents: number_of_documents ? Number(number_of_documents) : null,
      number_of_company: Number(number_of_company),
      number_of_users: Number(number_of_users),
      repeatable: Number(repeatable),
      created_by: Number(this.userRegistId),
      updated_by: Number(this.userRegistId),
    };
  
  
    this.spinner.show();
    this.service.updatePlanById(this.planId, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getAllPans();
        this.getAllStatus();
      } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });
  }
  
  
  getId(id: number) {
    this.planId = id;
  };

  filter(event:any)
  {
    let x = event.target.value;
     
   };

  executeFunctionsView(vatPerscentgeId: number, view: any) {
    this.viewData = view;
    this.getId(vatPerscentgeId);
    this.setValue(vatPerscentgeId);
    this.updateViewMd(this.viewData);
  };

  updateViewMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  changeStatus(id:number, status_plan:any){
    this.spinner.show();
  
    let model =
    {
      "status_plan": status_plan == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changePlanStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){
  
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllPans();
          this.getAllStatus();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  ngOnInit() {

    this.getAllPans();
    this.getAllStatus();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));

  };
}
