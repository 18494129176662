import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpHelpService } from "../../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { AddAccountantUsersCompanyEndPoient, AddCompanyEndPoient } from "../../service/global.service";

@Component({
  selector: "app-office-companies",
  templateUrl: "./office-companies.component.html",
  styleUrls: ["./office-companies.component.scss"]
})

export class OfficeCompaniesComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeIdNumber =JSON.parse(localStorage.getItem('office_id'));

  addAccountantUserCompanyForm:FormGroup;
  userData:any=[];

  term:any;
  p: any;
  companyData:any=[];
  addCompany:FormGroup;
  addCompanyByAdmin:FormGroup;
  company_ID:any;
  merchantInfoData:any=[];
  merchantInfoCompanyData:any=[];
  vatInfoData:any=[];
  currentPage: number = 1;
  limit: number = 400;
  customLimit:number = 400;
  offset: number = 0;
  total: number = 0; 
  rowId:number;
  Permision_id:any
  employeeData:any=[];
  accountingUserCompanyData:any=[];
  MerchantData:any;
  EmployeeName: any;
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id')); 
    this.userRegistId  =JSON.parse(localStorage.getItem('user_id'));

   this.addCompany=new FormGroup({
      company_name_ar :new FormControl(''),
      company_name_en:new FormControl(''),
      company_name_he :new FormControl(''),  
      tax_register :new FormControl(''),  
      commercial_register :new FormControl(''),  
      start_invoice_number :new FormControl(''),  
      company_address_str :new FormControl(''),
      company_Building_number :new FormControl(''),
      company_address_city :new FormControl(''),
      company_address_zip_code :new FormControl(''),
      merchant_id :new FormControl(this.merchantId),  
      vat_list_id :new FormControl(''),
      created_by:new FormControl(this.userRegistId),
    });

    this.addCompanyByAdmin=new FormGroup({
      company_name_ar :new FormControl(''),
      company_name_en:new FormControl(''),
      company_name_he :new FormControl(''),  
      tax_register :new FormControl(''),  
      commercial_register :new FormControl(''),  
      start_invoice_number :new FormControl(''),  
      company_address_str :new FormControl(''),
      company_Building_number :new FormControl(''),
      company_address_city :new FormControl(''),
      company_address_zip_code :new FormControl(''),
      merchant_id :new FormControl(''),  
      vat_list_id :new FormControl(''),
      created_by:new FormControl(this.userRegistId),
    });
    this.addAccountantUserCompanyForm = new FormGroup({
      companyUser: new FormControl([] , Validators.required),   
      office_id: new FormControl(this.officeIdNumber),
      company_id: new FormControl(this.company_ID),
      created_by: new FormControl(this.userRegistId),
    });
  };

 

  getAllAccessByUserId(limit?: number, offset?: number , keyword?: any) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getAllAccessByUserId(this.userRegistId  ,limit, offset , keyword).subscribe({
      next: res => {
        this.accountingUserCompanyData = res.data;
        
        this.total = res.total;
        this.spinner.hide();
         
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  getAllAgreedPermissionsByOfficeId(limit?: number, offset?: number, keyword?: any) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
  
    this.service.getAllAgreedPermissionsByOfficeId(this.officeIdNumber, limit, offset, keyword).subscribe({
      next: res => {
        this.companyData = res.data;
        
        // Initialize an array to store individual MerchantData arrays
        this.MerchantData = res.data.map(merchant => ({
          accountantUsersCompanyAccesses: merchant.accountantUsersCompanyAccesses.map(access => ({
            user_name: access.user.user_name,
            phone_number: access.user.phone_number
          }))
        }));
  
        // Set EmployeeName to the first user's name from each merchant's access list
        this.EmployeeName = this.MerchantData.map(data => data.accountantUsersCompanyAccesses[0]?.user_name);
  
 
  
        this.total = res.total;
        this.spinner.hide();
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }
  
  openXl4(content4:any) {
    this.modalService.open(content4, { size: 'md'});
   
  };
  
 

  getAllUserAssesToCompany() {
    this.spinner.show();
    this.service.getAllUserAssesToCompany(this.officeIdNumber, this.rowId).subscribe({
      next: res => {
        this.employeeData = res;
         
        if (this.employeeData && this.employeeData.length > 0) {
          this.Permision_id = this.employeeData[0].permission_id;    
        }
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  onLimitChange() {
    this.limit = this.customLimit;
    this.offset = (this.currentPage - 1) * this.limit;
    this.getAllAgreedPermissionsByOfficeId(this.limit, this.offset, this.term);
  }

  onLimitChange2() {
    this.limit = this.customLimit;
    this.offset = (this.currentPage - 1) * this.limit;
    this.getAllAccessByUserId(this.limit, this.offset, this.term);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllAgreedPermissionsByOfficeId(this.limit, offset , this.term);
  }
  onPageChange2(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllAccessByUserId(this.limit, offset , this.term);
  }

  getCompanyId(id: number , permision_id:number) {
    this.company_ID = id;
    this.Permision_id = permision_id
  
  };

  onCompanyMerchantChange(event) {
    this.addAccountantUserCompanyForm.patchValue({
      companyUser: event
      
    });
  };

  addedAccountingUserCompany() {
    if (this.addAccountantUserCompanyForm.invalid) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      return;
    } else {
      const companyUsers = this.addAccountantUserCompanyForm.value.companyUser;
       
      const models = companyUsers.map(companyUser => ({
        office_id: Number(this.officeIdNumber),
        company_id: Number(this.company_ID),
        permission_id: Number(this.Permision_id),
        user_id: Number(companyUser.user_id),
        created_by: Number(this.userRegistId),
      }));
  
      this.spinner.show();
      this.service.Post(AddAccountantUsersCompanyEndPoient.POST, models).subscribe((res) => {
        this.spinner.hide();
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          // this.getALLUsersByBasedToken();
          
          this.addAccountantUserCompanyForm.reset();
        } else {
          let errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
      });
    }
  }
  

  getALLUsersByBasedToken() {
    this.spinner.show();
    this.service.getALLUsersByBasedToken().subscribe({
      next: res => {
        this.userData = res;
          
         this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllMerchantInfo() {
    this.spinner.show();
    this.service.getAllMerchantInfo().subscribe({
      next: res => {
        this.merchantInfoData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  filterMerchant(event:any)
{
  let x = event;
  
 };

  filter(event:any)
{
  let x = event.target.value;
 };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  openXl2(contentAdmin:any) {
    this.modalService.open(contentAdmin, { size: 'xl' , centered:true });
  };

  openXl3(content2:any) {
    this.modalService.open(content2, { size: 'md'   });
  };

  addedData() {
    // التحقق من صحة النموذج باستخدام formControl
    if (this.addCompany.invalid) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
  
      // Mark all controls as touched to show validation errors
      this.addCompany.markAllAsTouched();
      return;
    }
  
    let model = {
      company_name_ar: this.addCompany.value.company_name_ar,
      company_name_en: this.addCompany.value.company_name_en,
      company_name_he: this.addCompany.value.company_name_he,
      company_address_str: this.addCompany.value.company_address_str,
      company_Building_number: this.addCompany.value.company_Building_number,
      company_address_city: this.addCompany.value.company_address_city,
      company_address_zip_code: this.addCompany.value.company_address_zip_code,
      tax_register: this.addCompany.value.tax_register,
      commercial_register: this.addCompany.value.commercial_register,
      start_invoice_number: this.addCompany.value.start_invoice_number,
      vat_list_id: Number(this.addCompany.value.vat_list_id),
      merchant_id: Number(this.merchantId),
      created_by: Number(this.userRegistId),
    };
  
    this.spinner.show();
    this.service.Post(AddCompanyEndPoient.POST, model).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllAgreedPermissionsByOfficeId();
          this.getAllMerchantInfo();
          // إعادة تعيين النموذج مع القيم الافتراضية
          this.resetForm();
        } else {
          this.handleErrorResponse(res);
        }
      },
      (err) => {
        this.spinner.hide();
        this.handleErrorResponse(err);
      }
    );
  }

  resetForm() {
    this.addCompany.reset({
      company_name_ar: '',
      company_name_en: '',
      company_name_he: '',
      company_address_str: '',
      company_Building_number: '',
      company_address_city: '',
      company_address_zip_code: '',
      tax_register: '',
      commercial_register: '',
      start_invoice_number: '',
      vat_list_id: null,
    });
  }

  resetFormAdmin() {
    this.addCompanyByAdmin.reset({
      company_name_ar: '',
      company_name_en: '',
      company_name_he: '',
      company_address_str: '',
      company_Building_number: '',
      company_address_city: '',
      company_address_zip_code: '',
      tax_register: '',
      commercial_register: '',
      start_invoice_number: '',
      merchant_id: null,
      vat_list_id: null,
    });
  }

  handleErrorResponse(response: any) {
    let errorMessage;
    switch (response.status) {
      case 400:
      case 403:
      case 406:
        errorMessage = this.curentLang === 'ar' ? response.messageAr : (this.curentLang === 'en' ? response.messageEn : response.messageHe);
        break;
      default:
        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
    }
    Swal.fire({
      icon: 'error',
      title: errorMessage
    });
  }

  handleErrorResponseA(response: any) {
    let errorMessage;
    switch (response.status) {
      case 400:
      case 403:
      case 406:
        errorMessage = this.curentLang === 'ar' ? response.messageAr : (this.curentLang === 'en' ? response.messageEn : response.messageHe);
        break;
      default:
        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
    }
    Swal.fire({
      icon: 'error',
      title: errorMessage
    });
  }
  
  
  getId(id:number)
{
   this.merchantId = id ;
};

openVerticallyCentered(dataDeleted:any) {
  this.modalService.open(dataDeleted, { centered: true });
};

getRowId(id:number)
{
 this.rowId = id ;
 this.getAllUserAssesToCompany()
  
};

deleteRelatedOfficeToCompany() {

  this.service.deleteRelatedOfficeToCompany( this.rowId).subscribe(({
    next:res=> {
      
      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
      })
          this.getAllAgreedPermissionsByOfficeId();
          this.getAllAgreedPermissionsByOfficeId();
          this.companyData = res;
          this.modalService.dismissAll()
    },
    error:err =>{
      Swal.fire(err)
    }
  }))
};

  ngOnInit() {
    this.getAllAgreedPermissionsByOfficeId();
    this.getAllMerchantInfo();
    this.getALLUsersByBasedToken();
    this.getAllAccessByUserId();
    // this.getAllCompaniesInfoByMerchantId();
   this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));    

  };
}
