<app-breadcrumb [title]=" curentLang === 'en' ? 'Add Receipts': curentLang === 'ar' ? ' اضافة ايصال ' : ' הוסף קבלה '
      " [items]="curentLang === 'en'? ['Dashboard']: curentLang === 'ar' ? ['لوحة التحكم']: ['  לוח הבקרה']
      " [active_item]="  curentLang === 'en' ? 'Add Receipts': curentLang === 'ar' ? ' اضافة ايصال ' : ' הוסף קבלה ' ">
</app-breadcrumb>

<div class="container-fluid basic_table">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div>
                            <form [formGroup]="receiptForm" >
                                <div class="row">
                                  
                                
                                    <div class="col-lg-5">
                                        <div class="form-group mx-2">
                                            <label class="mt-2">{{ curentLang === 'en' ? 'Select Company Name' :
                                                (curentLang === 'ar' ? 'اختر اسم الشركة' : 'בחר את שם החברה ')}}</label>
                                            <span class="text-danger">*</span>
                                            <!-- <select formControlName="company_id" class="form-control form-select">
                                                <option *ngFor="let item of masterCompany" [value]="item.company_id">
                                                    {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar'
                                                    ? item.company_name_ar : item.company_name_he) }}
                                                </option>
                                            </select> -->

                                            <ng-select formControlName="company_id" (change)="filter($event)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                <ng-option *ngFor="let item of masterCompany; let i = index " [value]="item.company_id">
                                                    {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar'
                                                    ? item.company_name_ar : item.company_name_he) }}
                                               </ng-option>
                                              </ng-select>

                                        </div>
                                    </div>

                                    <div class="col-lg-5">
                                        <div class="form-group mx-1">
                                            <label class="mt-2"> {{ curentLang === 'en' ? 'Select Customer Name' :
                                                (curentLang === 'ar' ? 'اختر اسم العميل' : 'בחר את שם הלקוח ')}}</label>
                                            <span class="text-danger">*</span>
                                            <!-- <select formControlName="customer_id" class="form-control form-select">
                                                <option *ngFor="let item of masterCustomer" [value]="item.customer_id">
                                                    {{ curentLang === 'en' ? item.customer_name_en : (curentLang ===
                                                    'ar' ? item.customer_name_ar : item.customer_name_he) }}
                                                </option>
                                            </select> -->


                                            <ng-select formControlName="customer_id" (change)="filterCustomer($event)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                <ng-option *ngFor="let item of masterCustomer; let i = index " [value]="item.customer_id">
                                                    {{ curentLang === 'en' ? item.customer_name_en : (curentLang ===
                                                    'ar' ? item.customer_name_ar : item.customer_name_he) }}   
                                               </ng-option>
                                              </ng-select>
                                        </div>
                                    </div>
                                
                                    <div class="col-md-2 " style="padding-top: 42px;" >
                                        <button [hidden]="user_type == 200" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXlCustomer(contentAddCustomer)">
                                            <i class="fa fa-plus-circle mx-1"></i>
                                          {{ curentLang === 'en' ? 'Add Customer' : (curentLang === 'ar' ? '  اضافة عميل' : ' הוסף לקוח ')}}
                                        </button>
                                      </div>

                                    <div class="col-lg-4">
                                        <div class="form-group mx-2">
                                            <label class="mt-2"> {{ curentLang === 'en' ? 'Receipt Date' :
                                                (curentLang === 'ar' ? 'تاريخ الايصال' : 'תאריך קבלה')}}</label>
                                            <input type="date" formControlName="invoice_date" class="form-control w-100">
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-4">
                                        <div class="form-group mx-2">
                                            <label class="mt-2">
                                                {{
                                                curentLang === "en"
                                                ? "Document Language"
                                                : curentLang === "ar"
                                                ? "لغة المستند "
                                                : "שפת המסמך"
                                                }}</label>
                                            <select formControlName="document_language" class="form-control form-select" (change)="filter($event)">
                                                <option *ngFor="let lang of availableLanguages" [value]="lang.code"
                                                    [selected]="lang.code === curentLang">
                                                    {{ lang.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-4">
                                        <div class="form-group mx-2">
                                            <label class="mt-2">
                                                {{
                                                curentLang === "en"
                                                ? "Currency"
                                                : curentLang === "ar"
                                                ? "العملة"
                                                : "שפת המסמך"
                                                }}</label>
                                            <input type="text" formControlName="currency" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                
                                
                                
                                <br />

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="table-responsive">
                                            <!-- Main table for larger screens -->
                                            <table class="table table-bordered d-none d-lg-table" formArrayName="linesChecks">
                                                <!-- Table header -->
                                                <thead class="text-black">
                                                    <tr>
                                                        <!-- Table headers with language support -->
                                                        <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</th>
                                                        <th>{{ curentLang === "en" ? "Reference" : (curentLang === "ar" ? "المرجع" : "הפניה") }}</th>
                                                        <th>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك" : "בנק") }}</th>
                                                        <th>{{ curentLang === "en" ? "Branch" : (curentLang === "ar" ? "الفرع" : "סניף") }}</th>
                                                        <th>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</th>
                                                        <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</th>
                                                        <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</th>
                                                        <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف" : "מחק") }}</th>
                                                    </tr>
                                                </thead>
                                                <!-- Table body -->
                                                <tbody>
                                                    <tr *ngFor="let item of linesChecks.controls; let i = index" [formGroupName]="i">
                                                        <td><input class="form-control" formControlName="date" type="date" /></td>
                                                        <td><input class="form-control" formControlName="reference" type="number" /></td>
                                                        <td>
                                                            <ng-select formControlName="bank" (change)="getBranches($event ,i)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                                <ng-option *ngFor="let item of masterBank" [value]="item?.bank_code">
                                                                    {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}
                                                                </ng-option>
                                                            </ng-select>
                                                        </td>
                                                        <td>
                                                            <ng-select formControlName="branch" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                                <ng-option *ngFor="let item of getBranchesForIndex(i)" [value]="item?.bank_branch_code">
                                                                    {{ curentLang === 'en' ? item.bank_branch_name_en : (curentLang === 'ar' ? item.bank_branch_name_ar : item.bank_branch_name_he) }}
                                                                </ng-option>
                                                            </ng-select>
                                                        </td>
                                                        <td><input class="form-control" formControlName="account" type="number" /></td>
                                                        <td><input class="form-control" formControlName="details" type="text" /></td>
                                                        <td><input class="form-control" formControlName="amount" type="number" /></td>
                                                        <td>
                                                            <i (click)="removeLineFromChecks(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <!-- Table footer -->
                                                <tfoot>
                                                    <tr>
                                                        <td>
                                                            <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToChecks()">
                                                                <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                                {{ curentLang === 'en' ? 'Add Check' : (curentLang === 'ar' ? 'اضافة دفعة شيك' : 'הוסף מוצר') }}
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <input type="number" min="0" [value]="getTotalAmountChecksArray()" class="form-control" formControlName="checks_amount">
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                
                                            <!-- Vertical form layout for small screens -->
                                            <div class="d-lg-none" formArrayName="linesChecks">
                                                <div *ngFor="let item of linesChecks.controls; let i = index" class="border mb-3 p-3" [formGroupName]="i">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</label>
                                                            <input class="form-control" formControlName="date" type="date" />
                                                        </div>
                                                        <div class="col-12">
                                                            <label>{{ curentLang === "en" ? "Reference" : (curentLang === "ar" ? "المرجع" : "הפניה") }}</label>
                                                            <input class="form-control" formControlName="reference" type="number" />
                                                        </div>
                                                        <div class="col-12">
                                                            <label>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك" : "בנק") }}</label>
                                                            <ng-select formControlName="bank" (change)="getBranches($event, i)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                                <ng-option *ngFor="let item of masterBank" [value]="item?.bank_code">
                                                                    {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}
                                                                </ng-option>
                                                            </ng-select>
                                                        </div>
                                                        <div class="col-12">
                                                            <label>{{ curentLang === "en" ? "Branch" : (curentLang === "ar" ? "الفرع" : "סניף") }}</label>
                                                            <ng-select formControlName="branch" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                                <ng-option *ngFor="let item of getBranchesForIndex(i)" [value]="item?.bank_branch_code">
                                                                    {{ curentLang === 'en' ? item.bank_branch_name_en : (curentLang === 'ar' ? item.bank_branch_name_ar : item.bank_branch_name_he) }}
                                                                </ng-option>
                                                            </ng-select>
                                                        </div>
                                                        <div class="col-12">
                                                            <label>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</label>
                                                            <input class="form-control" formControlName="account" type="number" />
                                                        </div>
                                                        <div class="col-12">
                                                            <label>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</label>
                                                            <input class="form-control" formControlName="details" type="text" />
                                                        </div>
                                                        <div class="col-12">
                                                            <label>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</label>
                                                            <input class="form-control" formControlName="amount" type="number" />
                                                        </div>
                                                        <div class="col-12 text-center">
                                                            <button class="btn btn-sm m-2" (click)="removeLineFromChecks(i)" style="background-color: red; color: #fff;">
                                                                <i  class="fa fa-trash mx1" style="font-size: 14px; cursor: pointer; color: #fff;" aria-hidden="true"></i>
                                                                {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? '  حذف' : '  לִמְחוֹק') }}
                                                            </button>
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                   <!-- Total amount for small screens -->
                                                   <div class="text-center m-3">
                                                    <input type="number" min="0" [value]="getTotalAmountChecksArray()" class="form-control" formControlName="checks_amount">
                                                </div>
                                                <!-- Add button for small screens -->
                                                <div class="text-center">
                                                    <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToChecks()">
                                                        <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                        {{ curentLang === 'en' ? 'Add Check' : (curentLang === 'ar' ? 'اضافة دفعة شيك' : 'הוסף מוצר') }}
                                                    </button>
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="table-responsive  ">
                                            <table class="table table-bordered d-none d-lg-table" formArrayName="linesBankPayment">
                                                <!-- Table header -->
                                                <thead class="text-black">
                                                    <tr>
                                                        <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</th>
                                                        <th>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك" : "בנק") }}</th>
                                                        <th>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</th>
                                                        <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</th>
                                                        <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</th>
                                                        <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف" : "מחק") }}</th>
                                                    </tr>
                                                </thead>
                                                <!-- Table body -->
                                                <tbody>
                                                    <tr *ngFor="let item of linesBankPayment.controls; let i = index" [formGroupName]="i">
                                                        <td><input class="form-control" formControlName="date" type="date" /></td>
                                                        <td>
                                                            <ng-select formControlName="bank" (change)="getBranches($event, i)" style="cursor: pointer;"
                                                                placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                                <ng-option *ngFor="let item of masterBank" [value]="item?.bank_code">
                                                                    {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}
                                                                </ng-option>
                                                            </ng-select>
                                                        </td>
                                                        <td><input class="form-control" formControlName="account" type="number" /></td>
                                                        <td><input class="form-control" formControlName="details" type="text" /></td>
                                                        <td><input class="form-control" formControlName="amount" type="number" /></td>
                                                        <td>
                                                            <i (click)="removeLineFromBankPayment(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <!-- Table footer -->
                                                <tfoot>
                                                    <tr>
                                                        <td >
                                                            <!-- Add button to add new line -->
                                                            <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToBankPayment()">
                                                                <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                                {{ curentLang === 'en' ? 'Add Transfer Bank' : (curentLang === 'ar' ? 'اضافة تحويل بنكي' : 'הוסף מוצר') }}
                                                            </button>

                                                            
                                                        </td>
                                                      
                                                        <td>
                                                            <input type="number" min="0" [value]="getTotalAmountBankPaymentArray()" class="form-control" formControlName="bank_payment_amount">
                                                        </td>
                                                        
                                                    </tr>
                                                
                                                </tfoot>
                                            </table>
                                        </div>
                                
                                        <!-- Mobile layout -->
                                        <div class="d-lg-none" formArrayName="linesBankPayment">
                                            <div *ngFor="let item of linesBankPayment.controls; let i = index" [formGroupName]="i" class="border p-3 my-2">
                                                <div class="form-group">
                                                    <label>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</label>
                                                    <input class="form-control" formControlName="date" type="date" />
                                                </div>
                                                <div class="form-group">
                                                    <label>{{ curentLang === "en" ? "Bank" : (curentLang === "ar" ? "البنك" : "בנק") }}</label>
                                                    <ng-select formControlName="bank" (change)="getBranches($event, i)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                        <ng-option *ngFor="let item of masterBank" [value]="item?.bank_code">
                                                            {{ curentLang === 'en' ? item.bank_name_en : (curentLang === 'ar' ? item.bank_name_ar : item.bank_name_he) }}
                                                        </ng-option>
                                                    </ng-select>
                                                </div>
                                                <div class="form-group">
                                                    <label>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</label>
                                                    <input class="form-control" formControlName="account" type="number" />
                                                </div>
                                                <div class="form-group">
                                                    <label>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</label>
                                                    <input class="form-control" formControlName="details" type="text" />
                                                </div>
                                                <div class="form-group">
                                                    <label>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</label>
                                                    <input class="form-control" formControlName="amount" type="number" />
                                                </div>
                                                <div class="text-center">
                                                    <button (click)="removeLineFromBankPayment(i)" class="btn btn-sm m-2" style="background-color: red; color: #fff;">
                                                        <i  class="fa fa-trash" style="font-size: 14px; cursor: pointer; color: #fff;" aria-hidden="true"></i>
                                                        {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? '  حذف' : '  לִמְחוֹק') }}
                                                    </button>
                                                  
                                                </div>
                                            </div>
                                
                                             <!-- Total amount for small screens -->
                                             <div class="text-center m-3">
                                                <input type="number" min="0" [value]="getTotalAmountBankPaymentArray()" class="form-control" formControlName="bank_payment_amount">
                                            </div>
                                            <!-- Add button for small screens -->
                                            <div class="text-center mt-3">
                                                <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToBankPayment()">
                                                    <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                    {{ curentLang === 'en' ? 'Add Transfer Bank' : (curentLang === 'ar' ? 'اضافة تحويل بنكي' : 'הוסף מוצר') }}
                                                </button>
                                            </div>
                                
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                      <!-- Desktop Table View -->
                                      <div class="table-responsive ">
                                        <table class="table d-none d-lg-block table-bordered" formArrayName="linesCredit">
                                          <!-- Table header -->
                                          <thead class="text-black">
                                            <tr>
                                              <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</th>
                                              <th>{{ curentLang === "en" ? "Payment Type" : (curentLang === "ar" ? "نوع الدفع" : "סוג תשלום") }}</th>
                                              <th>{{ curentLang === "en" ? "Credit Type" : (curentLang === "ar" ? "نوع الأتمان" : "סוג אשראי") }}</th>
                                              <th>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</th>
                                              <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</th>
                                              <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</th>
                                              <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف" : "מחק") }}</th>
                                            </tr>
                                          </thead>
                                          <!-- Table body -->
                                          <tbody>
                                            <tr *ngFor="let item of linesCredit.controls; let i = index" [formGroupName]="i">
                                              <td>
                                                <input class="form-control" formControlName="date" type="date" />
                                              </td>
                                              <td>
                                                <select formControlName="credit_card_name" class="form-control form-select" (change)="filterPayment($event)">
                                                  <option [value]="1">Normal</option>
                                                  <option [value]="2">Payments</option>
                                                  <option [value]="3">Credit</option>
                                                  <option [value]="4">Deferred Payment</option>
                                                  <option [value]="5">Others</option>
                                                  <option [value]="0">Not Credit Card</option>
                                                </select>
                                              </td>
                                              <td>
                                                <select formControlName="credit_type" class="form-control form-select" (change)="filterCreidt($event, i)">
                                                  <option [value]="1">Isracard</option>
                                                  <option [value]="2">Cal</option>
                                                  <option [value]="3">Diners</option>
                                                  <option [value]="4">American Express</option>
                                                  <option [value]="6">Leumi Card</option>
                                                  <option [value]="0">Not Credit Card</option>
                                                </select>
                                              </td>
                                              <td>
                                                <input class="form-control" formControlName="account" type="number" />
                                              </td>
                                              <td>
                                                <input class="form-control" formControlName="details" type="text" />
                                              </td>
                                              <td>
                                                <input class="form-control" formControlName="amount" type="number" />
                                              </td>
                                              <td>
                                                <i (click)="removeLineFromCreidtPayment(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true"></i>
                                              </td>
                                            </tr>
                                          </tbody>
                                          <!-- Table footer -->
                                          <tfoot>
                                            <tr>
                                              <td>
                                                <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToCredit()">
                                                  <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                  {{ curentLang === 'en' ? 'Add Credit' : (curentLang === 'ar' ? 'إضافة فيزا' : 'הוסף אשראי') }}
                                                </button>
                                              </td>
                                              <td>
                                                <input type="number" min="0" [value]="getTotalAmountCreidtArray()" class="form-control" formControlName="credit_amount">
                                              </td>
                                            </tr>
                                          </tfoot>
                                        </table>
                                      </div>
                                  
                                      <!-- Mobile-Friendly Layout -->
                                      <div class="d-block d-lg-none" formArrayName="linesCredit">
                                        <div *ngFor="let item of linesCredit.controls; let i = index" [formGroupName]="i" class="mb-3 border rounded p-2">
                                          <div class="form-group">
                                            <label>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</label>
                                            <input class="form-control" formControlName="date" type="date" />
                                          </div>
                                  
                                          <div class="form-group">
                                            <label>{{ curentLang === "en" ? "Payment Type" : (curentLang === "ar" ? "نوع الدفع" : "סוג תשלום") }}</label>
                                            <select formControlName="credit_card_name" class="form-control form-select" (change)="filterPayment($event)">
                                              <option [value]="1">Normal</option>
                                              <option [value]="2">Payments</option>
                                              <option [value]="3">Credit</option>
                                              <option [value]="4">Deferred Payment</option>
                                              <option [value]="5">Others</option>
                                              <option [value]="0">Not Credit Card</option>
                                            </select>
                                          </div>
                                  
                                          <div class="form-group">
                                            <label>{{ curentLang === "en" ? "Credit Type" : (curentLang === "ar" ? "نوع الأتمان" : "סוג אשראי") }}</label>
                                            <select formControlName="credit_type" class="form-control form-select" (change)="filterCreidt($event, i)">
                                              <option [value]="1">Isracard</option>
                                              <option [value]="2">Cal</option>
                                              <option [value]="3">Diners</option>
                                              <option [value]="4">American Express</option>
                                              <option [value]="6">Leumi Card</option>
                                              <option [value]="0">Not Credit Card</option>
                                            </select>
                                          </div>
                                  
                                          <div class="form-group">
                                            <label>{{ curentLang === "en" ? "Account" : (curentLang === "ar" ? "الحساب" : "חשבון") }}</label>
                                            <input class="form-control" formControlName="account" type="number" />
                                          </div>
                                  
                                          <div class="form-group">
                                            <label>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</label>
                                            <input class="form-control" formControlName="details" type="text" />
                                          </div>
                                  
                                          <div class="form-group">
                                            <label>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</label>
                                            <input class="form-control" formControlName="amount" type="number" />
                                          </div>
                                  
                                          <div class="text-center my-3">
                                            <button class="btn btn-sm m-2" style="background-color: red; color: #fff;" (click)="removeLineFromCreidtPayment(i)">
                                                <i  class="fa fa-trash mx1" style="font-size: 14px; cursor: pointer; color: #fff;" aria-hidden="true"></i>
                                                {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? '  حذف' : '  לִמְחוֹק') }}
                                            </button>
                                            
                                          </div>
                                        </div>

                                        <div class="text-center m-3">
                                            <input type="number" min="0" [value]="getTotalAmountCreidtArray()" class="form-control" formControlName="credit_amount">
                                        </div>
                                        <!-- Add button for mobile layout -->
                                        <div class="text-center">
                                        <button type="button" class="btn my-2  " style="background-color: #141414; color: #fff;" (click)="addNewLineToCredit()">
                                          <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                          {{ curentLang === 'en' ? 'Add Credit' : (curentLang === 'ar' ? 'إضافة فيزا' : 'הוסף אשראי') }}
                                        </button>
                                        </div>
                                       
                                      </div>
                                    </div>
                                  </div>
                                  

                                  <div class="row">
                                    <div class="col-lg-12">
                                        <!-- Table for larger screens -->
                                        <div class="table-responsive ">
                                            <table class="table d-none d-lg-table table-bordered" formArrayName="linesCash">
                                                <!-- Table header -->
                                                <thead class="text-black">
                                                    <tr>
                                                        <th>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</th>
                                                        <th>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</th>
                                                        <th>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</th>
                                                        <th>{{ curentLang === "en" ? "Remove" : (curentLang === "ar" ? "حذف" : "מחק") }}</th>
                                                    </tr>
                                                </thead>
                                                <!-- Table body -->
                                                <tbody>
                                                    <tr *ngFor="let item of linesCash.controls; let i = index" [formGroupName]="i">
                                                        <td>
                                                            <input class="form-control" formControlName="date" type="date" />
                                                        </td>
                                                        <td>
                                                            <input class="form-control" formControlName="details" type="text" />
                                                        </td>
                                                        <td>
                                                            <input class="form-control" formControlName="amount" type="number" />
                                                        </td>
                                                        <td>
                                                            <i (click)="removeLineFromCash(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true"></i>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <!-- Table footer -->
                                                <tfoot>
                                                    <tr>
                                                        <td >
                                                            <!-- Add button to add new line -->
                                                            <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToCash()">
                                                                <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                                {{ curentLang === 'en' ? 'Add Cash' : (curentLang === 'ar' ? 'اضافة دفعة كاش' : 'הוסף מוצר') }}
                                                            </button>
                                                        </td>
                                                   
                                                        <td  >
                                                            <input type="number" min="0" [value]="getTotalAmountCashArray()" class="form-control" formControlName="cash_amount">
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                                
                                            </table>
                                        </div>
                                
                                        <!-- Mobile layout for smaller screens -->
                                        <div class="d-lg-none" formArrayName="linesCash">
                                            <div *ngFor="let item of linesCash.controls; let i = index" [formGroupName]="i" class="border p-3 my-2">
                                                <div class="form-group">
                                                    <label>{{ curentLang === "en" ? "Date" : (curentLang === "ar" ? "التاريخ" : "תאריך") }}</label>
                                                    <input class="form-control" formControlName="date" type="date" />
                                                </div>
                                                <div class="form-group">
                                                    <label>{{ curentLang === "en" ? "Details" : (curentLang === "ar" ? "التفاصيل" : "פרטים") }}</label>
                                                    <input class="form-control" formControlName="details" type="text" />
                                                </div>
                                                <div class="form-group">
                                                    <label>{{ curentLang === "en" ? "Amount" : (curentLang === "ar" ? "المبلغ" : "סכום") }}</label>
                                                    <input class="form-control" formControlName="amount" type="number" />
                                                </div>
                                                <div class="text-center">
                                                    <button class="btn btn-sm m-2" style="background-color: red; color: #fff;" (click)="removeLineFromCash(i)">
                                                        <i  class="fa fa-trash" style="font-size: 14px; cursor: pointer; color: #fff;" aria-hidden="true"></i>
                                                        {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? '  حذف' : '  לִמְחוֹק') }}
                                                    </button>
                                                   
                                                </div>
                                            </div>
                                

                                              <!-- Total amount for small screens -->
                                              <div class="text-center m-3">
                                                <input type="number" min="0" [value]="getTotalAmountCashArray()" class="form-control" formControlName="cash_amount">
                                            </div>
                                            <!-- Add button for small screens -->
                                            <div class="text-center mt-3">
                                                <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addNewLineToCash()">
                                                    <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                    {{ curentLang === 'en' ? 'Add Cash' : (curentLang === 'ar' ? 'اضافة دفعة كاش' : 'הוסף מוצר') }}
                                                </button>
                                            </div>
                                
                                          
                                        </div>
                                    </div>
                                </div>
                                



                                <br />


                                <!-- <div class="row d-flex  ">
                                    <div class="col-lg-12 m-2 text-center d-flex justify-content-between">
                                        <br />
                                        <div class="col-lg-12   ">
                                            <label for="" style="font-weight: 800;">
                                            {{ curentLang === "en" ? "Deduction From Source" : curentLang === "ar" ? "  الخصم من المصدر" : "   ניכוי מהמקור  " }}
                                            </label>
                                            <input type="text" formControlName="deduction_from_source" class="form-control m-auto w-25">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 m-2 text-center d-flex justify-content-between">
                                        <br />
                                        <div class="col-lg-12">
                                            <label for="" style="font-weight: 800;">
                                                {{ curentLang === "en" ? "Final Amount" : curentLang === "ar" ? "القيمة النهائية " : "     סכום סופי  " }}
                                            </label>
                                         
                                            <input class="form-control m-auto w-25" formControlName="total_amount" type="number" [value]="getTotalAmount()">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 m-2 text-center d-flex justify-content-between">
                                        <hr>  
                                    </div>
                                  
                                </div> -->

                                <div class="row d-flex justify-content-center">
                                    <div class="col-md-3"></div>

                                   <div class="col-md-3 ">
                                    <div class="col-lg-12 p-2  ">
                                        <label for="" style="font-weight: 800;">
                                        {{ curentLang === "en" ? "Deduction From Source" : curentLang === "ar" ? "  الخصم من المصدر" : "   ניכוי מהמקור  " }}
                                        </label>
                                        <input type="text" formControlName="deduction_from_source" class="form-control m-auto w-100">
                                    </div>
                                   </div>

                                   <div class="col-md-3 ">
                                   <div class="col-lg-12 p-2">
                                    <label for="" style="font-weight: 800;">
                                        {{ curentLang === "en" ? "Final Amount" : curentLang === "ar" ? "القيمة النهائية " : "     סכום סופי  " }}
                                    </label>
                                    <!-- Second input -->
                                    <input class="form-control m-auto w-100" formControlName="total_amount" type="number" [value]="getTotalAmount()">
                                </div>
                                   </div>

                                <div class="col-md-3"></div>
                                
                                </div>
                                <div class="row text-center m-2 d-flex justify-content-between">
                                    <div class="col-6 col-lg-6">
                                        <!-- Save button -->
                                        <button class="btn mx-1" style="background-color: #277d29; color: #fff" type="submit" (click)="SaveReceipt()">
                                            <i class="fa fa-save mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === "en" ? "Save  " : curentLang === "ar" ? "  حفظ" : "  לְהַצִיל  " }}
                                        </button>
                                    </div>
                                    <div class="col-6 col-lg-6">
                                        <!-- Back button -->
                                        <a class="btn mx-1 btn-danger" routerLink="/dashboard/receipts">
                                            <i class="fa fa-rotate-left mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === "en" ? "Back" : curentLang === "ar" ? "رجوع" : "חזרה" }}
                                        </a>
                                    </div>
                                </div>
                                
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>











<!--add Customer-->

<ng-template #contentAddCustomer let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Add Customer ' : (curentLang === 'ar' ? ' اضافة عميل ' : '  הוסף לקוח ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="addCustomer" novalidate [ngClass]="{ 'was-validated': validate }">
                        <div class="row">

                            <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Customer Name Ar' : (curentLang === 'ar' ? '  اسم العميل عربي   ' : '  שם החברה בערבית    ')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="customer_name_ar" 
                                  />
                              </div> -->
              
                              <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Customer Name En' : (curentLang === 'ar' ? '  اسم العميل انجليزي   ' : '    שם מס באנגלית     ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="customer_name_en"
                                   />
                                </div> -->
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Customer Name ' : (curentLang === 'ar' ? '  اسم العميل    ' : ' שם הסוכן ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="customer_name_he" 
                                   maxlength="80"
                                   required="" onlyAlphabets
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم العميل ' : 'נא להזין את שם הלקוח')}}  </div>

                                </div>

                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Customer Type' : (curentLang === 'ar' ? 'نوع العميل' : '  סוג לקוח ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                 <select class="form-control form-select" required="" onlyAlphabets
                                 style="cursor: pointer; "
                                 (change)="filterAddCustomer($event)" formControlName="customer_type">
                                 <option *ngFor='let item of customerTypeData; let i = index '
                                     [value]="item?.lookup_detail_id">
                                     {{ curentLang === 'en' ?  item?.lookupDetailEnName :(curentLang === 'ar' ? item?.lookupDetailArName  :  item?.lookupDetailHeName )}}
                                 </option>
                             </select>
                             <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please choose customer type ' : (curentLang === 'ar' ? ' الرجاء اختيار نوع العميل ' : ' אנא בחר סוג לקוח ')}}  </div>

                             
                                </div>

                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Customer Phone' : (curentLang === 'ar' ? ' هاتف العميل ' : ' טלפון לקוח ')}}
                                 </label>
                                 <div class="input-group">
                                  <span style="color: #141414;" class="input-group-text">+972</span>
                                  <input class="form-control" type="text" maxlength="10"    formControlName="customer_phone">
                                </div>
                                <div *ngIf="addCustomer.get('customer_phone')?.errors != null && addCustomer.get('customer_phone')?.touched" class="alert text-danger my-1">
                                  <p *ngIf="addCustomer.get('customer_phone')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                                  <p *ngIf="addCustomer.get('customer_phone')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{addCustomer.get('customer_phone')?.errors?.invalidMobilePrefix.message}}</p>
                                  <p *ngIf="addCustomer.get('customer_phone')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{addCustomer.get('customer_phone')?.errors?.invalidPhoneNumber.message}}</p>
                                </div>
                                </div>

                                <div class="mb-3 col-xl-4 col-md-4 " *ngIf="addCustomer.value.customer_type != 6">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'ID Number' : (curentLang === 'ar' ? 'رقم الهوية' : ' מספר תעודת זהות ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="number" 
                                   formControlName="id_number"
                                   maxlength="9"
                                     />
                                </div>
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'City' : (curentLang === 'ar' ? ' المدينة    ' : '  עִיר  ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" required="" onlyAlphabets
                                  class="form-control" type="text" 
                                   formControlName="customer_address_city"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer address city ' : (curentLang === 'ar' ? '  الرجاء ادخال مدينة العميل' : '  אנא הזן עיר לקוח')}}  </div>

                                </div>

                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Street Name' : (curentLang === 'ar' ? ' أسم الشارع  ' : '   שם רחוב ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" required="" onlyAlphabets
                                  class="form-control" type="text" 
                                   formControlName="customer_address_str"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer address street ' : (curentLang === 'ar' ? ' الرجاء ادخال شارع العميل ' : '  אנא הזן רחוב לקוח')}}  </div>

                                </div>

                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Building Number' : (curentLang === 'ar' ? 'رقم المبني ' : 'מספר בניין ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" required="" onlyAlphabets
                                  class="form-control" type="number" 
                                   formControlName="customer_Building_number"
                                   maxlength="20"
                                     /> 
                                    <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer building number ' : (curentLang === 'ar' ? '  الرجاء ادخال رقم المبني العميل' : '     אנא הזן מספר בניין לקוח  ')}}  </div>

                                </div>
                                
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Zip Code' : (curentLang === 'ar' ? ' الرقم البريدي    ' : '  מיקוד דואר  ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" required="" onlyAlphabets
                                  class="form-control" type="number" 
                                   formControlName="customer_address_zip_code"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer zip code  ' : (curentLang === 'ar' ? '  الرجاء ادخال الرقم البريدي  ' : ' נא להזין את המיקוד ')}}  </div>

                                </div>

                                <div class="mb-3 col-xl-4 col-md-4 " *ngIf="addCustomer.value.customer_type != 5">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي للعميل' : ' מספר מס לקוח')}}
                                  </label>
                                  <input style="color: grey;" 
                                         class="form-control" 
                                         type="text"
                                         (keypress)="isNumber($event)"
                                          maxlength="9"
                                          max="9"
                                          min="0"
                                         inputmode="numeric" 
                                         
                                         pattern="[0-9]{9}"
                                         title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}"
                                         formControlName="customer_vat_number"
                                         maxlength="9"
                                  />
                                  <div *ngIf="addCustomer?.get('customer_vat_number').invalid && (addCustomer?.get('customer_vat_number').dirty || addCustomer?.get('customer_vat_number').touched)" class="text-danger">
                                    {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                  </div>
                              </div>
                              
                              <div class="mb-3 col-xl-4 col-md-4 " *ngIf="addCustomer.value.customer_type != 5">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Customer Vat Tax' : (curentLang === 'ar' ? 'رقم ضريبة القيمة المضافة' : ' מס מע"מ לקוחות    ')}}
                                  </label>
                                  <input style="color: grey;" 
                                         class="form-control" 
                                         type="number" 
                                         formControlName="customer_vat_tax"
                                         maxlength="9"
                                  />
                              </div>
                        </div>
                  
                          <div class="modal-footer">
                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                          </div>
                      </form>
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>