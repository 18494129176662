import { AuthService } from './../service/auth.service';
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { RegisterEndPoient } from 'src/app/Data/service/global.service';
import Swal from 'sweetalert2';
import { israelPhoneNumberValidator } from './../../validators/israelPhoneNumberValidator';

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"]
})

export class RegisterComponent implements OnInit {

  public show: boolean = false;
  userLanguage: string;
  RegisterForm: FormGroup;
  curentLang:string="";

  constructor(
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private Router: Router,
    private ToastrService: ToastrService,
  ) {
    this.userLanguage = localStorage.getItem('curentLang');
    this.RegisterForm = new FormGroup({
      'phone_number': new FormControl('', [Validators.required, israelPhoneNumberValidator()]),
      'user_name': new FormControl('', [Validators.required, Validators.maxLength(20)]),
      'language': new FormControl(this.userLanguage),
      'merchant_name_en': new FormControl(''),
      'merchant_name_ar': new FormControl(''),
      'merchant_name_he': new FormControl('', [Validators.required, Validators.maxLength(80)]),
      'merchant_address': new FormControl('', [Validators.required]),
      'id_number': new FormControl('', [Validators.required, Validators.maxLength(20)]),
      'password': new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_\-+=<>?])[A-Z][A-Za-z0-9!@#$%^&*()_\-+=<>?]{3,20}$/)]),
    });

    this.curentLang = localStorage.getItem('curentLang');

  };

  submitRegisterForm(RegisterForm: FormGroup) {
    let customerPhone = this.RegisterForm.value.phone_number;
    if (customerPhone.startsWith('0')) {
      customerPhone = customerPhone.substring(1);
    }
    let password = this.RegisterForm.value.password;
      if (password.length < 8 || password.length > 20) {
          Swal.fire({
              icon: 'error',
              title: this.curentLang === 'ar' ? 'يجب أن تكون كلمة المرور أطول من أو تساوي 8 أحرف وأقل من أو تساوي 20 حرفًا' : 
                     (this.curentLang === 'en' ? 'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters' : 
                     (this.curentLang === 'he' ? 'הסיסמה חייבת להיות ארוכה מ-8 תווים וקצרה מ-20 תווים' : ''))
          });
          return;
      }

    const merchantNameHe = this.RegisterForm.value.merchant_name_he;
    this.RegisterForm.patchValue({
      merchant_name_ar: merchantNameHe,
      merchant_name_en: merchantNameHe
    });

    let model = {
      "phone_number": '+972' + customerPhone,
      "user_name": RegisterForm.value.user_name,
      "language": this.userLanguage,
      "merchant_name_en": RegisterForm.value.merchant_name_en,
      "merchant_name_ar": RegisterForm.value.merchant_name_ar,
      "merchant_name_he": RegisterForm.value.merchant_name_he,
      "merchant_address": RegisterForm.value.merchant_address,
      "id_number": RegisterForm.value.id_number,
      "password": RegisterForm.value.password,
    };

    this.spinner.show();

    this.authService.Register(RegisterEndPoient.POST, model).subscribe({
      next: (response: any) => {
        if (response.status === 400 || response.status === 403) {
          let errorMessage = "";
          switch (this.userLanguage) {
            case "ar":
              errorMessage = response.messageAr;
              break;
            case "en":
              errorMessage = response.messageEn;
              break;
            case "he":
              errorMessage = response.messageHe;
              break;
            default:
              errorMessage = "Unknown error occurred";
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage,
          });
        } else {
          let successMessage = "";
          switch (this.userLanguage) {
            case "ar":
              successMessage = response.messageAr;
              break;
            case "en":
              successMessage = response.messageEn;
              break;
            case "he":
              successMessage = response.messageHe;
              break;
            default:
              successMessage = "Unknown message";
          }
          Swal.fire({
            icon: 'success',
            title: successMessage,
          });
          this.Router.navigate(['/login']);
        }
        this.spinner.hide();
      },
      error: (error: any) => {
        Swal.fire({
          icon: 'error',
          title: error.message || 'Error',
          text: error.message_ar || error.message_en || error.error_en || error.error_ar || 'Unknown error occurred',
        });
        this.spinner.hide();
      }
    });
  };

  filterLangType(event: any) {
    let x = event.target.value;
  };

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    this.userLanguage = localStorage.getItem('curentLang');

  }

  showPassword() {
    this.show = !this.show;
  }
}
