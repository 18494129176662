
  
  <div class="container-fluid ">
      <div class="row mt-5">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header mt-5 m-auto text-center"> 
              <h2 style="color: #141414;"> Subscription plans </h2>
            </div>
            <div class="card-body">
              <div class="row pricingcontent">
                <div class="col-lg-3 col-md-6">
                  <div class="pricingtable">
                    <div class="pricingtable-header">
                      <h3 class="title">Standard</h3>
                    </div>
                    <div class="price-value" style="background-color: #141414;"><span class="currency">$</span><span class="amount">10</span><span class="duration">/mo</span></div>
                    <ul class="pricing-content" style="color: #141414;">
                      <li>50GB Disk Space</li>
                      <li>50 Email Accounts</li>
                      <li>Maintenance</li>
                      <li>15 Subdomains</li>
                    </ul>
                    <div class="pricingtable-signup"><a class="btn  btn-lg" style="background-color: #141414;" href="javascript:void(0)">Sign Up</a></div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="pricingtable">
                    <div class="pricingtable-header">
                      <h3 class="title">Premium</h3>
                    </div>
                    <div class="price-value"style="background-color: #141414;" ><span class="currency">$</span><span class="amount">20</span><span class="duration">/mo</span></div>
                    <ul class="pricing-content" style="color: #141414;">
                      <li>10% on all product</li>
                      <li>50 Email Accounts</li>
                      <li>Maintenance</li>
                      <li>15 Subdomains</li>
                    </ul>
                    <div class="pricingtable-signup"><a class="btn  btn-lg" style="background-color: #141414;" href="javascript:void(0)">Sign Up</a></div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="pricingtable">
                    <div class="pricingtable-header">
                      <h3 class="title">Auther pack</h3>
                    </div>
                    <div class="price-value" style="background-color: #141414;"><span class="currency">$</span><span class="amount">50</span><span class="duration">/mo</span></div>
                    <ul class="pricing-content" style="color: #141414;">
                      <li>Upload 50 product</li>
                      <li>50 Email Accounts</li>
                      <li>Maintenance</li>
                      <li>15 Subdomains</li>
                    </ul>
                    <div class="pricingtable-signup"><a class="btn  btn-lg" style="background-color: #141414;" href="javascript:void(0)">Sign Up</a></div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="pricingtable">
                    <div class="pricingtable-header">
                      <h3 class="title">Auther pack</h3>
                    </div>
                    <div class="price-value" style="background-color: #141414;"><span class="currency">$</span><span class="amount">60</span><span class="duration">/mo</span></div>
                    <ul class="pricing-content" style="color: #141414;">
                      <li>Upload 60 product</li>
                      <li>60 Email Accounts</li>
                      <li>Maintenance</li>
                      <li>20 Subdomains</li>
                    </ul>
                    <div class="pricingtable-signup"><a class="btn  btn-lg" style="background-color: #141414;" href="javascript:void(0)">Sign Up</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        </div>
      </div>
  </div>