import { Component, OnInit } from "@angular/core";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { HttpHelpService } from "../../service/http-help.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import Swal from 'sweetalert2';
import { baseUrl } from "../../service/global.service";
@Component({
  selector: "app-export-finaical-request-pdf",
  templateUrl: "./export-finaical-request-pdf.component.html",
  styleUrls: ["./export-finaical-request-pdf.component.scss"]
})

export class ExportFinaicalRequestPdfComponent implements OnInit {
  

  curentLang:string="";
  Confirm:any;
  DocumentLanguage:any;
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  DocumentNumber:any
  invoiceInfoData:any=[];
  id:any;
  CompanyVat:any;
  MerchantNameEn:any;
  constructor(
    private spinner: NgxSpinnerService,
    private service: HttpHelpService,
    activatedRoute: ActivatedRoute,
    private http: HttpClient,
    
  )
   { 
    this.curentLang = 
    this.id = activatedRoute.snapshot.params['id'];
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  }

  getPdfByInvoiceId() {
    this.spinner.show();
    this.service.getPdfByInvoiceId(this.id).subscribe({
      next: res => {
        this.invoiceInfoData = res;      
        this.Confirm = this.invoiceInfoData?.confirm;
        this.curentLang = this.invoiceInfoData?.document_language;
        this.DocumentNumber = this.invoiceInfoData?.document_number;
        this.CompanyVat = this.invoiceInfoData?.company_vat;
        this.MerchantNameEn = this.invoiceInfoData?.merchant?.merchant_name_en;

        this.spinner.hide();

      },
      error: error => {
        this.spinner.hide();
      }
    });
};


  updateVersionCopy() {
    this.spinner.show();
    this.service.updateVersionCopy(this.id).subscribe({
      next: res => {
        this.invoiceInfoData = res;      
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };
  async generatePDF2() {
    if (this.invoiceInfoData?.confirm === 0) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'لا يمكن تصدير الفاتورة لأنها غير مؤكدة' : (this.curentLang === 'en' ? 'The invoice cannot be exported because it is not confirmed' : 'לא ניתן לייצא את החשבונית מכיוון שהיא לא אושרה')
      });
      return;
    }

    try {
      this.spinner.show();
      const pages = document.querySelectorAll('.page');
      const pdf = new jspdf.jsPDF('p', 'mm', 'a4');

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const xOffset = 10;
      const yOffset = 10;
      const pdfPageHeight = pageHeight - yOffset * 2;
      const totalNumberOfPages = pages.length;

      for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const pageElement = pages[pageIndex] as HTMLElement;
        const canvas = await html2canvas(pageElement, {
          scale: 2,
          useCORS: true,
          scrollX: 0,
          scrollY: -window.scrollY,
          windowWidth: pageElement.scrollWidth,
          windowHeight: pageElement.scrollHeight
        });

        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        const imgHeight = (canvas.height * pageWidth) / canvas.width;
        let remainingHeight = canvas.height;
        let yPosition = 0;

        while (remainingHeight > 0) {
          if (yPosition > 0) {
            pdf.addPage();
          }

          const sliceHeight = Math.min(remainingHeight, pdfPageHeight * canvas.width / pageWidth);

          const canvasSlice = document.createElement('canvas');
          canvasSlice.width = canvas.width;
          canvasSlice.height = sliceHeight;

          const ctx = canvasSlice.getContext('2d');
          ctx.drawImage(canvas, 0, yPosition, canvas.width, sliceHeight, 0, 0, canvas.width, sliceHeight);

          const imgSlice = canvasSlice.toDataURL('image/jpeg', 1.0);
          pdf.addImage(imgSlice, 'JPEG', xOffset, yOffset, pageWidth - 2 * xOffset, (sliceHeight * pageWidth) / canvas.width);

          yPosition += sliceHeight;
          remainingHeight -= sliceHeight;

          // Adding page numbers
          pdf.setFontSize(10);
          pdf.text(`Page ${pageIndex + 1} of ${totalNumberOfPages}`, pageWidth - 20, pageHeight - 5); // Add page number and total pages to each page
        }
      }

      // Add company logo and page number on the last page
      const logoImg = await this.getLogoImageData();
      pdf.addImage(logoImg, 'PNG', pageWidth - 220, pageHeight - 15, 50, 10); // Adjust position and size as needed

      const pdfBlob = pdf.output('blob');
      await this.sendPdfToApi(pdfBlob, `thinvoices-${this.DocumentNumber}.pdf`);
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      Swal.fire({
        icon: 'error',
        title: this.curentLang === 'ar' ? 'حدث خطأ أثناء تصدير الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while exporting the invoice' : 'אירעה שגיאה במהלך ייצוא החשבונית')
      });
    }
  }

  async sendPdfToApi(pdfBlob: Blob, fileName: string) {
    const formData: FormData = new FormData();
    formData.append('file', pdfBlob, fileName);
    formData.append('companyName', this.CompanyVat); // الرقم الضريبي للشركه
    formData.append('signerName', this.MerchantNameEn); // اسم التاجر انجلش

    try {
      const response: Blob = await this.http.post(`${baseUrl}sign-pdf/sign`, formData, { responseType: 'blob' }).toPromise();
      this.downloadSignedPdf(response, `${fileName}`);
      // Swal.fire({
      //   icon: 'success',
      //   title: this.curentLang === 'ar' ? 'تم إرسال الفاتورة بنجاح' : (this.curentLang === 'en' ? 'Invoice sent successfully' : 'החשבונית נשלחה בהצלחה')
      // });
    } catch (error) {
      // Swal.fire({
      //   icon: 'error',
      //   title: this.curentLang === 'ar' ? 'حدث خطأ أثناء إرسال الفاتورة' : (this.curentLang === 'en' ? 'An error occurred while sending the invoice' : 'אירעה שגיאה במהלך שליחת החשבונית')
      // });
    }
  }

  downloadSignedPdf(blob: Blob, fileName: string) {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
  
  // Function to fetch company logo image data
  async getLogoImageData(): Promise<any> {
    const img = new Image();
    img.src = '../../../../assets/images/logo/thi.png'; // Adjust path as needed
    img.crossOrigin = 'Anonymous';
    img.style.width = '10px';
    img.style.height = '10px';
    await new Promise<void>(resolve => {
      img.onload = () => resolve();
    });
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    return canvas.toDataURL('image/png');
  }

  ngOnInit() 
  {
    this.getPdfByInvoiceId();
  };
}
