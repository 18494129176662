import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpHelpService } from "../../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { formatDate } from '@angular/common';

@Component({
  selector: "app-export-reports",
  templateUrl: "./export-reports.component.html",
  styleUrls: ["./export-reports.component.scss"]
})
export class ExportReportsComponent implements OnInit {
  curentLang: string = "";
  layoutVersion: any;
  userRegistId: any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  routeId = JSON.parse(localStorage.getItem('routeId'));

  term: any;
  p: any;
  companyDocumentsData: any[] = [];
  reportsForm: FormGroup;
  reportsIncomeData: any ;
  currentPage: number = 1;
  limit: number = 10;
  offset: number = 0;
  total: number = 0;
  fromDate: string | null = null;
  toDate: string | null = null;
  columnDefs: any[] = [];
  rowData: any[] = [];
  companyId: any;
  id: any;
  selectedReportType: string;
  uuid: any;
  selectedReport: number = 1;
  gridOptions = {
    components: {
      currencyCellRenderer: this.currencyCellRenderer
    },
    groupIncludeFooter: true,
    groupIncludeTotalFooter: true
  };
  constructor(
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
    private service: HttpHelpService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));

    this.uuid = this.activatedRoute.snapshot.params['id'];
  }

  ngOnInit() {
    this.getCompanyIdByUuid();

    this.reportsForm = this.fb.group({
        companyId: [null], // تعيين قيمة ابتدائية
        startDate: [''],
        endDate: [''],
        document_type: [1],
        lang: this.curentLang
    });
    this.selectedReportType = '1';
    this.getAllReportsExcel();
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    this.reportsForm.patchValue({
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate)
    });

    this.fromDate = this.formatDate(currentDate);
    this.toDate = this.formatDate(endDate);

    this.layoutVersion = localStorage.getItem("layout_version");
    if (!this.layoutVersion) {
        localStorage.setItem("layout_version", "light-only");
        this.layoutVersion = "light-only";
    } else {
        document.body.classList.add(this.layoutVersion);
    }
    this.curentLang = localStorage.getItem('curentLang');
};


currencyCellRenderer(params: any) {
  // تحقق من أن القيمة هي عدد
  const value = params.value;
  if (typeof value === 'number' && !isNaN(value)) {
    const numberFormat = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return numberFormat.format(value);
  } else {
    // في حالة عدم كون القيمة رقمية، ارجع قيمة افتراضية أو النص نفسه
    return value;
  }
};

filterMonth(event: any) {
  const selectedValue = parseInt(event.target.value, 10); // الحصول على القيمة المختارة
  const currentDate = new Date();

  let fromDate: Date;
  let toDate: Date;

  switch (selectedValue) {
      case 1:
          // الشهر الحالي
          fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
          toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
          break;
      case 2:
          // الشهر الماضي
          fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
          toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
          break;
      case 3:
          // من بداية العام
          fromDate = new Date(currentDate.getFullYear(), 0, 1);
          toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
          break;
      case 4:
          // العام الماضي
          fromDate = new Date(currentDate.getFullYear() - 1, 0, 1);
          toDate = new Date(currentDate.getFullYear() - 1, 11, 31);
          break;
      case 5:
          // آخر 3 شهور
          fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
          toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
          break;
      case 6:
          // آخر 6 شهور
          fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, 1);
          toDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
          break;
      default:
          return;
  }

  this.fromDate = this.formatDate(fromDate);
  this.toDate = this.formatDate(toDate);

  this.reportsForm.patchValue({
      startDate: this.fromDate,
      endDate: this.toDate
  });

  // استدعاء دالة لتحديث الفواتير أو أي بيانات أخرى تعتمد على التاريخ المحدد
  // this.getInvoicesFilters(this.fromDate, this.toDate);
}

formatDate(date: Date): string {
  const day = ('0' + date.getDate()).slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

  getCompanyIdByUuid() {
    this.service.getCompanyIdByUuid(this.uuid).subscribe({
      next: res => {
        this.companyId = res.company_id;
  
  
        this.getCompanyInfoById()
        // تحديث قيمة الحقل companyId في النموذج
        this.reportsForm.patchValue({
          companyId: this.companyId
        });
      },
      error: error => {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'فشل في الحصول على معلومات الشركة' : (this.curentLang === 'en' ? 'Failed to get company information' : 'נכשל בהשגת מידע על החברה')
        });
      }
    });
  };

  getCompanyInfoById() {
    this.service.getCompanyInfoById(this.companyId).subscribe({
      next: res => {
     this.reportsIncomeData = res;
      },
      error: error => {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'فشل في الحصول على معلومات الشركة' : (this.curentLang === 'en' ? 'Failed to get company information' : 'נכשל בהשגת מידע על החברה')
        });
      }
    });
  };


  

  filter(event: any) {
    const selectedValue = event.target.value;
    this.selectedReportType = selectedValue;  // تخزين نوع التقرير المحدد
  };

 



  getAllReportsExcel(loadOnly: boolean = false) {
    const { companyId, startDate, endDate, lang } = this.reportsForm.value;
    this.spinner.show();

    let exportObservable;

    switch (this.selectedReportType) {
      case '1':
        exportObservable = this.service.getAllReportsIncomeExel(companyId, startDate, endDate, lang);
        break;
      case '2':
        exportObservable = this.service.getAllConsoliDatedIncomeExel(companyId, startDate, endDate, lang);
        break;
      case '3':
        exportObservable = this.service.getAllReportsDocumentExel(companyId, startDate, endDate, lang);
        break;
      case '4':
        exportObservable = this.service.getAllReportsItemExel(companyId, startDate, endDate, lang);
        break;
      case '5':
        exportObservable = this.service.getAllReportsConsolidatedIitemeExel(companyId, startDate, endDate, lang);
        break;

        case '6':
        exportObservable = this.service.getAllReceiptsDetailsExel(companyId, startDate, endDate, lang);
        break;

        case '7':
          exportObservable = this.service.getDashboardReportExel(companyId, lang);
          break;
      default:
        this.spinner.hide();
        return;
    }

    exportObservable.subscribe({
      next: (res: Blob) => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
          const excelData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 }) as any[][];
          this.updateTableDataFromExcel(excelData);

          if (!loadOnly) {
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'THI-INVOICES.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
          }
        };
        reader.readAsArrayBuffer(res);
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

  exportToExcel(): void {
    this.getAllReportsExcel(false);
  }

  displayExcelData(): void {
    this.getAllReportsExcel(true);
  }

  updateTableDataFromExcel(data: any[][]) {
    if (data.length > 0) {
      // تحويل الأرقام إلى تواريخ باستخدام moment
      const formattedData = data.map(row => {
        return row.map(cell => {
          // if (typeof cell === 'number' && cell > 40000) {
          //   // اعتبار الرقم كتاريخ في Excel وتحويله
          //   return moment('1899-12-30').add(cell, 'days').format('YYYY-MM-DD');
          // }
          return cell;
        });
      });

      this.columnDefs = formattedData[0].map(key => ({ field: key }));
      this.rowData = formattedData.slice(1).map(row => {
        const rowObject: any = {};
        formattedData[0].forEach((key, index) => {
          rowObject[key] = row[index];
        });
        return rowObject;
      });
    }
  }

  updateTableData(data: any[]) {
    if (data.length > 0) {
      this.columnDefs = Object.keys(data[0]).map(key => ({ field: key }));
      this.rowData = data;
    }
  }

  loadReportData() {
    this.getAllReportsExcel(true);
  }

  onFileChange(event: any) {
    this.displayExcelData();  // عرض البيانات في الموقع
  }

  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
  }

  getInvoiceTypeDescription(invoiceType: number): string {
    switch (invoiceType) {
      case 305:
        return this.curentLang === 'ar' ? 'فاتورة ضريبية' : (this.curentLang === 'en' ? 'Tax Invoice' : 'חשבונית מס');
      case 320:
        return this.curentLang === 'ar' ? 'فاتورة وسند قبض' : (this.curentLang === 'en' ? 'Invoice And Receipt' : 'חשבונית וקבלה');
      case 332:
        return this.curentLang === 'ar' ? 'مطالبة مالية' : (this.curentLang === 'en' ? 'Financial Request' : 'בקשה פינאלית');
      case 330:
        return this.curentLang === 'ar' ? 'فاتورة عكسية' : (this.curentLang === 'en' ? 'Invoice/Transaction Invoice' : 'חשבונית/חשבונית עסקה');
      case 400:
        return this.curentLang === 'ar' ? 'سند قبض' : (this.curentLang === 'en' ? 'Receipt' : 'קבלה');
      default:
        return this.curentLang === 'ar' ? 'نوع غير معروف' : (this.curentLang === 'en' ? 'Unknown Type' : 'סוג לא ידוע');
    }
  }
}
