<app-breadcrumb [title]="curentLang === 'en' ? 'App Info' : (curentLang === 'ar' ? ' معلومات التطبيق  ' : '   מידע על התוכנית  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'App Info' : (curentLang === 'ar' ? ' معلومات التطبيق  ' : '    מידע על התוכנית  ')">
</app-breadcrumb>

<!--App info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'App Info' : (curentLang === 'ar' ? '  معلومات التطبيق ' : ' מידע על התוכנית')}}
        </h5>

       <div>
        <button  class="btn m-1" style="background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add App Info' : (curentLang === 'ar' ? 'اضافة معلومات التطبيق' : 'הוסף מידע על התוכנית')}}
        </button>
        
        
        

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAppInfoData()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                    <th scope="col"> {{ curentLang === 'en' ? 'App Logo  ' : (curentLang === 'ar' ? ' لوجو التطبيق ' : 'לוגו התוכנית')}} </th>

                   <th scope="col"> {{ curentLang === 'en' ? 'App Name En' : (curentLang === 'ar' ? '  أسم التطبيق عربي' : ' שם התוכנית הוא עברי')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'App Number' : (curentLang === 'ar' ? '   رقم التطبيق  ' : 'מספר תוכנית ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'App Company Name' : (curentLang === 'ar' ? ' أسم تطبيق الشركة  ' : 'שם תוכנית החברה')}}</th>

                   <th scope="col"> {{ curentLang === 'en' ? 'App Company Number' : (curentLang === 'ar' ? ' رقم تطبيق الشركة  ' : 'מספר תוכנית החברה ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'App Version' : (curentLang === 'ar' ? ' النسخة ' : 'לְשַׁחְרֵר')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'App Tax Number' : (curentLang === 'ar' ? ' الرقم الضريبي للتطبيق ' : 'מספר מס התוכנית')}}</th>

                   <th scope="col"> {{ curentLang === 'en' ? 'Description' : (curentLang === 'ar' ? 'وصف التطبيق ' : 'תיאור התוכנית')}}</th>
 
                   <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
 
                </tr>
              </thead>

              <tbody>
                <tr >

                    <td>
                        <img [src]="appInfoData?.logo" style=" width: 50px; height: 50px; border-radius: 50%;" alt="">
                    </td>
                 
                  <td> 
                    {{curentLang === 'en' ? appInfoData?.app_name?.substring(0, 20) : (curentLang === 'ar' ? appInfoData?.app_name?.substring(0, 20) : appInfoData?.app_name?.substring(0, 20))}}
                  </td>

                   <td>{{appInfoData?.app_number}}</td>
                   <td>{{appInfoData?.app_company_name}}</td>
                   <td>{{appInfoData?.app_company_number}}</td>
                   <td>{{appInfoData?.version}}</td>
                   <td>{{appInfoData?.app_tax_number}}</td>
                   <td>{{appInfoData?.description?.substring(0, 20)}}</td>
                   <td>{{ appInfoData?.createdDate | date: 'dd/MM/yyyy'}}</td>
<!-- 
                  <td>
                    <button class=" btn" style="border: none;" (click)="executeFunctionsView(  appInfoData?.customer_id,viewData ) ">
                        <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                    </button>
                  </td>  -->

                   <td >
                  <button [disabled]="user_type == 100" class=" btn" style="border: none;"
                   (click)="executeFunctions(  appInfoData?.id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td> 

            

                </tr>
          
              </tbody>
            </table>
 
          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>


<!--add App-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Add App Info' : (curentLang === 'ar' ? 'اضافة معلومات التطبيق' : 'הוסף מידע על התוכנית')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="addApp">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'App Name En' : (curentLang === 'ar' ? '  أسم التطبيق عربي' : ' שם התוכנית הוא עברי')}} 
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="app_name" 
                                  />
                              </div>
              
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'App Number' : (curentLang === 'ar' ? '   رقم التطبيق  ' : 'מספר תוכנית ')}}                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="app_number"
                                   />
                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'App Company Name' : (curentLang === 'ar' ? ' أسم تطبيق الشركة  ' : 'שם תוכנית החברה')}}                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="app_company_name" 
                                   maxlength="80"
                                  />
                                   
                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'App Company Number' : (curentLang === 'ar' ? ' رقم تطبيق الشركة  ' : 'מספר תוכנית החברה ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="text" 
                                   formControlName="app_company_number"
                                   maxlength="9"
                                     />
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'App Version' : (curentLang === 'ar' ? ' النسخة ' : 'לְשַׁחְרֵר')}}
                                   </label>
                                    <input style="color: grey;" 
                                    class="form-control" type="text" 
                                     formControlName="version"
                                     maxlength="20"
                                       />
                                  </div>

                               

                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'App Tax Number' : (curentLang === 'ar' ? ' الرقم الضريبي للتطبيق ' : 'מספר מס התוכנית')}}                                   </label>
                                    <input style="color: grey;" 
                                    class="form-control" type="number" 
                                     formControlName="app_tax_number"
                                     maxlength="20"
                                       />
                                  </div>

                                  <div class="mb-3 col-xl-12 col-md-12 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description' : (curentLang === 'ar' ? 'وصف التطبيق ' : 'תיאור התוכנית')}}                                   </label>
                                    <textarea style="color: grey;" 
                                    class="form-control" type="text" 
                                     formControlName="description"
                                     maxlength="500" cols="30" rows="3"></textarea>
                                  </div>
                             
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" [disabled]="!addApp.valid"
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>



<!--update App-->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
              {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '    ערוך מידע ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
      <div class="modal-body">
          <div class="container-fluid ">
              <div class="edit-profile">
                <div class="row d-flex justify-content-between">
                       
                    <form  [formGroup]="updateApp">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'App Name En' : (curentLang === 'ar' ? '  أسم التطبيق عربي' : ' שם התוכנית הוא עברי')}} 
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="app_name" 
                                  />
                              </div>
              
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'App Number' : (curentLang === 'ar' ? '   رقم التطبيق  ' : 'מספר תוכנית ')}}                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="app_number"
                                   />
                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'App Company Name' : (curentLang === 'ar' ? ' أسم تطبيق الشركة  ' : 'שם תוכנית החברה')}}                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="app_company_name" 
                                   maxlength="80"
                                  />
                                   
                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'App Company Number' : (curentLang === 'ar' ? ' رقم تطبيق الشركة  ' : 'מספר תוכנית החברה ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="text" 
                                   formControlName="app_company_number"
                                   maxlength="9"
                                     />
                                </div>

                                <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'App Version' : (curentLang === 'ar' ? ' النسخة ' : 'לְשַׁחְרֵר')}}
                                   </label>
                                    <input style="color: grey;" 
                                    class="form-control" type="text" 
                                     formControlName="version"
                                     maxlength="20"
                                       />
                                  </div>

                               

                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'App Tax Number' : (curentLang === 'ar' ? ' الرقم الضريبي للتطبيق ' : 'מספר מס התוכנית')}}                                   </label>
                                    <input style="color: grey;" 
                                    class="form-control" type="number" 
                                     formControlName="app_tax_number"
                                     maxlength="20"
                                       />
                                  </div>

                                  <div class="mb-3 col-xl-12 col-md-12 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description' : (curentLang === 'ar' ? 'وصف التطبيق ' : 'תיאור התוכנית')}}                                   </label>
                                    <textarea style="color: grey;" 
                                    class="form-control" type="text" 
                                     formControlName="description"
                                     maxlength="500" cols="30" rows="3"></textarea>
                                  </div>
                             
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" [disabled]="!updateApp.valid"
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updatedAppData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
              </div>
              
            </div>
          
          </div>
      </div>
     
  </ng-template>