import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from 'sweetalert2';
import { AddCompanyPermissionsEndPoient } from "../service/global.service";

@Component({
  selector: "app-accountants",
  templateUrl: "./accountants.component.html",
  styleUrls: ["./accountants.component.scss"]
})

export class AccountantsComponent implements OnInit {
  @Input() isInsideModal: boolean = false;
  
  openedIndex: number | null = null; 

  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
   
  accountantsData:any=[];
  activeAccountantOfficeData:any=[];
  activeCompanyData:any=[];
  receiptId: number;
  updatedData: any;
  viewData: any;
  userData:any=[];
  userTypeData:any=[];
  rowId:number;
  addPermissionForm:FormGroup;
  selectedOffice:any;

  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
  
    this.addPermissionForm  = new FormGroup({
      companyUser: new FormControl([] , Validators.required),   
      office_id: new FormControl( Validators.required),
      merchant_id: new FormControl(this.merchantId),
      created_by: new FormControl(this.userRegistId),
    });
 
  };

  toggleCollapse(index: number): void {
    // إذا كان القسم المفتوح هو القسم الذي نضغط عليه، إغلاقه، وإلا فتح القسم الجديد
    this.openedIndex = this.openedIndex === index ? null : index;
     
    
  };

  getAllActiveAccountingOffices( ) {
   
    this.spinner.show();
    this.service.getAllActiveAccountingOffices().subscribe({
      next: res => {
        this.activeAccountantOfficeData = res;
        this.spinner.hide();
  
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }
  getAllActiveCompanyByMerchant( ) {
   
    this.spinner.show();
    this.service.getAllActiveCompanyByMerchant().subscribe({
      next: res => {
        this.activeCompanyData = res;
        this.spinner.hide();
       },
      error: () => {
        this.spinner.hide();
      }
    });
  }
  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' });
  };

  filter(event:any)
  {
      this.selectedOffice = event;
     
   };

  addedPermission() {
    if (this.addPermissionForm.invalid) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      return;
    } else {
      const companyUsers = this.addPermissionForm.value.companyUser;
      const models = companyUsers.map(companyUser => ({
        office_id: Number(this.selectedOffice),
        company_id: Number(companyUser.company_id),
         merchant_id: Number(this.merchantId),
        created_by: Number(this.userRegistId),
      }));
  
      this.spinner.show();
      this.service.Post(AddCompanyPermissionsEndPoient.POST, models).subscribe((res) => {
        this.spinner.hide();
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllActiveAccountingOffices();
          this.getAllActiveCompanyByMerchant();
          this.getAllPermissionsByMerchantId();
          this.addPermissionForm.reset();
        } else {
          let errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
      });
    }
  }

  getRowId(id:number)
  {
   this.rowId = id ;
    
  };

  openVerticallyCentered(dataDeleted:any) {
    this.modalService.open(dataDeleted, { centered: true });
  };

  deleteRelatedOfficeToCompany() {
    this.spinner.show();
    this.service.deleteRelatedOfficeToCompany(this.rowId).subscribe(({
      next:res=> {
        
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
            this.getAllPermissionsByMerchantId();
             this.accountantsData = res;
            this.modalService.dismissAll();
            
      },
      error:err =>{
        Swal.fire(err)
      }
    }))
    this.spinner.hide();
  };
  
  getAllPermissionsByMerchantId( ) {
    this.spinner.show();
    this.service.getAllPermissionsByMerchantId(this.merchantId).subscribe({
      next: res => {
        this.accountantsData = res;
        this.spinner.hide();
         
      },
      error: () => {
        this.spinner.hide();
      }
    });
  };


  
  ngOnInit() 
  {
   
    this.getAllPermissionsByMerchantId();
    this.getAllActiveAccountingOffices();
    this.getAllActiveCompanyByMerchant();
   this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
   };

   
}
