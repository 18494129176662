<app-breadcrumb [title]="curentLang === 'en' ? 'Reverce Invoices Info' : (curentLang === 'ar' ? ' معلومات الفواتير العكسية ' : 'פירוט חשובוניות מס זיכוי')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Invoices Info' : (curentLang === 'ar' ? ' معلومات الفواتير  ' : 'פירוט חשובוניות מס זיכוי')">
</app-breadcrumb>

<!--invoices info -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Reverce Invoices Info' : (curentLang === 'ar' ? '   معلومات  الفواتير العكسية ' : '   פירוט חשובוניות מס זיכוי     ')}}
        </h5>
           
       <div>
        <button [hidden]="!isValidMerchant" [disabled]="user_type ==200" class="btn" style=" background-color: #141414; color: #fff;" 
        routerLink="/dashboard/add-reverce-invoice">
          <i class="fa fa-plus-circle mx-1"></i>
        {{ curentLang === 'en' ? 'Add Reverce Invoice' : (curentLang === 'ar' ? '  اضافة  فاتورة عكسية  ' : ' הוסף חשבונית חזרה ')}}
        
      </button>
 
      <!-- <button class="btn mx-1 btn-outline-warning"  >
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? 'تحديث' : 'רענן')}}
      </button> -->
      
       </div>
     
      </div>

      <div class="container-fluid basic_table" >
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group search px-1">
              <label for="seaarch" class="ml-2" style="color: grey;">
                {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
              </label>
              <div class="input-group">
                <input class="form-control w-100" type="text" name="search"
                       [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term"
                       placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"
                       />
                <!-- <div class="input-group-append m-auto">
                  <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                          (click)="getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                    <i class="fa fa-search mx-1"></i>
                    {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                  </button>
                  <button class="btn btn-outline-secondary" type="button" (click)="term='';getInvoicesFilters(fromDate, toDate, limit, offset, undefined, term)">
                    <i class="fa fa-times"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
         
          <div class="col-sm-3 mt-2" >
            <label class="form-label px-1" style="color: grey;">
              {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה   ') }}
            </label>
            <ng-select [(ngModel)]="selectedCompany" (change)="filter($event)" class="px-1"
                       style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
              <ng-option [value]="allCompaniesOption">
                {{ curentLang === 'en' ? 'All Companies' : (curentLang === 'ar' ? 'الكل' : 'הכל') }}
              </ng-option>
              <ng-option *ngFor="let item of companyData" [value]="item">
                {{ curentLang === 'en' ? item?.company_name_en : (curentLang === 'ar' ? item?.company_name_ar : item?.company_name_he)}}
              </ng-option>
            </ng-select>
            <ng-template *ngIf="companyData.length === 0" class="px-1">
              <span style="color: red;">
                {{ curentLang === 'en' ? 'There are no invoices for this company yet' : (curentLang === 'ar' ? "لا توجد فواتير لهذه الشركة حتى الآن" : "אין עדיין חשבוניות לחברה זו")}}
              </span>
            </ng-template>
          </div>
         
          <form [formGroup]="documentForm"   >
  
        
  <div class="row d-flex justify-content-between ">
    <div class="col-md-3 mt-2 "  >
      <h6 class="form-label px-1 " style="color: grey;"> 
        {{ curentLang === 'en' ? 'Period' : (curentLang === 'ar' ? 'الفترة' : 'תקופה  ')}}
      </h6>
      <div class="px-1">
        <select class="form-control  px-1  form-select" style="cursor: pointer;" (change)="filterMonth($event)" (click)="confirmAction()">
          <option [value]="1">{{ curentLang === 'en' ? 'Current month' : (curentLang === 'ar' ? 'الشهر الحالي' : 'חודש נוכחי  ') }}</option>
          <option [value]="2">{{ curentLang === 'en' ? 'Last month' : (curentLang === 'ar' ? 'الشهر الماضي' : '  חודש קודם') }}</option>
          <option [value]="5">{{ curentLang === 'en' ? 'Last two month' : (curentLang === 'ar' ? ' أخر شهران ' : ' שני חודשים קודמים  ') }}</option>
          <option [value]="3">{{ curentLang === 'en' ? 'Start of the year' : (curentLang === 'ar' ? 'من بداية العام' : ' מתחילת שנה  ') }}</option>
          <option [value]="4">{{ curentLang === 'en' ? 'Last year' : (curentLang === 'ar' ? 'العام الماضي' : ' שנה קודמת  ') }}</option>
        </select>
      </div>
   
    </div>
  
    <div class="col-md-6 ">
      <div class="row d-flex justify-content-between">
  
      
        <div class="col-md-6 mt-2">
        <h6 for="fromDate" class="ml-2" style="color: grey;">
          {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : '  מתאריך')}}
        </h6>
        <div  >
          <p-calendar [(ngModel)]="fromDate" style="display: block; width: 100%;"
           showIcon="true" (ngModelChange)="onFromDateChange($event)"
              formControlName="startDate" 
              dateFormat="dd/mm/yy"
               placeholder="DD/MM/YYYY"
                >
              </p-calendar>
  
        </div>
      </div>
  
        <div class="col-md-6 " >
          <h6 for="toDate"  style="color: grey;">
           {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : '  עד תאריך   ')}}
         </h6>
         <div class="">
         <p-calendar 
         style="display: block; width: 100%;"
           [(ngModel)]="toDate" 
           showIcon="true" 
           (ngModelChange)="onToDateChange($event)" 
           formControlName="endDate" 
           dateFormat="dd/mm/yy" 
           placeholder="DD/MM/YYYY">
         </p-calendar>
        </div>
     </div>
  
      </div>
    </div>
    
   
    
     
    
    <div class="col-md-3 mt-4 " style="padding-top: 6px;">
      <div class="form-group search  ">
        <button class="btn btn-sm mx-1  mt-2 " style="background-color: #141414; color: #fff;" 
        [disabled]="!fromDate || !toDate"
        (click)="getInvoicesFilters(fromDate, toDate)">
          <i class="fa fa-refresh mx-1"></i>
          {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
        </button>
      </div>
    </div>
  </div>
     
     
    
      </form>
    
      </div>
        </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive mt-2">
            <table class="table table-light text-center table-hover d-none d-lg-table  " >
              <thead>
                <tr>
                  <th scope="col"> {{ curentLang === 'en' ? 'Confirmation Number' : (curentLang === 'ar' ? '  رقم التأكيد  ' : '  מס׳ אישור ')}} </th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : ' מס׳    ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Invoice Reference Number' : (curentLang === 'ar' ? '    رقم مرجع الفاتورة  ' : 'מס מסמך')}}</th>
                 <!-- <th scope="col"> {{ curentLang === 'en' ? 'Vat Number' : (curentLang === 'ar' ? '      الرقم الضريبي  ' : 'מספר מס ')}}</th> -->
                 <th scope="col"> {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة ' : '    תאריך  ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Company Name  ' : (curentLang === 'ar' ? ' أسم الشركة ' : 'שם החברה  ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Customer Name  ' : (curentLang === 'ar' ? '  أسم العميل   ' : ' שם   ')}}</th>
                 <!-- <th scope="col"> {{ curentLang === 'en' ? 'Customer Vat Number  ' : (curentLang === 'ar' ? '  الرقم الضريبي العميل   ' : '    מספר מס לקוח')}}</th> -->
                 <th scope="col"> {{ curentLang === 'en' ? 'Amount Before Discount  ' : (curentLang === 'ar' ? ' المبلغ قبل الخصم   ' : 'מחיר לפני הנחה   ')}}</th>
                 <!-- <th scope="col"> {{ curentLang === 'en' ? ' Discount  ' : (curentLang === 'ar' ? 'الخصم  ' : ' הנחה   ')}}</th> -->
                 <th scope="col"> {{ curentLang === 'en' ? ' Payment Amount  ' : (curentLang === 'ar' ? 'المبلغ المدفوع  ' : ' סה"כ ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? ' Vat Amount ' : (curentLang === 'ar' ? ' قيمة الضريبة  ' : '   מע"מ   ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? ' Invoice Type' : (curentLang === 'ar' ? 'نوع الفاتورة' : '    סוג מסמך     ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '   تأكيد' : '    אישור   ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Export Pdf  ' : (curentLang === 'ar' ? '   تصدير Pdf  ' : '  הפקה pdf   ')}}</th>
                 <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחיקה ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of invoicesDataFilters | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} | filter: term; let i = index"
                [ngClass]="{'table-danger': selectedInvoiceUuid === item.invoice_uuid }">
                  <td>{{item.confirmation_number}}</td>
                  <td>{{item.document_number}}</td>
                  <td>{{item.invoice_reference_number}}</td>
                  <td>{{ item.invoice_date | date: 'dd/MM/yyyy' }}</td>
                  <td>{{ curentLang === 'en' ? item.company?.company_name_en.substring(0, 10) : (curentLang === 'ar' ? item.company?.company_name_ar.substring(0, 10) : item.company?.company_name_he.substring(0, 10)) }}</td>
                  <td>{{ curentLang === 'en' ? item?.customer_name.substring(0, 10) : (curentLang === 'ar' ? item?.customer_name.substring(0, 10) : item?.customer_name.substring(0, 10)) }}</td>
                  <td>{{ item.amount_before_discount }}</td>
                  <td>{{ item.payment_amount }}</td>
                  <td>{{ item.all_vat_amount }}</td>
                  <td *ngIf=" item?.invoice_type == 305 "><span>{{curentLang === 'en' ? 'Tax Invoice' : (curentLang === 'ar' ? 'فاتورة ضريبية' : ' חשבונית מס') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 320 "><span>{{curentLang === 'en' ? 'Invoice With Receipt' : (curentLang === 'ar' ? 'فاتورة مع ايصال' : ' חשבונית עם חשבונית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 332 "><span>{{curentLang === 'en' ? ' Financial Request' : (curentLang === 'ar' ? 'مطالبة مالية' : ' בקשה פינאלית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 400 "><span>{{curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? ' ايصال' : '  חשבונית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 330 "><span>{{curentLang === 'en' ? 'Reverce Invoice' : (curentLang === 'ar' ? ' فاتورة عكسية' : '  חשבונית עם חשבונית') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 100 "><span>{{curentLang === 'en' ? 'order ' : (curentLang === 'ar' ? ' طلبية  ' : '     לְהַזמִין   ') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 200 "><span>{{curentLang === 'en' ? 'mission ' : (curentLang === 'ar' ? ' ارسالية  ' : 'מְשִׁימָה') }}</span></td>
                  <td *ngIf=" item?.invoice_type == 000 "><span>{{curentLang === 'en' ? 'Offer Price ' : (curentLang === 'ar' ? ' عرض سعر  ' : ' מחיר הצעה') }}</span></td>
                  <td *ngIf="item.confirm == 0">
                    <button [disabled]="user_type == 200" style="background-color: #213FA6; color: white;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredConfirmed(dataConfirm)" class="btn btn-xs">
                      <i class="fa fa-check-circle-o text-white mx-1"></i> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    </button>
                  </td>
                  <td *ngIf="item.confirm == 1">
                    <span class="text-success" style="font-weight: 700;"><i class="fa fa-check-circle"></i></span>
                  </td>
                  <td *ngIf="item.confirm == 0">
                    <i [hidden]="user_type == 200" class="fa fa-edit" style="cursor: pointer; color: green;" routerLink="/dashboard/update-reverce-invoice/{{item.invoice_uuid}}"></i>
                    <i [hidden]="user_type == 100" class="fa fa-close" style="color: grey;"></i>
                  </td>

                  <td *ngIf="item.confirm == 1">
                    <i class="fa fa-close" style="color: grey;"></i>
                  </td>

                  <td>
                    <i class="fa fa-file-pdf-o" [ngStyle]="{'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1}"
                     style="color: grey; font-size: 15px; cursor: pointer; color: #213FA6;"  (click)="executeFunctions(item.invoice_uuid , content4)"></i>
                  </td>

                  <td *ngIf="item.confirm == 0">
                    <button [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'" [ngStyle]="{'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red'}" class="btn" style="border: none;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" [ngStyle]="{'font-size': '15px', 'cursor': 'pointer', 'color': (user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted') ? 'gray' : 'red'}"></i>
                    </button>
                  </td>

                  <td *ngIf="item.confirm == 1">
                    <button [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'" [ngStyle]="{'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red'}" class="btn" style="border: none;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredReverse(reverseInvoice)">
                      <i class="fa fa-trash-o" [ngStyle]="{'font-size': '15px', 'cursor': 'pointer', 'color': (user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted') ? 'gray' : 'red'}"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
              
            </table>


            
<!-- invoices Info on phone -->
 

  <div class="container-fluid basic_table d-lg-none my-2" >

    <div class="row">
<div class="col-sm-12">
   
    <div class="card"  id="headingTwo"
     *ngFor="let item of invoicesDataFilters  | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ; let i = index ">
    
            <p   
            
            (click)="toggleCollapse(i)" 
            [attr.aria-expanded]="openedIndex === i" 
            aria-controls="collapse"
            style="cursor: pointer; background-color: #FB4438;" 
            class="py-1">
              <i class="fa fa-file mx-1"  style="color: #fff; font-size: 16px;" > </i>
              <span style="color: #fff; font-size: 16px;" class="mx-2">
                {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : 'מס׳  ')}} :
              </span>
              <span style="color: #fff; font-size: 16px;" class="mx-2">{{item.document_number}}</span>
            </p>
      
             
            <div class="row d-flex justify-content-between">

              <div class="col-md-12  col-sm-12 ">
                <div class="row collapse   show" id="collapseTwo" [ngbCollapse]="openedIndex !== i">

               
                <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Confirmation Number' : (curentLang === 'ar' ? '  رقم التأكيد  ' : '      מס׳ אישור     ')}} 
                  </span>
                    <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>
                    <span class="col-sm-4   text-end ">
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.confirmation_number}}</span>
                    </span>
                 </div>
                 <hr style="border: solid 1px #141414;" >
                 <!---->
      
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">
                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2" >
                    {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '   رقم المستند ' : 'מס׳  ')}}
                  </span>

                  <span style="color: #141414; font-size: 14px; " class="text-center col-sm-4" ></span>

                  <span class="col-sm-4   text-end ">
                    <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.document_number}}</span>
                  </span>
                  
                     
     
                 </div>
                 <hr style="border: solid 1px #141414;" >

                 <!---->
                 <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                  <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                    {{ curentLang === 'en' ? 'Invoice Reference Number' : (curentLang === 'ar' ? '    رقم مرجع الفاتورة  ' : 'מס מסמך')}}
                  </span>
                  
                  <div class="text-center col-sm-4">  </div>

                    <span style="color: #141414;   " class="col-sm-4   text-end "  >
                      <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{item.invoice_reference_number}}</span>
                  </span>

                
                 </div>
                 <hr style="border: solid 1px #141414;" >

                   <!---->

                   <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                    <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                      {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة ' : '    תאריך  ')}}

                    </span>
                    
                    <div class="text-center col-sm-4">  </div>
  
                      <span style="color: #141414;   " class="col-sm-4   text-end "  >
                        <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{ item.invoice_date | date: 'dd/MM/yyyy' }}</span>
                    </span>
  
                  
                   </div>
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Company Name  ' : (curentLang === 'ar' ? ' أسم الشركة ' : 'שם החברה   ')}}  
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
    
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > 
                            {{ curentLang === 'en' ? item.company?.company_name_en.substring(0, 10) : (curentLang === 'ar' ? item.company?.company_name_ar.substring(0, 10) : item.company?.company_name_he.substring(0, 10)) }}
                          </span>
                      </span>
    
                    
                     </div>
         
                   <hr style="border: solid 1px #141414;" >
                    <!---->

                    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

                      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
                        {{ curentLang === 'en' ? 'Customer Name  ' : (curentLang === 'ar' ? '  أسم العميل   ' : ' שם  ')}}                
                      </span>
                      
                      <div class="text-center col-sm-4">  </div>
                
                        <span style="color: #141414;   " class="col-sm-4   text-end "  >
                          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ curentLang === 'en' ? item?.customer_name.substring(0, 10) : (curentLang === 'ar' ? item?.customer_name.substring(0, 10) : item?.customer_name.substring(0, 10)) }}</span>
                      </span>
                
                    
                     </div>
                 <hr style="border: solid 1px #141414;" >
                    <!---->

    <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

      <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
        {{ curentLang === 'en' ? 'Amount Before Discount  ' : (curentLang === 'ar' ? ' المبلغ قبل الخصم   ' : 'מחיר לפני הנחה      ')}}
      </span>
      
      <div class="text-center col-sm-4">  </div>

        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" > {{ item.amount_before_discount }}</span>
      </span>

    
     </div>
    
   <hr style="border: solid 1px #141414;" >

       <!---->

       <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

        <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
          {{ curentLang === 'en' ? ' Payment Amount  ' : (curentLang === 'ar' ? 'المبلغ المدفوع  ' : '   סה"כ   ')}}  
        </span>
        
        <div class="text-center col-sm-4">  </div>
        <span style="color: #141414;   " class="col-sm-4   text-end "  >
          <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item.payment_amount }}</span>
      </span>
  
      
       </div>
     <hr style="border: solid 1px #141414;" >
      <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? ' Vat Amount ' : (curentLang === 'ar' ? ' قيمة الضريبة  ' : '      מע"מ       ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4   text-end "  >
            <span class="  px-5 "  style="color: #141414; font-size: 16px ;" >{{ item.all_vat_amount }}</span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? ' Invoice Type' : (curentLang === 'ar' ? 'نوع الفاتورة' : '    סוג מסמך       ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4   text-end "  >
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 305 "><span>{{curentLang === 'en' ? 'Tax Invoice' : (curentLang === 'ar' ? 'فاتورة ضريبية' : ' חשבונית מס') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 320 "><span>{{curentLang === 'en' ? 'Invoice With Receipt' : (curentLang === 'ar' ? 'فاتورة مع ايصال' : ' חשבונית עם חשבונית') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 332 "><span>{{curentLang === 'en' ? ' Financial Request' : (curentLang === 'ar' ? 'مطالبة مالية' : ' בקשה פינאלית') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 400 "><span>{{curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? ' ايصال' : '  חשבונית') }}</span></span>
            <span class="  px-5 "  *ngIf=" item?.invoice_type == 330 "><span>{{curentLang === 'en' ? 'Reverce Invoice' : (curentLang === 'ar' ? ' فاتورة عكسية' : '  חשבונית עם חשבונית') }}</span></span>
            <span class="  px-5 " *ngIf=" item?.invoice_type == 100 "><span>{{curentLang === 'en' ? 'order ' : (curentLang === 'ar' ? ' طلبية  ' : '     לְהַזמִין   ') }}</span></span>
            <span class="  px-5 " *ngIf=" item?.invoice_type == 200 "><span>{{curentLang === 'en' ? 'mission ' : (curentLang === 'ar' ? ' ارسالية  ' : 'מְשִׁימָה') }}</span></span>
            <span class="  px-5 " *ngIf=" item?.invoice_type == 000 "><span>{{curentLang === 'en' ? 'Offer Price ' : (curentLang === 'ar' ? ' عرض سعر  ' : ' מחיר הצעה') }}</span></span>        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '   تأكيد' : '    אישור   ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
             <span *ngIf="item.confirm == 0" class="px-5">
                    <button [disabled]="user_type == 200" style="background-color: #213FA6; color: white;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredConfirmed(dataConfirm)" class="btn btn-xs mb-3">
                      <i class="fa fa-check-circle-o text-white mx-1"></i> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    </button>
                  </span>
                  
                  <span *ngIf="item.confirm == 1" class="px-5">
                    <span class="text-success" style="font-weight: 700;"><i class="fa fa-check-circle"></i></span>
                  </span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
            <span *ngIf="item.confirm == 0" class="px-5">
              <i [hidden]="user_type == 200" class="fa fa-edit" style="cursor: pointer; color: green;" routerLink="/dashboard/update-reverce-invoice/{{item.invoice_uuid}}"></i>
              <i [hidden]="user_type == 100" class="fa fa-close" style="color: grey;"></i>
            </span>

            <span *ngIf="item.confirm == 1"class="px-5">
              <i class="fa fa-close" style="color: grey;"></i>
            </span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Export Pdf  ' : (curentLang === 'ar' ? '   تصدير Pdf  ' : '   הפקה pdf ')}}
          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
            <span class="px-5" >
              <i class="fa fa-file-pdf-o" 
              (click)="executeFunctions(item.invoice_uuid , content4)" style="cursor: pointer;"
              [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }" 
               ></i>
            </span >
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->

        <div class="col-md-12 col-sm-12 col-xs-12  d-flex justify-content-between  ">

          <span style="color: #141414; font-size: 14px;  ;" class="col-sm-4 mx-2 " >
            {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחיקה ')}}

          </span>
          
          <div class="text-center col-sm-4">  </div>
          
          <span style="color: #141414;   " class="col-sm-4    text-end "  >
            <span *ngIf="item.confirm == 0" >
              <button    [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'" [ngStyle]="{'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red'}" class="btn px-5" style="border: none;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCentered(dataDeleted)">
                <i class="fa fa-trash-o" [ngStyle]="{'font-size': '15px', 'cursor': 'pointer', 'color': (user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted') ? 'gray' : 'red'}"></i> 
              </button>
            </span>

            <span *ngIf="item.confirm == 1" >
              <i [disabled]="user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted'" [ngStyle]="{'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red'}" class="btn px-5"  style="border: none;" (click)="getId(item?.invoice_id, item?.company_id); openVerticallyCenteredReverse(reverseInvoice)">
                <i class="fa fa-trash-o" [ngStyle]="{'font-size': '15px', 'cursor': 'pointer', 'color': (user_type == 200 || item.invoiceCategory?.lookupDetailEnName == 'Reverse invoice' || item.invoiceCategory?.lookupDetailEnName == 'Deleted') ? 'gray' : 'red'}"></i>
              </i>
            </span>
        </span>
    
        
         </div>
       <hr style="border: solid 1px #141414;" >
        <!---->


                </div>
              </div>

            </div>
            <hr style="color: #141414; border: solid 1px;">
    </div>

 


  </div>

    </div>
</div>





            <div *ngIf="invoicesDataFilters?.length == 0" class="alert text-danger mt-3">
              <span> {{ curentLang === 'en' ? 'There are no invoices for this company yet... ' : (curentLang === 'ar' ? ' لا توجد فواتير لهذة الشركة حتي الأن ... ' : 'עדין לא הופקו חשובניות מס .. ')}}</span>
          </div>
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>

              <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">

            </div>
          
         
          </div>
          <div class=" d-flex justify-content-between"> 
            <div></div>

           <div *ngIf="user_type == 100 || user_type == 101">
            <a routerLink="/dashboard/merchant-info">
                <button class="btn btn-danger m-2 " *ngIf="!isInsideModal">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

           <div *ngIf="user_type == 200 || user_type == 201">
            <a routerLink="/dashboard/users">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>





     <!--Delete invoice-->

     <ng-template #dataDeleted let-modal>
      <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
            {{curentLang === 'en' ? 'Are you sure you want to delete this invoice?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذف هذة الفاتورة؟' : ' האם אתה בטוח שברצונך למחוק חשבונית זו? ') }}
          </p>
      </div>
      <div class="modal-footer">

        <button type="button" 
         class="btn btn-sm m-auto mt-2" 
        style="background-color: #141414; color: #fff;"
         (click)="deleteInvoiceById()"
         > 
          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
      </button>
  
          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
          </button>
  
        
  
      </div>
  </ng-template>


   <!--Reverse Invoice-->

<ng-template #reverseInvoice let-modal>
  <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="text-center text-danger">
      <i class="fa fa-trash text-danger"></i>
      {{curentLang === 'en' ? 'Sorry: You cannot delete a confirmed invoice. Do you want to convert this invoice to a reverse invoice?' : (curentLang === 'ar' ? 'عذرا: لا يمكنك حذف فاتورة مؤكدة, هل تريد تحويل هذه الفاتورة إلى فاتورة عكسية؟' : 'סליחה: אינך יכול למחוק חשבונית מאושרת האם אתה רוצה להמיר חשבונית זו לחשבונית הפוכה?')}}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" (click)="checkAndConvertToReverse(invoiceId, companyId)">
      {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
    </button>
    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
      <i class="fa fa-rotate-left mx-1"></i>
    </button>
  </div>
</ng-template>


  



              <!--confirm-->

              <ng-template #dataConfirm let-modal>
                <div class="modal-header">
                <h4 class="modal-title"></h4>
                <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                  <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
                    {{curentLang === 'en' ? 'Are you sure you want to confirm this invoice?' : (curentLang === 'ar' ? '  هل تريد تأكيد هذةالفاتورة؟' : '   האם אתה בטוח שברצונך לאשר חשבונית זו? ') }}
                  </p>
                </div>
              
                <div class="modal-footer">

                  <button type="button" 
                   class="btn btn-sm m-auto mt-2" 
                  style="background-color: #141414; color: #fff;"
                   (click)="confirmInvoice()"
                   > 
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                </button>
            
                    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                    </button>
            
                  
            
                </div>


              </ng-template>







              
              <ng-template #content4 let-modal>
                <div class="modal-header d-flex justify-content-between">
                  <h6 class="modal-title"style="color: grey;">
                    {{ curentLangDocument === 'en' ? 'Reverce Invoices Info' : (curentLangDocument === 'ar' ? '   معلومات  الفواتير العكسية ' : '   פירוט חשובוניות מס זיכוי     ')}}                  </h6>
                  <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="container-fluid">


                    <div class="row  ">
                      <div class="col-md-4">
                        <button type="button" (click)="downloadDocumentPdf();modal.close('Close click')" class="btn btn-sm mx-4 mt-4"
                        style=" background-color: #141414; color: #fff;">
                        <i class="fa fa-file-pdf-o mx-1"></i>
                         {{ curentLangDocument === 'en' ? 'Export PDF' : (curentLangDocument === 'ar' ? 'تصدير PDF' : '   הפקה pdf')}}
                    </button>
                      </div>

                      <div class="col-md-4">

                      </div>

                      <div class="col-md-4">

                        <div class="form-group mx-4" *ngIf="CopyVersion === 'نسخة مطبوعه' || CopyVersion === 'עותק משוחזר' || CopyVersion === 'version copy'">
                          <label class="mt-2" style="color: #141414;">
                            {{ curentLang === 'en' ? 'Change Document Language' : (curentLang === 'ar' ? 'تغيير لغة المستند' : '  שינוי שפת המסמך     ') }}
                          </label>
                          <select class="form-control" (change)="filterDocumentLanguage($event.target.value)">
                            <option *ngFor="let lang of availableLanguages" [value]="lang.code">
                              {{ lang.name }}
                            </option>
                          </select>
                        </div>
                        
                        </div>
                    </div>



              <div class="container" [hidden]="receiptInfoData?.confirm == 0" >
                  <div class="row m-auto">
                      <div class="col-sm-12 ">
              
                        <div class="page m-auto  invoice-content" id="printDiv">

                          <div class="card-2">
          
                              <div class="row m-2 ">
                                  <div class="col-lg-12">
                                      <div class="invoice-inner" id="invoice_wrapper">
                                          <div class="invoice-top">
                                              <div class="row">
                                                  <div class="col-sm-4">
                                                      <div class="logo my-2"  *ngIf="invoiceInfoData?.logo">
                                                          <img  
                                                              [src]="invoiceInfoData?.logo"
                                                              alt="company logo"
                                                              style="width: 100px;height: 100px; border-radius: 5%;">
                                                      </div>
                                                  </div>
          
                                                  <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4">
                                                      <h2
                                                          class="text-center water-mark ">{{invoiceInfoData?.copy_version}}</h2>
                                                  </div>
          
                                                  <div class="col-sm-4">
                                               
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="invoice-info">
                                              <div class="row">
                                                 
                                                  <div class="col-sm-6 mb-30">
                                                      <div class="invoice-number ">
                                                          <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">{{ curentLangDocument === 'en' ? 'For Hon' : (curentLangDocument === 'ar' ? '   لحضرة' : 'לכבוד  ')}}</h4>
                                                          <div class="invo-addr-1">
                                                              <div style="font-weight: 400;">
                                                                  <!-- <strong> {{ curentLangDocument === 'en' ? 'Customer Name' : (curentLangDocument === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} / </strong> -->
                                                                  <strong>{{ invoiceInfoData?.customer_name }}</strong>
                                                              </div>
                                                              
                                                              <div *ngIf="invoiceInfoData?.customer_address; else noAddress" style="font-weight: 300;" class="m-0">
                                                                  <strong>{{ invoiceInfoData?.customer_address }}</strong>
                                                              </div>
                                                              
                                                              <ng-template #noAddress>
                                                                  <div style="font-weight: 300;">
                                                                      <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '    מס עוסק מורשה ')}} </strong>
                                                                      <div style="font-weight: 300;">
                                                                          <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                                      </div>
                                                                  </div>
                                                              </ng-template>
                                                              
                                                              <div *ngIf="invoiceInfoData?.customer_address" style="font-weight: 300;">
                                                                  <strong>{{ curentLangDocument === 'en' ? 'Customer Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : '    מס עוסק מורשה ')}} </strong>
                                                                  <div style="font-weight: 300;">
                                                                      <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                                  </div>
                                                              </div>
                                                              
          
                                                              
                                                              <div style="font-weight: 300;">
                                                                  <!-- <strong>{{ curentLangDocument === 'en' ? 'Customer Id Number' : (curentLangDocument === 'ar' ? '  رقم الهوية للعميل' : '  מספר זיהוי לקוח ')}} / </strong> -->
                                                                  <strong>{{ invoiceInfoData?.customer_id_number }}</strong>
                                                              </div>
                                                              
                                                          </div>
                                                      </div>
                                                      
                                                  </div>
          
                                                  <div class="col-sm-6 mb-30">
                                                      <div class="invoice-number text-end">
                                                          <!-- <h4 class="inv-title-1"> {{ curentLangDocument === 'en' ? 'Invoice From' : (curentLangDocument === 'ar' ? 'فاتورة من' : 'חשבונית מ')}} </h4> -->
                                                          <div class="invo-addr-1">
                                                              <div style="font-weight: bold;">
                                                                  <!-- <strong>{{ curentLangDocument === 'en' ? 'Company Name' : (curentLangDocument === 'ar' ? 'اسم الشركة' : 'שם החברה')}} / </strong>  -->
                                                                  <strong>{{ invoiceInfoData?.company_name }}</strong>
                                                              </div>
                                                              
                                                              <div style="font-weight: 300;">
                                                                  <!-- <strong> {{ curentLangDocument === 'en' ? 'Company Address' : (curentLangDocument === 'ar' ? 'عنوان الشركة' : 'כתובת החברה')}} / </strong> -->
                                                                  <strong>{{ invoiceInfoData?.company_address_str }} -</strong>
                                                                  <strong>{{ invoiceInfoData?.company_Building_number }} -</strong>
          
                                                                  
                                                                  <strong>{{ invoiceInfoData?.company_address_city }}</strong>
                                                              </div>
                                                              
                                                            
                                                              <!-- <div style="font-weight: 400;">{{ invoiceInfoData?.company_address_zip_code }}</div> -->
                                                              <!-- <div style="font-weight: 300;">
                                                                  <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? '  الرقم الضريبي للشركة' : 'מספר מס חברה')}} / </strong>
                                                                  <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                              
                                                              </div> -->
          
                                                              <div  style="font-weight: 300;"> 
                                                                  <strong>{{ curentLangDocument === 'en' ? 'Company Vat Number' : (curentLangDocument === 'ar' ? 'مشغل رقم' : ' מס עוסק מורשה    ')}}  </strong> 
                                                             </div>
                                                             <div  style="font-weight: 300;"> 
                                                             <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                             </div>
                                                            
          
                                                            
                                                              
                                                          </div>
                                                      </div>
                                                      
                                                      
                                                      
                                                  </div>
                                              </div>
                                              <div class="row  ">
                                         
                                                  <div class="col-sm-4"></div>
                                                  <div class="invoice text-center   col-sm-4">
                                                      <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Invoice Details' : (curentLangDocument === 'ar' ? 'تفاصيل الفاتورة' : ' פרטי החשבונית  ')}}</h4> -->
          
                                                      <p class="mb-1"
                                                          *ngIf="invoiceInfoData?.invoice_reference_number !== null">
                                                          <!-- {{ curentLangDocument === 'en' ? 'Invoice Reference Number' : (curentLangDocument === 'ar' ? 'الرقم المرجعي للفاتورة' : ' מספר הפנייה לחשבונית ')}} -->
                                                          <span>{{invoiceInfoData?.invoice_reference_number}}</span>
                                                      </p>
          
                                                      <div class="mb-1"*ngIf="invoiceInfoData?.document_number !== null" style="color: black;">
                                                    <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Reverce Invoice Number' : (curentLangDocument === 'ar' ? ' فاتورة عكسية رقم  ' : ' חשבונית הפוכה מס')}} :</strong>   
                                                    <strong style="font-weight: 400; color: black;">{{invoiceInfoData?.document_number}}</strong>
                                                  </div>
                                                
          
                                                  <div [hidden]="invoiceInfoData?.confirmation_number == null"  style="font-weight: 300;color: black;">
                                                      <strong style="font-weight: bold;" class="mx-1">{{ curentLangDocument === 'en' ? 'Confirmation Number' : (curentLangDocument === 'ar' ? ' رقم تخصيص' : 'מספר הקצאה')}} : </strong> 
                                                     <strong>{{ invoiceInfoData?.confirmation_number }}  </strong>
                                                 </div>
                                                     
                                                  </div>
          
                                                  <div class="col-sm-4  text-end  ">
                                                      <!-- <h4 class="inv-title-1">{{ curentLangDocument === 'en' ? 'Date' : (curentLangDocument === 'ar' ? ' التاريخ  ' : ' תאריך  ')}}</h4> -->
                                                     
                                                            <div class="mb-0" style="font-weight: bold;color: black;">
                                                              {{ curentLangDocument === 'en' ? '  Date' : (curentLangDocument === 'ar' ? 'تاريخ  ' : 'תאריך')}}
                                                          </div>  
                                                          <div style="font-weight: 400; color: black;">{{ invoiceInfoData?.invoice_date  }}</div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="order-summary">
                                              <div class="table-responsive">
                                                  <table class="table invoice-table">
                                                      <thead class="bg-active" style="font-weight: bold;">
                                                          <tr>
                                                              <th
                                                                  class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Item Name' : (curentLangDocument === 'ar' ? 'اسم الصنف' : 'פריט  ')}}</th>
                                                              <!-- <th
                                                                  class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Description' : (curentLangDocument === 'ar' ? 'الوصف' : 'תיאור')}}</th> -->
                                                              <!-- <th
                                                                  class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Measure Unit' : (curentLangDocument === 'ar' ? 'وحدة القياس' : 'יחידת מידה')}}</th> -->
                                                              <th
                                                                  class="text-center" style="font-weight: bold;"> {{ curentLangDocument === 'en' ? 'Price' : (curentLangDocument === 'ar' ? 'السعر  ' : ' מחיר  ')}}</th>
                                                              <th
                                                                  class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Quantity' : (curentLangDocument === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                              <th
                                                                  class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Discount' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</th>
                                                              <th class="text-center" style="font-weight: bold;">{{ curentLangDocument === 'en' ? 'Total' : (curentLangDocument === 'ar' ? 'المجموع' : 'סה"כ ')}}</th>
          
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                          <tr
                                                              *ngFor=" let item of invoiceInfoData?.lines">
                                                              <td class="text-center" style="font-weight: 400; color: black;" >{{item?.item_name}}</td>  
                                                              
                                                              <!-- <td  class="text-center  description-column"style="font-weight: 400; color: black;">{{item?.description}}</td>
          
                                                              <td class="text-center  description-column" style="font-weight: 400; color: black;">{{item?.measure_unit_description}}</td> -->
          
                                                              <td class="text-center" style="font-weight: 400; color: black;" >{{item?.price_per_unit | number:'1.2-2'}}</td>
          
                                                              <td class="text-center" style="font-weight: 400; color: black;" >{{item?.quantity}}</td>
          
                                                              <td class="text-center" style="font-weight: 400; color: black;" >{{item?.discount}}</td>
          
                                                              <td class="text-center" style="font-weight: 400; color: black;" >{{item?.sub_total | number:'1.2-2'}}</td>
          
                                                          </tr>
          
                                                      </tbody>
                                                  </table>
          
                                                
          
                                              </div>
                                              <div class="small-table my-4">
                                                  <div class="row">
                                                      <div class="col-md-12  ">
                                                        <div class="tabe table-responsive" style="background-color: #F1F1F1;">

                                                          <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                                                              <div class="col-md-6  p-2 ">
                                                                  <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Amount Before Discount:' : (curentLangDocument === 'ar' ? 'المبلغ قبل الخصم' : ' מחיר לפני הנחה ')}}</div>
                                                                  <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Discount:' : (curentLangDocument === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                                                  <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'Payment Amount:' : (curentLangDocument === 'ar' ? '   المبلغ المدفوع    ' : '      סה"כ')}}</div>
                                                                  <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLangDocument === 'en' ? 'VAT Amount:' : (curentLangDocument === 'ar' ? '       المبلغ الضريبي    ' : '          מע"מ   ')}}</div>
                                                                  <div style="width: 300px; font-weight: bold;" class="m-1"> {{ curentLangDocument === 'en' ? 'Payment Amount Including VAT:' : (curentLangDocument === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : '  סה"כ לשתלום  ')}}</div>
          
                                                              </div>
          
                                                           
          
                                                              <div class="col-md-6 text-end px-3 p-2">
                                                                  <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.amount_before_discount | number:'1.2-2'}}</div>
                                                                  <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.all_discount | number:'1.2-2'}}</div>
                                                                  <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount | number:'1.2-2'}}</div>
                                                                  <div style="font-weight: 400; color: black;" class="my-1">({{invoiceInfoData?.vat_percentage}} %)   {{invoiceInfoData?.all_vat_amount | number:'1.2-2'}}</div>
                                                                  <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount_including_vat | number:'1.2-2'}}</div>
          
                                                              </div>
                                                             
                                                           
                                                          </div>
                                                        </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              
                                              
                                              
                                              
                                              
                                          </div>
          
                                      </div>
          
                                  </div>
                              </div>
                          </div>
                      </div>
                          </div>
              
              
                          <div class="modal-footer my-2">
                            <button type="button" class="btn btn-sm m-auto mt-2"   hidden
                                    style="background-color: #141414; color: #fff;" 
                                     > 
                                 <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                            </button>
                            <button type="button" class="btn btn-sm mt-2 btn-danger" 
                                    (click)="modal.close('Close click')">
                                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                                <i class="fa fa-rotate-left mx-1"></i>
                            </button>
                        </div> 
              
              
              
                      </div>
              
                      
              
                  </div>
              
              
              
              
                  <!--back-->
              
                  </div>
                </div>
              </ng-template>