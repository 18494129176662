import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddAppInfoEndPoient } from "../service/global.service";
import Swal from 'sweetalert2';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-app-info",
  templateUrl: "./app-info.component.html",
  styleUrls: ["./app-info.component.scss"]
})

export class AppInfoComponent implements OnInit {
[x: string]: any;
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addApp:FormGroup;
  updateApp:FormGroup;
  appInfoData:any ;
  customerDataMerchant:any=[];
  customerTypeData:any=[];
  appId: number;
  updatedData: any;
  viewData: any;

  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    private http: HttpClient
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));

   this.addApp=new FormGroup({
    app_name :new FormControl(''),
    app_number:new FormControl(''),
    app_company_name :new FormControl(''),  
    app_company_number :new FormControl(''),
    version :new FormControl(''),  
    description :new FormControl(''), 
    app_tax_number :new FormControl(''), 
    created_by:new FormControl(this.userRegistId),
    });

    this.updateApp=new FormGroup({
      app_name :new FormControl(''),
      app_number:new FormControl(''),
      app_company_name :new FormControl(''),  
      app_company_number :new FormControl(''),
      version :new FormControl(''),  
      description :new FormControl(''), 
      app_tax_number :new FormControl(''), 
      updated_by:new FormControl(this.userRegistId),
      });
  };

  getAppInfoData() {
    this.spinner.show();
    this.service.getAppInfoData().subscribe({
      next: res => {
        this.appInfoData = res;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  filter(event:any)
  {
    let x = event.target.value;
     
   };

  addedData() {
    if (
      this.addApp.value.app_name == ''||
      this.addApp.value.app_number == ''||
      this.addApp.value.app_company_name == ''||
      this.addApp.value.app_company_number == ''  ||
      this.addApp.value.version == '' ||
      this.addApp.value.description == ''||
      this.addApp.value.app_tax_number == ''

        ) 
      {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      
      return; 
    }
      
    else
    {
      let model = {
        app_name: this.addApp.value.app_name,
        app_number: this.addApp.value.app_number,
        app_company_name: this.addApp.value.app_company_name,
        app_company_number: this.addApp.value.app_company_number,
        version: this.addApp.value.version,
        description: this.addApp.value.description,
        app_tax_number: this.addApp.value.app_tax_number,
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddAppInfoEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAppInfoData();
          this.addApp.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }

  };

  setValue(appId: number) {
        this.updateApp.controls.app_name.setValue(this.appInfoData.app_name);
        this.updateApp.controls.app_number.setValue(this.appInfoData.app_number);
        this.updateApp.controls.app_company_name.setValue(this.appInfoData.app_company_name);
        this.updateApp.controls.app_company_number.setValue(this.appInfoData.app_company_number);
        this.updateApp.controls.version.setValue(this.appInfoData.version);
        this.updateApp.controls.description.setValue(this.appInfoData.description);
        this.updateApp.controls.app_tax_number.setValue(this.appInfoData.app_tax_number);
  };

  executeFunctions(AppID: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(AppID);
    this.setValue(AppID);
    this.updateMd(this.updatedData);
 
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  getId(id: number) {
    this.appId = id;
  };

  updatedAppData() {

    let model = {
      app_name: this.updateApp.value.app_name,
      app_number: this.updateApp.value.app_number,
      app_company_name: this.updateApp.value.app_company_name,
      app_company_number: this.updateApp.value.app_company_number,
      version: this.updateApp.value.version,
      description: this.updateApp.value.description,
      app_tax_number: this.updateApp.value.app_tax_number,
      updated_by: Number(this.userRegistId),
    };
  
    this.spinner.show();
    this.service.updateAppInfoData( this.appId, model).subscribe((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
        });
        this.modalService.dismissAll();
        this.getAppInfoData();
       } else if (res.status === 400 || res.status === 403 || res.status === 500) {
        let errorMessage;
        switch (res.status) {
          case 400:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 403:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          case 500:
            errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
            break;
          default:
            errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
        }
        Swal.fire({
          icon: 'error',
          title: errorMessage
        });
      }
      this.spinner.hide();
    });


};



  ngOnInit() 
  {
  this.getAppInfoData();
   this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
};
