<div class="notification-box" (click)="toggleNotificationMobile()">
  <svg>
    <use href="assets/svg/icon-sprite.svg#notification"></use>
  </svg>
  <span class="badge rounded-pill badge-secondary">
    {{notificationsData?.length}}
  </span>
</div>
<div class="onhover-show-div notification-dropdown">
  <h6 class="f-18 mb-0 dropdown-title">
    {{ curentLang === 'en' ? 'Notifications  ' : (curentLang === 'ar' ? ' الأشعارات' : '  הודעות')}}
  </h6>
  <ul *ngFor="let item of notificationsData.slice(0 , 3)| paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total}; let i = index">
    <p style="font-size: 12px;" class="mx-1">
      {{ getMessage(item, curentLang) }}
    </p>
    <li class="b-l-primary border-4 d-flex justify-content-between">
     
      <p style="font-size: 12px;" class="mx-1">
        <!-- {{ curentLang === 'en' ? item?.lookupDetailsOfficeStatus?.lookupDetailEnName : 
           (curentLang === 'ar' ? item?.lookupDetailsOfficeStatus?.lookupDetailArName : 
           item?.lookupDetailsOfficeStatus?.lookupDetailHeName)}} -->
        <span class="mx-1">
          <button style=" background-color: black; color: white;"
          (click)="getId(item?.permission_id);openVerticallyCenteredConfirmed(dataConfirm)"
          class="btn  btn-sm"  ><i class="fa fa-check-circle-o text-white "></i> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'قبول' : 'אישור')}}
        </button>
        </span> 

        <span class="mx-1">
          <button class="btn btn-sm" style="background-color: red; color: whitesmoke;"
          (click)="getId(item?.permission_id);openVerticallyCenteredRejected(dataReject)">
           <i class="fa fa-times-circle " style="color: #fff;"></i>
          {{ curentLang === 'en' ? 'Reject' : (curentLang === 'ar' ? 'رفض' : 'לדחות')}}
          </button>   
        </span>

      </p>
    </li>
    
    <!-- <li class="b-l-success border-4">
      <p>Order Complete<span class="font-success">1 hr</span></p>
    </li>
    <li class="b-l-secondary border-4">
      <p>Tickets Generated<span class="font-secondary">3 hr</span></p>
    </li>
    <li class="b-l-warning border-4">
      <p>Delivery Complete<span class="font-warning">6 hr</span></p>
    </li> -->
  </ul>
  <li class="text-center">
    <a class="f-w-700" style="color: black;" routerLink="/dashboard/notification-details" *ngIf="notificationsData.length > 0">
      {{ curentLang === 'en' ? 'See All' : (curentLang === 'ar' ? 'عرض الجميع' : 'הצג הכל') }}
    </a>
    <span *ngIf="notificationsData.length === 0">
      {{ curentLang === 'en' ? 'There are no notifications yet' : (curentLang === 'ar' ? 'لا توجد إشعارات حتى الآن' : 'עדיין אין התראות') }}
    </span>
  </li>
   

  <!-- <div class="pagination-container">
    <pagination-controls 
      (pageChange)="onPageChange($event)" 
      (totalItems)="total" 
      (currentPage)="currentPage">
    </pagination-controls>
  </div> -->
</div>




        <!--confirm-->

        <ng-template #dataConfirm let-modal>
          <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
          <div class="modal-body text-center">
            <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
              {{curentLang === 'en' ? 'Are you sure you want to accept this company offer ?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد قبول عرض هذة الشركة ؟' : ' האם אתה בטוח שאתה רוצה לקבל את ההצעה של חברה זו? ') }}
            </p>
          </div>
        
          <div class="modal-footer">

            <button type="button" 
             class="btn btn-sm m-auto mt-2" 
            style="background-color: #141414; color: #fff;"
             (click)="changeCompanyPermissionStatus()"
             > 
              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
          </button>
      
              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
              </button>
      
            
      
          </div>


        </ng-template>



           <!--reject-->

           <ng-template #dataReject let-modal>
              <div class="modal-header">
              <h4 class="modal-title"></h4>
              <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
              </div>
              <div class="modal-body text-center">
                <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
                  {{curentLang === 'en' ? 'Are you sure you want to reject this company offer ?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد رفض عرض هذة الشركة ؟' : 'האם אתה בטוח שאתה רוצה לדחות את ההצעה של החברה הזו?') }}
                </p>
              </div>
            
              <div class="modal-footer">

                <button type="button" 
                 class="btn btn-sm m-auto mt-2" 
                style="background-color: #141414; color: #fff;"
                 (click)="changeCompanyPermissionStatusReject()"
                 > 
                 {{ curentLang === 'en' ? 'Reject' : (curentLang === 'ar' ? 'رفض' : 'לדחות')}}
                  <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
              </button>
          
                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                      {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                      <i class="fa fa-rotate-left mx-1"></i>
                  </button>
          
                
          
              </div>


            </ng-template>