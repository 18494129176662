import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from 'sweetalert2';
import { AddCompanyPermissionsEndPoient } from "../../service/global.service";

@Component({
  selector: "app-accounting-offices-companies",
  templateUrl: "./accounting-offices-companies.component.html",
  styleUrls: ["./accounting-offices-companies.component.scss"]
})

export class AccountingOfficesCompaniesComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addAccountingOffice:FormGroup;
  updateAccountingOffice:FormGroup;
  customersData:any=[];
  customerDataMerchant:any=[];
  accountingOfficeCompanyData:any=[];
  officeId: number;
  updatedData: any;
  viewData: any;
  currentPage: number = 1;
  limit: number = 100;
  offset: number = 0;
  total: number = 0; 
  id:any;
  activeCompanyData:any=[];

  addCompanyAccountingOffice:FormGroup;

  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    private router: Router,
    activatedRoute:ActivatedRoute,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
    this.id = activatedRoute.snapshot.params['id'];

    this.addCompanyAccountingOffice = new FormGroup({
      companyMerchant: new FormControl([] , Validators.required),   
      office_id: new FormControl(this.id),
      created_by: new FormControl(this.userRegistId),
      permission_status: new FormControl('')
    });
    
  };

  getALLActiveCompany() {
    this.spinner.show();
    this.service.getALLActiveCompany().subscribe({
      next: res => {
        this.activeCompanyData = res;
          
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'lg'  });
  };

  onCompanyMerchantChange(event) {
    this.addCompanyAccountingOffice.patchValue({
      companyMerchant: event
    });
  };

  addedAccountingOfficeCompany() {
    if (this.addCompanyAccountingOffice.invalid) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      return;
    } else {
      const companyMerchants = this.addCompanyAccountingOffice.value.companyMerchant;
      const models = companyMerchants.map(companyMerchant => ({
        office_id: Number(this.id),
        company_id: Number(companyMerchant.company_id),
        merchant_id: Number(companyMerchant.merchant_id),
        created_by: Number(this.userRegistId),
        permission_status: Number(16)
      }));
  
      this.spinner.show();
      this.service.Post(AddCompanyPermissionsEndPoient.POST, models).subscribe((res) => {
        this.spinner.hide();
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getALLActiveCompany();
          this.getAllPermissionsByOfficeId();
          this.addCompanyAccountingOffice.reset();
        } else {
          let errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
      });
    }
  }
  onPageChange(page: number) {
    this.currentPage = page;
    const offset = (page - 1) * this.limit;
    this.getAllPermissionsByOfficeId(this.limit, offset);
  }

  getAllPermissionsByOfficeId(limit?: number, offset?: number) {
    limit = limit || this.limit;
    offset = offset || (this.currentPage - 1) * this.limit;
    this.spinner.show();
    this.service.getAllPermissionsByOfficeId(this.id ,limit, offset).subscribe({
      next: res => {
        this.accountingOfficeCompanyData = res.data;
        
        this.total = res.total;
        this.spinner.hide();
         
      },
      error: () => {
        this.spinner.hide();
      }
    });
  }

  openVerticallyCentered(dataDeleted:any) {
    this.modalService.open(dataDeleted, { centered: true });
  };

  getId(id:number)
  {
     this.officeId = id ;
   };

  deleteRelatedOfficeToCompany() {
    this.spinner.show();
    this.service.deleteRelatedOfficeToCompany(this.officeId).subscribe({
      next: (res) => {
        if (res.status == 400) {
          Swal.fire({
            icon: 'error',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.spinner.hide();
          return;
        } else {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.getAllPermissionsByOfficeId();
          this.modalService.dismissAll();
          this.spinner.hide();
        }
      },
      error: (err) => {
        Swal.fire(err.messageAr);
        this.spinner.hide(); 
      }
    });
  };
  
  ngOnInit() 
  {
    this.getALLActiveCompany();

    this.getAllPermissionsByOfficeId();
   this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
   this.officeIdNumber = JSON.parse(localStorage.getItem('office_id'));
   
  };
}
