<ng-container *ngIf="!isInsideModal">

<app-breadcrumb [title]="curentLang === 'en' ? 'Customers Info' : (curentLang === 'ar' ? ' معلومات العملاء  ' : '   מידע ללקוחות')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Customers Info' : (curentLang === 'ar' ? ' معلومات العملاء  ' : '    מידע ללקוחות    ')">
</app-breadcrumb>

</ng-container>

<!--Customers info -->

<ng-container *ngIf="!isInsideModal">


<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Customers Info' : (curentLang === 'ar' ? '  معلومات العملاء ' : ' מידע ללקוחות  ')}}
        </h5>

       <div>
        <button [hidden]="user_type == 200" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add Customer' : (curentLang === 'ar' ? '  اضافة عميل' : ' הוסף לקוח ')}}
        </button>

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllCustomersBasedOnMerchant()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Customer Name ' : (curentLang === 'ar' ? '  أسم العميل   ' : '        שם הלקוח בערבית   ')}} </th>
                   <!-- <th scope="col"> {{ curentLang === 'en' ? 'Customer Address' : (curentLang === 'ar' ? '   عنوان العميل  ' : '  כתובת הלקוח   ')}}</th> -->
                   <th scope="col"> {{ curentLang === 'en' ? 'Customer Phone' : (curentLang === 'ar' ? '   هاتف العميل  ' : '    טלפון לקוח   ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? ' الرقم الضريبي للعميل' : ' מספר מע"מ של לקוחות   ')}}</th>
                   <th scope="col">                            
                      {{ curentLang === 'en' ? 'Customer Vat Tax' : (curentLang === 'ar' ? 'رقم ضريبة القيمة المضافة' : 'מס עוסק מורשה  ')}}  
                    </th>

                   <th scope="col"> {{ curentLang === 'en' ? 'ID Number' : (curentLang === 'ar' ? '   رقم الهوية  ' : '    מספר תעודת זהות  ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Customer Type' : (curentLang === 'ar' ? ' نوع العميل  ' : '    סוג לקוח    ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of customersData | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total}; let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td> 
                    {{curentLang === 'en' ? item.customer_name_en.substring(0, 20) : (curentLang === 'ar' ? item.customer_name_ar.substring(0, 20) : item.customer_name_he.substring(0, 20))}}
                  </td>

                   <!-- <td>{{item.customer_address}}</td> -->
                   <td>{{item.customer_phone}}</td>
                   <td>{{item.customer_vat_number}}</td>
                   <td>{{item.customer_vat_tax}}</td>
                   <td>{{item.id_number}}</td>
                   <td *ngIf="item.customer_type==6"> {{ curentLang === 'en' ? 'Company' : (curentLang === 'ar' ? '  شركة' : '  חברה ')}}</td>
                   <td *ngIf="item.customer_type==5"> {{ curentLang === 'en' ? 'Individual' : (curentLang === 'ar' ? '  فرد' : '  פרט ')}}</td>

                   <td>
                    <button  *ngIf="item.customer_status==1" class=" btn" style="border: none;" (click)="changeStatus(item?.customer_id, item.customer_status)">
                        <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                    </button> 

                    <button  *ngIf="item.customer_status==59" class=" btn"  style="border: none;" (click)="changeStatus(item?.customer_id, item.customer_status)">
                        <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                    </button> 

                  </td>

                   <td>{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>

                  <td>
                    <button class=" btn" style="border: none;" (click)="executeFunctionsView(  item?.customer_id,viewData ) ">
                        <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                    </button>
                  </td> 

                  <td >
                  <button  class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.customer_id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td>

                   <td >
                    <button 
                    [ngStyle]="{ 'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.customer_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
                     </td>

                </tr>
          
              </tbody>
            </table>
            <div class="pagination-container">
              <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>

</ng-container>


<!--add Customer-->

<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title" style="color: grey;">
          {{ curentLang === 'en' ? 'Add Customer ' : (curentLang === 'ar' ? ' اضافة عميل ' : ' הוסף לקוח ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid">
          <div class="edit-profile">
              <form [formGroup]="addCustomer" novalidate>

                  <!-- الصف الأول -->
                  <div class="row">
                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Customer Name' : (curentLang === 'ar' ? 'اسم العميل' : ' שם הסוכן ') }}
                              <span class="text-danger" style="color: red;">*</span>
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="text" 
                              formControlName="customer_name_he" 
                              maxlength="80"
                              required
                              [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_name_he'].invalid }"
                          />
                          <div *ngIf="validate && addCustomer.controls['customer_name_he'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer name' : (curentLang === 'ar' ? 'الرجاء ادخال اسم العميل' : 'נא להזין את שם הלקוח') }}
                          </div>
                      </div>

                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Customer Type' : (curentLang === 'ar' ? 'نوع العميل' : 'סוג לקוח') }}
                              <span class="text-danger" style="color: red;">*</span>
                          </label>
                          <select 
                              class="form-control form-select"
                              required
                              formControlName="customer_type"
                              [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_type'].invalid }"
                          >
                              <option *ngFor="let item of customerTypeData; let i = index" [value]="item?.lookup_detail_id">
                                  {{ curentLang === 'en' ? item?.lookupDetailEnName : (curentLang === 'ar' ? item?.lookupDetailArName : item?.lookupDetailHeName) }}
                              </option>
                          </select>
                          <div *ngIf="validate && addCustomer.controls['customer_type'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please choose customer type' : (curentLang === 'ar' ? 'الرجاء اختيار نوع العميل' : 'אנא בחר סוג לקוח') }}
                          </div>
                      </div>

                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Customer Phone' : (curentLang === 'ar' ? 'هاتف العميل' : 'טלפון לקוח') }}
                          </label>
                          <div class="input-group">
                              <span style="color: #141414;" class="input-group-text">+972</span>
                              <input 
                                  class="form-control" 
                                  type="text" 
                                  maxlength="10"
                                  formControlName="customer_phone"
                                  [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_phone'].invalid }"
                              />
                          </div>
                          <div *ngIf="validate && addCustomer.controls['customer_phone'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter a valid phone number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم هاتف صالح' : 'נא להזין מספר טלפון תקין') }}
                          </div>
                      </div>
                  </div>

                  <!-- الصف الثاني -->
                  <div class="row">
                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'ID Number' : (curentLang === 'ar' ? 'رقم الهوية' : 'מספר תעודת זהות') }}
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="number" 
                              formControlName="id_number"
                              maxlength="9"
                              [ngClass]="{ 'is-invalid': validate && addCustomer.controls['id_number'].invalid }"
                          />
                          <div *ngIf="validate && addCustomer.controls['id_number'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter a valid ID number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم هوية صالح' : 'נא להזין מספר תעודת זהות תקין') }}
                          </div>
                      </div>

                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'City' : (curentLang === 'ar' ? 'المدينة' : 'עִיר') }}
                              <!-- <span class="text-danger" style="color: red;">*</span> -->
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="text" 
                              formControlName="customer_address_city"
                              maxlength="20"
                              
                          />
                          <!-- <div *ngIf="validate && addCustomer.controls['customer_address_city'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer city' : (curentLang === 'ar' ? 'الرجاء إدخال مدينة العميل' : 'אנא הזן עיר לקוח') }}
                          </div> -->
                      </div>

                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Street Name' : (curentLang === 'ar' ? 'أسم الشارع' : 'שם רחוב') }}
                              <!-- <span class="text-danger" style="color: red;">*</span> -->
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="text" 
                              formControlName="customer_address_str"
                              maxlength="20"
                          />
                          <!-- <div *ngIf="validate && addCustomer.controls['customer_address_str'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer street' : (curentLang === 'ar' ? 'الرجاء إدخال شارع العميل' : 'אנא הזן רחוב לקוח') }}
                          </div> -->
                      </div>
                  </div>

                  <!-- الصف الثالث -->
                  <div class="row">
                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Building Number' : (curentLang === 'ar' ? 'رقم المبني' : 'מספר בניין') }}
                              <!-- <span class="text-danger" style="color: red;">*</span> -->
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="number" 
                              formControlName="customer_Building_number"
                              maxlength="20"
                          />
                          <!-- <div *ngIf="validate && addCustomer.controls['customer_Building_number'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer building number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم مبنى العميل' : 'אנא הזן מספר בניין לקוח') }}
                          </div> -->
                      </div>

                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Zip Code' : (curentLang === 'ar' ? 'الرقم البريدي' : 'מיקוד דואר') }}
                              <!-- <span class="text-danger" style="color: red;">*</span> -->
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="number" 
                              formControlName="customer_address_zip_code"
                              maxlength="20"
                          />
                          <!-- <div *ngIf="validate && addCustomer.controls['customer_address_zip_code'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter customer zip code' : (curentLang === 'ar' ? 'الرجاء إدخال الرقم البريدي للعميل' : 'אנא הזן את המיקוד של הלקוח') }}
                          </div> -->
                      </div>

                      <div class="mb-3 col-xl-4 col-md-4">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي للعميل' : 'מספר מס לקוח') }}
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="text"
                              formControlName="customer_vat_number"
                              maxlength="9"
                              pattern="[0-9]{9}"
                              title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}"
                              [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_vat_number'].invalid }"
                          />
                          <div *ngIf="validate && addCustomer.controls['customer_vat_number'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                                            <span class="text-danger" style="color: red;">*</span>

                          </div>
                      </div>
                  </div>

                  <!-- الصف الرابع (حقل ضريبة القيمة المضافة، أو يمكنك إضافة المزيد من الحقول) -->
                  <div class="row">
                      <div class="mb-3 col-xl-4 col-md-4" *ngIf="addCustomer.value.customer_type != 5">
                          <label class="form-label" style="color: grey;">
                              {{ curentLang === 'en' ? 'Customer Vat Tax' : (curentLang === 'ar' ? 'رقم ضريبة القيمة المضافة' : '  מס עוסק מורשה   ') }}
                          </label>
                          <input 
                              style="color: grey;" 
                              class="form-control"
                              type="number"
                              formControlName="customer_vat_tax"
                              maxlength="9"
                              [ngClass]="{ 'is-invalid': validate && addCustomer.controls['customer_vat_tax'].invalid }"
                          />
                          <div *ngIf="validate && addCustomer.controls['customer_vat_tax'].invalid" class="invalid-feedback text-danger">
                              {{ curentLang === 'en' ? 'Please enter a valid VAT number' : (curentLang === 'ar' ? 'الرجاء إدخال رقم ضريبة القيمة المضافة الصحيح' : 'אנא הזן מספר מס מע"מ תקין') }}
                          </div>
                      </div>
                  </div>
              
                  <div class="modal-footer">
                      <button type="button" class="btn btn-sm m-auto mt-2" 
                              style="background-color: #141414; color: #fff;" 
                              (click)="addedData()"> 
                          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تأكيد' : 'אישור') }}
                          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                      </button>
                      <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" 
                              (click)="modal.close('Close click')">
                          {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                          <i class="fa fa-rotate-left mx-1"></i>
                      </button>
                  </div>
              </form>
              
          </div>
      </div>
  </div>
</ng-template>




<!--update Customer-->

<ng-template #updatedData let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '    ערוך מידע ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="updateCustomer"  novalidate [ngClass]="{ 'was-validated': updatevalidate }">
                        <div class="row">
 
                          <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Customer Name Ar' : (curentLang === 'ar' ? '  اسم العميل عربي   ' : '  שם החברה בערבית    ')}}
                             </label>
                              <input style="color: grey;" 
                               class="form-control" maxlength="80" 
                               type="text" formControlName="customer_name_ar" 
                                />
                            </div> 
            
                             <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Customer Name En' : (curentLang === 'ar' ? '  اسم العميل انجليزي   ' : '    שם מס באנגלית     ')}}
                               </label>
                                <input style="color: grey;" 
                                class="form-control"
                                 type="text"
                                 maxlength="80"
                                  formControlName="customer_name_en"
                                 />
                              </div>
             
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Customer Name He' : (curentLang === 'ar' ? '   اسم العميل عبري   ' : 'שם הלקוח הוא עברי')}}
                                  <span class="text-danger" style="color: red;">*</span>
                               </label>
                                <input style="color: grey;" class="form-control"
                                 type="text" 
                                 formControlName="customer_name_he" 
                                 maxlength="80"
                                 required="" onlyAlphabets
                                />
                                <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم العميل ' : 'נא להזין את שם הלקוח')}}  </div>

                              </div>
                              <div class="mb-3 col-xl-4 col-md-4">
                                <label class="form-label" style="color: grey;">
                                    {{ curentLang === 'en' ? 'Customer Type' : (curentLang === 'ar' ? 'نوع العميل' : 'סוג לקוח')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                </label>
                                <div *ngIf="updateCustomer.get('customer_type')?.value === 5">
                                    <input class="form-control" readonly
                                        value="{{ curentLang === 'en' ? 'Individual' : (curentLang === 'ar' ? 'فرد' : 'אישי')}}">
                                </div>
                                <div *ngIf="updateCustomer.get('customer_type')?.value === 6">
                                    <input class="form-control" readonly
                                        value="{{ curentLang === 'en' ? 'Company' : (curentLang === 'ar' ? 'شركة' : 'חברה')}}">
                                </div>
                            </div>
                            



                              <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Customer Phone' : (curentLang === 'ar' ? ' هاتف العميل ' : ' טלפון לקוח ')}}
                               </label>
                               <div class="input-group">
                                <span style="color: #141414;" class="input-group-text">+972</span>
                                <input class="form-control" type="text" maxlength="10"    formControlName="customer_phone">
                              </div>
                              <div *ngIf="updateCustomer.get('customer_phone')?.errors != null && updateCustomer.get('customer_phone')?.touched" class="alert text-danger my-1">
                                <p *ngIf="updateCustomer.get('customer_phone')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                                <p *ngIf="updateCustomer.get('customer_phone')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{updateCustomer.get('customer_phone')?.errors?.invalidMobilePrefix.message}}</p>
                                <p *ngIf="updateCustomer.get('customer_phone')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{updateCustomer.get('customer_phone')?.errors?.invalidPhoneNumber.message}}</p>
                              </div>
                              </div>

                              <div class="mb-3 col-xl-4 col-md-4 " *ngIf="updateCustomer.value.customer_type != 6">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'ID Number' : (curentLang === 'ar' ? 'رقم الهوية' : ' מספר תעודת זהות ')}}
                               </label>
                                <input style="color: grey;" 
                                class="form-control" type="number" 
                                 formControlName="id_number"
                                 maxlength="20" readonly
                                   />
                              </div>
            
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'City' : (curentLang === 'ar' ? ' المدينة    ' : '  עִיר  ')}}
                                  <span class="text-danger" style="color: red;">*</span>
                               </label>
                                <input style="color: grey;" required="" onlyAlphabets
                                class="form-control" type="text" 
                                 formControlName="customer_address_city"
                                 maxlength="20"
                                   />
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer address city ' : (curentLang === 'ar' ? '  الرجاء ادخال مدينة العميل' : '  אנא הזן עיר לקוח')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Street Name' : (curentLang === 'ar' ? ' أسم الشارع  ' : '   שם רחוב ')}}
                                  <span class="text-danger" style="color: red;">*</span>
                               </label>
                                <input style="color: grey;" required="" onlyAlphabets
                                class="form-control" type="text" 
                                 formControlName="customer_address_str"
                                 maxlength="20"
                                   />
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer address street ' : (curentLang === 'ar' ? ' الرجاء ادخال شارع العميل ' : '  אנא הזן רחוב לקוח')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Building Number' : (curentLang === 'ar' ? 'رقم المبني ' : 'מספר בניין ')}}
                                  <span class="text-danger" style="color: red;">*</span>
                               </label>
                                <input style="color: grey;" required="" onlyAlphabets
                                class="form-control" type="number" 
                                 formControlName="customer_Building_number"
                                 maxlength="20"
                                   /> 
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer building number ' : (curentLang === 'ar' ? '  الرجاء ادخال رقم المبني العميل' : '     אנא הזן מספר בניין לקוח  ')}}  </div>

                              </div>
                              
                              <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Zip Code' : (curentLang === 'ar' ? ' الرقم البريدي    ' : '  מיקוד דואר  ')}}
                                  <span class="text-danger" style="color: red;">*</span>
                               </label>
                                <input style="color: grey;" required="" onlyAlphabets
                                class="form-control" type="number" 
                                 formControlName="customer_address_zip_code"
                                 maxlength="20"
                                   />
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer zip code  ' : (curentLang === 'ar' ? '  الرجاء ادخال الرقم البريدي  ' : ' נא להזין את המיקוד ')}}  </div>

                              </div>

                              <div class="mb-3 col-xl-4 col-md-4 " *ngIf="updateCustomer.value.customer_type != 5">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي للعميل' : ' מספר מס לקוח')}}
                                </label>
                                <input style="color: grey;" 
                                       class="form-control" 
                                       type="text"
                                       (keypress)="isNumber($event)"
                                       maxlength="9"
                                       max="9"
                                       min="0"
                                       inputmode="numeric" 
                                       formControlName="tax_register" 
                                       pattern="[0-9]{9}"
                                       title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}"
                                       formControlName="customer_vat_number"
                                       maxlength="9"
                                />
                                <div *ngIf="updateCustomer?.get('customer_vat_number').invalid && (updateCustomer?.get('customer_vat_number').dirty || updateCustomer?.get('customer_vat_number').touched)" class="text-danger">
                                  {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                </div>
                            </div>
                            
                            <div class="mb-3 col-xl-4 col-md-4 " *ngIf="updateCustomer.value.customer_type != 5">
                                <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Customer Vat Tax' : (curentLang === 'ar' ? 'رقم ضريبة القيمة المضافة' : ' מס  עוסק מורשה   ')}}
                                </label>
                                <input style="color: grey;" 
                                       class="form-control" 
                                       type="number" 
                                       formControlName="customer_vat_tax"
                                       maxlength="20"
                                />
                            </div>
                      </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updatedCustomerData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>

<!--View Customer-->

<ng-template #viewData let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'View Info ' : (curentLang === 'ar' ? ' مشاهدة المعلومات ' : '     הצג מידע      ')}} 
    </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
                    <form  [formGroup]="updateCustomer">
                      <div class="row">

                       <div class="mb-3 col-xl-4 col-md-4 ">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Customer Name Ar' : (curentLang === 'ar' ? '  اسم العميل عربي   ' : '  שם החברה בערבית    ')}}
                           </label>
                            <input style="color: grey;" 
                             class="form-control" maxlength="80" 
                             type="text" formControlName="customer_name_ar" readonly 
                              />
                          </div> 
          
                       <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Customer Name En' : (curentLang === 'ar' ? '  اسم العميل انجليزي   ' : '    שם מס באנגלית     ')}}
                             </label>
                              <input style="color: grey;" 
                              class="form-control"
                               type="text"
                               maxlength="80"
                                formControlName="customer_name_en" readonly
                               />
                            </div> 
          
                            <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Customer Name He ' : (curentLang === 'ar' ? '  اسم العميل عبري    ' : ' שם הלקוח הוא עברי ')}}
                             </label>
                              <input style="color: grey;" class="form-control"
                               type="text" 
                               formControlName="customer_name_he" 
                               maxlength="80" readonly
                              />
                               
                            </div>

                            <div class="mb-3 col-xl-4 col-md-4">
                              <label class="form-label" style="color: grey;">
                                  {{ curentLang === 'en' ? 'Customer Type' : (curentLang === 'ar' ? 'نوع العميل' : 'סוג לקוח')}}
                                  <span class="text-danger" style="color: red;">*</span>
                              </label>
                              <div *ngIf="updateCustomer.get('customer_type')?.value === 5">
                                  <input class="form-control" readonly
                                      value="{{ curentLang === 'en' ? 'Individual' : (curentLang === 'ar' ? 'فرد' : 'אישי')}}">
                              </div>
                              <div *ngIf="updateCustomer.get('customer_type')?.value === 6">
                                  <input class="form-control" readonly
                                      value="{{ curentLang === 'en' ? 'Company' : (curentLang === 'ar' ? 'شركة' : 'חברה')}}">
                              </div>
                          </div>

                            <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Customer Phone' : (curentLang === 'ar' ? ' هاتف العميل ' : ' טלפון לקוח ')}}
                             </label>
                            
                             <div class="input-group">
                              <span style="color: #141414;" class="input-group-text">+972</span>
                              <input class="form-control" type="text" maxlength="10" required="" placeholder="{{'Register.phone'|translate}}" formControlName="customer_phone">
                            </div>
                            <div *ngIf="updateCustomer.get('customer_phone')?.errors != null && updateCustomer.get('customer_phone')?.touched" class="alert text-danger my-1">
                              <p *ngIf="updateCustomer.get('customer_phone')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                              <p *ngIf="updateCustomer.get('customer_phone')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{updateCustomer.get('customer_phone')?.errors?.invalidMobilePrefix.message}}</p>
                              <p *ngIf="updateCustomer.get('customer_phone')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{updateCustomer.get('customer_phone')?.errors?.invalidPhoneNumber.message}}</p>
                            </div>
                            </div>

                            <div class="mb-3 col-xl-4 col-md-4 " *ngIf="updateCustomer.value.customer_type != 6">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'ID Number' : (curentLang === 'ar' ? 'رقم الهوية' : ' מספר תעודת זהות ')}}
                             </label>
                              <input style="color: grey;" 
                              class="form-control" type="number" 
                               formControlName="id_number"
                               maxlength="20" readonly
                                 />
                            </div>
          
                            <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'City' : (curentLang === 'ar' ? ' المدينة    ' : '  עִיר  ')}}
                             </label>
                              <input style="color: grey;" 
                              class="form-control" type="text" 
                               formControlName="customer_address_city"
                               maxlength="20" readonly
                                 />
                            </div>

                            <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Street Name' : (curentLang === 'ar' ? ' أسم الشارع  ' : '   שם רחוב ')}}
                             </label>
                              <input style="color: grey;" 
                              class="form-control" type="text" 
                               formControlName="customer_address_str"
                               maxlength="20" readonly
                                 />
                            </div>

                            <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Building Number' : (curentLang === 'ar' ? 'رقم المبني ' : 'מספר בניין ')}}
                             </label>
                              <input style="color: grey;" 
                              class="form-control" type="number" 
                               formControlName="customer_Building_number"
                               maxlength="20" readonly
                                 />
                            </div>
                            
                            <div class="mb-3 col-xl-4 col-md-4 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Zip Code' : (curentLang === 'ar' ? ' الرقم البريدي    ' : '  מיקוד דואר  ')}}
                             </label>
                              <input style="color: grey;" 
                              class="form-control" type="number" 
                               formControlName="customer_address_zip_code"
                               maxlength="20" readonly
                                 />
                            </div>

                            <div class="mb-3 col-xl-4 col-md-4 " *ngIf="updateCustomer.value.customer_type != 5">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي للعميل' : ' מספר מס לקוח')}}
                              </label>
                              <input style="color: grey;" 
                              class="form-control" 
                              type="text"
                              inputmode="numeric" 
                              formControlName="tax_register" 
                              pattern="[0-9]{9}"
                              title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}"
                              formControlName="customer_vat_number"
                              maxlength="9" readonly
                       />
                       <div *ngIf="updateCustomer?.get('customer_vat_number').invalid && (updateCustomer?.get('customer_vat_number').dirty || updateCustomer?.get('customer_vat_number').touched)" class="text-danger">
                         {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                       </div>
                          </div>
                          
                          <div class="mb-3 col-xl-4 col-md-4 " *ngIf="updateCustomer.value.customer_type != 5">
                              <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Customer Vat Tax' : (curentLang === 'ar' ? 'رقم ضريبة القيمة المضافة' : ' מס  עוסק מורשה   ')}}
                              </label>
                              <input style="color: grey;" 
                                     class="form-control" 
                                     type="number" 
                                     formControlName="customer_vat_tax"
                                     maxlength="20" readonly
                              />
                          </div>
                    </div>
                

                    </form>
              
            
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>


     <!--Delete Customer-->

     <ng-template #dataDeleted let-modal>
      <div class="modal-header">
          <h4 class="modal-title"></h4>
          <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
            {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : ' אתב בטח שברוצנך למחוק?  ') }}
          </p>
      </div>
      <div class="modal-footer">

        <button type="button" 
         class="btn btn-sm m-auto mt-2" 
        style="background-color: #141414; color: #fff;"
         (click)="deleteCustomerById()"
         > 
          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
      </button>
  
          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
          </button>
  
        
  
      </div>
  </ng-template>








  <ng-container *ngIf="isInsideModal">


    <div class="container-fluid basic_table" >
        <div class="row">
    <div class="col-sm-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5 style="color: grey;"> 
                {{ curentLang === 'en' ? 'Customers Info' : (curentLang === 'ar' ? '  معلومات العملاء ' : ' מידע ללקוחות  ')}}
            </h5>
    
           <div>
            <button [hidden]="user_type == 200" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
                <i class="fa fa-plus-circle mx-1"></i>
              {{ curentLang === 'en' ? 'Add Customer' : (curentLang === 'ar' ? '  اضافة عميل' : ' הוסף לקוח ')}}
            </button>
    
            <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllCustomersBasedOnMerchant()">
              <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
          </button>
    
             </div>
         
          </div>
          
          <div class="row">
            <div class="col-sm-5">
                <div class="form-group search m-3">
                  <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                     <input class="form-control  w-100" type="text" name="search"
                      [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
                </div>
            </div>
        </div>
    
          <div class="card-block row">
            <div class="col-sm-12 col-lg-12 col-xl-12">
              <div class="table-responsive">
                <table class="table table-light text-center table-hover  " >
                  <thead>
                    <tr>
                      <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                      <th scope="col"> {{ curentLang === 'en' ? 'Customer Name ' : (curentLang === 'ar' ? '  أسم العميل   ' : '        שם הלקוח בערבית   ')}} </th>
                       <!-- <th scope="col"> {{ curentLang === 'en' ? 'Customer Address' : (curentLang === 'ar' ? '   عنوان العميل  ' : '  כתובת הלקוח   ')}}</th> -->
                       <th scope="col"> {{ curentLang === 'en' ? 'Customer Phone' : (curentLang === 'ar' ? '   هاتف العميل  ' : '    טלפון לקוח   ')}}</th>
                       <th scope="col"> {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? ' الرقم الضريبي للعميل' : ' מספר מע"מ של לקוחות   ')}}</th>
                       <th scope="col">                            
                          {{ curentLang === 'en' ? 'Customer Vat Tax' : (curentLang === 'ar' ? 'رقم ضريبة القيمة المضافة' : ' מס  עוסק מורשה ')}}  
                        </th>
    
                       <th scope="col"> {{ curentLang === 'en' ? 'ID Number' : (curentLang === 'ar' ? '   رقم الهوية  ' : '    מספר תעודת זהות  ')}}</th>
                       <th scope="col"> {{ curentLang === 'en' ? 'Customer Type' : (curentLang === 'ar' ? ' نوع العميل  ' : '    סוג לקוח    ')}}</th>
    
                      <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>
                      <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                      <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th>
                      <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                      <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>
    
                    </tr>
                  </thead>
    
                  <tbody>
                    <tr *ngFor="let item of customersData | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total}; let i = index">
                      <td scope="row">{{i+1}}</td>
                      <td> 
                        {{curentLang === 'en' ? item.customer_name_en.substring(0, 20) : (curentLang === 'ar' ? item.customer_name_ar.substring(0, 20) : item.customer_name_he.substring(0, 20))}}
                      </td>
    
                       <!-- <td>{{item.customer_address}}</td> -->
                       <td>{{item.customer_phone}}</td>
                       <td>{{item.customer_vat_number}}</td>
                       <td>{{item.customer_vat_tax}}</td>
                       <td>{{item.id_number}}</td>
                       <td *ngIf="item.customer_type==6"> {{ curentLang === 'en' ? 'Company' : (curentLang === 'ar' ? '  شركة' : '  חברה ')}}</td>
                       <td *ngIf="item.customer_type==5"> {{ curentLang === 'en' ? 'Individual' : (curentLang === 'ar' ? '  فرد' : '  פרט ')}}</td>
    
                       <td>
                        <button  *ngIf="item.customer_status==1" class=" btn" style="border: none;" (click)="changeStatus(item?.customer_id, item.customer_status)">
                            <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                        </button> 
    
                        <button  *ngIf="item.customer_status==59" class=" btn"  style="border: none;" (click)="changeStatus(item?.customer_id, item.customer_status)">
                            <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                        </button> 
    
                      </td>
    
                       <td>{{ item.createdDate | date: 'dd/MM/yyyy'}}</td>
    
                      <td>
                        <button class=" btn" style="border: none;" (click)="executeFunctionsView(  item?.customer_id,viewData ) ">
                            <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                        </button>
                      </td> 
    
                      <td >
                      <button  class=" btn" style="border: none;"
                       (click)="executeFunctions(  item?.customer_id,updatedData ) ">
                        <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                       </i>
                      </button>
                       </td>
    
                       <td >
                        <button 
                        [ngStyle]="{ 'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red' }" 
                        class="btn" 
                        style="border: none;" 
                        (click)="getId(item?.customer_id);openVerticallyCentered(dataDeleted)">
                          <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                          </button>
                         </td>
    
                    </tr>
              
                  </tbody>
                </table>
                <div class="pagination-container">
                  <pagination-controls 
                    (pageChange)="onPageChange($event)" 
                    (totalItems)="total" 
                    (currentPage)="currentPage">
                  </pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
        </div>
    </div>
    
    </ng-container>



