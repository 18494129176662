<app-breadcrumb [title]="curentLang === 'en' ? 'Company Documents Info' : (curentLang === 'ar' ? ' معلومات مستندات الشركات   ' : '   מידע על מסמכי תאגיד    ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Company Documents Info' : (curentLang === 'ar' ? ' معلومات مستندات الشركات   ' : '   מידע על מסמכי תאגיד   ')">
</app-breadcrumb>

<div class="container-fluid basic_table">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h5 style="color: grey;">
            {{ curentLang === 'en' ? 'Company Documents Info' : (curentLang === 'ar' ? '  معلومات مستندات الشركات  ' : '  מידע על מסמכי תאגיד   ')}}
          </h5>

          <div>
            <!-- <button [hidden]="user_type == 200" class="btn m-2" style="background-color: #141414; color: #fff;"
            routerLink="/dashboard/export-reports/{{uuid}}">
       <i class="fa fa-upload mx-1"></i>
      {{ curentLang === 'en' ? 'Export Reports' : (curentLang === 'ar' ? ' اصدار تقارير' : ' הוספת חברה')}}
    </button>
    
    <button (click)="downloadAllInvoicesZip()" class="btn m-2" style="background-color: #141414; color: #fff;">
      <i class="fa fa-download mx-1"></i>
      {{ curentLang === 'en' ? 'Download All Invoices ZIP' : (curentLang === 'ar' ? 'تحميل كل الفواتير ZIP' : 'הורד את כל החשבוניות ב-ZIP') }}
    </button> -->
    
    

            <!-- <button class="btn mx-1 btn-outline-warning" (click)="getAllCompanyDocumentsComponent()">
              <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
            </button> -->
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="form-group search">
              <label for="search" style="color: grey;">
                {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
              </label>
              <div class="input-group">
                <input class="form-control form-control-search" type="text" name="search"
                       [ngModelOptions]="{standalone: true}" id="search" [(ngModel)]="term" style="color: black;"
                       placeholder="{{curentLang === 'en' ? 'Search by customer name or document number ' : (curentLang === 'ar' ? 'بحث بأسم العميل او رقم المستند' : 'חפש לפי שם הלקוח או מספר המסמך')}}"

                       >
                <!-- <div class="input-group-append">
                  <button class="btn" style="background-color: #141414; color: #fff;" type="button"
                          (click)="getAllCompanyDocumentsFilters(fromDate, toDate, limit, offset, term)">
                    <i class="fa fa-search mx-1"></i>
                    {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                  </button>
                  <button class="btn  btn-outline-secondary" type="button" (click)="term='';getAllCompanyDocumentsFilters(fromDate, toDate, limit, offset, term)">
                    <i class="fa fa-times"></i>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          
        
          <div class="col-sm-3 ">
            <div class="form-group search">
              <label for="fromDate"  style="color: grey;">
                {{curentLang === 'en' ? 'From Date' : (curentLang === 'ar' ? 'من تاريخ' : 'מהתאריך של')}}
              </label>
              <input class="form-control" type="date" [(ngModel)]="fromDate" id="fromDate" />
            </div>
          </div>
        
          <div class="col-sm-5">
            <div class="row">
              <div class="col-md-6 ">
                <div class="form-group search">
                  <label for="toDate"  style="color: grey;">
                    {{curentLang === 'en' ? 'To Date' : (curentLang === 'ar' ? 'الي تاريخ' : ' עד היום')}}
                  </label>
                  <input class="form-control" type="date" [(ngModel)]="toDate" id="toDate" />
                </div>
              </div>

              <div class="col-md-6 ">

                <div class="form-group search " style="margin-top: 30px;">
                  <button class="btn mx-1" style="background-color: #141414; color: #fff;" [disabled]="!fromDate || !toDate"
                          (click)="getAllCompanyDocumentsComponent(fromDate, toDate)">
                    <i class="fa fa-search mx-1"></i>
                    {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}
                  </button>
                </div>
              </div>

            </div>
           

          </div>
      
        </div>
        
        
         
       
        

        <div class="card-block row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div class="table-responsive">
              <table class="table table-light text-start table-hover">
                <thead>
                  <tr>
                    <th scope="col">{{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? '  رقم المستند' : '  מספר מסמך') }}</th>
                    <th scope="col"> {{ curentLang === 'en' ? 'Invoice Type' : (curentLang === 'ar' ? '  نوع المستند  ' : ' סוג מסמך ')}} </th>
                    <th scope="col"> {{ curentLang === 'en' ? ' Customer Name ' : (curentLang === 'ar' ? ' اسم العميل' : 'שם הסוכן')}}</th>   
                    <th scope="col"> {{ curentLang === 'en' ? ' Customer Vat Number ' : (curentLang === 'ar' ? ' رقم مشغل العميل' : 'מספר מע"מ של לקוח  ')}}</th>     
  
                    <th scope="col"> {{ curentLang === 'en' ? 'Total Amount ' : (curentLang === 'ar' ? 'المبلغ الاجمالي' : '  סכום כולל')}}</th>
                    <th scope="col"> {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? '  تاريخ الفاتورة' : 'תאריך חשבונית ')}}</th>
                    <th scope="col"> {{ curentLang === 'en' ? 'Export Pdf ' : (curentLang === 'ar' ? ' تصدير Pdf ' : ' ייצוא PDF ')}}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of companyDocumentsData | paginate: { itemsPerPage: limit, currentPage: currentPage, totalItems: total } | filter: term; let i = index">
                    <td scope="row">{{item?.document_number}}</td>
                    <td>{{ getInvoiceTypeDescription(item?.invoice_type) }}</td>
                    <td class="customer-name">{{item?.customer_name}}</td>
                    <td>{{item?.customer_vat_number}}</td>
                    <td>
                      <span *ngIf="item.invoice_type == 400">{{item?.total_amount | number:'1.2-2'}}</span>
                      <span *ngIf="item.invoice_type != 400">{{ item?.payment_amount_including_vat | number:'1.2-2'}}</span>
                    </td>
                    <td>{{ item?.invoice_date | date: 'dd/MM/yyyy' }}</td>


                    <td>
                      <i class="fa fa-file-pdf-o" (click)="getCompanyId(item.company_id)"
                        [ngStyle]="{ 'pointer-events': item.confirm == 0 ? 'none' : 'auto', 'opacity': item.confirm == 0 ? 0.6 : 1 }"
                        style="color: grey; font-size: 15px; cursor: pointer; color: #213FA6;"
                        [routerLink]="['/dashboard/document-pdf', item.invoice_uuid]"  >
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-container">
                <pagination-controls (pageChange)="onPageChange($event)" (totalItems)="total" (currentPage)="currentPage">
                </pagination-controls>
                <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <a routerLink="/dashboard/companies">
                  <button class="btn btn-danger m-2">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה ')}}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
