import { Component, OnInit } from "@angular/core";
 import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { AddZipEndPoient, baseUrl } from "../service/global.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from "src/environments/environment";


@Component({
  selector: "app-permanent-file",
  templateUrl: "./permanent-file.component.html",
  styleUrls: ["./permanent-file.component.scss"]
})

export class PermanentFileComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  mastercompany: any;
  companyId:number;
  createZip:FormGroup;
  oneCompanyData: any;
  TaxCompany: any;
  company_id: any;
  allTransactionData:any=[];
  selectedCompany: any;

  constructor
  (  
     private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
    private http: HttpClient // إضافة هذا السطر

     ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
     this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));

    this.createZip=new FormGroup({
      company_id :new FormControl('' , Validators.required),
      start_date:new FormControl('', Validators.required),
      end_date :new FormControl('', Validators.required),  
      lang :new FormControl(this.curentLang),
      merchant_id :new FormControl(this.merchantId),  
      created_by:new FormControl(this.userRegistId),
      });
  
  };

  GetCompany() {
    this.service.GetCompany().subscribe(res => {
      this.mastercompany = res;
       
    })
  };


   addedData() {
    this.spinner.show();
    if (
      this.createZip.value.company_id == '' ||
      this.createZip.value.start_date == '' ||
      this.createZip.value.end_date == ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
      return;
    } else {
  
      const requestData = {
        company_id: this.createZip.value.company_id,
        start_date: this.createZip.value.start_date,
        end_date: this.createZip.value.end_date,
        lang: this.curentLang,
        merchant_id: this.merchantId,
        created_by: Number(this.userRegistId),
      };
  
      // Execute the download request
      this.http.post(`${baseUrl}zip/create`, requestData, { observe: 'response', responseType: 'blob' }).subscribe(response => {
        // Constructing the filename using company information
        const fileName = `OPENFRMT_${this.TaxCompany}_${requestData.start_date}_${requestData.end_date}.zip`;
  
        // Create a Blob from the response body
        const blob = new Blob([response.body], { type: 'application/zip' });
  
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);
  
        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
  
        // Click the anchor element to start the download
        a.click();
  
        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
        this.spinner.hide();
      });
    }
  }
  
  
  filter(companyId: any) {
    // استدعاء الدالة getCompanyInfoById() وتمرير معرف الشركة إليها
    this.getCompanyInfoById(companyId);
  }
  
  getCompanyInfoById(companyId: any) {
    if (companyId != null && companyId != '') {
      this.service.getCompanyInfoById(companyId).subscribe({
        next: res => {
          this.oneCompanyData = res;
          this.TaxCompany = this.oneCompanyData?.tax_register;
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    }
  }
  

  getAllTransactionsByMerchantId() {
    this.spinner.show();
    this.service.getAllTransactionsByMerchantId(this.merchantId).subscribe({
      next: res => {
        this.allTransactionData = res;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  
  


  ngOnInit() 
  {
    this.GetCompany();
    // this.getAllTransactionsByMerchantId();
    this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) 
   {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   }
    else 
    {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only")
     {
       document.body.classList.add("dark-only");
     } 
     else
     {
       document.body.classList.add("light-only");
     }
   };
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  }
}
