<app-breadcrumb [title]="curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '   מידע על החברה    ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '     מידע על החברה       ')">
</app-breadcrumb>

<!--Company info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? '  معلومات الشركة ' : '   מידע על החברה    ')}}
        </h5>

       <div>
       
        <button class="btn mx-1 " style="background-color: #141414; color: #fff;"   (click)="openXl(content)">
          <i class="fa fa-plus-circle mx-1"></i> {{ curentLang === 'en' ? ' Add Company' : (curentLang === 'ar' ? '  اضافة شركة   ' : '   הוספת חברה    ')}}
      </button>

        <button class="btn mx-1 btn-outline-warning"  (click)="getAllPermissionsByOfficeId()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Office Name ' : (curentLang === 'ar' ? '  أسم المكتب   ' : ' שם המשרד  ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Office Address' : (curentLang === 'ar' ? '   عنوان المكتب  ' : '  כתובת משרד  ')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  أسم الشركة  ' : '    שם החברה  ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Company Address' : (curentLang === 'ar' ? ' عنوان الشركة ' : ' כתובת החברה ')}}</th>
                   <th scope="col">                            
                      {{ curentLang === 'en' ? ' Company Tax Number' : (curentLang === 'ar' ? 'الرقم الضريبي للشركة  ' : ' מספר מס חברה ')}}  
                    </th>

                    <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                    <!-- <th scope="col"> {{ curentLang === 'en' ? 'Add Company  ' : (curentLang === 'ar' ? ' اضافة شركة ' : 'הוסף חברה ')}}</th> -->
                    <!-- <th scope="col"> {{ curentLang === 'en' ? 'View Companies  ' : (curentLang === 'ar' ? 'عرض الشركات ' : ' לצפות בחברות ')}}</th> -->

                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th> -->
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  לצפות   ')}}</th> -->
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th> -->
                   <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th> 

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of accountingOfficeCompanyData  | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total} |filter:term ;let i = index">
                  <td scope="row">{{i+1}}</td>
                  <td>{{item?.office?.office_name.substring(0, 20)}}</td>

                   <td>{{item?.office?.office_address}}</td>
                   <td> 
                    {{curentLang === 'en' ? item?.company?.company_name_en : (curentLang === 'ar' ? item?.company?.company_name_ar : item?.company?.company_name_he)}}
                  </td>
                   <td>{{item?.company?.company_address_str}}</td>
                   <td>{{item?.company?.tax_register}}</td>
                   
                    <td>{{ item.createdDate | date: 'dd/MM/yyyy' }}</td>

                    <!-- <td>
                        <i class="fa fa-plus-circle" style="color: rgb(0, 0, 0);   cursor: pointer;"
                        (click)="getId(item.office_id);openXl2(content2)"></i>
                      </td> -->

                      <!-- <td>
                        <i class="fa fa-building-o" style="color: rgb(0, 0, 0);   cursor: pointer;"
                        routerLink="/dashboard/accounting-offices-companies/{{item.office_id}}"></i>
                      </td> -->
<!-- 
                   <td>

                 
                    <button  *ngIf="item.office_status==1" class=" btn" style="border: none;" (click)="changeStatus(item?.office_id, item.office_status)">
                        <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                    </button> 

                    <button  *ngIf="item.office_status==59" class=" btn"  style="border: none;" (click)="changeStatus(item?.office_id, item.office_status)">
                        <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                    </button> 

                  </td> -->

                   

                  <!-- <td>
                    <button class=" btn" style="border: none;" (click)="executeFunctionsView(  item?.office_id,viewData ) ">
                        <i class="fa fa-eye" style="color: grey;   cursor: pointer;"></i>
                    </button>
                  </td> 

                  <td >
                  <button  class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.office_id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td> -->

                  <td >
                    <button 
                    [ngStyle]="{ 'pointer-events': item.customer_status == 1 ? 'none' : 'auto', 'color': item.customer_status == 1 ? 'gray' : 'red' }" 
                    class="btn" 
                    style="border: none;" 
                    (click)="getId(item?.permission_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
                     </td> 

                </tr>
          
              </tbody>
            </table>
            <div class="pagination-container">
                <div *ngIf="accountingOfficeCompanyData?.length == 0" class="alert text-danger mt-3">
                    <span> {{ curentLang === 'en' ? 'There are no companies for this accounting office yet... ' : (curentLang === 'ar' ? ' لا توجد شركات لمكتب المحاسبة هذا حتي الأن ... ' : 'אין עדיין חברות עבור משרד רואי חשבון זה ... ')}}</span>
                </div>
                <pagination-controls 
                (pageChange)="onPageChange($event)" 
                (totalItems)="total" 
                (currentPage)="currentPage">
              </pagination-controls>
            </div>
          </div>

         

        <div class=" d-flex justify-content-between"> 
            <div></div>

           <div>
            <a routerLink="/dashboard/accounting-offices">
                <button class="btn btn-danger m-2 " >
                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                  <i class="fa fa-rotate-left mx-1"></i>
                </button>
              </a>
           </div>

          

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>



<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Adding a company to an accounting office' : (curentLang === 'ar' ? '  اضافة شركة لمكتب المحاسبة' : 'הוספת חברה למשרד רואי חשבון ')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
              <form [formGroup]="addCompanyAccountingOffice">
                <div class="row">
                  <div class="mb-3 col-xl-12 col-md-12">
                    <label class="form-label" style="color: grey;">
                      {{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה') }}
                    </label>
                    <ng-select formControlName="companyMerchant" (change)="onCompanyMerchantChange($event)" style="cursor: pointer;" [multiple]="true"
                               placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                      <ng-option *ngFor="let item of activeCompanyData" [value]="item" >
                        {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }} /
                        {{ curentLang === 'en' ? item.merchant.merchant_name_en : (curentLang === 'ar' ? item.merchant.merchant_name_ar : item.merchant.merchant_name_he) }}
                      </ng-option>
                    </ng-select>
                  </div>
                </div>
                
                <div class="modal-footer">
                  <button type="button" class="btn btn-sm m-auto mt-2" style="background-color: #141414; color: #fff;" 
                  [disabled]="addCompanyAccountingOffice.invalid"    (click)="addedAccountingOfficeCompany()">
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'تاكيد' : 'אישור') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                  </button>
                  
                  <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                    {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                    <i class="fa fa-rotate-left mx-1"></i>
                  </button>
                </div>
              </form>
              
              
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>



 <!--Delete company-->

 <ng-template #dataDeleted let-modal>
  <div class="modal-header">
      <h4 class="modal-title"></h4>
      <button type="button" class="close btn-close"  style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
        {{curentLang === 'en' ? 'Are you sure you want to delete ?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد الحذف ؟ ' : 'אתה בטוח שאתה רוצה למחוק?') }}
      </p>
  </div>
  <div class="modal-footer">

    <button type="button" 
     class="btn btn-sm m-auto mt-2" 
    style="background-color: #141414; color: #fff;"
     (click)="deleteRelatedOfficeToCompany()"
     > 
      {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
      <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
  </button>

      <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
          {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
          <i class="fa fa-rotate-left mx-1"></i>
      </button>

    

  </div>
</ng-template>