import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpHelpService } from 'src/app/Data/service/http-help.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  curentLang:string="";
  public openCart: boolean = false;
  allActivecompanyData:any;
  companyId:any;
  selectedCompanyId: number | null = null;
  selectedCompanyLogo: string | null = null;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));

  constructor(private service: HttpHelpService, private spinner: NgxSpinnerService ,) 
  {
    this.curentLang = localStorage.getItem('curentLang');
  }
  GetCompany() 
{
    this.service.GetCompany().subscribe(res => {
      this.allActivecompanyData = res;
    })
  };

  getCompanyId(id: number): void {
    this.selectedCompanyId = id;
    localStorage.setItem('companyDefault', id.toString());
    this.updateSelectedCompanyLogo();
   }

  setCompanyDefault(  company_default:any){
    this.spinner.show();
  
    let model =
    {
      "company_default": this.selectedCompanyId  ,
     "updated_by": this.userRegistId
    }
  
    this.service.setCompanyDefault(  model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        // Swal.fire({
        //   icon: 'success',
        //   // title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        // })
          this.GetCompany();
          this.spinner.hide();
          window.location.reload();

       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };

  ngOnInit() {
    this.GetCompany();
    this.userRegistId = JSON.parse(localStorage.getItem('user_id'));

    const storedCompanyId = localStorage.getItem('companyDefault');
    const storedCompanyLogo = localStorage.getItem('selectedCompanyLogo');
    if (storedCompanyId && storedCompanyLogo) {
      this.selectedCompanyId = Number(storedCompanyId);
      this.selectedCompanyLogo = storedCompanyLogo;
    }
  }

  updateSelectedCompanyLogo(): void {
    const selectedCompany = this.allActivecompanyData.find(company => company.company_id === this.selectedCompanyId);
    if (selectedCompany) {
      this.selectedCompanyLogo = selectedCompany.logo_url;
      localStorage.setItem('selectedCompanyLogo', selectedCompany.logo_url);
    } else {
      this.selectedCompanyLogo = null;
      localStorage.removeItem('selectedCompanyLogo');
    }
  }
  // For Mobile Device
  toggleCart() {
    this.openCart = !this.openCart;
  }

}
