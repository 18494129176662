<button type="button" (click)="downloadDocumentPdf()" class="btn btn-sm my-3"
    style=" background-color: #141414; color: #fff;">
    <i class="fa fa-file-pdf-o mx-1"></i>
     {{ curentLang === 'en' ? 'Export PDF' : (curentLang === 'ar' ? 'تصدير PDF' : ' הפקה pdf  ')}}
</button>
<div class="container" [hidden]="invoiceInfoData?.confirm == 0" >
    <div class="row m-auto">
        <div class="col-sm-12 ">

            <div class="page m-auto  invoice-content" style="width: 800px; ">

                <div class="card-2">

                    <div class="row m-2 ">
                        <div class="col-lg-12">
                            <div class="invoice-inner" id="invoice_wrapper">
                                <div class="invoice-top">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="logo my-2"  *ngIf="invoiceInfoData?.logo" >
                                                <img 
                                                    [src]="invoiceInfoData?.logo"
                                                     title="company logo"
                                                    style="width: 100px;height: 100px; border-radius: 5%;">
                                            </div>
                                        </div>

                                        <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 300 || user_type == 301">
                                            <h2 class="text-center water-mark">
                                                {{ curentLang === 'en' ? ' Accounting Copy' : (curentLang === 'ar' ? 'نسخة المحاسب' : ' עותק רואה חשבון')}}
                                            </h2>
                                        </div>

                                        <div [class.py-5]="invoiceInfoData?.logo" [class.py-2]="!invoiceInfoData?.logo" class="col-sm-4" *ngIf="user_type == 200 || user_type == 201">
                                            <h2 class="text-center water-mark">{{invoiceInfoData?.copy_version}}</h2>
                                        </div>
                                        
                                        <div class="col-sm-4">
                                     
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice-info py-2">
                                    <div class="row">
                                       
                                        <div class="col-sm-6 mb-30">
                                            <div class="invoice-number ">
                                                <h4 class="inv-title-1" style="font-weight: bold; font-size: 14px;">{{ curentLang === 'en' ? 'For Hon' : (curentLang === 'ar' ? '   لحضرة' : 'לכבוד  ')}}</h4>
                                                <div class="invo-addr-1">
                                                    <div style="font-weight: 400;">
                                                        <!-- <strong> {{ curentLang === 'en' ? 'Customer Name' : (curentLang === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} / </strong> -->
                                                        <strong>{{ invoiceInfoData?.customer_name }}</strong>
                                                    </div>
                                                    
                                                    <div *ngIf="invoiceInfoData?.customer_address; else noAddress" style="font-weight: 300;" class="m-0">
                                                        <strong>{{ invoiceInfoData?.customer_address }}</strong>
                                                    </div>
                                                    
                                                    <ng-template #noAddress>
                                                        <div style="font-weight: 300;">
                                                            <strong>{{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'مشغل رقم' : '     מס עוסק מורשה ' )}} </strong>
                                                            <div style="font-weight: 300;">
                                                                <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    
                                                    <div *ngIf="invoiceInfoData?.customer_address" style="font-weight: 300;">
                                                        <strong>{{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'مشغل رقم' : '    מס עוסק מורשה ')}} </strong>
                                                        <div style="font-weight: 300;">
                                                            <strong>{{ invoiceInfoData?.customer_vat_number }}</strong>
                                                        </div>
                                                    </div>
                                                    

                                                    
                                                    <div style="font-weight: 300;">
                                                        <!-- <strong>{{ curentLang === 'en' ? 'Customer Id Number' : (curentLang === 'ar' ? '  رقم الهوية للعميل' : '  מספר זיהוי לקוח ')}} / </strong> -->
                                                        <strong>{{ invoiceInfoData?.customer_id_number }}</strong>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div class="col-sm-6 mb-30">
                                            <div class="invoice-number text-end">
                                                <!-- <h4 class="inv-title-1"> {{ curentLang === 'en' ? 'Invoice From' : (curentLang === 'ar' ? 'فاتورة من' : 'חשבונית מ')}} </h4> -->
                                                <div class="invo-addr-1">
                                                    <div style="font-weight: bold;">
                                                        <!-- <strong>{{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה')}} / </strong>  -->
                                                        <strong>{{ invoiceInfoData?.company_name }}</strong>
                                                    </div>
                                                    
                                                    <div style="font-weight: 300;">
                                                        <!-- <strong> {{ curentLang === 'en' ? 'Company Address' : (curentLang === 'ar' ? 'عنوان الشركة' : 'כתובת החברה')}} / </strong> -->
                                                        <strong>{{ invoiceInfoData?.company_address_str }} -</strong>
                                                        <strong>{{ invoiceInfoData?.company_Building_number }} -</strong>

                                                        
                                                        <strong>{{ invoiceInfoData?.company_address_city }}</strong>
                                                    </div>
                                                    
                                                  
                                                    <!-- <div style="font-weight: 400;">{{ invoiceInfoData?.company_address_zip_code }}</div> -->
                                                    <!-- <div style="font-weight: 300;">
                                                        <strong>{{ curentLang === 'en' ? 'Company Vat Number' : (curentLang === 'ar' ? '  الرقم الضريبي للشركة' : 'מספר מס חברה')}} / </strong>
                                                        <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                    
                                                    </div> -->

                                                    <div  style="font-weight: 300;"> 
                                                        <strong>{{ curentLang === 'en' ? 'Company Vat Number' : (curentLang === 'ar' ? 'مشغل رقم' : '   מס עוסק מורשה       ')}}  </strong> 
                                                   </div>
                                                   <div  style="font-weight: 300;"> 
                                                   <strong>{{ invoiceInfoData?.company_vat }}</strong>
                                                   </div>
                                                  

                                                  
                                                    
                                                </div>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                    <div class="row  ">
                                        <div class="col-sm-4"></div>

                                        

                                        <div class="invoice text-center   col-sm-4">
                                            <!-- <h4 class="inv-title-1">{{ curentLang === 'en' ? 'Invoice Details' : (curentLang === 'ar' ? 'تفاصيل الفاتورة' : ' פרטי החשבונית  ')}}</h4> -->

                                       

                                            <div class="mb-1"*ngIf="invoiceInfoData?.document_number !== null">
                                           <strong *ngIf="invoiceInfoData?.invoice_type == 305" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? 'Tax Invoice Number' : (curentLang === 'ar' ? 'فاتورة ضريبية رقم' : '  חשבונית מס מספר       ') }}
                                          </strong> 

                                          <strong *ngIf="invoiceInfoData?.invoice_type == 320" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? 'Invoice With Receipt Number' : (curentLang === 'ar' ? 'فاتورة مع  ايصال رقم' : 'חשבונית עם מספר קבלה') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 332" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? ' Financial Request Number' : (curentLang === 'ar' ? 'مطالبة مالية رقم ' : ' תביעה כספית מס  ') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 400" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? 'Receipt Number' : (curentLang === 'ar' ? ' ايصال رقم' : ' מס קבלה  ') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 330" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? 'Reverce Invoice Number' : (curentLang === 'ar' ? '  فاتورة عكسية رقم  ' : '    חשבונית מס זיכוי קבלה') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 100" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? '    order' : (curentLang === 'ar' ? 'طلبية    ' : '   לְהַזמִין   ') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 200" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? '    mission' : (curentLang === 'ar' ? 'ارسالية    ' : '   מְשִׁימָה   ') }}
                                         </strong> 

                                         <strong *ngIf="invoiceInfoData?.invoice_type == 000" style="font-weight: bold;" class="mx-1">
                                            {{curentLang === 'en' ? '    Offer Price' : (curentLang === 'ar' ? 'عرض سعر     ' : ' מחיר הצעה ') }}
                                         </strong> 

                                         <strong style="font-weight: 400; color: black;">{{invoiceInfoData?.document_number}}</strong>


                                            
                                        </div>
                                        <div [hidden]="invoiceInfoData?.confirmation_number == null"  style="font-weight: 300;color: black;">
                                            <strong style="font-weight: bold;" class="mx-1">{{ curentLang === 'en' ? 'Confirmation Number' : (curentLang === 'ar' ? ' رقم تخصيص' : 'מספר הקצאה')}} : </strong> 
                                           <strong>{{ invoiceInfoData?.confirmation_number }}  </strong>
                                       </div>
                                           
                                           
                                        </div>

                                        <div class="col-sm-4    text-end">
                                            <!-- <h4 class="inv-title-1">{{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' التاريخ  ' : ' תאריך  ')}}</h4> -->
                                           
                                                 <div class="mb-0" style="font-weight: bold;">
                                                    {{ curentLang === 'en' ? ' Date' : (curentLang === 'ar' ? 'تاريخ ' : 'תאריך ')}}
                                                </div> 
                                                <div style="font-weight: 400; color: black;">{{ invoiceInfoData?.invoice_date| date:'dd/MM/yyyy' }}</div>
                                        </div>


                                    </div>
                                </div>
                                <div class="order-summary" *ngIf="invoiceInfoData?.invoice_type !== 400" >
                                    <div class="table-responsive">
                                        <table class="table invoice-table">
                                            <thead class="bg-active" style="font-weight: bold;">
                                                <tr>
                                                    <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLang === 'en' ? 'Item Name' : (curentLang === 'ar' ? 'اسم الصنف' : 'פריט  ')}}</th>
                                                    <!-- <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLang === 'en' ? 'Description' : (curentLang === 'ar' ? 'الوصف' : 'תיאור')}}</th> -->
                                                    <!-- <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLang === 'en' ? 'Measure Unit' : (curentLang === 'ar' ? 'وحدة القياس' : 'יחידת מידה')}}</th> -->
                                                    <th
                                                        class="text-center" style="font-weight: bold;"> {{ curentLang === 'en' ? 'Price' : (curentLang === 'ar' ? 'السعر  ' : ' מחיר  ')}}</th>
                                                    <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLang === 'en' ? 'Quantity' : (curentLang === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                    <th
                                                        class="text-center" style="font-weight: bold;">{{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'الخصم' : 'הנחה')}}</th>
                                                    <th class="text-center" style="font-weight: bold;">{{ curentLang === 'en' ? 'Total' : (curentLang === 'ar' ? 'المجموع' : 'סה"כ')}}</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor=" let item of invoiceInfoData?.lines">
                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.item_name}}</td>  
                                                    
                                                    <!-- <td  class="text-center  description-column"style="font-weight: 400; color: black;">{{item?.description}}</td>

                                                    <td class="text-center  description-column" style="font-weight: 400; color: black;">{{item?.measure_unit_description}}</td> -->

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.price_per_unit | number:'1.2-2'}}</td>

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.quantity}}</td>

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.discount}}</td>

                                                    <td class="text-center" style="font-weight: 400; color: black;" >{{item?.sub_total | number:'1.2-2'}}</td>

                                                </tr>

                                            </tbody>
                                        </table>

                                      

                                    </div>
                                    <div class="small-table my-4">
                                        <div class="row">
                                            <div class="col-md-12  ">
                                                <div class="d-flex justify-content-between" style="background-color: #F1F1F1;">
                                                    <div class="col-md-6  p-2 ">
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLang === 'en' ? 'Amount Before Discount:' : (curentLang === 'ar' ? 'المبلغ قبل الخصم' : '   מחיר לפני הנחה    ')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLang === 'en' ? 'Discount:' : (curentLang === 'ar' ? 'الخصم' : 'הנחה')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLang === 'en' ? 'Payment Amount:' : (curentLang === 'ar' ? '   المبلغ المدفوع    ' : '        סה"כ    ')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1">{{ curentLang === 'en' ? 'VAT Amount:' : (curentLang === 'ar' ? '       المبلغ الضريبي    ' : 'מע"מ')}}</div>
                                                        <div style="width: 300px; font-weight: bold;" class="m-1"> {{ curentLang === 'en' ? 'Payment Amount Including VAT:' : (curentLang === 'ar' ? ' المبلغ المدفوع شامل الضريبة ' : 'סה"כ לשתלום')}}</div>

                                                    </div>

                                                 

                                                    <div class="col-md-6 text-end px-3 p-2">
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.amount_before_discount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.all_discount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">({{invoiceInfoData?.vat_percentage}} %)   {{invoiceInfoData?.all_vat_amount | number:'1.2-2'}}</div>
                                                        <div style="font-weight: 400; color: black;" class="my-1">{{invoiceInfoData?.payment_amount_including_vat | number:'1.2-2'}}</div>

                                                    </div>
                                                   
                                                 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    

                                </div>


                                  <div class="order-summary  " *ngIf="invoiceInfoData?.invoice_type == 320 || invoiceInfoData?.invoice_type == 400">

                                    <div class="col-lg-12 " >
                                        <ng-container  >
                                            <div class="mt-4 d-flex justify-content-between">
                                                <span style="font-weight: bold; " class="mx-2">{{ curentLang === 'en' ? 'Payment Info' : (curentLang === 'ar' ? 'بيانات الدفع  ' : ' נתוני תשלום')}} </span>
                                                <!-- <span style="font-weight: 500;" class="mx-3">{{ receiptInfoData.checks_amount | number:'1.0-0' }}</span> -->
                                            </div>  
                                    
                                            <div class="table-responsive ">
                                                <table class="table invoice-table">
                                                    <thead>
                                                    
                                                        <tr>
                                                            <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Payment Method ' : (curentLang === 'ar' ? 'طرق الدفع  ' : 'אמצעי תשלום')}}
                                                            </th>
                                                            <!-- <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Ref' : (curentLang === 'ar' ? 'مرجع  ' : 'רפ')}}</th> -->
                                                            <!-- <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Bank' : (curentLang === 'ar' ? 'بنك  ' : 'בַּנק')}}
                                                            </th> -->
                                                            <!-- <th
                                                                class="text-center" style="font-weight: bold;" > {{ curentLang === 'en' ? 'Branch' : (curentLang === 'ar' ? 'فرع  ' : 'סניף')}}
                                                            </th>
                                                            
                                                            <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Account' : (curentLang === 'ar' ? 'حساب  ' : 'חֶשְׁבּוֹן')}}
                                                            </th> -->
                    
                                                            <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Details' : (curentLang === 'ar' ? 'تفاصيل  ' : 'פרטים')}}</th>
                                                                <th
                                                                class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ  ' : ' תאריך')}}</th>
                                                            <th class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Total' : (curentLang === 'ar' ? 'المجموع  ' : 'סכום')}}</th>
                    
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor=" let item of invoiceInfoData?.receiptsDetails">
                                                            <td class="text-center" style="font-weight: 400; color: black;">
                                                                <span *ngIf=" item?.payment_type == 1"> {{ curentLang === 'en' ? 'Cash' : (curentLang === 'ar' ? 'كاش' : 'כַּךָ')}}</span>
                                                                <span *ngIf=" item?.payment_type == 2"> {{ curentLang === 'en' ? 'Check' : (curentLang === 'ar' ? 'شيك' : 'סִּכָּה')}}</span>
                                                                <span *ngIf=" item?.payment_type == 3"> {{ curentLang === 'en' ? 'Bank' : (curentLang === 'ar' ? 'بنك' : 'בַּנק')}}</span>
                                                                <span *ngIf=" item?.payment_type == 4"> {{ curentLang === 'en' ? 'Credit' : (curentLang === 'ar' ? 'ائتمان' : 'קִרְּדָּן')}}</span>
                                                            </td>
                                                            <!-- <td class="text-center">{{ item.bank }}</td>
                                                            <td class="text-center">{{ item.branch }}</td>
                                                            <td class="text-center">{{ item.account }}</td>
                                                             -->
                    
                                                            <td class="text-center description-column" style="font-weight: 400; color: black;">{{ item.details }}</td>
                                                            <td class="text-center" style="font-weight: 400; color: black;">{{ item.date | date: 'yyyy-MM-dd' }}</td>
                                                            <td class="text-center" style="font-weight: 400; color: black;">{{ item.amount | number:'1.2-2'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ng-container>
                                    </div>


              

                <div class="small-table my-4" >
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-between" >
                            <div class="col-md-6 mt-5">
                              <!-- <span>Weighted date: 05/05/24</span> -->
                             </div>
                             <div class="col-md-6">
                                <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                                    <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                        <span class="py-1"> {{ curentLang === 'en' ? 'Deduction at source' : (curentLang === 'ar' ? 'الخصم من المصدر  ' : 'ניכוי במקור  ')}} :  </span>
                                    </div>

                                    <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                        <span class="mx-1">{{invoiceInfoData.deduction_from_source | number:'1.0-0'}}</span>
                                    </div>

                                    <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                        <span>{{ curentLang === 'en' ? 'Total' : (curentLang === 'ar' ? 'المجموع' : ' סה"כ ')}}  :  </span>
                                    </div>

                                    <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                        <span class="mx-1">{{ invoiceInfoData.total_amount | number:'1.2-2' }}</span>

                                    </div>


                                </div>
                                
                             </div>

                             <div class="col-md-6">

                             </div>
                        </div>
                    </div>
                </div>
                
                
                
                <!-- <div class=" d-flex justify-content-between"> 

                    <button type="button"  hidden
                    class="btn   m-2" 
                   style="background-color: #141414; color: #fff;"
                    
                    > 
                     {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? '     تحديث ' : '     עדכן  ') }}
                     <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                 </button>
            
                   

                        <img src="../../../../assets/images/logo/thi.png"  class="  m-2 "  alt="">
                  
                      
                    
            
                  </div> -->
                
            </div>
                                    
                                    
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


            



        </div>

        

    </div>




    <!--back-->
    <div class=" d-flex justify-content-between"> 

        <button type="button"  hidden
        class="btn   m-2" 
       style="background-color: #141414; color: #fff;"
        
        > 
         {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? '     تحديث ' : '     עדכן  ') }}
         <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
     </button>

        <a routerLink="/dashboard/company-documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 300 || user_type == 301">
          <button class="btn btn-danger m-2 " >
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
          </button>
        </a>

        <a routerLink="/dashboard/documents/{{invoiceInfoData?.company_uuid}}" *ngIf="user_type == 200 || user_type == 201">
            <button class="btn btn-danger m-2 " >
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
            </button>
          </a>

      </div>