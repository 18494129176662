<app-breadcrumb [title]="curentLang === 'en' ? 'Merchant Info' : (curentLang === 'ar' ? ' معلومات التاجر  ' : '   פרטי בעל העסק/החברה')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['   לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Merchant Info' : (curentLang === 'ar' ? ' معلومات التاجر  ' : '   פרטי בעל העסק/החברה')">
</app-breadcrumb>



<!-- Merchant info -->
 
<div class="container-fluid basic_table" [hidden]="user_type == 100 || user_type == 101">
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;">
          {{ curentLang === 'en' ? 'Merchant Info' : (curentLang === 'ar' ? '  معلومات التاجر ' :'   פרטי בעל העסק/החברה')}}
      </h5> 
        <div>

          <button [hidden]="user_type == 100" class="btn" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
          {{ curentLang === 'en' ? 'Add Merchant' : (curentLang === 'ar' ? '  اضافة تاجر '  : '  הוספת בעל עסק     ')}}
        </button>

      <button class="btn mx-1 btn-outline-warning"  (click)="getAllMerchantInfo()">
        <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>
        </div>
        
     
      </div>

      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>
      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive" >
            <table class="table table-light text-center table-hover   " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Merchant Name' : (curentLang === 'ar' ? '  أسم التاجر  ' : '  שם הסוחר')}} </th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Merchant Address' : (curentLang === 'ar' ? ' عنوان التاجر' : '   כתובת הסוחר')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'ID Number' : (curentLang === 'ar' ? '   رقم الهوية' : '  מספר תעודת זהות ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Phone Number' : (curentLang === 'ar' ? '   رقم الهاتف' : '  מספר הטלפון')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Merchant Status' : (curentLang === 'ar' ? ' حالة التاجر' : '     סטטוס')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Subscription Status' : (curentLang === 'ar' ? '   حالة الأشتراك' : 'ססטס ההרשמה')}}</th>
              
                  <th scope="col"> {{ curentLang === 'en' ? 'Plan Name' : (curentLang === 'ar' ? '  اسم الخطة' : '  החבילה  ')}} </th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמה  ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'Merchant Users' : (curentLang === 'ar' ? ' مستخدمين التاجر ' : 'משתמשי טריידר')}}</th>

                  <th scope="col"> {{ curentLang === 'en' ? 'View' : (curentLang === 'ar' ? '   عرض' : '  הצגה   ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  עריכה ')}}</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of merchantInfoData | paginate: {itemsPerPage:100, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> {{curentLang === 'en' ? item.merchant_name_en.substring(0, 20) : (curentLang === 'ar' ? item.merchant_name_ar.substring(0, 20) : item.merchant_name_he.substring(0, 20))}}</td>
                  <td> {{item.merchant_address.substring(0, 20)}}</td>
                  <td>{{item.id_number}}</td>
                  <td>{{item?.users[0]?.phone_number}}</td>
                  <td *ngIf="item.lookupDetailsStatus?.status === 1" style="color:#54BA4A;"   > 
                     {{ curentLang === 'en' ? item.lookupDetailsStatus?.lookupDetailEnName : (curentLang === 'ar' ? item.lookupDetailsStatus?.lookupDetailArName : item.lookupDetailsStatus?.lookupDetailHeName)}}
                  </td>

                  <td *ngIf="item.lookupDetailsStatus?.status === 59" style="color: #FC4438;"   > 
                    {{ curentLang === 'en' ? item.lookupDetailsStatus?.lookupDetailEnName : (curentLang === 'ar' ? item.lookupDetailsStatus?.lookupDetailArName : item.lookupDetailsStatus?.lookupDetailHeName)}}
                 </td>

                 <td *ngIf="item.lookupDetailsSubscribe?.lookup_detail_id === 99" style="color:#54BA4A;"   > 
                  {{ curentLang === 'en' ? item.lookupDetailsSubscribe?.lookupDetailEnName : (curentLang === 'ar' ? item.lookupDetailsSubscribe?.lookupDetailArName : item.lookupDetailsSubscribe?.lookupDetailHeName)}}
               </td>

               <td *ngIf="item.lookupDetailsSubscribe?.lookup_detail_id === 90" style="color: #FC4438;"   > 
                 {{ curentLang === 'en' ? item.lookupDetailsSubscribe?.lookupDetailEnName : (curentLang === 'ar' ? item.lookupDetailsSubscribe?.lookupDetailArName : item.lookupDetailsSubscribe?.lookupDetailHeName)}}
              </td>
              <td> {{
                curentLang === 'en' ? 
                  (item.subscribe_plan_details.length > 0 ? item.subscribe_plan_details[0]?.plan_name_en : 'No Data') :
                (curentLang === 'ar' ? 
                  (item.subscribe_plan_details.length > 0 ? item.subscribe_plan_details[0]?.plan_name_ar : ' لا يوجد بيانات') :
                  (item.subscribe_plan_details.length > 0 ? item.subscribe_plan_details[0]?.plan_name_he : ' אין מידע'))
              }}</td>
              
              <td>{{ item.createdDate  | date: 'dd/MM/yyyy'}}</td>
              <td>
                <button [disabled]="user_type == 100 || user_type == 101"
                 *ngIf="item.merchant_status==1" class=" btn" style="border: none;" 
                 (click)="changeStatus(item?.merchant_id, item.merchant_status)">
                    <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                </button> 

                <button [disabled]="user_type == 100 || user_type == 101"
                 *ngIf="item.merchant_status==59" class=" btn" 
                (click)="changeStatus(item?.merchant_id, item.merchant_status)">
                    <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                </button> 

              </td>

                  

                  <td>
                    <i class="fa fa-user-circle-o" style="color: rgb(0, 0, 0);  font-size: 15px; cursor: pointer;"
                    routerLink="/dashboard/all-merchant-users/{{item.merchant_id}}" ></i>
                  </td>

                  <td>
                    <i class="fa fa-eye" style="color: grey;   cursor: pointer;"
                     routerLink="/dashboard/view-merchant/{{item.merchant_id}}"></i>
                  </td>

                  <td >
                  
                    <button  class="btn" style="border: none;">
                      <i class="fa fa-edit" style=" cursor: pointer; color: green;"
                       routerLink="/dashboard/update-merchant/{{item.merchant_id}}"></i>
                    </button>
                  </td>
                  
                  

                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
      </div>
    </div>
  </div>

    </div>
</div>

 


<!--add Merchant-->

<ng-template #content let-modal>
  <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
        {{ curentLang === 'en' ? 'Add Merchant' : (curentLang === 'ar' ? '  اضافة تاجر' : '   הוספת בעל עסק')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <div class="container-fluid ">
          <div class="edit-profile">
            <div class="row d-flex justify-content-between">
              <form class="theme-form" [formGroup]="merchantForm" novalidate [ngClass]="{ 'was-validated': validate }">
              
                <div class="form-group ">

                  <div class="row">

                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                      <label class="col-form-label pt-0" style="color: #141414;">{{'Register.name'|translate}}<span class="text-danger">*</span></label>
                      <input class="form-control" type="text" maxlength="20" required="" onlyAlphabets
                        placeholder="{{'Register.name'|translate}}" formControlName="user_name">
                      <div *ngIf="merchantForm.get('user_name')?.errors != null && merchantForm.get('user_name')?.touched" class=" alert text-danger  my-1">
                        <p  *ngIf="merchantForm.get('user_name')?.errors?.required" class=" my-1 text-danger"> {{'Register.nameuserRequierd'|translate}}</p>
                        <p *ngIf="merchantForm.get('user_name')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                        <p *ngIf="merchantForm.get('user_name')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                       </div>
                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter user name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم المستخدم ' : ' אנא הזן שם משתמש ')}}  </div>

                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                      <label class="col-form-label pt-0" style="color: #141414;">{{'Register.phone'|translate}}<span class="text-danger">*</span> </label>     <div class="input-group">
                        <span style="color: #141414;" class="input-group-text">+972</span>
                        <input class="form-control" type="text" maxlength="10" required="" onlyAlphabets
                         placeholder="{{'Register.phone'|translate}}" formControlName="phone_number">
                      </div>
                      <div *ngIf="merchantForm.get('phone_number')?.errors != null && merchantForm.get('phone_number')?.touched" class="alert text-danger my-1">
                        <p *ngIf="merchantForm.get('phone_number')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                        <p *ngIf="merchantForm.get('phone_number')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{merchantForm.get('phone_number')?.errors?.invalidMobilePrefix.message}}</p>
                        <p *ngIf="merchantForm.get('phone_number')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{merchantForm.get('phone_number')?.errors?.invalidPhoneNumber.message}}</p>
                      </div>
                      <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter phone number' : (curentLang === 'ar' ? '   الرجاء ادخال رقم الهاتف ' : ' אנא הזן מספר טלפון ')}}  </div>
                    </div>

                    
                <!---->
<div class="col-lg-4 col-md-6 col-sm-12">
  <div class="form-group  ">
      <label class="col-form-label pt-0">{{ curentLang === 'en' ? 'Select Plan Name' : (curentLang === 'ar' ? 'اختر اسم الخطة' : 'בחר שם תוכנית  ')}}
        <span class="text-danger">*</span>
      </label>
      <ng-select formControlName="plan_id" (change)="filter($event)" style="cursor: pointer;" onlyAlphabets required=""
 
      placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
      <ng-option *ngFor="let item of planData; let i = index" [value]="item.plan_id">
        {{ curentLang === 'en' ? item.plan_name_en : (curentLang === 'ar' ? item.plan_name_ar : item.plan_name_he) }} /
        {{ curentLang === 'en' ? 'Document Number' : (curentLang === 'ar' ? 'عدد المستندات' : 'מספר מסמכים') }} =
        ({{item.number_of_documents}})
      </ng-option>

       </ng-select>
       <div *ngIf="merchantForm.get('plan_id')?.errors?.required" class="my-1 text-danger">
       <p>{{ curentLang === 'en' ? 'please choose plan' : (curentLang === 'ar' ? 'الرجاء اختيار الخطة' : 'אנא בחר תוכנית') }}</p> 
       </div>
      
  </div>
 


</div>
                  </div>
                </div>
                <!---->

 <!---->
                <div class=" row">
                 <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="form-group">
                  <label class="col-form-label"style="color: #141414;">{{'Register.merchant_nameAr'|translate}}</label>
                  <input class="form-control" type="text" maxlength="80"   placeholder="{{'Register.merchant_nameAr'|translate}}" formControlName="merchant_name_ar">
                  <div *ngIf="merchantForm.get('merchant_name_ar')?.errors != null && merchantForm.get('merchant_name_ar')?.touched" class=" alert text-danger  my-1">
                    <!-- <p  *ngIf="merchantForm.get('merchant_name_ar')?.errors?.required" class=" my-1 text-danger"> {{'Register.merchant_name_ar'|translate}}</p> -->
                    <p *ngIf="merchantForm.get('merchant_name_ar')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                    <p *ngIf="merchantForm.get('merchant_name_ar')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                   </div>
                </div>
                </div>
                    <!---->
                <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="col-form-label"style="color: #141414;">{{'Register.merchant_nameEn'|translate}}</label>
        <input class="form-control" type="text"   maxlength="80" placeholder="{{'Register.merchant_nameEn'|translate}}" formControlName="merchant_name_en">
        <div *ngIf="merchantForm.get('merchant_name_en')?.errors != null && merchantForm.get('merchant_name_en')?.touched" class=" alert text-danger  my-1">
          <!-- <p  *ngIf="merchantForm.get('merchant_name_en')?.errors?.required" class=" my-1 text-danger"> {{'Register.merchant_name_ar'|translate}}</p> -->
          <p *ngIf="merchantForm.get('merchant_name_en')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
          <p *ngIf="merchantForm.get('merchant_name_en')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
         </div>
      </div>
                </div>
            <!---->
            <div class="col-lg-4 col-md-6 col-sm-12">

            <div class="form-group">
              <label class="col-form-label"style="color: #141414;">{{'Register.merchant_nameHe'|translate}}<span class="text-danger">*</span></label>
              <input class="form-control" type="text" maxlength="80" required="" onlyAlphabets placeholder="{{'Register.merchant_nameHe'|translate}}" formControlName="merchant_name_he">
              <div *ngIf="merchantForm.get('merchant_name_he')?.errors != null && merchantForm.get('merchant_name_he')?.touched" class=" alert text-danger  my-1">
                <p  *ngIf="merchantForm.get('merchant_name_he')?.errors?.required" class=" my-1 text-danger"> {{'Register.merchant_name_ar'|translate}}</p>
                <p *ngIf="merchantForm.get('merchant_name_he')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                <p *ngIf="merchantForm.get('merchant_name_he')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
               </div>
               <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter merchant name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم التاجر  ' : ' אנא הזן שם שם  ')}}  </div>

            </div>
            </div>

                 <!---->
                 <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                  <div class="form-group">
                    <label class="col-form-label"style="color: #141414;">{{'Register.merchantadress'|translate}} <span class="text-danger">*</span> </label>
                   <input class="form-control" type="text" required="" onlyAlphabets maxlength="400" placeholder="{{'Register.merchantadress'|translate}}" formControlName="merchant_address">
                   <div *ngIf="merchantForm.get('merchant_address')?.errors != null && merchantForm.get('merchant_address')?.touched" class=" alert text-danger  my-1">
                    <p  *ngIf="merchantForm.get('merchant_address')?.errors?.required" class=" my-1 text-danger"> {{'Register.merchant_address'|translate}}</p>
                    <p *ngIf="merchantForm.get('merchant_name_he')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error3'|translate}}</p>

                   </div>
                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter merchant address' : (curentLang === 'ar' ? '   الرجاء ادخال عنوان التاجر  ' : '  נא להזין את כתובת הסוחר     ')}}  </div>

                 </div>
                 </div>
                      <!---->
                      <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                        <div class="form-group">
                          <label class="col-form-label"style="color: #141414;">
                            {{ curentLang === 'en' ? '  id number ' : (curentLang === 'ar' ? '    رقم الهوية   ' : '  מס׳ זהות   ')}}  
                          </label>
                         <input class="form-control" type="number"  
                          maxlength="20"   placeholder="{{'Register.id_number'|translate}}" formControlName="id_number">
                         <div *ngIf="merchantForm.get('id_number')?.errors != null && merchantForm.get('id_number')?.touched" class=" alert text-danger  my-1">
                          <p  *ngIf="merchantForm.get('id_number')?.errors?.required" class=" my-1 text-danger"> {{'Register.id_numberreq'|translate}}</p>
                         </div>
                       </div>
                       </div>

                         <!---->
                      <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                       <div class="form-group">
                        <label class="col-form-label"style="color: #141414;">{{'Register.password'|translate}} <span class="text-danger">*</span></label>
                        <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" onlyAlphabets
                         required=""
                         minlength="8"
                         maxlength="20"
                         placeholder="*********" 
                        formControlName="password">
                        <div *ngIf="merchantForm.get('password')?.errors != null && merchantForm.get('password')?.touched" class=" alert text-danger my-1">
                          <p  *ngIf="merchantForm.get('password')?.errors?.required" class=" "> {{'Login.error18'|translate}}</p>
                                  <ul  *ngIf="merchantForm.get('password')?.errors?.pattern && merchantForm.get('password')?.touched" class="text-start"> 
                                      <li>{{'Login.error19'|translate}}</li>
                                      <li>{{'Login.error20'|translate}}</li>
                                      <li>{{'Login.error21'|translate}}</li>
                                      <li>{{ curentLang === 'en' ? 'Password does not match the criteria' : (curentLang === 'ar' ? 'كلمة المرور غير مطابقة للمعايير' : 'הסיסמה אינה תואמת את הקריטריונים') }}

                                  </ul>
                           </div>
                              
                           
                            <div class="show-hideAr" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                            <div class="show-hideAr" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                     
                            <!-- <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                            <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div> -->
                        
                       

                      </div>
                      </div>

                </div>
             
              
           
            
            

               
                <div class="modal-footer">
                  <button type="button" [disabled]="!merchantForm.valid"
                   class="btn btn-sm m-auto mt-2" 
                  style="background-color: #141414; color: #fff;"
                  [disabled]="merchantForm.get('password').invalid"
                   (click)="submitMerchantForm(merchantForm)"
                   > 
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                </button>
            
                    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                    </button>
            
                </div>
                 
               </form>
          </div>
          
        </div>
      
      </div>
  </div>
 
</ng-template>


<!-- Merchant info -->

<div class="container-fluid" [hidden]="user_type == 200 || user_type == 201">
  <div class="user-profile">
    <div class="row">
      <!-- user profile first-style start-->
      <div class="col-sm-12">
        <div class="card hovercard text-center ">
         
          <div class="user-image mt-5">
            <div class="avatar">
              <img  src="../../../assets/icons/apple-touch-icon.png" alt="">
           <!--   <img [src]="profileData?.image_url ? profileData?.image_url : '../../../assets/icons/apple-touch-icon.png'" alt="">-->
            </div>
           <!--     <div class="icon-wrapper">
              <i (click)="executeFunctions(profileData?.user_id, uploadData);" class="icofont icofont-pencil-alt-5" style="color: #141414;"></i>
            </div>-->
          </div>
          <div class="info" *ngFor="let item of merchantInfoData">
            <div class="row">
            
            
              <div class="col-6 col-md-4 order-sm-0 order-xl-1 m-auto" style="color: #141414;">
                
                  <div class="title  " style="font-size: 14px;">
                   
                      <h6 style="font-size: 14px;">
                        {{ curentLang === 'en' ? 'Phone Number ' : (curentLang === 'ar' ? 'رقم الهاتف' : ' מספר טלפון  ')}}
                        {{ MerchantPhone }}</h6>
                      <h6   style="font-size: 14px;">
                        {{ curentLang === 'en' ? item.merchant_name_en.substring(0, 20) : (curentLang === 'ar' ? item.merchant_name_ar.substring(0, 20) : item.merchant_name_he.substring(0, 20)) }}
                    </h6>
                   

                  </div>
               
              </div>
            
             
            </div>
            
            <hr />
            <div class="follow">
              <div class="row d-flex justify-content-between">
                <div class="col-4 text-md-end border-end" style="color: #141414;" >
                  <div class="follow-num"></div>
                  <span (click)="openXl3(content3)"class="small-text" style="color: rgb(0, 0, 0);   cursor: pointer;">
                     <i class="fa fa-user-circle-o" style="color: rgb(0, 0, 0);  font-size: 10px; cursor: pointer;" 
                    title="{{ curentLang === 'en' ? 'Merchant Users' : (curentLang === 'ar' ? ' مستخدمين التاجر ' : 'משתמשי טריידר')}}"
                    (click)="openXl3(content3)" ></i>
                    {{ curentLang === 'en' ? ' Users' : (curentLang === 'ar' ? ' المستخدمين  ' : '  ')}}
                  </span>
                </div>
                <div class="col-4 text-md-center" style="color: #141414;">
                  <div class="follow-num counter"></div>
                  <span routerLink="/dashboard/view-merchant/{{item.merchant_id}}" class="small-text" style="color: rgb(0, 0, 0);   cursor: pointer;">
                   
                   <i class="fa fa-eye" style="color: rgb(0, 0, 0);  font-size: 10px; cursor: pointer;" 
                     title="{{ curentLang === 'en' ? 'View info' : (curentLang === 'ar' ? '   عرض المعلومات' : ' הצג מידע')}}"
                   routerLink="/dashboard/view-merchant/{{item.merchant_id}}"></i>
                   {{ curentLang === 'en' ? 'View info' : (curentLang === 'ar' ? '   عرض المعلومات' : ' הצג מידע')}}
                 </span>
                </div>

                <div class="col-4 text-md-start" style="color: #141414;">
                  <div class="follow-num counter"></div>
                  <span routerLink="/dashboard/update-merchant/{{item.merchant_id}}"class="small-text" style="color: rgb(0, 0, 0);   cursor: pointer;">
                   
                    <i class="fa fa-edit" style="color: rgb(0, 0, 0);  font-size: 10px; cursor: pointer;" 
                      title="{{ curentLang === 'en' ? 'Update info' : (curentLang === 'ar' ? '   تعديل المعلومات ' : ' ערוך מידע')}}"
                      routerLink="/dashboard/update-merchant/{{item.merchant_id}}"></i>
                    {{ curentLang === 'en' ? 'Update info' : (curentLang === 'ar' ? '   تعديل المعلومات' : ' ערוך מידע')}}
                  </span>
                </div>
              </div>

              <hr style="color: #141414;"/>

              <div class="row">
               
                <!-- demo ribbon end here-->

                <div class="col-6 col-sm-6 col-xl-4" style="cursor: pointer;" (click)="openXl3(content3)">
                  
                  <div class="ribbon-wrapper card" >
                     <div class="card-body text-center text-white">  
                       <i class="icofont icofont-people" style="color:#fff;  font-size: 35px; cursor: pointer;"></i>
                      <p>
                        {{ curentLang === 'en' ? 'Users' : (curentLang === 'ar' ? '  المستخدمين' : 'משתמשים')}}
                      </p>
                    
                  </div>
                  </div>
                
                </div>

                <div class="col-6 col-sm-6 col-xl-4" style="cursor: pointer;" (click)="openXl2(content2)">
                  
                  <div class="ribbon-wrapper card" >
                     <div class="card-body text-center text-white">  
                       <i class="fa fa-file-text" style="color:#fff;  font-size: 35px; cursor: pointer;"></i>
                      <p>
                        {{ curentLang === 'en' ? 'Invoices' : (curentLang === 'ar' ? '  الفواتير' : 'חשבוניות')}}
                      </p>
                    
                  </div>
                  </div>
            
                </div>

                <div class="col-6 col-sm-6 col-xl-4" style="cursor: pointer;" (click)="openXl4(content4)">
                  <div class="ribbon-wrapper card" >
                    <div class="card-body text-center text-white">
                      <i class="icofont icofont-businessman" style="color:#fff;  font-size: 35px; cursor: pointer;"></i>
                     <p>
                       {{ curentLang === 'en' ? 'Accountants' : (curentLang === 'ar' ? 'المحاسبين' : 'רואי חשבון')}}
                     </p>
                   
                 </div>
                 </div>
                </div>

                <div class="col-6 col-sm-6 col-xl-4" style="cursor: pointer;"  >
                 <a routerLink="/dashboard/permanent-file"> 
                   <div class="ribbon-wrapper card" >
                     <div class="card-body text-center text-white">
                      <i class="icofont icofont-folder" style="color:#fff;  font-size: 35px; cursor: pointer;"></i>
                      <p>
                        {{ curentLang === 'en' ? 'Permanent File' : (curentLang === 'ar' ? '  الملف الموحد '  : 'קובץ מאוחד  ')}}
                      </p>
                    
                  </div>
                  </div>
                </a>
                 </div>



                <div class="col-6 col-sm-6 col-xl-4" style="cursor: pointer;"  (click)="openXl7(content7)">
                  
                  <div class="ribbon-wrapper card" >
                     <div class="card-body text-center text-white">
                      <i class="fa fa-building-o" style="color:#fff;  font-size: 35px; cursor: pointer;"></i>
                      <p>
                        {{ curentLang === 'en' ? 'Companies' : (curentLang === 'ar' ? 'الشركات' : 'חברות')}}
                      </p>
                    
                  </div>
                  </div>
                 
                </div>
              
                
               

              


                <div class="col-6 col-sm-6 col-xl-4" style="cursor: pointer;" (click)="openXl6(content6)">
                 
                  <div class="ribbon-wrapper card" >
                     <div class="card-body text-center text-white">  
                       <i class="fa fa-shopping-cart" style="color:#fff;  font-size: 35px; cursor: pointer;"></i>
                      <p>
                        {{ curentLang === 'en' ? ' Products' : (curentLang === 'ar' ? '  المنتجات' : 'מוצרים')}}
                      </p>
                    
                  </div>
                  </div>
              
                </div>

           
         
              </div>
            </div>
          </div>
        </div>
      </div>
      
   
    </div>
  </div>
</div>


  <!-- upload image-->

  <ng-template #uploadData let-modal>
    <div class="modal-header">
    <h6 class="modal-title"style="color: grey;">
          {{ curentLang === 'en' ? 'Update Profile Image ' : (curentLang === 'ar' ? ' تحديث الصورة الشخصية ' : '    עדכן תמונה אישית   ')}} 
      </h6>
    <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  
    </div>
    <div class="modal-body my-2">
     
      <div class="product-image text-center">
          <img *ngIf="currentImageUrl" [src]="currentImageUrl" alt="" class="my-2">
          <input type="file" class="form-control form-control-sm"  (change)="onFileChanged($event)" [accept]="'image/*'">
        </div>
        
              
    </div>
      <div class="modal-footer">
  
          <button type="button" 
           class="btn btn-sm m-auto mt-2" 
          style="background-color: #141414; color: #fff;"
          (click)="uploadFile()"
           > 
            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
            <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
        </button>
    
            <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
            </button>
    
          
    
        </div>
    </ng-template>






 <!-- Invoices Types-->

    <ng-template #content2 let-modal>
      <div class="modal-header d-flex justify-content-between">
          <h6 class="modal-title"style="color: grey;">
            {{ curentLang === 'en' ? 'Invoices Types' : (curentLang === 'ar' ? 'أنواع الفواتير' : 'סוגי חשבוניות')}}
          </h6>
          <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <div class="container-fluid ">
              <div class="edit-profile">
                <div class="row d-flex justify-content-between">
                 <div class="content">
                  <p routerLink="/dashboard/add-receipts" style="cursor: pointer;" (click)="modal.close('Close click')">
                    <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
                    {{ curentLang === 'en' ? 'Add receipt' : (curentLang === 'ar' ? ' اضافة ايصال ' : 'מידע קבלה')}}
                  </p>
                    <hr style="color: #141414; border: solid 1px; ">
                 </div>
                 <!---->
                 <div class="content">
                  <p routerLink="/dashboard/add-invoice" style="cursor: pointer;" (click)="modal.close('Close click')">
                    <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
                    {{ curentLang === 'en' ? 'Add Invoice' : (curentLang === 'ar' ? '  اضافة فاتورة  ' : 'הוסף חשבונית ')}}
                  </p>
                  <hr style="color: #141414; border: solid 1px; ">

                 </div>
                    <!---->
                    <div class="content">
                      <p routerLink="/dashboard/add-invoice-with-receipt" style="cursor: pointer;" (click)="modal.close('Close click')">
                        <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
                        {{ curentLang === 'en' ? 'Add Invoice And Receipt' : (curentLang === 'ar' ? ' اضافة فاتورة مع ايصال  ' : 'הוסף חשבונית וקבלה    ')}}
                      </p>
                      <hr style="color: #141414; border: solid 1px; ">

                     </div>
                       <!---->
                    <div class="content">
                      <p routerLink="/dashboard/add-finaical-request" style="cursor: pointer;" (click)="modal.close('Close click')">
                        <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
                        {{ curentLang === 'en' ? 'Add Finaical Request' : (curentLang === 'ar' ? '  اضافة مطالبة مالية  ' : 'הוסף תביעה כספית')}}
                      </p>
                      <hr style="color: #141414; border: solid 1px; ">

                     </div>
                           <!---->
                    <div class="content">
                      <p routerLink="/dashboard/add-reverce-invoice" style="cursor: pointer;" (click)="modal.close('Close click')">
                        <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
                        {{ curentLang === 'en' ? 'Add Reverce Invoice' : (curentLang === 'ar' ? '  اضافة  فاتورة عكسية  ' : ' הוסף חשבונית חזרה ')}}
                      </p>
                      <hr style="color: #141414; border: solid 1px; ">

                     </div>

                           <!---->
                    <div class="content">
                      <p routerLink="/dashboard/add-mission" style="cursor: pointer;" (click)="modal.close('Close click')">
                        <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
                        {{ curentLang === 'en' ? 'Add Mission' : (curentLang === 'ar' ? '   اضافة ارسالية  ' : ' הוסף פוסט ')}}
                      </p>
                      <hr style="color: #141414; border: solid 1px; ">

                     </div>
               
                               <!---->
                    <div class="content">
                      <p routerLink="/dashboard/add-offer-price" style="cursor: pointer;" (click)="modal.close('Close click')">
                        <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
                        {{ curentLang === 'en' ? 'Add Offer Price' : (curentLang === 'ar' ? '  اضافة عرض سعر  ' : '    הוסף הצעת מחיר  ')}}
                      </p>
                      <hr style="color: #141414; border: solid 1px; ">

                     </div>

                                     <!---->
                    <div class="content">
                      <p routerLink="/dashboard/add-order" style="cursor: pointer;" (click)="modal.close('Close click')">
                        <i class="fa fa-plus mx-1" style="color: #141414; font-size: 14px;"></i>
                        {{ curentLang === 'en' ? 'Add Order' : (curentLang === 'ar' ? '   اضافة طلبية  ' : '  הוסף הזמנה  ')}}
                      </p>
                      <hr style="color: #141414; border: solid 1px; ">

                     </div>
              </div>
              
            </div>
          
          </div>
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <div></div>
  
          <button routerLink="/dashboard/merchant-info" type="button" class="btn btn-sm  ml-auto  btn-danger" (click)="modal.close('Close click')">
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
          </button>
  
      </div>
     
    </ng-template>
    

    <!-- users Types-->

    <ng-template #content3 let-modal>
      <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title" style="color: grey;">
          {{ curentLang === 'en' ? 'Users Info' : (curentLang === 'ar' ? 'معلومات المستخدمين ' : 'מידע על סוגי חשבוניות')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- تمرير القيمة true عند استخدام app-users داخل المودال -->
        <app-users [isInsideModal]="true"></app-users>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div></div>
        <button routerLink="/dashboard/merchant-info" type="button" class="btn btn-sm ml-auto btn-danger" (click)="modal.close('Close click')">
          {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
          <i class="fa fa-rotate-left mx-1"></i>
        </button>
      </div>
    </ng-template>
    

  <!-- accountants  -->

  <ng-template #content4 let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title" style="color: grey;">
        {{ curentLang === 'en' ? 'Accountants Info' : (curentLang === 'ar' ? 'معلومات المحاسبين ' : '   מידע על מנהלי חשבוניות ')}}
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- تمرير القيمة true عند استخدام app-users داخل المودال -->
      <app-accountants [isInsideModal]="true"></app-accountants>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <div></div>
      <button routerLink="/dashboard/merchant-info" type="button" class="btn btn-sm ml-auto btn-danger" (click)="modal.close('Close click')">
        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
        <i class="fa fa-rotate-left mx-1"></i>
      </button>
    </div>
  </ng-template>
    


 
 



    <!-- products  -->

    <ng-template #content6 let-modal>
      <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title" style="color: grey;">
          {{ curentLang === 'en' ? 'Products Info' : (curentLang === 'ar' ? '  معلومات المنتجات ' : ' מידע על מוצרים ')}}
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- تمرير القيمة true عند استخدام app-users داخل المودال -->
        <app-products [isInsideModal]="true"></app-products>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div></div>
        <button routerLink="/dashboard/merchant-info" type="button" class="btn btn-sm ml-auto btn-danger" (click)="modal.close('Close click')">
          {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
          <i class="fa fa-rotate-left mx-1"></i>
        </button>
      </div>
    </ng-template>


      <!-- companies  -->

      <ng-template #content7 let-modal>
        <div class="modal-header d-flex justify-content-between">
          <h6 class="modal-title" style="color: grey;">
            {{ curentLang === 'en' ? 'Companies Info' : (curentLang === 'ar' ? '  معلومات الشركات ' : '  מידע על חברות ')}}
          </h6>
          <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- تمرير القيمة true عند استخدام app-users داخل المودال -->
          <app-company [isInsideModal]="true"></app-company>
        </div>
        <div class="modal-footer d-flex justify-content-between">
          <div></div>
          <button routerLink="/dashboard/merchant-info" type="button" class="btn btn-sm ml-auto btn-danger" (click)="modal.close('Close click')">
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
            <i class="fa fa-rotate-left mx-1"></i>
          </button>
        </div>
      </ng-template>


      