import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { HttpHelpService } from "../../service/http-help.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { EMPTY, catchError, concatMap, of, throwError } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-update-finaical-request",
  templateUrl: "./update-finaical-request.component.html",
  styleUrls: ["./update-finaical-request.component.scss"]
})

export class UpdateFinaicalRequestComponent implements OnInit {
  
  vatTypeList: any;
  vatPersentageData: any;
  VatPercentage: any;
  invoiceId: any;
  vatListId: number;
  today: any;
  mastercompany: any;
  oneCompanyData: any=[];
  oneCustomerData: any=[];
  id:number;
  masterInvoiceTypeList: any;
  curentLang:string="";
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  CompanyNameEn:string = '';
  CompanyNameAr:string = '';
  CompanyNameHe:string = '';

  CustomerNameEn:string = '';
  CustomerNameAr:string = '';
  CustomerNameHe:string = '';
   CustomerAddrees:string = '';
   customerIdNumber:any;
   // CustomerNameHe:string = '';

  invoiceData : any 
  CompanyAddress:string = '';
  TaxCompany:string = '';
  customerVatNumber:string='';
  LOGO:string='';
  companyId:any;
  CompanyAddress_str: any;
  CompanyAddress_number: any;
  CompanyAddress_city: any;
  CompanyAddress_zip_code: any;
  uuId:any;
  constructor(private builder: FormBuilder,
     private service: HttpHelpService, 
    private router: Router, private alert: ToastrService,
    private activeroute: ActivatedRoute, private spinner: NgxSpinnerService, 
     private datePipe: DatePipe , private modalService: NgbModal,
    private toastr: ToastrService ,
    
  )
   { 
    this.curentLang = localStorage.getItem('curentLang');
    this.id = activeroute.snapshot.params['id'];

   }
   invoicedetail !: FormArray<any>;
  invoiceproduct !: FormGroup<any>;

  mastercustomer: any;
  masterproduct: any;
  editinvoiceno: any;
  isedit = false;
  editinvdetail: any;

  ngOnInit(): void {

    this.editinvoiceno = this.activeroute.snapshot.paramMap.get('invoiceno');
    this.loadInvoiceData();

    this.GetCustomers();
    this.GetProducts();
    this.vatTypechange();
    this.GetCompany();
    this.GetInvoiceTypeList();
    this.stopVatPercentageSubscription();

}

loadInvoiceData() {
  this.spinner.show(); // عرض رمز التحميل قبل استدعاء البيانات

  this.service.GetInvoiceForUpdate(this.id).subscribe(res => {
    this.invoiceData = res;

    this.CustomerNameAr = this.invoiceData?.customer?.customer_name_ar
    this.CustomerNameEn = this.invoiceData?.customer?.customer_name_en
    this.CustomerNameHe = this.invoiceData?.customer?.customer_name_he
    this.customerVatNumber = this.invoiceData?.customer?.customer_vat_number
    this.customerIdNumber = this .invoiceData?.customer?.id_number
    this.CustomerAddrees =  this.invoiceData?.customer?.customer_address
    this.CompanyNameEn = this.invoiceData?.company?.company_name_en;
    this.CompanyNameAr = this.invoiceData?.company?.company_name_ar;
    this.CompanyNameHe = this.invoiceData?.company?.company_name_he;
    this.companyId = this.invoiceData?.company_id;
    this.CompanyAddress = this.invoiceData?.company?.company_address;
    this.LOGO = this.invoiceData?.company?.logo_url;
    this.TaxCompany = this.invoiceData?.company?.tax_register;

  

    this.invoiceform.patchValue({
      invoice_type: this.invoiceData.invoice_type,
      customer_id: this.invoiceData.customer_id,
      company_id: this.invoiceData.company_id,
      invoice_date: this.invoiceData.invoice_date,
      vat_list_id: this.invoiceData.vat_list_id,
      document_language: this.invoiceData.document_language,
      vat_percentage: this.invoiceData.vat_percentage,
      invoice_note: this.invoiceData.invoice_note,
      allocation_number : this.invoiceData.allocation_number
    });


    // استدعاء getCompanyInfoById() بعد اكتمال الباتش
    if (this.invoiceData.soldItems && this.invoiceData.soldItems.length > 0) {
      const linesFormArray = this.invoiceform.get('lines') as FormArray;
      linesFormArray.clear(); // قم بمسح أي بيانات قديمة
      this.invoiceData.soldItems.forEach(item => {
        const lineFormGroup = this.builder.group({
          index : item.index,
          product_id: item.product_id, 
          category: item.category,
          measure_unit_description: item.measure_unit_description,
          measurement_unit_id: item.measurement_unit_id,
          description: item.description,
          catalog_id: item.catalog_id,
          item_name: item.item_name,
          quantity: item.quantity,    
          price_per_unit: item.price_per_unit, 
          discount: item.discount,    
          sub_total: item.sub_total,    
          vat_rate: item.vat_rate,    
          vat_amount: item.vat_amount,    
          total_amount: item.total_amount    
        });
        linesFormArray.push(lineFormGroup); 
      });
      this.getCompanyInfoById();
      this.getVatPercentageByVatListIdAndDate();
      this.getCustomerById();
    }
  });
   this.spinner.hide();
}




  onDateChange(event: any) {
    const selectedDate: Date = event;
    const formattedDate = selectedDate.toISOString().slice(0, 10).replace(/-/g, "");
 }

  
  availableLanguages = [
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "he", name: "Hebrew" }
];

// تعيين curentLang كلغة افتراضية
  
  invoiceform = this.builder.group({
    invoice_type : this.builder.control('332', Validators.required),
    customer_id: this.builder.control('', Validators.required),
    company_id: this.builder.control('', Validators.required),
    vat_list_id: this.builder.control(null, Validators.required),
    vat_percentage: this.builder.control({ value: 0, disabled: true }),
    invoice_date: this.builder.control('' , Validators.required),
    invoice_note: this.builder.control('', Validators.maxLength(255)),
    document_language: this.builder.control(this.curentLang ||'en'),
    allocation_number: this.builder.control(null, Validators.required),

    amount_before_discount : this.builder.control({ value: 0, disabled: true }),
    all_discount: this.builder.control({ value: 0, disabled: true }),
    payment_amount: this.builder.control({ value: 0, disabled: true }),
    all_vat_amount: this.builder.control({ value: 0, disabled: true }),
    payment_amount_including_vat: this.builder.control({ value: 0, disabled: true }),
    confirm: this.builder.control({ value: 0, disabled: true }),
    merchant_id : Number(this.merchantId),
    customer_name: this.builder.control('', Validators.required),
    customer_id_number : this.builder.control(''),
    customer_vat_number : this.builder.control(''),
    created_by: Number(this.userRegistId),
    lines: this.builder.array([] , Validators.required),

  });

 
  confirmInvoice() {
    this.spinner.show();   
  
    if (this.invoiceform.valid) {
        this.spinner.show();
        return this.service.SaveUpdatedInvoice(this.id , this.invoiceform.getRawValue()).pipe(
          concatMap((res: any) => {
            let result: any = res;
             this.invoiceId = result?.data;
            this.uuId = result?.data?.invoice_uuid;
            if (result.status === 200) {
                // Return an observable here
                return of(null);
            } else if (result.status === 400 || result.status === 403 || result.status === 500) {
                this.spinner.hide();
                let errorMessage;
                switch (result.status) {
                    case 400:
                    case 403:
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                Swal.fire({
                    icon: 'error',
                    title: errorMessage
                });
                // Return an observable here
                return throwError(errorMessage);
            }
            // Return an observable here
            return of(null);
          }),
          catchError(error => {
            this.spinner.hide();
             return throwError(error);
          }),
          concatMap(() => this.service.confirmInvoice(this.invoiceId))
        ).subscribe((res) => {
            if (res.status === 200) {
               
              this.router.navigate([`/dashboard/finaical-request`]);

                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  color: 'green',
                  backdrop:true,
                   
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                
                Toast.fire({
                  icon: 'success',
                  title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
                })


                this.modalService.dismissAll();
            } else if (res.status === 400 || res.status === 403 || res.status === 500) {
                let errorMessage;
                switch (res.status) {
                    case 400:
                    case 403:
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
                        this.router.navigate(['/dashboard/finaical-request']);

                        break;
                        
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }

                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  color: 'red',
                  backdrop:true,
                   
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                
                Toast.fire({
                  icon: 'error',
                  title: errorMessage
                })
            }
            this.spinner.hide();
        });
    } else {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
        });
        this.spinner.hide();
        return EMPTY;
    }
    
  }

  SaveUpdatedInvoice() {
    if (this.invoiceform.valid) {
        this.spinner.show(); // تشغيل الاسبينر قبل استدعاء الخدمة
  
        this.service.SaveUpdatedInvoice(this.id , this.invoiceform.getRawValue()).subscribe(res => {
            let result: any;
            result = res;
            this.spinner.hide(); // إيقاف الاسبينر بعد الانتهاء من الطلب
            this.invoiceId = result?.data;
            this.uuId = result?.data?.invoice_uuid;
            if (result.status === 200) {
              this.router.navigate(['/dashboard/finaical-request']);

                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  color: 'green',
                  backdrop:true,
                   
                  didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                })
                
                Toast.fire({
                  icon: 'success',
                  title: this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe)
                })

                this.invoiceform.reset();
            } else if (result.status === 400 || result.status === 403 || result.status === 500) {
                let errorMessage;
                switch (result.status) {
                    case 400:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    case 403:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    case 500:
                        errorMessage = this.curentLang === 'ar' ? result.messageAr : (this.curentLang === 'en' ? result.messageEn : result.messageHe);
                        break;
                    default:
                        errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
                }
                Swal.fire({
                    icon: 'error',
                    title: errorMessage
                });
            }
        });
    } else {
        Swal.fire({
            icon: 'warning',
            title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
        });
    }
    this.spinner.hide()

  };

  

  addnewproduct() {
  
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  
    let customercode = this.invoiceform.get("customer_id")?.value;
    let vatListId = this.invoiceform.get("vat_list_id")?.value;
    let invoiceTypeCode = this.invoiceform.get("invoice_type")?.value;
    let invoiceDate = this.invoiceform.get("invoice_date")?.value;
    let companyid = this.invoiceform.get("company_id")?.value;
    
    // يمكنك إضافة شروط إضافية هنا بحسب احتياجات التطبيق
    if (
      (customercode != null && customercode != '') &&
      (vatListId != null && vatListId != '') &&
      (invoiceTypeCode != null && invoiceTypeCode != '') &&
      (invoiceDate != null && invoiceDate != '') &&
      (companyid != null && companyid != '') ||
      this.isedit
    ) {
      this.invoicedetail.push(this.Generaterow());
      
    } else {
      
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء جميع الحقول' : (this.curentLang === 'en' ? 'Please fill all fields' : (this.curentLang === 'he' ? 'בבקשה תמלא את כל השדות' : ''))
      });
    }
  }
  

  get invproducts() {
    return this.invoiceform.get("lines") as FormArray;
  }

  Generaterow() {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;

    const newIndex = this.invoicedetail.length + 1; // Calculate the new index
    
    this.stopVatPercentageSubscription();


    return this.builder.group({
        index: this.builder.control(newIndex),
        product_id: this.builder.control('', Validators.required),
        quantity: this.builder.control(1),
        category: this.builder.control('', Validators.required),
      measure_unit_description: this.builder.control('', Validators.required),
      measurement_unit_id: this.builder.control('', Validators.required),
      description: this.builder.control('', Validators.required),
      catalog_id: this.builder.control('', Validators.required),
      item_name: this.builder.control('', Validators.required),
        price_per_unit: this.builder.control(0),
        discount: this.builder.control(0),
        sub_total: this.builder.control({ value: 0, disabled: true }),
        vat_rate: this.builder.control({ value: this.VatPercentage, disabled: true }),
        vat_amount: this.builder.control({ value: 0, disabled: true }),
        total_amount: this.builder.control({ value: 0, disabled: true }),
    });
}


  stopVatPercentageSubscription() {
    this.invoiceform.get('vat_list_id')?.disable();
    this.invoiceform.get('invoice_date')?.disable();
    
}

resumeVatPercentageSubscription() {
  // استئناف الاشتراك في التغييرات لـ 'vat_list_id'
  this.invoiceform.get('vat_list_id')?.enable();
  this.invoiceform.get('invoice_date')?.enable();
}



  GetCustomers() {
    this.spinner.show();
    this.service.getAllActiveCustomersBasedOnMerchantId().subscribe(res => {
      this.mastercustomer = res;
      this.spinner.hide();
    })
  }



  getCompanyInfoById() {

    const companyId = this.invoiceform.get("company_id")?.value;
     
    if (companyId != null && companyId != '') {
    this.service.getCompanyInfoById(this?.companyId).subscribe({
      next: res => {
        this.oneCompanyData = res;
           
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
    }
    
  };

  getIdd(id: number) {
    this.id = id;
  }
  getCustomerById() {
    const customerId = this.invoiceform.get("customer_id")?.value;
    if (customerId != null && customerId !== '') {
      this.spinner.show();
      this.service.getCustomerById(Number(customerId)).subscribe({
        next: res => {
          this.oneCustomerData = res;
          console.log(this.oneCustomerData, 'this.oneCustomerData');
  
          const customerType = this.oneCustomerData.customer_type;
          const customerId = this.oneCustomerData.customer_id;
          if (customerType === 6) {
            const customerVatNumber = Number(this.oneCustomerData.customer_vat_number);
            console.log(customerVatNumber, '6');
  
            if (customerVatNumber === 0 || customerVatNumber === null) {
              this.spinner.hide();
  
              // رسالة تحذير مع زري أكشن
              Swal.fire({
                icon: 'warning',
                title: this.curentLang === 'ar' ? 'الرقم الضريبي للعميل غير صحيح' : (this.curentLang === 'en' ? 'The customer tax number is incorrect' : (this.curentLang === 'he' ? 'מספר המס של הלקוח שגוי' : '')),
                text: this.curentLang === 'ar' ? 'القيمه الافتراضيه : 999999998' : (this.curentLang === 'en' ? 'Default Value : 999999998' : (this.curentLang === 'he' ? 'ערך ברירת מחדל : 999999998' : '')),
                showCancelButton: true,
                confirmButtonText: this.curentLang === 'ar' ? 'ارسال القيمة الافتراضية' : (this.curentLang === 'en' ? 'Send Default Value' : 'קבל ערך ברירת מחדל'),
                cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : (this.curentLang === 'en' ? 'Edit Customer Data' : 'ערוך נתוני לקוח'),
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                  // إذا تم الضغط على زر "ارسال القيمة الافتراضية"
                  this.invoiceform.patchValue({
                    customer_vat_number: '999999998',
                    customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                    customer_id_number: this.oneCustomerData?.id_number,
                  });
                  console.log(this.invoiceform.value, 'this.invoiceform.value');
                  
  
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  // إذا تم الضغط على زر "تعديل بيانات العميل" نفتح نافذة إدخال SweetAlert
                  Swal.fire({
                    title: this.curentLang === 'ar' ? 'تحديث الرقم الضريبي للعميل' : 
                           (this.curentLang === 'en' ? 'Update Customer VAT Number' : 'עדכון מספר מס של הלקוח'),
                    input: 'text',
                    inputLabel: this.curentLang === 'ar' ? 'أدخل الرقم الضريبي الجديد' : 
                                (this.curentLang === 'en' ? 'Enter new VAT number' : 'הזן מספר מס חדש'),
                    inputValue: this.oneCustomerData.customer_vat_number, // القيم الحالية
                    showCancelButton: true,
                    confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 
                                       (this.curentLang === 'en' ? 'Update' : 'עדכון'),
                    cancelButtonText: this.curentLang === 'ar' ? 'إلغاء' : 
                                      (this.curentLang === 'en' ? 'Cancel' : 'בטל'),
                                      inputValidator: (value) => {
                                        // التأكد من أن القيمة ليست فارغة، لا تبدأ بـ 0، لا تحتوي على أصفار فقط، ولا تقل عن 9 أرقام
                                        if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                                          return this.curentLang === 'ar' ? 'الرجاء إدخال رقم ضريبي صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 
                                                 (this.curentLang === 'en' ? 'Please enter a valid VAT number with at least 9 digits and does not start with 0' : 'הזן מספר מס תקין עם לפחות 9 ספרות ושלא מתחיל ב-0');
                                        }
                                      }
                                      
                                      
                  }).then((inputResult) => {
                    if (inputResult.isConfirmed) {
                      this.invoiceform.patchValue({
                        customer_vat_number: inputResult.value,
                        customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                        customer_id_number: this.oneCustomerData?.id_number
                      });
  
                      // تحديث بيانات العميل في الـ API
                      const model = {
                        customer_vat_number: inputResult.value,
                        updated_by: this.userRegistId
                      };
                      this.service.updateCustomersById(customerId, model).subscribe((res) => {
                        console.log('Customer VAT updated with new value.');
                      });
                    }
                  });
                }
              });
              return;
            }
          }
  
          if (customerType === 5) {
            const customerVatNumber = Number(this.oneCustomerData.id_number);
            console.log(customerVatNumber, '5');
  
            if (customerVatNumber === 0 || customerVatNumber === null) {
              this.spinner.hide();
              Swal.fire({
                icon: 'warning',
                title: this.curentLang === 'ar' ? 'رقم الهوية للعميل غير صحيح' : (this.curentLang === 'en' ? 'The customer ID number is incorrect' : (this.curentLang === 'he' ? 'מספר הזהות של הלקוח שגוי' : '')),
                text: this.curentLang === 'ar' ? 'القيمه الافتراضيه : 999999998' : (this.curentLang === 'en' ? 'Default Value : 999999998' : (this.curentLang === 'he' ? 'ערך ברירת מחדל : 999999998' : '')),
                showCancelButton: true,
                confirmButtonText: this.curentLang === 'ar' ? 'ارسال القيمة الافتراضية' : (this.curentLang === 'en' ? 'Send Default Value' : 'קבל ערך ברירת מחדל'),
                cancelButtonText: this.curentLang === 'ar' ? 'تعديل بيانات العميل' : (this.curentLang === 'en' ? 'Edit Customer Data' : 'ערוך נתוני לקוח'),
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                  // إذا تم الضغط على زر "ارسال القيمة الافتراضية"
                  this.invoiceform.patchValue({
                    customer_id_number: '999999998',
                    customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
                    customer_vat_number: this.oneCustomerData?.customer_vat_number,
                  });
                  console.log(this.invoiceform.value, 'this.invoiceform.value');
  
                  this.spinner.hide();
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  // إذا تم الضغط على زر "تعديل بيانات العميل" نفتح نافذة إدخال SweetAlert
                  Swal.fire({
                    title: this.curentLang === 'ar' ? 'تحديث رقم الهوية للعميل' : 
                           (this.curentLang === 'en' ? 'Update Customer ID Number' : 'עדכון מספר זהות של הלקוח'),
                    input: 'text',
                    inputLabel: this.curentLang === 'ar' ? 'أدخل رقم الهوية الجديد' : 
                                (this.curentLang === 'en' ? 'Enter new ID number' : 'הזן מספר זהות חדש'),
                    inputValue: this.oneCustomerData.id_number, // القيم الحالية
                    showCancelButton: true,
                    confirmButtonText: this.curentLang === 'ar' ? 'تحديث' : 
                                       (this.curentLang === 'en' ? 'Update' : 'עדכון'),
                    cancelButtonText: this.curentLang === 'ar' ? 'إلغاء' : 
                                      (this.curentLang === 'en' ? 'Cancel' : 'בטל'),
                    inputValidator: (value) => {
                                        // التأكد من أن القيمة ليست فارغة، لا تبدأ بـ 0، لا تحتوي على أصفار فقط، ولا تقل عن 9 أرقام
                                        if (!value || value.trim() === '' || value.startsWith('0') || /^0+$/.test(value) || value.length < 9) {
                                          return this.curentLang === 'ar' ? 'الرجاء إدخال رقم هوية صالح لا يقل عن 9 أرقام ولا يبدأ بصفر' : 
                                                 (this.curentLang === 'en' ? 'Please enter a valid ID number with at least 9 digits and does not start with 0' : 'הזן מספר מס תקין עם לפחות 9 ספרות ושלא מתחיל ב-0');
                                        }
                                      }
                                      
                                      
                  }).then((inputResult) => {
                    if (inputResult.isConfirmed) {
                      this.invoiceform.patchValue({
                        customer_id_number: inputResult.value,
                        customer_vat_number : this.oneCustomerData.customer_vat_number,
                        customer_name : this.oneCustomerData['customer_name_' + this.curentLang].substring(0, 25),
                      });
                      console.log(this.invoiceform.value, 'updated invoiceform');
  
                      // تحديث بيانات العميل في الـ API
                      const model = {
                        id_number: inputResult.value,
                        updated_by: this.userRegistId
                      };
                      this.service.updateCustomersById(customerId, model).subscribe((res) => {
                        console.log('Customer ID updated with new value.');
                      });
                    }
                  });
                }
              });
              return;
            }
          }
  
          // تحديث النموذج ببيانات العميل المستلمة
          this.invoiceform.patchValue({
            customer_name: this.oneCustomerData?.['customer_name_' + this.curentLang].substring(0, 25),
            customer_id_number: this.oneCustomerData?.id_number,
            customer_vat_number: this.oneCustomerData?.customer_vat_number,
          });
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
    }
  }


  GetCompany() {
    this.service.GetCompany().subscribe(res => {
      this.mastercompany = res;
    })
  }

  GetProducts() {
    this.service.getAllActiveProductsBasedOnMerchantId().subscribe(res => {
      this.masterproduct = res;

    })
  }

  GetInvoiceTypeList() {
    this.spinner.show();

    this.service.GetInvoiceTypeList().subscribe(res => {
      this.masterInvoiceTypeList = res;
      this.spinner.hide();
    })
  }


  customerchange() {
    let customer_id = this.invoiceform.get("customer_id")?.value;
    this.service.GetCustomerbyId(customer_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  }


  companychange() {
    let company_id = this.invoiceform.get("company_id")?.value;
    this.service.GetCompanybyId(company_id).subscribe(res => {
      let custdata: any;
      custdata = res;

    });
  }
  getCompanyId(id:any)
  {
    this.companyId =  id;
  };

  getVatPercentageByVatListIdAndDate() {
    const vatListId = this.invoiceform.get("vat_list_id")?.value;
    const invoiceDate = this.invoiceform.get("invoice_date")?.value;
  
    if (vatListId && invoiceDate) {
      this.spinner.show();
      this.service.getVatPercentageByVatListIdAndDate(vatListId, invoiceDate).subscribe({
        next: res => {
          this.vatPersentageData = res;
          if (this.vatPersentageData && this.vatPersentageData.length > 0) {
            this.VatPercentage = this.vatPersentageData[0].vat_percentage;
            this.invoiceform.patchValue({
              vat_percentage: this.VatPercentage // تحديث القيمة في النموذج
              
            });
            this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
          } else {
            // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
            this.invoiceform.patchValue({
              vat_percentage: 0
            });
          }
        },
        error: error => {
           // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
          this.invoiceform.patchValue({
            vat_percentage: 0
          });
        },
        complete: () => {
          this.spinner.hide(); // إخفاء المحمل بعد الحصول على البيانات بنجاح أو بعد حدوث خطأ
        }
      });
    } else {
      // إعادة تهيئة قيمة vat_percentage إلى قيمتها الافتراضية
      this.invoiceform.patchValue({
        vat_percentage: 0
      });
      this.spinner.hide(); // إخفاء المحمل إذا لم يتم تحديد كلا من vatListId و invoiceDate
    }
  }
  
  
  filter(event:any)
  {
    let x = event.target.value;
     
  };

  getId(id: number) {
    this.invoiceId = id;
  };
  vatTypechange() {
    this.spinner.show();

    this.service.GetVatTypeList().subscribe(res => {
      this.vatTypeList = res;
    });

    this.spinner.hide();
  }

  

  productchange(index: any) {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

    // استماع للتغيرات على Total Amount
    this.invoiceproduct.get('total_amount').valueChanges.subscribe(newTotalAmount => {
        this.calculateReverse(index, newTotalAmount);
    });

    let product_id = this.invoiceproduct.get("product_id")?.value;

    this.service.GetProductbycode(product_id).subscribe(res => {
        let proddata: any;
        proddata = res;

        // التحقق من إذا كانت بيانات المنتج غير مكتملة
        if (proddata?.product_code === '' || proddata?.product_code === null ||
            proddata?.categories_id === null || proddata?.measurement_unit_id === null) {
            
            // عرض الرسالة التحذيرية وإدخال للمستخدم لإضافة الكود
            Swal.fire({
              icon: 'warning',
              title: this.curentLang === 'ar' ? ' برجاء استكمال معلومات المنتج والمحاولة مرة اخري' : 
                     (this.curentLang === 'en' ? 'Please complete the product information and try again' : 
                     (this.curentLang === 'he' ? 'אנא מלא את פרטי המוצר ונסה שוב' : '')),
              input: 'text', // إدخال نص للمستخدم
              inputPlaceholder: this.curentLang === 'ar' ? 'أدخل كود المنتج' : 
                                 (this.curentLang === 'en' ? 'Enter product code' : 
                                 (this.curentLang === 'he' ? 'הזן קוד מוצר' : '')),
              showCancelButton: true,
              confirmButtonText: this.curentLang === 'ar' ? 'إرسال' : 
                                 (this.curentLang === 'en' ? 'Submit' : 
                                 (this.curentLang === 'he' ? 'שלח' : '')),
              preConfirm: (value) => {
                if (!value || value.trim() === '') {
                    Swal.showValidationMessage(
                        this.curentLang === 'ar' ? 'لا يمكن إدخال قيمة فارغة أو مسافة فقط' : 
                        (this.curentLang === 'en' ? 'Empty or whitespace-only values are not allowed' : 
                        (this.curentLang === 'he' ? 'אין להזין ערך ריק או הכולל רק רווחים' : ''))
                    );
                    return false;
                }
                return value.trim();
              }
            }).then(result => {
                if (result.isConfirmed && result.value) {
                    let newProductCode = result.value;
                    this.invoiceproduct.get("catalog_id")?.setValue(newProductCode);

                    // إرسال الكود الجديد إلى الخادم
                    const model = {
                      product_code: newProductCode,
                      updated_by: this.userRegistId
                    };

                    this.spinner.show();
                    this.service.updateProductsById(product_id, model).subscribe((res) => {
                        console.log('Product ID updated with new value.');

                        // جلب البيانات المحدثة بعد التعديل
                        this.service.GetProductbycode(product_id).subscribe(updatedProdData => {
                            this.updateFormWithProductData(updatedProdData, index);
                        });
                    });
                    this.spinner.hide();
                }
            });

            return;
        }

        if (proddata != null) {
            this.updateFormWithProductData(proddata, index);
        }
    });
}

// دالة لتحديث حقول النموذج بقيم المنتج المحدثة
updateFormWithProductData(proddata: any, index: number) {
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    this.invoiceproduct.get("productName")?.setValue(proddata.name);
    this.invoiceproduct.get("price_per_unit")?.setValue(proddata.price);
    this.invoiceproduct.get("category")?.setValue(proddata.categories.categories_code);
    this.invoiceproduct.get("measure_unit_description")?.setValue(proddata.measurementUnits['measurement_unit_name_' + this.curentLang].substring(0, 20));
    this.invoiceproduct.get("measurement_unit_id")?.setValue(proddata.measurementUnits.measurement_unit_id);
    this.invoiceproduct.get("description")?.setValue(proddata['des_' + this.curentLang].substring(0, 30));
    this.invoiceproduct.get("catalog_id")?.setValue(proddata.product_code.substring(0, 13));
    this.invoiceproduct.get("item_name")?.setValue(proddata['product_name_' + this.curentLang]);
    this.Itemcalculation(index);
}

//new
onTotalAmountChange(index: number) {
  this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  const newTotalAmount = parseFloat(this.invoiceproduct.get("total_amount")?.value);
  if (!isNaN(newTotalAmount)) {
      this.calculateReverse(index, newTotalAmount);
  }
}


/// new
calculateReverse(index: any, newTotalAmount: number) {
  this.invoicedetail = this.invoiceform.get("lines") as FormArray;
  this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;

  let quantity = this.invoiceproduct.get("quantity")?.value || 1; // التأكد من أن quantity ليس صفرًا
  let vat_rate = this.invoiceproduct.get("vat_rate")?.value || 0;
  let discount = this.invoiceproduct.get("discount")?.value || 0;

  // حساب Sub Total قبل تطبيق الضريبة
  let sub_total_before_vat = newTotalAmount / (1 + (vat_rate / 100));
  
  // حساب الخصم الكامل (يتم تطبيق الخصم على السعر الإجمالي للمنتجات)
  let total_without_discount = sub_total_before_vat + discount;

  // حساب السعر لكل وحدة
  let price_per_unit = total_without_discount / quantity;

  // حساب مبلغ الضريبة
  let vat_amount = parseFloat((sub_total_before_vat * (vat_rate / 100)).toFixed(2));

  // تعيين القيم المحسوبة إلى النموذج
  this.invoiceproduct.get("sub_total")?.setValue(sub_total_before_vat.toFixed(2));
  this.invoiceproduct.get("price_per_unit")?.setValue(price_per_unit.toFixed(2));
  this.invoiceproduct.get("vat_amount")?.setValue(vat_amount.toFixed(2));
  
  // تحديث القيم الإجمالية للفاتورة
  this.summarycalculation();
}


  Itemcalculation(index: any) {
    this.invoicedetail = this.invoiceform.get("lines") as FormArray;
    this.invoiceproduct = this.invoicedetail.at(index) as FormGroup;
    
    let quantity = this.invoiceproduct.get("quantity")?.value;
    let price = this.invoiceproduct.get("price_per_unit")?.value;
    let discount = this.invoiceproduct.get("discount")?.value;
    let vat_rate = this.invoiceproduct.get("vat_rate")?.value;
  
    let sub_total = (quantity * price) - discount; // الحساب الفرعي للعنصر بعد الخصم
    let vat_amount = parseFloat((sub_total * (vat_rate / 100)).toFixed(2)); // حساب مبلغ الضريبة المضافة
    let total_amount = parseFloat((sub_total + vat_amount).toFixed(2)); // حساب السعر النهائي بإضافة مبلغ الضريبة المضافة إلى الحساب الفرعي
    
    this.invoiceproduct.get("sub_total")?.setValue(sub_total); // تعيين قيمة الحساب الفرعي
    this.invoiceproduct.get("vat_amount")?.setValue(vat_amount); // تعيين قيمة مبلغ الضريبة المضافة
    this.invoiceproduct.get("total_amount")?.setValue(total_amount); // تعيين السعر النهائي للعنصر
    
    this.summarycalculation(); // إعادة حساب القيم الإجمالية للفاتورة
  }

  
  
  
  
  Removeproduct(index: any) {

    
      this.invproducts.removeAt(index);

      this.summarycalculation();
      if( this.invproducts.length == 0){
        this.resumeVatPercentageSubscription();
      }
    
  }

  summarycalculation() {
    let array = this.invoiceform.getRawValue().lines;
    
    let amount_before_discount = 0;
    let all_discount = 0;
    let all_vat_amount = 0;
  
    array.forEach((x: any) => {
      amount_before_discount += parseFloat(x.sub_total);
      all_discount += parseFloat(x.discount);
      all_vat_amount += parseFloat(x.vat_amount);
    });
  
    let payment_amount = amount_before_discount - all_discount; // المبلغ المطلوب للدفع
    let payment_amount_including_vat = payment_amount + all_vat_amount; // المبلغ المطلوب للدفع بما فيه ضريبة القيمة المضافة
  
    this.invoiceform.get("amount_before_discount")?.setValue(parseFloat(amount_before_discount.toFixed(2)));
    this.invoiceform.get("all_discount")?.setValue(parseFloat(all_discount.toFixed(2)));
    this.invoiceform.get("payment_amount")?.setValue(parseFloat(payment_amount.toFixed(2)));
    this.invoiceform.get("all_vat_amount")?.setValue(parseFloat(all_vat_amount.toFixed(2)));
    this.invoiceform.get("payment_amount_including_vat")?.setValue(parseFloat(payment_amount_including_vat.toFixed(2)));
    
  }
  
}
