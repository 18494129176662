<app-breadcrumb [title]="curentLang === 'en' ? 'Add Offer Price' : (curentLang === 'ar' ? ' اضافة عرض سعر ' : '    הוספת הצעת מחיר      ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Add Offer Price' : (curentLang === 'ar' ? ' اضافة عرض سعر ' : '      הוספת הצעת מחיר        ')">
</app-breadcrumb>

<!--Add Mission -->

<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12 ">
    <div class="card">
      

      <div class="card-block row"> 
        <div class="col-sm-12 col-lg-12 col-xl-12">
            <div>

                <form  [formGroup]="invoiceform"  >
     
                            <div class="row">
                                <div class="col-lg-12 row">
                
                                    <div class="col-md-5">
                                        <div class="form-group mx-2">
                                            <label class="mt-2">{{ curentLang === 'en' ? '  Company Name' :
                                                (curentLang === 'ar' ? '  اسم الشركة' :'שם החברה  ')}}
                                            </label>
                                            <span class="text-danger">*</span>
                                
                                        <!--     <input class="form-control w-100" type="text" name="search" formControlName="company_id"
                                            [(ngModel)]="selectedCompany"  readonly
                                            
                                            />
                                            -->
                                            <ng-select formControlName="company_id" (change)="getCompanyId($event);getCompanyInfoById();"  style="cursor: pointer;"
                                            placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                               <ng-option *ngFor="let item of mastercompany; let i = index " [value]="item.company_id">
                                                {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }}
                                            </ng-option>
                                             </ng-select>
                                            
                                        </div>
                                    </div>

                                    <div class="col-md-5">
                                        <div class="form-group mx-2">
                                          <label class="mt-2">{{ curentLang === 'en' ? 'Select Customer Name' : (curentLang === 'ar' ? 'اختر اسم العميل' : 'לקוח       ')}}</label>
                                          <span class="text-danger">*</span>
                                          <ng-select formControlName="customer_id" (change)="getCustomerById()" style="cursor: pointer;"
                                                     placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                            <ng-option *ngFor="let item of mastercustomer; let i = index" [value]="item.customer_id">
                                              {{ curentLang === 'en' ? item.customer_name_en : (curentLang === 'ar' ? item.customer_name_ar : item.customer_name_he) }}
                                            </ng-option>
                                          </ng-select>
                                        </div>
                                      </div>

                                      <div class="col-md-2 " style="padding-top: 42px;" >
                                        <button [hidden]="user_type == 200" class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
                                            <i class="fa fa-plus-circle mx-1"></i>
                                          {{ curentLang === 'en' ? 'Add Customer' : (curentLang === 'ar' ? '  اضافة عميل' : '   הוספת לקוח  ')}}
                                        </button>
                                      </div>
                                      
                                      
                                    
                                
                                </div>

                                <div class="col-lg-12 row">
                                    <div class="col-lg-4">
                                        <div class="col-lg-12 ">
                                            <div class="form-group mx-2 ">
                                                <label class="mt-2"> {{ curentLang === 'en' ? 'Offer Price Date' : (curentLang === 'ar' ? 'تاريخ عرض السعر' : 'תאריך       ')}}</label>
                                                 <input type="date"  (change)="filter($event); getVatPercentageByVatListIdAndDate()"
                                                 formControlName="invoice_date" class="form-control w-100">
                                                

                                                <!-- <p-calendar 
                                                 showIcon="true"    style="display: block; width: 100%;"
                                                 (ngModelChange)="filter($event);getVatPercentageByVatListIdAndDate()"
                                                    formControlName="invoice_date" 
                                                    dateFormat="dd/mm/yy"
                                                     placeholder="DD/MM/YYYY"
                                                      >
                                                    </p-calendar>
                                                     -->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group mx-2">
                                            <label class="mt-2"> {{ curentLang === 'en' ? 'Vat Type' : (curentLang === 'ar' ? 'نوع الضريبة' : '  סוג המע"מ ') }}</label>
                                            <span class="text-danger">*</span>
                          

                                            <ng-select formControlName="vat_list_id"   (change)="getVatPercentageByVatListIdAndDate()"style="cursor: pointer;"
                                             placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                <ng-option *ngFor="let item of vatTypeList; let i = index " [value]="item?.vat_list_id">
                                                    {{ curentLang === 'en' ? item.vat_name_en : (curentLang === 'ar' ? item.vat_name_ar : item.vat_name_he) }}      
                                                 </ng-option>
                                              </ng-select>

                                        </div> 
                                    </div>

                                    <div class="col-lg-4">
                                        <div class="form-group mx-2">
                                            <label class="mt-2"> {{ curentLang === 'en' ? 'Document Language' : (curentLang === 'ar' ? 'لغة المستند  ' : '  שפת מסמך')}}</label>
                                            <select formControlName="document_language" class="form-control form-select">
                                                <option *ngFor="let lang of availableLanguages" [ngValue]="lang.code" [selected]="lang.code === curentLang">
                                                    {{ lang.name }}
                                                </option>
                                            </select>

                                            <!-- <ng-select formControlName="document_language"  style="cursor: pointer;"
                                            placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                               <ng-option *ngFor="let lang of availableLanguages; let i = index "[ngValue]="lang.code" [selected]="lang.code === curentLang">
                                                {{ lang.name }}     
                                                </ng-option>
                                             </ng-select> -->

                                        </div>
                                        </div>
                                        
                   
                                </div>
                
                
                            </div>
                            <br>
                
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="table-responsive">
                                        <table class="table table-bordered d-none d-lg-table" formArrayName="lines">
                                            <thead>
                                                <tr>
                                                    <th>{{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם מוצר ')}}</th>
                                                    <th>{{ curentLang === 'en' ? 'Product Desc' : (curentLang === 'ar' ? 'وصف المنتج' : '    תיאור פרטי באנגלית')}}</th>
                                                    <th>{{ curentLang === 'en' ? 'Price per unit' : (curentLang === 'ar' ? 'سعر الوحدة' : 'מחיר יחידה  ')}}</th>
                                                    <th>{{ curentLang === 'en' ? 'Quantity' : (curentLang === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                    <th>{{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'خصم' : 'הנחה')}}</th>
                                                    <th>{{ curentLang === 'en' ? 'Vat Amount' : (curentLang === 'ar' ? 'قيمة الضريبة' : ' מע"מ' )}}</th>
                                                    <th>{{ curentLang === 'en' ? 'Total Amount' : (curentLang === 'ar' ? 'المجموع' : '  סה"כ')}}</th>
                                                    <th>{{ curentLang === 'en' ? 'Remove' : (curentLang === 'ar' ? 'حذف' : 'מחיקה')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of invproducts.controls; let i=index" [formGroupName]="i">
                                                    <td style="width:20%">
                                                        <ng-select formControlName="product_id" (change)="productchange(i)" style="cursor: pointer;">
                                                            <ng-option *ngFor="let item of masterproduct; let i = index" [value]="item.product_id">
                                                                {{ curentLang === 'en' ? item.product_name_en : (curentLang === 'ar' ? item.product_name_ar : item.product_name_he) }}
                                                            </ng-option>
                                                        </ng-select>
                                                    </td>
                                                    <td style="width:20%">
                                                        <textarea maxlength="250" cols="10" rows="1" class="form-control form-control-sm" formControlName="description"></textarea>
                                                    </td>
                                                    <td style="width:10%">
                                                        <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="price_per_unit" />
                                                    </td>
                                                    <td style="width:9%">
                                                        <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="quantity" />
                                                    </td>
                                                    <td style="width:10%">
                                                        <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="discount" />
                                                    </td>
                                                    <td style="width:11%">
                                                        <input class="form-control" formControlName="vat_amount" />
                                                    </td>
                                                    <td style="width:13%">
                                                        <input class="form-control" type="number" min="0" formControlName="total_amount" (change)="onTotalAmountChange(i)" />
                                                    </td>
                                                    <td style="width:5%">
                                                        <i (click)="Removeproduct(i)" class="fa fa-trash" style="font-size: 22px; cursor: pointer; color: red;"></i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="6">
                                                        <button type="button" class="btn  m-2" style="background-color: #141414; color: #fff;" (click)="addnewproduct()">
                                                            <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                            {{ curentLang === 'en' ? 'Select Product' : (curentLang === 'ar' ? 'اختيار منتج' : 'בחר מוצר  ') }}
                                                        </button>

                                                        <button [hidden]="user_type == 200" type="button" class="btn  m-2" style="background-color: #141414; color: #fff;" (click)="openXl2(content2)">
                                                            <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                            {{ curentLang === 'en' ? 'Add Product' : (curentLang === 'ar' ? 'اضافة منتج' : 'הוספת פריט   ') }}
                                                        </button>
                                                    </td>

                                                    
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>


            <!-- Mobile layout for smaller screens -->
            <div class="d-lg-none" formArrayName="lines">

                
                <div *ngFor="let item of invproducts.controls; let i=index" [formGroupName]="i" class="border p-3 my-2">
                    <div class="form-group">
                        <label>{{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם מוצר ')}}</label>
                        <ng-select formControlName="product_id" (change)="productchange(i)" style="cursor: pointer;">
                            <ng-option *ngFor="let item of masterproduct; let i = index" [value]="item.product_id">
                                {{ curentLang === 'en' ? item.product_name_en : (curentLang === 'ar' ? item.product_name_ar : item.product_name_he) }}
                            </ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group">
                        <label>{{ curentLang === 'en' ? 'Product Desc' : (curentLang === 'ar' ? 'وصف المنتج' : '    תיאור פרטי באנגלית')}}</label>
                        <textarea maxlength="250" cols="10" rows="2" class="form-control form-control-sm" formControlName="description"></textarea>
                    </div>
                    <div class="form-group">
                        <label>{{ curentLang === 'en' ? 'Price per unit' : (curentLang === 'ar' ? 'سعر الوحدة' : '  מחיר יחידה')}}</label>
                        <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="price_per_unit" />
                    </div>
                    <div class="form-group">
                        <label>{{ curentLang === 'en' ? 'Quantity' : (curentLang === 'ar' ? 'الكمية' : 'כמות')}}</label>
                        <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="quantity" />
                    </div>
                    <div class="form-group">
                        <label>{{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'خصم' : 'הנחה')}}</label>
                        <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="discount" />
                    </div>
                    <div class="form-group">
                        <label>{{ curentLang === 'en' ? 'Vat Amount' : (curentLang === 'ar' ? 'قيمة الضريبة' : ' מע"מ ')}}</label>
                        <input class="form-control" formControlName="vat_amount" />
                    </div>
                    <div class="form-group">
                        <label>{{ curentLang === 'en' ? 'Total Amount' : (curentLang === 'ar' ? 'المجموع' : 'סה"כ  ')}}</label>
                        <input class="form-control" type="number" min="0" formControlName="total_amount" (change)="onTotalAmountChange(i)" />
                    </div>
                    <div class="text-center">
                        <button (click)="Removeproduct(i)" class="btn btn-sm m-2" style="background-color: red; color: #fff;">
                            <i class="fa fa-trash" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                            {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? 'حذف' : 'מחיקה') }}
                        </button>
                    </div>
                </div>
    
                <!-- Add button for small screens -->
                <div class="text-center mt-3">
                    <button type="button" class="btn  my-2 mx-1" style="background-color: #141414; color: #fff;" (click)="addnewproduct()">
                        <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                        {{ curentLang === 'en' ? 'Select Product' : (curentLang === 'ar' ? 'اختيار منتج' : 'בחר מוצר  ') }}
                    </button>

                    <button [hidden]="user_type == 200" type="button" class="btn   my-2 mx-1" style="background-color: #141414; color: #fff;" (click)="openXl2(content2)">
                        <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                        {{ curentLang === 'en' ? 'Add Product' : (curentLang === 'ar' ? 'اضافة منتج' : '  הוספת פריט ') }}
                    </button>
                </div>
            </div>

                                
                                    <div class="row d-flex justify-content-between">
                
                                                <div class="col-md-6 col-sm-12" style="color: black;">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'Amount Before Discount' : (curentLang === 'ar' ? '   المبلغ قبل الخصم' : 'מחיר לפני הנחה')}}</div>
                                                                    <input formControlName="amount_before_discount" class="form-control">
                                                                </div>
                                                                
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'الخصم' : 'הנחה')}}</div> 
                                                                    <input formControlName="all_discount" class="form-control">
                                                                </div>
                                                                
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'Payment Amount' : (curentLang === 'ar' ? ' قيمة الدفع  ' : 'סה"כ  ')}}</div>
                                                                    <input formControlName="payment_amount" class="form-control">
                                                                </div>
                                                                
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'VAT Amount' : (curentLang === 'ar' ? '   قيمة الضريبة' : 'מע"מ     ')}} ( {{VatPercentage }} %)</div>
                                                                    <input formControlName="all_vat_amount" class="form-control">
                                                                </div>
                                                                
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'Payment Amount Including VAT' : (curentLang === 'ar' ? 'قيمة المبلغ شاملة الضريبة' : '    סה"כ לשתלום')}}</div>
                                                                    <input formControlName="payment_amount_including_vat" class="form-control">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                       
                                                    </div>
                                                </div>
                
                                               
                        
                                                <div class="col-md-6 col-sm-12" style="color: black;">
                                                    <div class="mt-8 px-4">
                                                        <div>
                                                            <label for="notes"> {{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? 'ملاحظة' : 'הערה')}}</label>
                                                            <textarea
                                                                formControlName="invoice_note"
                                                                id="notes" 
                                                                name="notes"
                                                                class="form-textarea form-control my-2"
                                                                placeholder="{{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? 'ملاحظة' : 'הערה')}}"
                                                                name="notes"
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                
                                            </div>
                
                                            </div>
                                
                
                            </div>

                            <br>

                            <div class="row d-flex justify-content-between text-center">
                   
                              
                                    <br>
                                    <div class="col-4 col-lg-4 p-1">
                                        <button class="btn" style="background-color: #141414; color: #fff;" type="submit" (click)="confirmInvoice()">
                                            <i class="fa fa-check mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === 'en' ? 'Confirm ' : (curentLang === 'ar' ? 'تأكيد    ' : 'אישור ') }}
                                        </button> 
                                    </div>
                                    

                                    <div class="col-4 col-lg-4 p-1 ">
                                        <button type="button" class="btn" (click)="SaveInvoice()" style="background-color: #277d29; color: #fff;" type="submit">
                                            <i class="fa fa-save mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === 'en' ? 'Save ' : (curentLang === 'ar' ? 'حفظ ' : ' שמירה ') }}
                                        </button>
                                    </div>
                                    
                                        
                                    
                                   
                                        <div class="col-4 col-lg-4 p-1 ">
                                            <button class="btn  btn-danger" routerLink="/dashboard/offer-price">
                                                <i><i class="fa fa-rotate-left mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i></i>
                                                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה') }}
                                            </button>
                                        </div>
                                
                
                            </div>

                    
                </form>
            </div>
            
        </div>

   

      </div>
    </div>
  </div>


  <!----------------------------------->
   
  <!-- <div class=" col-sm-6 col-lg-6 col-xl-6 ">
     
    <div class="card  p-3  ">
       <div class="panel mb-5 text-center  bg-white ">
           <h4 class="text-black dark:text-white m-2"> {{ curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? 'معلومات الشركة' : 'מידע על החברה')}}</h4>
           <div class="flex shrink-0 items-center text-black dark:text-white m-2 ">
             <img [src]="LOGO" alt="" class="my-3 text-center" style="width: 100px; height: 100px; border-radius: 50%;"/>
         </div>
         <div class="mt-6 space-y-1 my-2 mx-2" style="color: black;">
           <div>{{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה')}} : {{ curentLang === 'en' ? CompanyNameEn : (curentLang === 'ar' ? CompanyNameAr : CompanyNameHe)}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address Street' : (curentLang === 'ar' ? 'شارع  الشركة' : 'מספר רחוב כתובת החברה')}} : {{ CompanyAddress_str}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address Building Number' : (curentLang === 'ar' ? 'رقم المبنى الشركة' : 'עיר כתובת החברה')}} : {{ CompanyAddress_number}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address City' : (curentLang === 'ar' ? 'مدينة الشركة' : 'מיקוד כתובת החברה')}} : {{ CompanyAddress_city}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address Zip Code' : (curentLang === 'ar' ? 'رمز بريدي الشركة' : 'ארץ כתובת החברה')}} : {{ CompanyAddress_zip_code}}</div>
           
           <div> {{ curentLang === 'en' ? 'Company Tax Number' : (curentLang === 'ar' ? ' الرقم الضريبي للشركة  ' : 'מספר מס חברה  ')}} : {{TaxCompany}}</div>
           
       </div>
         
          
        
        
     </div>
    </div>
 
</div>

<div class=" col-sm-6 col-lg-6 col-xl-6   ">

   <div class=" card  p-3  ">
       <div class="panel mb-5 text-center bg-white ">
           <h4 class="text-black dark:text-white m-2">  {{ curentLang === 'en' ? 'Customer Info' : (curentLang === 'ar' ? 'معلومات العميل' : 'מידע על הלקוח')}}</h4>
        
         <div class="mt-6 space-y-1 my-2 mx-2" style="color: black;">
           <div> {{ curentLang === 'en' ? 'Customer Name' : (curentLang === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} : {{  curentLang === 'en' ? CustomerNameEn : (curentLang === 'ar' ? CustomerNameAr : CustomerNameHe)}}</div>
           <div>{{ curentLang === 'en' ? 'Customer Address' : (curentLang === 'ar' ? 'عنوان العميل' : 'כתובת הלקוח')}} : {{ oneCustomerData?.customer_address}}</div>
           <div> {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي للعميل' : 'מספר מס חשבונית')}} : {{oneCustomerData?.customer_vat_number}}</div>
           <div> {{ curentLang === 'en' ? 'Customer Id Number' : (curentLang === 'ar' ? 'رقم الهوية' : 'מספר תעודת זהות')}} : {{oneCustomerData?.id_number}}</div>
       </div>
         
         </div>
   </div>
     
</div> -->
   
    
 

    </div>
</div>








<!--add Customer-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Add Customer ' : (curentLang === 'ar' ? ' اضافة عميل ' : '     הוספת לקוח  ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="addCustomer" novalidate [ngClass]="{ 'was-validated': validate }">
                        <div class="row">

                            <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Customer Name Ar' : (curentLang === 'ar' ? '  اسم العميل عربي   ' : '  שם החברה בערבית    ')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="customer_name_ar" 
                                  />
                              </div> -->
              
                              <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Customer Name En' : (curentLang === 'ar' ? '  اسم العميل انجليزي   ' : '    שם מס באנגלית     ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="customer_name_en"
                                   />
                                </div> -->

                                <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Customer Type' : (curentLang === 'ar' ? 'نوع العميل' : '  סוג לקוח    ')}}
                                      <span class="text-danger" style="color: red;">*</span>
                                   </label>
                                   <select class="form-control form-select" required="" onlyAlphabets
                                   style="cursor: pointer; "
                                   (change)="filterCustomer($event)" formControlName="customer_type">
                                   <option *ngFor='let item of customerTypeData; let i = index '
                                       [value]="item?.lookup_detail_id">
                                       {{ curentLang === 'en' ?  item?.lookupDetailEnName :(curentLang === 'ar' ? item?.lookupDetailArName  :  item?.lookupDetailHeName )}}
                                   </option>
                               </select>
                               <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please choose customer type ' : (curentLang === 'ar' ? ' الرجاء اختيار نوع العميل ' : ' אנא בחר סוג לקוח ')}}  </div>
  
                               
                                  </div>
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Customer Name ' : (curentLang === 'ar' ? '  اسم العميل    ' : ' שם   ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control"
                                   type="text" 
                                   formControlName="customer_name_he" 
                                   maxlength="80"
                                   required="" onlyAlphabets
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم العميل ' : 'נא להזין את שם הלקוח')}}  </div>

                                </div>

                             

                                <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Customer Phone' : (curentLang === 'ar' ? ' هاتف العميل ' : ' טלפון לקוח ')}}
                                 </label>
                                 <div class="input-group">
                                  <span style="color: #141414;" class="input-group-text">+972</span>
                                  <input class="form-control" type="text" maxlength="10"    formControlName="customer_phone">
                                </div>
                                <div *ngIf="addCustomer.get('customer_phone')?.errors != null && addCustomer.get('customer_phone')?.touched" class="alert text-danger my-1">
                                  <p *ngIf="addCustomer.get('customer_phone')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                                  <p *ngIf="addCustomer.get('customer_phone')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{addCustomer.get('customer_phone')?.errors?.invalidMobilePrefix.message}}</p>
                                  <p *ngIf="addCustomer.get('customer_phone')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{addCustomer.get('customer_phone')?.errors?.invalidPhoneNumber.message}}</p>
                                </div>
                                </div> -->

                                <div class="mb-3 col-xl-4 col-md-4 " *ngIf="addCustomer.value.customer_type != 6">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'ID Number' : (curentLang === 'ar' ? 'رقم الهوية' : 'מס׳ זהות')}}
                                  
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control" type="number" 
                                   formControlName="id_number"
                                   maxlength="9"
                               
                                     />
                                </div>
              
                                <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'City' : (curentLang === 'ar' ? ' المدينة    ' : '  עִיר  ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" required="" onlyAlphabets
                                  class="form-control" type="text" 
                                   formControlName="customer_address_city"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer address city ' : (curentLang === 'ar' ? '  الرجاء ادخال مدينة العميل' : '  אנא הזן עיר לקוח')}}  </div>

                                </div> -->

                                <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Street Name' : (curentLang === 'ar' ? ' أسم الشارع  ' : '   שם רחוב ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" required="" onlyAlphabets
                                  class="form-control" type="text" 
                                   formControlName="customer_address_str"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer address street ' : (curentLang === 'ar' ? ' الرجاء ادخال شارع العميل ' : '  אנא הזן רחוב לקוח')}}  </div>

                                </div> -->

                                <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Building Number' : (curentLang === 'ar' ? 'رقم المبني ' : 'מספר בניין ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" required="" onlyAlphabets
                                  class="form-control" type="number" 
                                   formControlName="customer_Building_number"
                                   maxlength="20"
                                     /> 
                                    <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer building number ' : (curentLang === 'ar' ? '  الرجاء ادخال رقم المبني العميل' : '     אנא הזן מספר בניין לקוח  ')}}  </div>

                                </div> -->
                                
                                <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Zip Code' : (curentLang === 'ar' ? ' الرقم البريدي    ' : '  מיקוד דואר  ')}}
                                    <span class="text-danger" style="color: red;">*</span>
                                 </label>
                                  <input style="color: grey;" required="" onlyAlphabets
                                  class="form-control" type="number" 
                                   formControlName="customer_address_zip_code"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter customer zip code  ' : (curentLang === 'ar' ? '  الرجاء ادخال الرقم البريدي  ' : ' נא להזין את המיקוד ')}}  </div>

                                </div> -->

                                <div class="mb-3 col-xl-4 col-md-4 " *ngIf="addCustomer.value.customer_type != 5">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي للعميل' : '   מס עוסק מורשה  ')}}
                                      <span class="text-danger" style="color: red;">*</span>
                                  </label>
                                  <input style="color: grey;" 
                                         class="form-control" 
                                         type="text"
                                         (keypress)="isNumber($event)"
                                          maxlength="9"
                                          max="9"
                                          min="0"
                                         inputmode="numeric" 
                                         
                                         pattern="[0-9]{9}"
                                         title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}"
                                         formControlName="customer_vat_number"
                                         maxlength="9"
                                         required="" onlyAlphabets
                                  />
                                  <div *ngIf="addCustomer?.get('customer_vat_number').invalid && (addCustomer?.get('customer_vat_number').dirty || addCustomer?.get('customer_vat_number').touched)" class="text-danger">
                                    {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                                  </div>
                              </div>
                              
                              <!-- <div class="mb-3 col-xl-4 col-md-4 " *ngIf="addCustomer.value.customer_type != 5">
                                  <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Customer Vat Tax' : (curentLang === 'ar' ? 'رقم ضريبة القيمة المضافة' : ' מס מע"מ לקוחות    ')}}
                                  </label>
                                  <input style="color: grey;" 
                                         class="form-control" 
                                         type="number" 
                                         formControlName="customer_vat_tax"
                                         maxlength="9"
                                  />
                              </div> -->
                        </div>
                  
                          <div class="modal-footer">
                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                          </div>
                      </form>
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>




<!--add product-->

<ng-template #content2 let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Add Product ' : (curentLang === 'ar' ? ' اضافة منتج ' : '    הוספת פריט')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="addProduct" novalidate [ngClass]="{ 'was-validated': validate }">
                        <div class="row">
                            <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Product Name Ar' : (curentLang === 'ar' ? '  اسم المنتج عربي   ' : '  שם המוצר בערבית  ')}}
                               </label>
                                <input style="color: grey;" 
                                 class="form-control" maxlength="80" 
                                 type="text" formControlName="product_name_ar" 
                                  />
                              </div> -->
              
                              <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Name En' : (curentLang === 'ar' ? '  اسم المنتج انجليزي   ' : '   שם המוצר באנגלית  ')}}
                                 </label>
                                  <input style="color: grey;" 
                                  class="form-control"
                                   type="text"
                                   maxlength="80"
                                    formControlName="product_name_en"
                                   />
                                </div> -->
              
                                <div class="mb-3 col-xl-4 col-md-4 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Name ' : (curentLang === 'ar' ? '  اسم المنتج    ' : ' שם מוצר ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" class="form-control" onlyAlphabets required=""
                                   type="text" 
                                   formControlName="product_name_he" 
                                   maxlength="80"
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product name' : (curentLang === 'ar' ? '   الرجاء ادخال أسم المنتج ' : ' נא להזין את שם המוצר      ')}}  </div>

                                </div>
              
                                <div class="mb-3 col-xl-4 col-md-4  ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Product Code' : (curentLang === 'ar' ? '   رقم الصنف  ' : '  מספר פריט    ')}}
                                    <span class="text-danger">*</span>
                                 </label>
                                  <input style="color: grey;" onlyAlphabets required=""
                                  class="form-control" type="text" 
                                   formControlName="product_code"
                                   maxlength="20"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product code' : (curentLang === 'ar' ? '   الرجاء ادخال كود المنتج ' : ' נא להזין את קוד המוצר ')}}  </div>

                                </div>

                                <div class="mb-3 col-xl-4 col-md-4  ">
                                    <label class="form-label" style="color: grey;"> 
                                      {{ curentLang === 'en' ? 'Product Price' : (curentLang === 'ar' ? '    سعر المنتج ' : 'מחיר פריט')}}
                                      <span class="text-danger">*</span>
                                   </label>
                                    <input style="color: grey;"  onlyAlphabets required=""
                                    class="form-control" type="number" 
                                     formControlName="price"
                                     maxlength="20"
                                       />
                                       <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product price' : (curentLang === 'ar' ? '   الرجاء ادخال سعر المنتج ' : ' נא להזין את מחיר המוצר')}}  </div>

                                  </div>

                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Product Category' : (curentLang === 'ar' ? '    قسم المنتج ' : ' מחלקה   ')}}
                                        <span class="text-danger">*</span>
                                     </label>
     

                                   <ng-select formControlName="categories_id" [(ngModel)]="selectedProduct" onlyAlphabets required=""
                                   (change)="filterProduct(selectedProduct)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                    <ng-option *ngFor="let item of categoriesData; let i = index " [value]="item?.categories_id">
                                      {{ curentLang === 'en' ?  item?.categories_name_en :(curentLang === 'ar' ? item?.categories_name_ar  :  item?.categories_name_he )}}
                                    </ng-option>
                                  </ng-select>
                                  <div *ngIf="addProduct.get('categories_id')?.errors?.required" class="text-danger">
                                    <p>{{ curentLang === 'en' ? 'please chooce category' : (curentLang === 'ar' ? '   الرجاء اختيار القسم ' : 'אנא בחר מחלקה')}} </p>  
                                    </div>
                                  </div>
                                         
                                  <div class="mb-3 col-xl-6 col-md-6 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Measurement Unit Product' : (curentLang === 'ar' ? '  وحدة قياس المنتج ' : ' יחידה')}}
                                        <span class="text-danger">*</span>
                                     </label>
                                  

                                   <ng-select formControlName="measurement_unit_id" [(ngModel)]="selectedMeasurementUnit"  onlyAlphabets required=""
                                   (change)="filterMeasurementUnit(selectedMeasurementUnit)" style="cursor: pointer;" placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                    <ng-option *ngFor="let item of unitMesauereData; let i = index " [value]="item?.measurement_unit_id">
                                      {{ curentLang === 'en' ?  item?.measurement_unit_name_en :(curentLang === 'ar' ? item?.measurement_unit_name_ar  :  item?.measurement_unit_name_he )}}
                                    </ng-option>
                                  </ng-select>
                                  <div *ngIf="addProduct.get('measurement_unit_id')?.errors?.required" class="text-danger">
                                    <p>{{ curentLang === 'en' ? 'please chooce measurement unit name' : (curentLang === 'ar' ? '   الرجاء اختيار وحدة قياس المنتج  ' : '  אנא בחר יחידת מדידה של מוצר  ')}} </p>  
                                    </div>
                                  </div>

                                  <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description En' : (curentLang === 'ar' ? '  وصف المنتج انجليزي  ' : '   תיאור המוצר באנגלית')}} 
                                   </label>
                                        <textarea
                                         maxlength="600" name=""
                                          id="des_en" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_en">


                                   </textarea>
                                  </div> -->

                                  <!-- <div class="mb-3 col-xl-4 col-md-4 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description Ar' : (curentLang === 'ar' ? '  وصف المنتج عربي  ' : '   תיאור המוצר בערבית    ')}}   
                                   </label>
                                        <textarea
                                         maxlength="600" name=""
                                          id="des_ar" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_ar">
                                   </textarea>
                                  </div> -->
              
                                  <div class="mb-3 col-xl-12 col-md-12 ">
                                    <label class="form-label" style="color: grey;"> 
                                        {{ curentLang === 'en' ? 'Description ' : (curentLang === 'ar' ? '  وصف المنتج   ' : '  תיאור פרטי באנגלית')}} 
                                        <span class="text-danger">*</span>
                                   </label>
                                        <textarea
                                         maxlength="600" name=""  onlyAlphabets required=""
                                          id="des_he" cols="10" rows="5" type="text"
                                          class="form-control form-control-sm"
                                           formControlName="des_he">
                                   </textarea>

                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter product description  ' : (curentLang === 'ar' ? '   الرجاء ادخال وصف المنتج ' : 'נא להזין תיאור מוצר')}}  </div>

                                  </div>



                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button" 
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedProductData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form>
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>