<div class="page-title-area bg-color">
  <div class="container">
      <div class="page-title-content">
          <h2 class="text-white">{{ curentLang === 'en' ? 'Terms And Conditions   ' : (curentLang === 'ar' ? ' الشروط والأحكام'  : 'תנאים ')}}</h2>
          <ul>
            <li><a routerLink="/" style="color: #fff;">{{ curentLang === 'en' ? 'Home/' : (curentLang === 'ar' ? ' الرئيسية/'  : ' רָאשִׁי/ ')}}</a></li>
            <li class="text-white">{{ curentLang === 'en' ? 'Terms And Conditions' : (curentLang === 'ar' ? '  الشروط والأحكام'  : '  תנאים ')}}</li>
          </ul>
      </div>
  </div>
  <div class="divider"></div>
  <div class="lines">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
  </div>
  <div class="banner-shape1"><img src="../../../assets/shape/shape9.png" alt="image"></div>
</div>

<div class="container  mt-5">
  <div class="faq-wrap">
    <div class="row ">
      
 
      <div class="col-lg-12 mt-5">
  
        <div class="row default-according style-1 faq-accordion" id="accordionoc">
          <div class="col-xl-12 ">
        
    
            <div class="row default-according style-1 faq-accordion" id="accordionoc">
                <div class="col-xl-12">
                  <div class="faq-title">
                    
                  </div>
                   <div class="card" *ngFor="let item of termsData" >
                    <ngb-accordion #item="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0">
                      <ngb-panel>
                        <ng-template ngbPanelTitle ><i data-feather="help-circle" class="me-3"></i> 
                           {{ curentLang === 'en' ? item.question_en : (curentLang === 'ar' ? item.question_ar :  item.question_he)}}
  
                        </ng-template>
                        <ng-template ngbPanelContent>
                          {{ curentLang === 'en' ? item.answer_en : (curentLang === 'ar' ? item.answer_ar :  item.answer_he)}}
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>  
                </div>
            
              </div>
         
          </div>
       
        </div>
      </div>
     
    </div>
  </div>
</div>