<app-breadcrumb [title]="curentLang === 'en' ? 'Bank Branches Info' : (curentLang === 'ar' ? ' معلومات فروع البنك  ' : '   מידע על סניף הבנק    ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Bank Branches Info' : (curentLang === 'ar' ? '    معلومات فروع البنك   ' : '   מידע על סניף הבנק  ')">
</app-breadcrumb>

<!--Bank info -->

<div class="container-fluid basic_table" >
    <div class="row">
<div class="col-sm-12">
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h5 style="color: grey;"> 
            {{ curentLang === 'en' ? 'Bank Branches Info' : (curentLang === 'ar' ? 'معلومات فروع البنك  ' : '  מידע על סניף הבנק  ')}} 
        </h5>

       <div>
        <button  class="btn m-1" style=" background-color: #141414; color: #fff;" (click)="openXl(content)">
            <i class="fa fa-plus-circle mx-1"></i>
            {{ curentLang === 'en' ? 'Add Bank Branch ' : (curentLang === 'ar' ? ' اضافة فرع بنك ' : ' הוסף סניף בנק ')}} 
        </button>

        <button class="btn m-1 mx-1 btn-outline-warning"  (click)="getAllBankBranchById()">
          <i class="fa fa-refresh mx-1"></i> {{ curentLang === 'en' ? 'Refresh' : (curentLang === 'ar' ? '  تحديث   ' : '  רענן    ')}}
      </button>

         </div>
     
      </div>
      
      <div class="row">
        <div class="col-sm-5">
            <div class="form-group search m-3">
              <label for="seaarch" class="ml-2" style="color: grey;"> {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}  </label>
                 <input class="form-control  w-100" type="text" name="search"
                  [ngModelOptions]="{standalone: true}" id="seaarch" [(ngModel)]="term" placeholder=" {{curentLang === 'en' ? 'Search' : (curentLang === 'ar' ? 'بحث' : 'חיפוש')}}"/>
            </div>
        </div>
    </div>

      <div class="card-block row">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="table-responsive">
            <table class="table table-light text-center table-hover  " >
              <thead>
                <tr>
                  <th scope="col">{{ curentLang === 'en' ? 'index' : (curentLang === 'ar' ? '  م' : '  מ ') }}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Bank Branch Name En' : (curentLang === 'ar' ? '  أسم فرع البنك عربي  ' : ' שם סניף הבנק הוא עברי  ')}} </th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Bank Branch Code  ' : (curentLang === 'ar' ? '     كود فرع البنك  ' : ' קוד סניף בנק')}}</th>
                   <th scope="col"> {{ curentLang === 'en' ? 'Created Date' : (curentLang === 'ar' ? '  تاريخ التسجيل' : '  תאריך הרשמ')}}</th>
                  <!-- <th scope="col"> {{ curentLang === 'en' ? 'Status  ' : (curentLang === 'ar' ? ' الحالة ' : 'סטָטוּס ')}}</th> -->
                  <th scope="col"> {{ curentLang === 'en' ? 'Update  ' : (curentLang === 'ar' ? '   تعديل  ' : '  תיקון ')}}</th>
                  <th scope="col"> {{ curentLang === 'en' ? 'Delete  ' : (curentLang === 'ar' ? '   حذف  ' : '    מחק ')}}</th>

                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let item of bankBranchesData | paginate: {itemsPerPage:50, currentPage:p } |filter:term ; let i = index ">
                  <td scope="row">{{i+1}}</td>
                  <td> 
                    {{curentLang === 'en' ? item.bank_branch_name_en.substring(0, 20) : (curentLang === 'ar' ? item.bank_branch_name_ar.substring(0, 20) : item.bank_branch_name_he.substring(0, 20))}}
                  </td>

                   <td>{{item.bank_branch_code}}</td>
                   <td>{{ item.createdDate | date:'dd/MM/yyyy'}}</td>


                   <!-- <td>
                    <button  *ngIf="item.bank_branch_status==1" class=" btn" style="border: none;" (click)="changeBankStatus(item?.bank_branch_id, item.bank_branch_status)">
                        <i class="fa solid fa-solid fa-toggle-on " style=" color: #213FA6;" ></i>
                    </button> 

                    <button  *ngIf="item.bank_branch_status==59" class=" btn"  style="border: none;" (click)="changeBankStatus(item?.bank_branch_id, item.bank_branch_status)">
                        <i class="fa solid fa-solid fa-toggle-off " style=" color: red;"></i>
                    </button> 

                  </td> -->


                  <td >
                  <button  class=" btn" style="border: none;"
                   (click)="executeFunctions(  item?.bank_branch_id,updatedData ) ">
                    <i class="fa fa-edit" style=" cursor: pointer; color: green;">
                   </i>
                  </button>
                   </td>

                   <td >
                    <button  
                     class="btn text-danger" 
                    style="border: none;" 
                    (click)="getId(item?.bank_branch_id);openVerticallyCentered(dataDeleted)">
                      <i class="fa fa-trash-o" style=" cursor: pointer;"></i>
                      </button>
                     </td>

                     

                </tr>
          
              </tbody>
            </table>
            <pagination-controls (pageChange)="p = $event" class="m-auto my-3 current" > </pagination-controls>                

          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div></div>
          <div>
            <a routerLink="/dashboard/banks">
              <button class="btn btn-danger m-2">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה ')}}
                <i class="fa fa-rotate-left mx-1"></i>
              </button>
            </a>
          </div>
        </div>
      </div>
    
    </div>
  </div>

    </div>

    
</div>


<!--add Bank Branch-->

<ng-template #content let-modal>
    <div class="modal-header d-flex justify-content-between">
        <h6 class="modal-title"style="color: grey;">
                {{ curentLang === 'en' ? 'Add Bank ' : (curentLang === 'ar' ? ' اضافة بنك ' : '    הוסף בנק ')}} 
        </h6>
        <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="edit-profile">
              <div class="row d-flex justify-content-between">
                      <form  [formGroup]="addBank" novalidate [ngClass]="{ 'was-validated': validate }">
                        <div class="row">
                            <div class="mb-3 col-xl-6 col-md-6 ">
                                <label class="form-label" style="color: grey;"> 
                                  {{ curentLang === 'en' ? 'Bank Branch Name Ar' : (curentLang === 'ar' ? '  اسم فرع البنك عربي   ' : ' שם סניף הבנק בערבית')}}
                                  <span class="text-danger">*</span>
                               </label>
                                <input style="color: grey;" onlyAlphabets required="" 
                                 class="form-control" maxlength="200" 
                                 type="text" formControlName="bank_branch_name_ar" 
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter bank branch name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم فرع البنك ' : ' נא להזין את שם סניף הבנק ')}}  </div>

                              </div>
              
                              <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Bank Branch Name En' : (curentLang === 'ar' ? '  اسم فرع البنك انجليزي   ' : ' שם סניף הבנק הוא באנגלית ')}}
                                 </label>
                                  <input style="color: grey;" onlyAlphabets required="" 
                                  class="form-control"
                                   type="text"
                                   maxlength="200"
                                    formControlName="bank_branch_name_en"
                                   />
                                   <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter bank branch name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم فرع البنك ' : ' נא להזין את שם סניף הבנק ')}}  </div>

                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Bank Branch Name He' : (curentLang === 'ar' ? '  اسم فرع البنك عبري   ' : ' שם סניף הבנק הוא עברי ')}}
                                 </label>
                                  <input style="color: grey;" onlyAlphabets required="" class="form-control"
                                   type="text" 
                                   formControlName="bank_branch_name_he" 
                                   maxlength="200"
                                  />
                                  <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter bank branch name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم فرع البنك ' : ' נא להזין את שם סניף הבנק ')}}  </div>

                                </div>
              
                                <div class="mb-3 col-xl-6 col-md-6 ">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Bank Branch Code' : (curentLang === 'ar' ? ' كود فرع البنك ' : ' קוד סניף בנק')}}
                                 </label>
                                  <input style="color: grey;" onlyAlphabets required="" 
                                  class="form-control" type="text" 
                                   formControlName="bank_branch_code"
                                   maxlength="100"
                                     />
                                     <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter bank branch code' : (curentLang === 'ar' ? '   الرجاء ادخال كود فرع البنك ' : ' נא להזין את קוד סניף הבנק')}}  </div>

                                </div>

                    
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="addedData()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form> 
                
               
               
            
              
            </div>
            
          </div>
        
        </div>
    </div>
   
</ng-template>

<!--update Bank Branch-->

<ng-template #updatedData let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h6 class="modal-title"style="color: grey;">
              {{ curentLang === 'en' ? 'Update Info ' : (curentLang === 'ar' ? ' تعديل المعلومات ' : '    ערוך מידע ')}} 
      </h6>
      <button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
      <div class="modal-body">
          <div class="container-fluid ">
              <div class="edit-profile">
                <div class="row d-flex justify-content-between">
                      
                  
                    <form  [formGroup]="updateBank" novalidate [ngClass]="{ 'was-validated': validateUpdate }">
                        <div class="row">
                          <div class="mb-3 col-xl-6 col-md-6 ">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Bank Branch Name Ar' : (curentLang === 'ar' ? '  اسم فرع البنك عربي   ' : ' שם סניף הבנק בערבית')}}
                              <span class="text-danger">*</span>
                           </label>
                            <input style="color: grey;" onlyAlphabets required="" 
                             class="form-control" maxlength="200" 
                             type="text" formControlName="bank_branch_name_ar" 
                              />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter bank branch name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم فرع البنك ' : ' נא להזין את שם סניף הבנק ')}}  </div>

                          </div>
          
                          <div class="mb-3 col-xl-6 col-md-6 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Bank Branch Name En' : (curentLang === 'ar' ? '  اسم فرع البنك انجليزي   ' : ' שם סניף הבנק הוא באנגלית ')}}
                             </label>
                              <input style="color: grey;" onlyAlphabets required="" 
                              class="form-control"
                               type="text"
                               maxlength="200"
                                formControlName="bank_branch_name_en"
                               />
                               <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter bank branch name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم فرع البنك ' : ' נא להזין את שם סניף הבנק ')}}  </div>

                            </div>
          
                            <div class="mb-3 col-xl-6 col-md-6 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Bank Branch Name He' : (curentLang === 'ar' ? '  اسم فرع البنك عبري   ' : ' שם סניף הבנק הוא עברי ')}}
                             </label>
                              <input style="color: grey;" onlyAlphabets required="" class="form-control"
                               type="text" 
                               formControlName="bank_branch_name_he" 
                               maxlength="200"
                              />
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter bank branch name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم فرع البنك ' : ' נא להזין את שם סניף הבנק ')}}  </div>

                            </div>
          
                            <div class="mb-3 col-xl-6 col-md-6 ">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Bank Branch Code' : (curentLang === 'ar' ? ' كود فرع البنك ' : ' קוד סניף בנק')}}
                             </label>
                              <input style="color: grey;" onlyAlphabets required="" 
                              class="form-control" type="text" 
                               formControlName="bank_branch_code"
                               maxlength="100"
                                 />
                                 <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter bank branch code' : (curentLang === 'ar' ? '   الرجاء ادخال كود فرع البنك ' : ' נא להזין את קוד סניף הבנק')}}  </div>

                            </div>

                    
                        </div>
                  
                    
        
                          <div class="modal-footer">

                            <button type="button"  
                             class="btn btn-sm m-auto mt-2" 
                            style="background-color: #141414; color: #fff;"
                             (click)="updateBankBranchById()"
                             > 
                              {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                              <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                          </button>
                      
                              <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                                  {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                                  <i class="fa fa-rotate-left mx-1"></i>
                              </button>
                      
                            
                      
                          </div>
                      </form> 
                 
              
                
              </div>
              
            </div>
          
          </div>
      </div>
     
  </ng-template> 


    <!--Delete Bank Branch -->

    <ng-template #dataDeleted let-modal>
        <div class="modal-header">
            <h4 class="modal-title"></h4>
            <button type="button" class="close btn-close"  style="color: grey;"  aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p class="text-center text-danger"> <i class="fa fa-trash text-danger"></i>
              {{curentLang === 'en' ? 'Are you sure you want to delete it?' : (curentLang === 'ar' ? '  هل تريد بالتأكيد حذفه؟ ' : ' هل تريد بالتأكيد حذفه؟') }}
            </p>
        </div>
        <div class="modal-footer">
  
          <button type="button" 
           class="btn btn-sm m-auto mt-2" 
          style="background-color: #141414; color: #fff;"
           (click)="deleteBankBranchByID()"
           > 
            {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
            <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
        </button>
    
            <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
            </button>
    
          
    
        </div>
    </ng-template>