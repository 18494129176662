import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../../service/http-help.service";

@Component({
  selector: "app-view-company",
  templateUrl: "./view-company.component.html",
  styleUrls: ["./view-company.component.scss"]
})

export class ViewCompanyComponent implements OnInit {
  
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));

  companyInfoData:any=[];
  id:number;
  companyNameAr:string='';
  companyNameEn:string='';
  companyNamehE:string='';
  logoUrl:string='';
  TaxRegister:string='';
  CommercialRegister:string='';
  startInvoiceNumber :string='';
  VatListId :number;
  vatInfoData:any
  VatNameAR:string ='';
  VatNameEN:string='';
  VatNameHE:string='';
  VatPercentage:string='';
  CompanyAddressSTR :string='';
  CompanyBuildingNumber :string='';
  CompanyAddressCity :string='';
  CompanyAddressZipCode :string='';

  newOrders = {
    colorClass: "primary",
    icon: "new-order",
    colorClass2: "warning",
    icon2: "customers",
    colorClass3: "success",
    icon4: "invoice",
    colorClass4: "secondary",
    icon3: "profit"
  };
  constructor
  (  
     private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    activatedRoute:ActivatedRoute,
    ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.id = activatedRoute.snapshot.params['id'];
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  };

  getCompanyInfoById() {
    this.spinner.show();
    this.service.getCompanyInfoById(this.id).subscribe({
      next: res => {
        this.companyInfoData = res;       
        this.companyNameAr = this.companyInfoData?.company_name_ar;
        this.companyNameEn = this.companyInfoData?.company_name_en;
        this.companyNamehE = this.companyInfoData?.company_name_he;
        this.logoUrl = this.companyInfoData?.logo_url;
        this.TaxRegister = this.companyInfoData?.tax_register;
        this.CommercialRegister = this.companyInfoData?.commercial_register;
        this.startInvoiceNumber = this.companyInfoData?.start_invoice_number;
        this.VatListId = this.companyInfoData?.vat_list_id;
        this.VatNameAR = this.companyInfoData?.vatList?.vat_name_ar;
        this.VatNameEN = this.companyInfoData?.vatList?.vat_name_en;
        this.VatNameHE = this.companyInfoData?.vatList?.vat_name_he;
        this.CompanyAddressSTR = this.companyInfoData?.company_address_str;
        this.CompanyAddressCity = this.companyInfoData?.company_address_city;
        this.CompanyAddressZipCode = this.companyInfoData?.company_address_zip_code;
        this.CompanyBuildingNumber = this.companyInfoData?.company_Building_number;
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  filter(event:any)
  {
    let x = event.target.value;
   };

  getAllVatList() {
    this.spinner.show();
    this.service.getAllVatList().subscribe({
      next: res => {
        this.vatInfoData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  ngOnInit() {
    this.getCompanyInfoById();
    this.getAllVatList();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));
  };
}
