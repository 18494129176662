<button type="button" (click)="generatePDF2()" class="btn btn-sm my-3"
    style=" background-color: #141414; color: #fff;">
    <i class="fa fa-file-pdf-o mx-1"></i>
     {{ curentLang === 'en' ? 'Export PDF' : (curentLang === 'ar' ? 'تصدير PDF' : ' יְצוּא PDF')}}
</button>

 

<div class="container">
    <div class="row">
        <div class="col-sm-12 ">
        <div class="page m-auto  invoice-content" style="width: 800px; ">
            <div class="card-2">
          <div class="row d-flex justify-content-between  ">
           
            <div class="col-md-6  pt-2">
                <div class="mb-3">
                    <!-- <span style="font-weight: 500;"> {{ curentLang === 'en' ? '  Name' : (curentLang === 'ar' ? 'الاسم    ' : '   השם  ')}}</span>  : -->
                    <span style="font-weight: 500;" class="m-2" > {{receiptInfoData.customer_name}}</span>
                </div>

                <div>
                    <!-- <span style="font-weight: 500;">  {{ curentLang === 'en' ? '  Address' : (curentLang === 'ar' ? 'العنوان ' : ' הכתובת')}}</span> : -->
                    <span style="font-weight: 500;"class="m-2" > {{receiptInfoData.customer_address}}</span> 
                </div>

                <div>
                    <!-- {{ curentLang === 'en' ? 'ID' : (curentLang === 'ar' ? 'رقم الهوية  ' : 'תְעוּדַת זֶהוּת')}}: -->
                    <span  style="font-weight: 500" class="m-2">  {{receiptInfoData.customer_vat_number}}</span>
                    </div>

                <div>
                    <!-- {{ curentLang === 'en' ? 'ID' : (curentLang === 'ar' ? 'رقم الهوية  ' : 'תְעוּדַת זֶהוּת')}}: -->
                    <span  style="font-weight: 500" class="m-2">  {{receiptInfoData.id}}</span>
                    </div>


              
                    


            </div>

            <div class="col-md-6 pt-2 ">
                     <div class="invoice-number text-end ">
                        <span class="inv-title-1" style="font-weight: 500;" class="m-2">
                            <!-- {{ curentLang === 'en' ? 'Company Name:' : (curentLang === 'ar' ? '  أسم الشركة:  ' : '    שם החברה:  ')}} -->
                            {{receiptInfoData.company_name}} 
                            
                        </span>
                        <div class="invo-addr-1">
                            <div class="mt-2" style="font-weight: 500;" class="m-2">
                                <!-- {{ curentLang === 'en' ? 'Company City:' : (curentLang === 'ar' ? ' مدينة الشركة:' : 'עיר חברה :')}} -->
                                {{receiptInfoData.company_address_city}}
                                
                            </div>
                            <div class="mt-2" style="font-weight: 500;" class="m-2">
                                <!-- {{ curentLang === 'en' ? 'Company Street Name:' : (curentLang === 'ar' ? '  اسم شارع الشركة:' : 'שם רחוב של החברה:')}} -->

                                {{receiptInfoData.company_address_str}}

                            </div>
                            <div class="mt-2" style="font-weight: 500;" class="m-2">
                                <!-- {{ curentLang === 'en' ? 'Company Building Number:' : (curentLang === 'ar' ? '  رقم مبني الشركة:' : 'מספר בניין החברה: ')}} -->
                                {{receiptInfoData.company_Building_number}}
                            </div>
                            <div class="mt-2" style="font-weight: 500;" class="m-2">
                                <!-- {{ curentLang === 'en' ? '    Post number: ' : (curentLang === 'ar' ? ' الرقم البريدي:  ' : ' מיקוד דואר:')}} -->
                                {{receiptInfoData.company_address_zip_code}}
                                
                            </div>


                         
                         </div>
                    </div>
                    
                 
            </div>


            <div class="m-auto text-center" style="font-weight: 500; font-size: 28px;">
                <strong>{{ curentLang === 'en' ? 'Receipt' : (curentLang === 'ar' ? 'ايصال  ' : 'קַבָּלָה')}} {{receiptInfoData.receipt_number}}</strong>
            </div>
 

            <div class="row  ">
                <div class="col-sm-6 mb-30">
                   
                        
                        <div>
                            <span style="font-weight: 500;" class="mt-3 mx-2  text-center">{{receiptInfoData.copy_version}}</span>
                        </div>
               

                </div>
                <div class="invoice text-end  col-sm-6">
                    <div >
                        <span style="font-weight: 500;"  >               
                         <!-- {{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? 'تاريخ  ' : 'תַאֲרִיך')}}: -->
                          {{receiptInfoData.date | date: 'yyyy-MM-dd'}}
                        </span>
                    </div>                                                     
                    <!-- <div style="font-weight: 500;" class="mx-5">{{ curentLang === 'en' ? 'Account Key' : (curentLang === 'ar' ? ' مفتاح الحساب ' : 'מפתח חשבון')}} : {{receiptInfoData.account_key}}</div>                                               -->
                    <!-- <div style="font-weight: 500;" class="mx-5">{{ curentLang === 'en' ? 'ID' : (curentLang === 'ar' ? 'رقم الهوية  ' : 'תְעוּדַת זֶהוּת')}}:  {{receiptInfoData.id}}</div> -->
                    <!-- <div style="font-weight: 500;" class="mx-5">{{ curentLang === 'en' ? 'Currency' : (curentLang === 'ar' ? 'العملة  ' : 'מַטְבֵּעַ')}}: {{receiptInfoData.currency}}</div>    -->
                   
                </div>
            </div>



            <div class="order-summary  ">

                <div class="col-lg-12 " >
                    <ng-container  >
                        <div class="mt-4 d-flex justify-content-between">
                            <span style="font-weight: bold; " class="mx-2">{{ curentLang === 'en' ? 'Payment Info' : (curentLang === 'ar' ? 'بيانات الدفع  ' : ' נתוני תשלום')}} </span>
                            <!-- <span style="font-weight: 500;" class="mx-3">{{ receiptInfoData.checks_amount | number:'1.0-0' }}</span> -->
                        </div>  
                
                        <div class="table-responsive ">
                            <table class="table invoice-table">
                                <thead>
                                
                                    <tr>
                                        <th
                                            class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Payment Method ' : (curentLang === 'ar' ? 'طرق الدفع  ' : 'אמצעי תשלום')}}
                                        </th>
                                        <!-- <th
                                            class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Ref' : (curentLang === 'ar' ? 'مرجع  ' : 'רפ')}}</th> -->
                                        <!-- <th
                                            class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Bank' : (curentLang === 'ar' ? 'بنك  ' : 'בַּנק')}}
                                        </th> -->
                                        <!-- <th
                                            class="text-center" style="font-weight: bold;" > {{ curentLang === 'en' ? 'Branch' : (curentLang === 'ar' ? 'فرع  ' : 'סניף')}}
                                        </th>
                                        
                                        <th
                                            class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Account' : (curentLang === 'ar' ? 'حساب  ' : 'חֶשְׁבּוֹן')}}
                                        </th> -->

                                        <th
                                            class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Details' : (curentLang === 'ar' ? 'تفاصيل  ' : 'פרטים')}}</th>
                                            <th
                                            class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Date' : (curentLang === 'ar' ? ' تاريخ  ' : ' תאריך')}}</th>
                                        <th class="text-center" style="font-weight: bold;" >{{ curentLang === 'en' ? 'Total' : (curentLang === 'ar' ? 'المجموع  ' : 'סכום')}}</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor=" let item of receiptInfoData?.receiptsDetails">
                                        <td class="text-center" style="font-weight: 400; color: black;">
                                            <span *ngIf=" item?.payment_type == 1"> {{ curentLang === 'en' ? 'Cash' : (curentLang === 'ar' ? 'كاش' : 'כַּךָ')}}</span>
                                            <span *ngIf=" item?.payment_type == 2"> {{ curentLang === 'en' ? 'Check' : (curentLang === 'ar' ? 'شيك' : 'סִּכָּה')}}</span>
                                            <span *ngIf=" item?.payment_type == 3"> {{ curentLang === 'en' ? 'Bank' : (curentLang === 'ar' ? 'بنك' : 'בַּנק')}}</span>
                                            <span *ngIf=" item?.payment_type == 4"> {{ curentLang === 'en' ? 'Credit' : (curentLang === 'ar' ? 'ائتمان' : 'קִרְּדָּן')}}</span>
                                        </td>
                                        <!-- <td class="text-center">{{ item.bank }}</td>
                                        <td class="text-center">{{ item.branch }}</td>
                                        <td class="text-center">{{ item.account }}</td>
                                         -->

                                        <td class="text-center description-column" style="font-weight: 400; color: black;">{{ item.details }}</td>
                                        <td class="text-center" style="font-weight: 400; color: black;">{{ item.date | date: 'yyyy-MM-dd' }}</td>
                                        <td class="text-center" style="font-weight: 400; color: black;">{{ item.amount | number:'1.2-2'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>
                </div>


              

                <div class="small-table my-4">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-between" >
                            <div class="col-md-6 mt-5">
                              <!-- <span>Weighted date: 05/05/24</span> -->
                             </div>
                             <div class="col-md-6">
                                <div class="row d-flex justify-content-between mx-1" style="background-color: #F1F1F1;">
                                    <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                        <span class="py-1"> {{ curentLang === 'en' ? 'Deduction at source' : (curentLang === 'ar' ? 'الخصم من المصدر  ' : 'ניכוי במקור')}} :  </span>
                                    </div>

                                    <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                        <span class="mx-1">{{receiptInfoData.deduction_from_source | number:'1.0-0'}}</span>
                                    </div>

                                    <div class="col-md-6 py-1" style="font-weight: bold; color: black;">
                                        <span>{{ curentLang === 'en' ? 'Total' : (curentLang === 'ar' ? 'المجموع' : 'סה"כ')}}  :  </span>
                                    </div>

                                    <div class="col-md-6 text-end py-1" style="font-weight: 400; color: black;">
                                        <span class="mx-1">{{ receiptInfoData.total_amount | number:'1.2-2' }}</span>

                                    </div>


                                </div>
                                
                             </div>

                             <div class="col-md-6">

                             </div>
                        </div>
                    </div>
                </div>
                
                
                
                
                
            </div>



          </div>





          

            </div>

        </div>
    </div>
    </div>
</div>

 


    <!--back-->
    <div class=" d-flex justify-content-between"> 

        <button type="button"  hidden
        class="btn   m-2" 
       style="background-color: #141414; color: #fff;"
        
        > 
         {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? '     تحديث ' : '     עדכן  ') }}
         <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
     </button>

        <a routerLink="/dashboard/receipts">
          <button class="btn btn-danger m-2 " >
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
          </button>
        </a>

      </div>