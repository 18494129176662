import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { HttpHelpService } from "../service/http-help.service";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { addNewMerchantByAdmin } from '../service/global.service';
import { israelPhoneNumberValidator } from './../../validators/israelPhoneNumberValidator';

@Component({
  selector: "app-merchant",
  templateUrl: "./merchant.component.html",
  styleUrls: ["./merchant.component.scss"]
})

export class MerchantComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  merchantForm:FormGroup;
  term:any;
  p: any;
  merchantInfoData:any=[];
  userData:any=[];
  MerchantPhone:any
  public show: boolean = false;
  planData:any=[];
  planId: number;
  public validate = false;
  profileData:any = [];
  public file: any;
  url: any; 
  files: File[] = [];
  currentImageUrl:any
  @ViewChild('content4', { static: true }) content4!: TemplateRef<any>;

  uploadImage=new FormGroup({
		file :new FormControl(''),  
		id:new FormControl(''),
	  });
  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private router: Router ,
    private modalService: NgbModal,
    private cdRef:ChangeDetectorRef ,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.user_type = JSON.parse(localStorage.getItem('user_type'));
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));

    this.merchantForm = new FormGroup({
   'phone_number': new FormControl('', [Validators.required, israelPhoneNumberValidator()]),
        'user_name': new FormControl('', [Validators.required, Validators.maxLength(20)]),
      'language': new FormControl(this.curentLang),
      'merchant_name_en': new FormControl('', [  Validators.maxLength(80)]),
      'merchant_name_ar': new FormControl('', [ Validators.maxLength(80)]), // Removed extra comma
      'merchant_name_he': new FormControl('', [Validators.required, Validators.maxLength(80)]), // Removed extra comma
      'merchant_address': new FormControl('', [Validators.required]),
      'plan_id': new FormControl('', [Validators.required]),
      'id_number': new FormControl('', [Validators.required, Validators.maxLength(20)]), // Removed extra comma
      'password': new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[!@#$%^&*()_\-+=<>?])[A-Z][A-Za-z0-9!@#$%^&*()_\-+=<>?]{3,20}$/)]),
      created_by:new FormControl(this.userRegistId),

    });

    
  };


  submitMerchantForm(merchantForm: FormGroup) {
    let customerPhone = this.merchantForm.value.phone_number;
    if (customerPhone.startsWith('0')) {
      customerPhone = customerPhone.substring(1);
    }
  
    if (
      this.merchantForm.value.phone_number === '' ||
      this.merchantForm.value.user_name === '' ||
      this.merchantForm.value.password === '' ||
      this.merchantForm.value.merchant_name_he === '' ||
      this.merchantForm.value.merchant_address === '' ||
      this.merchantForm.value.plan_id === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة' : 
               (this.curentLang === 'en' ? 'Please fill out the required fields' : 
               (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים' : ''))
      });
      this.validate = !this.validate;
      return;
    } else {
      let merchantPhone = this.merchantForm.value.phone_number;
      if (merchantPhone.length < 7) {
        Swal.fire({
          icon: 'error',
          title: this.curentLang === 'ar' ? 'يجب ألا يقل رقم الهاتف عن 7 أرقام' : 
                 (this.curentLang === 'en' ? 'The phone number must be at least 7 digits long' : 
                 (this.curentLang === 'he' ? 'מספר הטלפון חייב להיות בן 7 ספרות לפחות' : ''))
        });
        return;
      }
  
      const validPrefixes = ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59'];
      let validationStartPhoneNumber = customerPhone.substring(0, 2);
  
      if (!validPrefixes.includes(validationStartPhoneNumber)) {
        Swal.fire({
          icon: 'warning',
          title: this.curentLang === 'ar' ? 'يجب أن يبدأ رقم الهاتف بأي رقم من هذة الأرقام (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                 (this.curentLang === 'en' ? 'Phone number must start with one of these numbers (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : 
                 (this.curentLang === 'he' ? 'צריך להתחיל עם אחד מהמספרים (50, 51, 52, 53, 54, 55, 56, 57, 58, 59)' : ''))
        });
        return;
      }
  
      let customerPhoneFinal = this.merchantForm.value.phone_number;
      if (customerPhoneFinal.startsWith('0')) {
        customerPhoneFinal = customerPhoneFinal.substring(1);
      }
  
      let model = {
        phone_number: '+972' + customerPhoneFinal,
        user_name: merchantForm.value.user_name,
        language: this.curentLang,
        merchant_name_en: merchantForm.value.merchant_name_en,
        merchant_name_ar: merchantForm.value.merchant_name_ar,
        merchant_name_he: merchantForm.value.merchant_name_he,
        merchant_address: merchantForm.value.merchant_address,
        plan_id: merchantForm.value.plan_id,
        id_number: merchantForm.value.id_number,
        password: merchantForm.value.password,
        created_by: this.userRegistId
      };
  
      this.spinner.show();
  
      this.service.Post(addNewMerchantByAdmin.POST, model).subscribe({
        next: (response: any) => {
          if (response.status === 400 || response.status === 403) {
            let errorMessage = "";
            switch (this.curentLang) {
              case "ar":
                errorMessage = response.messageAr;
                break;
              case "en":
                errorMessage = response.messageEn;
                break;
              case "he":
                errorMessage = response.messageHe;
                break;
              default:
                errorMessage = "Unknown error occurred";
            }
            Swal.fire({
              icon: 'error',
              title: errorMessage,
            });
          } else {
            let successMessage = "";
            switch (this.curentLang) {
              case "ar":
                successMessage = response.messageAr;
                break;
              case "en":
                successMessage = response.messageEn;
                break;
              case "he":
                successMessage = response.messageHe;
                break;
              default:
                successMessage = "Unknown message";
            }
            Swal.fire({
              icon: 'success',
              title: successMessage,
            });
            this.modalService.dismissAll();
            this.getAllMerchantInfo();
            this.getAllPans();
            this.merchantForm.reset({
              phone_number: '',
              user_name: '',
              password: '',
              merchant_name_en: '',
              merchant_name_ar: '',
              merchant_name_he: '',
              merchant_address: '',
              plan_id: '',
              id_number: ''
            });
          }
          this.spinner.hide();
        },
        error: (error: any) => {
          Swal.fire({
            icon: 'error',
            title: error.message || 'Error',
            text: error.message_ar || error.message_en || error.error_en || error.error_ar || 'Unknown error occurred',
          });
          this.spinner.hide();
        }
      });
    }
  };
  
  isNumber(event: KeyboardEvent) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    } else {
      return true;
    }
  }

  showPassword() {
    this.show = !this.show;
  }
  getAllMerchantInfo() {
    this.spinner.show();
    this.service.getAllMerchantInfo().subscribe({
      next: res => {
        this.merchantInfoData = res;
         const user = this.merchantInfoData[0].users.find(user => user.user_type === 100);
  
  
        if (user) {
           this.MerchantPhone = this.formatPhoneNumber(user.phone_number);
       
         } else {
         }
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getAllPans() {
    this.spinner.show();
    this.service.getAllPans().subscribe({
      next: res => {
        this.planData = res;
          
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  getId(id:number)
{
   this.merchantId = id ;
};

getPlanId(id: number) {
  this.planId = id;
};


filter(event:any)
{
  let x = event;
   
 };
openXl(content:any) {
  this.modalService.open(content, { size: 'xl' , centered:true });
};
changeStatus(id:number, merchant_status:any){
  this.spinner.show();

  let model =
  {
    "merchant_status": merchant_status == 1 ? 59 : 1,
   "updated_by": this.userRegistId
  }

  this.service.changeMerchantStatus(id , model ).subscribe(({
    
    next:res =>{
     if (res.status ==200){

      Swal.fire({
        icon: 'success',
        title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
      })
        this.getAllMerchantInfo();
        this.spinner.hide();
     }

    },
    error:err =>{
      Swal.fire({
        icon:"error",
        title:err.error.message[0]
      });
      this.spinner.hide();

    }
    
  }))
};

  ngOnInit() {
    this.getAllMerchantInfo();
    this.getAllPans();
    this.getProfileInfo();
    this.getALLUsersByBasedToken();

  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));

  };

  getProfileInfo() {
		this.spinner.show();
		this.service.getProfileInfo().subscribe({
		  next: res => {
			this.profileData = res;		
       
			const data =  this.profileData?.lookupDetailsUserType?.lookupDetailEnName;
 			  localStorage.setItem( "userType_en",data );
			 
		
			this.spinner.hide();
		  },
		  error: error => {
			this.spinner.hide();
		  }
		});
	  };
	
    executeFunctions(userId: number, uploadData: any) {
      this.openVerticallyCentered2(uploadData , userId);
      this.setValue(userId);
      const selectedProfile = this.profileData;
      
        if (selectedProfile) {
        this.currentImageUrl = selectedProfile?.image_url; 
        } 
      };

      
	  openVerticallyCentered2(uploadData:any , userId:any) {
      this.modalService.open(uploadData, { centered: true });
      userId = this.userRegistId;
      
      };
  
      setValue(userId: number) {
      const userToUpdate = this.profileData; 
      
      if (userToUpdate) {
        this.uploadImage?.get('id')?.setValue(userToUpdate.company_id);
        this.uploadImage?.get('file')?.setValue(this.currentImageUrl);
      }
      };
  
      onFileChanged(event: any) {
        this.file = event.target.files[0];
        
         if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
        
            reader.onload = (event:any) => {
            this.currentImageUrl = event.target.result; 
            this.cdRef.detectChanges();
            }
        
            reader.readAsDataURL(event.target.files[0]);
        
          };
        };
    
        uploadFile()
        {
        
        const formData= new FormData();
        if(this.file != null){
          formData.append("file", this.file, this.file.name);
        }
           this.spinner.show();
         this.service.updateUserImage(this.userRegistId , formData  ).subscribe(({
          next:response=>
          {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? response.messageAr : (this.curentLang === 'en' ? response.messageEn : response.messageHe)
          });
          this.modalService.dismissAll();
          this.getProfileInfo();
          this.uploadImage.reset();
          this.spinner.hide();
         if(response.status == 400) {
          Swal.fire(response.message);
          this.spinner.hide();
        }
        },
        error:error =>
        {
        Swal.fire(error.message);
        this.spinner.hide();
        }
           
         }));
        };

        formatPhoneNumber(phoneNumber: string): string {
          // Remove any non-digit characters except '+'
          const cleaned = phoneNumber.replace(/[^ \d]/g, '');
        
          // Ensure the number ends with '+'
          if (!cleaned.endsWith('+')) {
            return `${cleaned}+`;
          }
        
          return cleaned;
        };

        openXl2(content2:any) {
          this.modalService.open(content2, { size: 'lg'  });
        };

        openXl3(content3:any) {
          this.modalService.open(content3, { size: 'lg'  });
        };

        openXl4(content4:any) {
          this.modalService.open(content4, { size: 'lg'  });
        };

        openXl5(content5:any) {
          this.modalService.open(content5, { size: 'xl'  });
        };

        openXl6(content6:any) {
          this.modalService.open(content6, { size: 'xl'  });
        };

        openXl7(content7:any) {
          this.modalService.open(content7, { size: 'xl'  });
        };

        getALLUsersByBasedToken() {
          this.spinner.show();
          this.service.getALLUsersByBasedToken().subscribe({
            next: res => {
              this.userData = res;
               this.spinner.hide();
            },
            error: error => {
              this.spinner.hide();
            }
          });
        };

        
}
