<app-breadcrumb [title]="curentLang === 'en' ? 'Update Finaical Request' : (curentLang === 'ar' ? ' تعديل المطالبة المالية ' : '    עדכון בקשה פיניאלית  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['  לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Update Finaical Request' : (curentLang === 'ar' ? ' تعديل المطالبة المالية ' : '      עדכון בקשה פיניאלית    ')">
</app-breadcrumb>



<div class="container-fluid basic_table">
    <div class="row">
<div class="col-sm-12 ">
    <div class="card">
      

      <div class="card-block row"> 
        <div class="col-sm-12 col-lg-12 col-xl-12">
            <div>

                <form [formGroup]="invoiceform">
     
                            <div class="row">
                                <div class="col-lg-12 row">
                
                                    <div class="col-md-6 col-sm-12">
                                        <div class="form-group mx-2">
                                            <label class="mt-2">{{ curentLang === 'en' ? 'Select Company Name' : (curentLang === 'ar' ? 'اختر اسم الشركة' : 'בחר את שם החברה  ')}}</label>
                                            <span class="text-danger">*</span>
                                            <!-- <select formControlName="company_id"  (change)="getCompanyId($event.target?.value);getCompanyInfoById();" class="form-control form-select">
                                                <option *ngFor="let item of mastercompany" [value]="item.company_id">
                                                    {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }}
                                                </option>
                                            </select> -->
    
                                            <ng-select formControlName="company_id" (change)="getCompanyId($event);getCompanyInfoById();"  style="cursor: pointer;"
                                            placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                               <ng-option *ngFor="let item of mastercompany; let i = index " [value]="item.company_id">
                                                {{ curentLang === 'en' ? item.company_name_en : (curentLang === 'ar' ? item.company_name_ar : item.company_name_he) }}
                                            </ng-option>
                                             </ng-select>
                                            
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-sm-12">
                                        <div class="form-group mx-2">
                                            <label class="mt-2"> {{ curentLang === 'en' ? 'Select Customer Name' : (curentLang === 'ar' ? 'اختر اسم العميل' : 'בחר את שם הלקוח ')}}</label>
                                            <span class="text-danger">*</span>
                                            <!-- <select formControlName="customer_id" (change)="getCompanyId($event.target?.value);getCustomerById();" class="form-control form-select">
                                                <option *ngFor="let item of mastercustomer" [value]="item.customer_id">
                                                    {{ curentLang === 'en' ? item.customer_name_en : (curentLang === 'ar' ? item.customer_name_ar : item.customer_name_he) }}
                                                </option>
                                            </select> -->


                                            <ng-select formControlName="customer_id" (change)="getCompanyId($event);getCustomerById();"  style="cursor: pointer;"
                                            placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                               <ng-option *ngFor="let item of mastercustomer; let i = index " [value]="item.customer_id">
                                                {{ curentLang === 'en' ? item.customer_name_en : (curentLang === 'ar' ? item.customer_name_ar : item.customer_name_he) }}
                                            </ng-option>
                                             </ng-select>

                                        </div>
                                        </div>
                       

                                   
                                    
                
                                </div>

                                <div class="col-lg-12 row">
                                    <div class="col-md-4">
                                        <div class="col-lg-12 ">
                                            <div class="form-group mx-2 ">
                                                <label class="mt-2"> {{ curentLang === 'en' ? 'Invoice Date' : (curentLang === 'ar' ? 'تاريخ الفاتورة' : 'תאריך חשבונית ')}}</label>
                                                <input type="date"  (change)="filter($event); getVatPercentageByVatListIdAndDate()"
                                                 formControlName="invoice_date" class="form-control w-100">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group mx-2">
                                            <label class="mt-2"> {{ curentLang === 'en' ? 'Vat Type' : (curentLang === 'ar' ? 'نوع الضريبة' : 'סוג המס') }}</label>
                                            <span class="text-danger">*</span>
  

                                            <ng-select formControlName="vat_list_id"   (change)="getVatPercentageByVatListIdAndDate()"style="cursor: pointer;"
                                             placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                <ng-option *ngFor="let item of vatTypeList; let i = index " [value]="item?.vat_list_id">
                                                    {{ curentLang === 'en' ? item.vat_name_en : (curentLang === 'ar' ? item.vat_name_ar : item.vat_name_he) }}      
                                                 </ng-option>
                                              </ng-select>

                                        </div> 
                                    </div>

                                    <div class="col-md-4">
                                        <div class="form-group mx-2">
                                            <label class="mt-2"> {{ curentLang === 'en' ? 'Document Language' : (curentLang === 'ar' ? 'لغة المستند  ' : 'שפת המסמך')}}</label>
                                            <select formControlName="document_language" class="form-control form-select">
                                                <option *ngFor="let lang of availableLanguages" [ngValue]="lang.code" [selected]="lang.code === curentLang">
                                                    {{ lang.name }}
                                                </option>
                                            </select>

                       

                                        </div>
                                        </div>
                                        
                   
                                </div>
                
                
                            </div>
                            <br>
                
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="table-responsive">
                                        <table class="table table-bordered d-none d-lg-table" formArrayName="lines">
                
                                            <thead class="   ">
                                                <tr>
                                                    <th  > {{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם המוצר')}}</th>
                                                    <th  > {{ curentLang === 'en' ? 'Product Desc' : (curentLang === 'ar' ? ' وصف المنتج' : 'תיאור מוצר')}}</th>

                                                    <th> {{ curentLang === 'en' ? 'Price per unit' : (curentLang === 'ar' ? 'سعر الوحدة' : 'מחיר ליחידה')}}</th>
                                                    <th> {{ curentLang === 'en' ? 'Quantity' : (curentLang === 'ar' ? 'الكمية' : 'כמות')}}</th>
                                                    <th> {{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'خصم' : 'הנחה')}}</th>
                                                    <th>  {{ curentLang === 'en' ? 'Sub Total' : (curentLang === 'ar' ? 'المجموع الفرعي' : 'סכום כולל')}} </th>
                                                    <th> {{ curentLang === 'en' ? 'Vat Rate' : (curentLang === 'ar' ? 'نسبة الضريبة' : 'שיעור מס  ')}}</th>
                                                    <th> {{ curentLang === 'en' ? 'Vat Amount' : (curentLang === 'ar' ? 'قيمة الضريبة' : 'סכום מס  ')}}</th>
                                                    <th> {{ curentLang === 'en' ? 'Total Amount' : (curentLang === 'ar' ? 'المجموع' : 'סכום כולל')}}</th>
                                                    <th> {{ curentLang === 'en' ? 'Remove' : (curentLang === 'ar' ? 'حذف' : 'מחק')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of invproducts.controls;let i=index" [formGroupName]="i">
                                                    <td style="width:20%">
 
                                                        <ng-select formControlName="product_id"  (change)="productchange(i)"  style="cursor: pointer; "
                                                        placeholder="{{ curentLang === 'en' ? 'Select' : (curentLang === 'ar' ? 'اختر' : 'בחר') }}">
                                                           <ng-option *ngFor="let item of masterproduct; let i = index " [value]="item.product_id">
                                                            {{ curentLang === 'en' ? item.product_name_en : (curentLang === 'ar' ? item.product_name_ar : item.product_name_he) }}
                                                        </ng-option>
                                                         </ng-select>

                                                    </td>

                                                    <td style="width:20%">
 
                                                        <textarea
                                                        maxlength="250" name=""
                                                         id="des_en" cols="10" rows="1" type="text"
                                                         class="form-control form-control-sm"
                                                         formControlName="description" >
                        
                        
                                                  </textarea>
                                                    </td>
                    
                                                    <td style="width:10%">
                                                        <input class="form-control" type="number"  min="0" (change)="Itemcalculation(i)" formControlName="price_per_unit" />
                                                    </td>
                    
                                                    <td style="width:7%">
                                                        <input class="form-control" type="number"  min="0" (change)="Itemcalculation(i)" formControlName="quantity" />
                                                    </td>
                    
                                                    <td style="width:7%">
                                                        <input class="form-control" type="number"  min="0" (change)="Itemcalculation(i)" formControlName="discount" />
                                                    </td>
                    
                                                  
                                                    <td style="width:10%">
                                                        <input class="form-control" formControlName="sub_total" />
                                                    </td>
                    
                                                    <td style="width:5%">
                                                        <input class="form-control" formControlName="vat_rate" />
                                                    </td>
                    
                                                    <td style="width:8%">
                                                        <input class="form-control" formControlName="vat_amount" />
                                                    </td>
                    
                                                    <td style="width:8%">
                                                        <input class="form-control" formControlName="total_amount" />
                                                    </td>
                                                    <td style="width:5%">
                                                        <i (click)="Removeproduct(i)" class="fa fa-trash"
                                                        style="font-size: 22px; cursor: pointer; color: red;" aria-hidden="true">
                                                       </i>
                                                    </td>
                    
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colspan="6">
                                                        <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addnewproduct()">
                                                            <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                         {{ curentLang === 'en' ? 'Add Product' : (curentLang === 'ar' ? 'اضافة منتج  ' : 'הוסף מוצר')}}    </button>
                                                    </td>
                                                </tr>
                                            </tfoot>
                    
                                        </table>
                                    </div>


                                      <!-- Mobile layout for smaller screens -->
                                      <div class="d-lg-none" formArrayName="lines">

                
                                        <div *ngFor="let item of invproducts.controls; let i=index" [formGroupName]="i" class="border p-3 my-2">
                                            <div class="form-group">
                                                <label>{{ curentLang === 'en' ? 'Product Name' : (curentLang === 'ar' ? 'اسم المنتج' : 'שם המוצר')}}</label>
                                                <ng-select formControlName="product_id" (change)="productchange(i)" style="cursor: pointer;">
                                                    <ng-option *ngFor="let item of masterproduct; let i = index" [value]="item.product_id">
                                                        {{ curentLang === 'en' ? item.product_name_en : (curentLang === 'ar' ? item.product_name_ar : item.product_name_he) }}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                            <div class="form-group">
                                                <label>{{ curentLang === 'en' ? 'Product Desc' : (curentLang === 'ar' ? 'وصف المنتج' : 'תיאור מוצר')}}</label>
                                                <textarea maxlength="250" cols="10" rows="2" class="form-control form-control-sm" formControlName="description"></textarea>
                                            </div>
                                            <div class="form-group">
                                                <label>{{ curentLang === 'en' ? 'Price per unit' : (curentLang === 'ar' ? 'سعر الوحدة' : 'מחיר ליחידה')}}</label>
                                                <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="price_per_unit" />
                                            </div>
                                            <div class="form-group">
                                                <label>{{ curentLang === 'en' ? 'Quantity' : (curentLang === 'ar' ? 'الكمية' : 'כמות')}}</label>
                                                <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="quantity" />
                                            </div>
                                            <div class="form-group">
                                                <label>{{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'خصم' : 'הנחה')}}</label>
                                                <input class="form-control" type="number" min="0" (change)="Itemcalculation(i)" formControlName="discount" />
                                            </div>
                                            <div class="form-group">
                                                <label>{{ curentLang === 'en' ? 'Vat Amount' : (curentLang === 'ar' ? 'قيمة الضريبة' : 'סכום מס')}}</label>
                                                <input class="form-control" formControlName="vat_amount" />
                                            </div>
                                            <div class="form-group">
                                                <label>{{ curentLang === 'en' ? 'Total Amount' : (curentLang === 'ar' ? 'المجموع' : 'סכום כולל')}}</label>
                                                <input class="form-control" type="number" min="0" formControlName="total_amount" (change)="onTotalAmountChange(i)" />
                                            </div>
                                            <div class="text-center">
                                                <button (click)="Removeproduct(i)" class="btn btn-sm m-2" style="background-color: red; color: #fff;">
                                                    <i class="fa fa-trash" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                    {{ curentLang === 'en' ? 'Delete' : (curentLang === 'ar' ? 'حذف' : 'לִמְחוֹק') }}
                                                </button>
                                            </div>
                                        </div>
                            
                                        <!-- Add button for small screens -->
                                        <div class="text-center mt-3">
                                            <button type="button" class="btn my-2" style="background-color: #141414; color: #fff;" (click)="addnewproduct()">
                                                <i class="fa fa-plus-circle mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                                {{ curentLang === 'en' ? 'Add Product' : (curentLang === 'ar' ? 'اضافة منتج' : 'הוסף מוצר') }}
                                            </button>
                                        </div>
                                    </div>



                                
                                    <div class="row d-flex justify-content-between">
                                       
                
                                                <div class="col-md-6 col-sm-12" style="color: black;">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="d-flex flex-column">
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'Amount Before Discount' : (curentLang === 'ar' ? '   المبلغ قبل الخصم' : '  סכום לפני הנחה')}}</div>
                                                                    <input formControlName="amount_before_discount" class="form-control">
                                                                </div>
                                                                
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'Discount' : (curentLang === 'ar' ? 'الخصم' : 'הנחה')}}</div> 
                                                                    <input formControlName="all_discount" class="form-control">
                                                                </div>
                                                                
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'Payment Amount' : (curentLang === 'ar' ? ' قيمة الدفع  ' : 'ערך תשלום')}}</div>
                                                                    <input formControlName="payment_amount" class="form-control">
                                                                </div>
                                                                
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'VAT Amount' : (curentLang === 'ar' ? '   قيمة الضريبة' : '  ערך מס    ')}} ( {{VatPercentage }} %)</div>
                                                                    <input formControlName="all_vat_amount" class="form-control">
                                                                </div>
                                                                
                                                                <div class="d-flex justify-content-between px-4 my-2">
                                                                    <div style="width: 200px;"> {{ curentLang === 'en' ? 'Payment Amount Including VAT' : (curentLang === 'ar' ? 'قيمة المبلغ شاملة الضريبة' : 'הסכום כולל מס')}}</div>
                                                                    <input formControlName="payment_amount_including_vat" class="form-control">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                       
                                                    </div>
                                                </div>
                
                                               
                        
                                                <div class="col-md-6 col-sm-12" style="color: black;">
                                                    <div class="mt-8 px-4">
                                                        <div>
                                                            <label for="notes"> {{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? 'ملاحظة' : 'הערה')}}</label>
                                                            <textarea
                                                                formControlName="invoice_note"
                                                                id="notes" 
                                                                name="notes"
                                                                class="form-textarea form-control my-2"
                                                                placeholder="{{ curentLang === 'en' ? 'Note' : (curentLang === 'ar' ? 'ملاحظة' : 'הערה')}}"
                                                                name="notes"
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <div class=" w-100 mt-8 px-4 d-flex justify-content-between">
                                                        <div>
                                                            <label for="allocation_number_1">
                                                                {{ curentLang === 'en' ? 'Assignment number' : (curentLang === 'ar' ? 'رقم تخصيص' : 'מספר משימה')}}
                                                            </label>
                                                            <input type="radio" formControlName="allocation_number" [value]="1" id="allocation_number_1" >
                                                        </div>
                                                    
                                                        <div>
                                                            <label for="allocation_number_0">
                                                                {{ curentLang === 'en' ? 'Without assignment number' : (curentLang === 'ar' ? 'بدون رقم تخصيص' : 'ללא מספר משימה')}}
                                                            </label>
                                                            <input type="radio" formControlName="allocation_number" [value]="0" id="allocation_number_0" >
                                                        </div>
                                                    </div>
                                                </div>
                                                
                
                                        
                                    </div>
                                </div>
                
                            </div>
                            <br>
                            <div class="row  d-flex justify-content-between text-center">
                             
                                     <br>

                                    <div class="col-4 col-lg-4 p-1   ">
                                        <button class="btn  " style="background-color: #141414; color: #fff;" type="submit" (click)="confirmInvoice()">
                                            <i class="fa fa-check mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === 'en' ? 'Confirm ' : (curentLang === 'ar' ? 'تأكيد    ' : 'כדי להיות בטוח')}}
                                        </button> 
                                    </div>

                                    <div class="col-4 col-lg-4 p-1    ">
                                        <button type="button" class="btn "   style="background-color: green; color: #fff;" type="submit" (click)="SaveUpdatedInvoice()">
                                            <i class="fa fa-save mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i>
                                            {{ curentLang === 'en' ? 'Save ' : (curentLang === 'ar' ? 'حفظ    ' : ' שמור ')}}
                                          </button>
                                    </div>         
                                   
                                        <div class="col-4 col-lg-4 p-1    ">
                                            <button class="btn  btn-danger" routerLink="/dashboard/finaical-request">
                                                <i><i class="fa fa-rotate-left mx-1" style="font-size: 14px; cursor: pointer; color: #fff;"></i></i>
                                                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? 'رجوع' : 'חזרה')}}</button>
                                        </div>
                                  
                                </div>
                
                             

                    
                </form>
            </div>
            
        </div>

   

      </div>
    </div>
  </div>


  <!----------------------------------->
   
  <!-- <div class=" col-sm-6 col-lg-6 col-xl-6 ">
     
    <div class="card  p-3  ">
       <div class="panel mb-5 text-center  bg-white ">
           <h4 class="text-black dark:text-white m-2"> {{ curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? 'معلومات الشركة' : 'מידע על החברה')}}</h4>
           <div class="flex shrink-0 items-center text-black dark:text-white m-2 ">
             <img [src]="LOGO" alt="" class="my-3 text-center" style="width: 100px; height: 100px; border-radius: 50%;"/>
         </div>
         <div class="mt-6 space-y-1 my-2 mx-2" style="color: black;">
           <div>{{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? 'اسم الشركة' : 'שם החברה')}} : {{ curentLang === 'en' ? CompanyNameEn : (curentLang === 'ar' ? CompanyNameAr : CompanyNameHe)}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address Street' : (curentLang === 'ar' ? 'شارع  الشركة' : 'מספר רחוב כתובת החברה')}} : {{ CompanyAddress_str}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address Building Number' : (curentLang === 'ar' ? 'رقم المبنى الشركة' : 'עיר כתובת החברה')}} : {{ CompanyAddress_number}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address City' : (curentLang === 'ar' ? 'مدينة الشركة' : 'מיקוד כתובת החברה')}} : {{ CompanyAddress_city}}</div>
           <div>{{ curentLang === 'en' ? 'Company Address Zip Code' : (curentLang === 'ar' ? 'رمز بريدي الشركة' : 'ארץ כתובת החברה')}} : {{ CompanyAddress_zip_code}}</div>
           
           <div> {{ curentLang === 'en' ? 'Company Tax Number' : (curentLang === 'ar' ? ' الرقم الضريبي للشركة  ' : 'מספר מס חברה  ')}} : {{TaxCompany}}</div>
           
       </div>
         
          
        
        
     </div>
    </div>
 
</div>

<div class=" col-sm-6 col-lg-6 col-xl-6   ">

   <div class=" card  p-3  ">
       <div class="panel mb-5 text-center bg-white ">
           <h4 class="text-black dark:text-white m-2">  {{ curentLang === 'en' ? 'Customer Info' : (curentLang === 'ar' ? 'معلومات العميل' : 'מידע על הלקוח')}}</h4>
        
         <div class="mt-6 space-y-1 my-2 mx-2" style="color: black;">
           <div> {{ curentLang === 'en' ? 'Customer Name' : (curentLang === 'ar' ? 'اسم العميل' : 'שם הלקוח')}} : {{  curentLang === 'en' ? CustomerNameEn : (curentLang === 'ar' ? CustomerNameAr : CustomerNameHe)}}</div>
           <div>{{ curentLang === 'en' ? 'Customer Address' : (curentLang === 'ar' ? 'عنوان العميل' : 'כתובת הלקוח')}} : {{ oneCustomerData?.customer_address}}</div>
           <div> {{ curentLang === 'en' ? 'Customer Vat Number' : (curentLang === 'ar' ? 'الرقم الضريبي للعميل' : 'מספר מס חשבונית')}} : {{oneCustomerData?.customer_vat_number}}</div>
           <div> {{ curentLang === 'en' ? 'Customer Id Number' : (curentLang === 'ar' ? 'رقم الهوية' : 'מספר תעודת זהות')}} : {{oneCustomerData?.id_number}}</div>
       </div>
         
         </div>
   </div>
     
</div> -->
   
    
 

    </div>
</div>
