<app-breadcrumb [title]="curentLang === 'en' ? 'Notifications Info' : (curentLang === 'ar' ? ' معلومات الأشعارات' : '   מידע על הודעה  ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : ['    לוח הבקרה'])" 
[active_item]="curentLang === 'en' ? 'Notifications Info' : (curentLang === 'ar' ? ' معلومات الأشعارات ' : '    מידע על הודעה  ')">
</app-breadcrumb>

<div class="row">
<div class="col-sm-12">
<div class="card">
  

    <div class="card-header card-no-border d-flex justify-content-between">
      <div class="header-top">
        <h5 class="m-0" style="color: black;">
          {{ curentLang === 'en' ? 'All Notifications  ' : (curentLang === 'ar' ? '  كل الأشعارات ' : ' כל ההתראות  ') }}
        </h5>
       
      </div>

      <div>

      <button  class="btn m-1" style=" background-color: #141414; color: #fff;"  (click)="openVerticallyCenteredConfirmedAll(dataConfirmAl)" >
        <i class="fa fa-check-circle-o text-white mx-1"></i>
        {{ curentLang === 'en' ? 'Accept All' : (curentLang === 'ar' ? ' قبول الكل' : 'קבל הכל')}}
    </button>
 
    <button class="btn m-1 mx-1 btn-danger"  (click)="openVerticallyCenteredRejectedAll(dataRejectAll)" >
      <i class="fa fa-times-circle mx-1"></i> {{ curentLang === 'en' ? 'Reject All' : (curentLang === 'ar' ? 'رفض الكل' : 'הוא דחה את כולם')}}
  </button>

      </div>

    </div>
    <div class="card-body pt-0 campaign-table">
      <div class="recent-table table-responsive currency-table">
        <table class="table text-center">
          <thead style="background-color: grey; color: black;">
            <tr>
              <th class="f-light">   {{ curentLang === 'en' ? 'Company Logo' : (curentLang === 'ar' ? '  لوجو الشركة ' : '    תמונת חברה ') }} </th>
              <th class="f-light">{{ curentLang === 'en' ? 'Company Name' : (curentLang === 'ar' ? '  أسم الشركة ' : '       שם החברה   ') }}</th>
              <th class="f-light">{{ curentLang === 'en' ? 'Merchant Name' : (curentLang === 'ar' ? '    أسم التاجر ' : ' שם סוחר  ') }}</th>
              <th class="f-light">{{ curentLang === 'en' ? 'Notification Info' : (curentLang === 'ar' ? ' تفاصيل الأشعار ' : 'שים לב לפרטי') }}</th>
              <th class="f-light">{{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'قبول' : 'אישור')}}</th>
              <th class="f-light">  {{ curentLang === 'en' ? 'Reject' : (curentLang === 'ar' ? 'رفض' : 'לדחות')}}</th>
             </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of notificationsData | paginate: {itemsPerPage: limit, currentPage: currentPage, totalItems: total}; let i = index">
                 
                <td>
                <img [src]="item?.company?.logo_url" alt="logo" style="width: 50px; height: 50px ; border-radius: 50%;"  >
              </td>
              <td> {{curentLang === 'en' ? item?.company?.company_name_en : (curentLang === 'ar' ? item?.company?.company_name_ar : item?.company?.company_name_he)}}</td>
              <td>{{curentLang === 'en' ? item?.merchant?.merchant_name_en : (curentLang === 'ar' ? item?.merchant?.merchant_name_ar : item?.merchant?.merchant_name_he)}}</td>
              <td>
                {{ getMessage(item, curentLang) }}
              </td>
              <td>
                <button style=" background-color: black; color: white;"
                (click)="getId(item?.permission_id);openVerticallyCenteredConfirmed(dataConfirm)"
                class="btn  btn-sm"  ><i class="fa fa-check-circle-o text-white mx-1"></i> {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? 'قبول' : 'אישור')}}
              </button>
              </td>

              <td>
              <button class="btn btn-sm" style="background-color: red; color: whitesmoke;"
              (click)="getId(item?.permission_id);openVerticallyCenteredRejected(dataReject)">
               <i class="fa fa-times-circle mx-1" style="color: #fff;"></i>
              {{ curentLang === 'en' ? 'Reject' : (curentLang === 'ar' ? 'رفض' : 'לדחות')}}
              </button>       
             </td>
           
            </tr>
          </tbody>
        </table>

        <div class="pagination-container">
          <pagination-controls 
            (pageChange)="onPageChange($event)" 
            (totalItems)="total" 
            (currentPage)="currentPage">
          </pagination-controls>
          <input type="number" class="mt-1" [(ngModel)]="customLimit" (change)="onLimitChange()">

        </div>  


      </div>
    </div>
  </div>
</div>
</div>
              <!--confirm-->

              <ng-template #dataConfirm let-modal>
                <div class="modal-header">
                <h4 class="modal-title"></h4>
                <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                  <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
                    {{curentLang === 'en' ? 'Are you sure you want to accept this company offer ?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد قبول عرض هذة الشركة ؟' : ' האם אתה בטוח שאתה רוצה לקבל את ההצעה של חברה זו? ') }}
                  </p>
                </div>
              
                <div class="modal-footer">

                  <button type="button" 
                   class="btn btn-sm m-auto mt-2" 
                  style="background-color: #141414; color: #fff;"
                   (click)="changeCompanyPermissionStatus()"
                   > 
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                </button>
            
                    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                    </button>
            
                  
            
                </div>


              </ng-template>



                 <!--reject-->

                 <ng-template #dataReject let-modal>
                    <div class="modal-header">
                    <h4 class="modal-title"></h4>
                    <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body text-center">
                      <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
                        {{curentLang === 'en' ? 'Are you sure you want to reject this company offer ?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد رفض عرض هذة الشركة ؟' : 'האם אתה בטוח שאתה רוצה לדחות את ההצעה של החברה הזו?') }}
                      </p>
                    </div>
                  
                    <div class="modal-footer">
    
                      <button type="button" 
                       class="btn btn-sm m-auto mt-2" 
                      style="background-color: #141414; color: #fff;"
                       (click)="changeCompanyPermissionStatusReject()"
                       > 
                       {{ curentLang === 'en' ? 'Reject' : (curentLang === 'ar' ? 'رفض' : 'לדחות')}}
                        <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                    </button>
                
                        <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                            <i class="fa fa-rotate-left mx-1"></i>
                        </button>
                
                      
                
                    </div>
    
    
                  </ng-template>



                      <!--confirm all-->

              <ng-template #dataConfirmAl let-modal>
                <div class="modal-header">
                <h4 class="modal-title"></h4>
                <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                  <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
                    {{curentLang === 'en' ? 'Are you sure you want to accept offers from all companies?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد قبول عروض كل الشركات ؟' : 'האם אתה בטוח שאתה רוצה לקבל הצעות מכל החברות?') }}
                  </p>
                </div>
              
                <div class="modal-footer">

                  <button type="button" 
                   class="btn btn-sm m-auto mt-2" 
                  style="background-color: #141414; color: #fff;"
                   (click)="changeCompanyPermissionStatusAll()"
                   > 
                    {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                </button>
            
                    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                    </button>
            
                  
            
                </div>


              </ng-template>


               <!--reject all-->

               <ng-template #dataRejectAll let-modal>
                <div class="modal-header">
                <h4 class="modal-title"></h4>
                <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body text-center">
                  <p class="text-center text-danger"> <i class="fa fa-check-circle  text-danger"></i>
                    {{curentLang === 'en' ? 'Are you sure you want to reject these companies offers?' : (curentLang === 'ar' ? 'هل تريد بالتأكيد رفض عروض هذة الشركات ؟' : 'האם אתה בטוח שברצונך לדחות את ההצעות של חברות אלה?') }}
                  </p>
                </div>
              
                <div class="modal-footer">

                  <button type="button" 
                   class="btn btn-sm m-auto mt-2" 
                  style="background-color: #141414; color: #fff;"
                   (click)="changeCompanyPermissionStatusRejectAll()"
                   > 
                   {{ curentLang === 'en' ? 'Reject' : (curentLang === 'ar' ? 'رفض' : 'לדחות')}}
                    <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
                </button>
            
                    <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
                        {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                        <i class="fa fa-rotate-left mx-1"></i>
                    </button>
            
                  
            
                </div>


              </ng-template>