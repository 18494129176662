import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HttpHelpService } from "../service/http-help.service";
import Swal from 'sweetalert2';
import { AddCategoryEndPoient } from "../service/global.service";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"]
})

export class CategoriesComponent implements OnInit {
  curentLang:string="";
  layoutVersion:any;
  userRegistId:any = JSON.parse(localStorage.getItem('user_id'));
  merchantId = JSON.parse(localStorage.getItem('merchant_id'));
  user_type = JSON.parse(localStorage.getItem('user_type'));
  term:any;
  p: any;
  addCategory:FormGroup;
  updateCategory:FormGroup;
  categoriesData:any=[];
  categoryId: number;
  updatedData: any;
  viewData: any;
  public validate = false;
  public validateUpdate = false;

  constructor(
    private spinner: NgxSpinnerService ,
    public translate:TranslateService ,
    private service:HttpHelpService  ,
    private modalService: NgbModal,
  ) 
  { 
    this.curentLang = localStorage.getItem('curentLang');
    this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
    this.user_type = JSON.parse(localStorage.getItem('user_type'));


   this.addCategory=new FormGroup({
    categories_name_ar :new FormControl('' , Validators.required),
    categories_name_en:new FormControl('' , Validators.required),
    categories_name_he :new FormControl('' , Validators.required),  
    categories_code :new FormControl('' , Validators.required),  
     created_by:new FormControl(this.userRegistId),
    });

    this.updateCategory=new FormGroup({
      categories_name_ar :new FormControl('' , Validators.required),
      categories_name_en:new FormControl('' , Validators.required),
      categories_name_he :new FormControl('' , Validators.required),  
      categories_code :new FormControl('' , Validators.required),  
      updated_by:new FormControl(this.userRegistId),
      });
  };

  getAllCategories() {
    this.spinner.show();
    this.service.getAllCategories().subscribe({
      next: res => {
        this.categoriesData = res;
         
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  };

  openXl(content:any) {
    this.modalService.open(content, { size: 'xl' , centered:true });
  };

  addedData() {
    const categories_name_ar = this.addCategory.value.categories_name_ar.trim();
    const categories_name_en = this.addCategory.value.categories_name_en.trim();
    const categories_name_he = this.addCategory.value.categories_name_he.trim();
    const categories_code = this.addCategory.value.categories_code.trim();
  
    if (
      categories_name_ar === '' ||
      categories_name_en === '' ||
      categories_name_he === '' ||
      categories_code === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.addCategory.markAllAsTouched();
      this.validate = !this.validate;
      return;
    } else {
      let model = {
        categories_name_ar: categories_name_ar,
        categories_name_en: categories_name_en,
        categories_name_he: categories_name_he,
        categories_code: categories_code,
        created_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.Post(AddCategoryEndPoient.POST, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllCategories();
          this.addCategory.reset();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
  };
  

  setValue(categoryId: number) {
    for (let i = 0; i < this.categoriesData.length; i++) {
      if (this.categoriesData[i].categories_id == this.categoryId) {
        const selectedCategory = this.categoriesData.find(item => item.categories_id === categoryId);

        this.updateCategory.controls.categories_name_en.setValue(this.categoriesData[i].categories_name_en);
        this.updateCategory.controls.categories_name_ar.setValue(this.categoriesData[i].categories_name_ar);
        this.updateCategory.controls.categories_name_he.setValue(this.categoriesData[i].categories_name_he);
        this.updateCategory.controls.categories_code.setValue(this.categoriesData[i].categories_code);
      }
    };
  };

  executeFunctions(vatPerscentgeId: number, uploadData: any) {
    this.updatedData = uploadData;
    this.getId(vatPerscentgeId);
    this.setValue(vatPerscentgeId);
    this.updateMd(this.updatedData);
 
  };

  executeFunctionsView(vatPerscentgeId: number, view: any) {
    this.viewData = view;
    this.getId(vatPerscentgeId);
    this.setValue(vatPerscentgeId);
    this.updateViewMd(this.viewData);
 
  };

  updateMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  updateViewMd(paymentsUpdated: any) {
    this.modalService.open(paymentsUpdated, { size: 'xl' , centered:true});
  };

  updatedCategoryData() {

    const categories_name_ar = this.updateCategory.value.categories_name_ar.trim();
    const categories_name_en = this.updateCategory.value.categories_name_en.trim();
    const categories_name_he = this.updateCategory.value.categories_name_he.trim();
    const categories_code = this.updateCategory.value.categories_code.trim();
  
    if (
      categories_name_ar === '' ||
      categories_name_en === '' ||
      categories_name_he === '' ||
      categories_code === ''
    ) {
      Swal.fire({
        icon: 'warning',
        title: this.curentLang === 'ar' ? 'يرجى ملء الحقول المطلوبة وعدم تركها فارغة أو تحتوي على مسافات فقط' : (this.curentLang === 'en' ? 'Please fill out the required fields and do not leave them empty or containing only spaces' : (this.curentLang === 'he' ? 'אנא מלא את השדות הנדרשים ואל תשאיר אותם ריקים או המכילים רק רווחים' : ''))
      });
  
      this.updateCategory.markAllAsTouched();
      this.validateUpdate = !this.validateUpdate;
      return;
    }

    else
    {
      let model = {
        categories_name_ar: this.updateCategory.value.categories_name_ar,
        categories_name_en: this.updateCategory.value.categories_name_en,
        categories_name_he: this.updateCategory.value.categories_name_he,
        categories_code: this.updateCategory.value.categories_code,
        updated_by: Number(this.userRegistId),
      };
    
      this.spinner.show();
      this.service.updateCategoryById( this.categoryId, model).subscribe((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe)
          });
          this.modalService.dismissAll();
          this.getAllCategories();
        } else if (res.status === 400 || res.status === 403 || res.status === 500) {
          let errorMessage;
          switch (res.status) {
            case 400:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 403:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            case 500:
              errorMessage = this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe);
              break;
            default:
              errorMessage = this.curentLang === 'ar' ? 'حدث خطأ غير معروف' : (this.curentLang === 'en' ? 'An unknown error occurred' : 'אירעה שגיאה לא ידועה');
          }
          Swal.fire({
            icon: 'error',
            title: errorMessage
          });
        }
        this.spinner.hide();
      });
    }
};

  getId(id: number) {
    this.categoryId = id;
  };

  changeStatus(id:number, categories_status:any){
    this.spinner.show();
  
    let model =
    {
      "categories_status": categories_status == 1 ? 59 : 1,
     "updated_by": this.userRegistId
    }
  
    this.service.changeCategoryStatus(id , model ).subscribe(({
      
      next:res =>{
       if (res.status ==200){

        Swal.fire({
          icon: 'success',
          title: this.curentLang === 'ar' ? res.messageAr : (this.curentLang === 'en' ? res.messageEn : res.messageHe),
        })
          this.getAllCategories();
          this.spinner.hide();
       }
  
      },
      error:err =>{
        Swal.fire({
          icon:"error",
          title:err.error.message[0]
        });
        this.spinner.hide();
  
      }
      
    }))
  };
  ngOnInit() {

    this.getAllCategories();
  this.layoutVersion = localStorage.getItem("layout_version");
   if (this.layoutVersion == null || this.layoutVersion == undefined) {
     localStorage.setItem("layout_version", "light-only");
     this.layoutVersion= "light-only"; 
   } else {
     this.layoutVersion = localStorage.getItem("layout_version"); 
     if (this.layoutVersion === "dark-only") {
       document.body.classList.add("dark-only");
     } else {
       document.body.classList.add("light-only");

     }
   }
   this.userRegistId = JSON.parse(localStorage.getItem('user_id'));
   this.merchantId = JSON.parse(localStorage.getItem('merchant_id'));
   this.user_type = JSON.parse(localStorage.getItem('user_type'));

  };
}
