<app-breadcrumb [title]="curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '  מידע על החברה   ')" 
[items]="curentLang === 'en' ? ['Dashboard'] : (curentLang === 'ar' ? ['لوحة التحكم'] : [' לוח הבקרה '])" 
[active_item]="curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '  מידע על החברה   ')">
</app-breadcrumb>


<div class="container-fluid ">
    <div class="edit-profile">
      <div class="row">
        <div class="col-xl-4 ">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0" style="color:  grey;"> 
                {{curentLang === 'en' ? 'Company Logo' : (curentLang === 'ar' ? ' لوجو الشركة  ' : '  לוגו חברה  ')}}
            </h5>
              <div class="card-options">
                <a class="card-options-collapse" href="javascript:void(0)" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="javascript:void(0)" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a>
              </div>
            </div>
            <div class="card-body">
              <form >
                <div class="row mb-2">
                  <div class="col-auto icon-container position-relative" style="background-color: #E9E9E9;">
                    <img class="rounded" style="width: 220px; height: 220px;" alt="company logo"
                         [src]="companyInfoData.logo_url ? companyInfoData.logo_url == null || companyInfoData.logo_url == '' : 'assets/default-logo/WhatsApp Image 2024-07-09 at 21.30.21_fc2afcbb.jpg'" />
                    <i (click)="executeFunctions(companyInfoData?.company_id, uploadData);"  
                       class="fa fa-camera position-absolute"></i>
                </div>
                
                </div>
              </form>
            </div>
          </div>
        </div>
       
        <div class="col-xl-8 " *ngIf="user_type == 100 || user_type == 101">
            <div class="card ">
                <div class="card-header mx-4">
                    <h5 style="color: grey;"> 
                      {{curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '  מידע על החברה   ')}}
                    </h5>
                  </div>
                <div class="card-body">
                    <form  [formGroup]="updateCompanyInfo">
            <div class="row">
               

              
              <div class="col-xxl-6 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                   <div class="card-body {{ newOrders.colorClass }}" >
                    <div class="d-flex align-items-end gap-1">
                      <div class="mb-3 w-75">
                        <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Company Name Ar' : (curentLang === 'ar' ? '  اسم الشركة عربي   ' : '  שמו של הסוחר הוא ערבי  ')}}
                       </label>
                        <input style="color: grey;" class="form-control" 
                        type="text"  [(ngModel)]="companyNameAr"
                        formControlName="company_name_ar"/>
                      </div>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div> 
                </div>
              </div>
              <!---->

              <div class="col-xxl-6 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <div class="d-flex align-items-end gap-1">
                      <div class="mb-3 w-75">
                        <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Company Name En' : (curentLang === 'ar' ? '  اسم الشركة انجليزي   ' : ' שם החברה באנגלית')}}
                       </label>
                        <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="companyNameEn"
                        formControlName="company_name_en"/>
                      </div>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div> 
                </div>
              </div>
              <!---->

              <div class="col-xxl-6 col-sm-6 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <div class="d-flex align-items-end gap-1">
                      <div class="mb-3 w-75">
                        <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Company Name He ' : (curentLang === 'ar' ? '  اسم الشركة عبري    ' : 'שם החברה הוא עברי ')}}       
                       </label> 
                        <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="companyNamehE"
                        formControlName="company_name_he"/>
                      </div>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

                        <!---->
           
                        <div class="col-xxl-6 col-sm-6 box-col-6 " >
                          <div class="card small-widget">
                            <div class="card-body {{ newOrders.colorClass }}" >
                              <div class="d-flex align-items-end gap-1">
                                <div class="mb-3 w-75">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company City' : (curentLang === 'ar' ? ' مدينة الشركة' : 'עיר חברה ')}}
                                 </label> 
                                  <input style="color: grey;" class="form-control " type="text" formControlName ="company_address_city"   [(ngModel)]="CompanyAddressCity"/>
                                </div>
          
                              </div>
                              <div class="bg-gradient">
                                <svg class="stroke-icon svg-fill">
                                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!---->

                        <div class="col-xxl-4 col-sm-4 box-col-4 " >
                          <div class="card small-widget">
                            <div class="card-body {{ newOrders.colorClass }}" >
                              <div class="d-flex align-items-end gap-1">
                                <div class="mb-3 w-75">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company Street Name' : (curentLang === 'ar' ? '  اسم شارع الشركة' : 'שם רחוב של החברה')}}
                                 </label> 
                                  <input style="color: grey;" class="form-control " type="text" formControlName ="company_address_str"   [(ngModel)]="CompanyAddressSTR"/>
                                </div>
          
                              </div>
                              <div class="bg-gradient">
                                <svg class="stroke-icon svg-fill">
                                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!---->

                        <div class="col-xxl-4 col-sm-4 box-col-4 " >
                          <div class="card small-widget">
                            <div class="card-body {{ newOrders.colorClass }}" >
                              <div class="d-flex align-items-end gap-1">
                                <div class="mb-3 w-75">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? 'Company Building Number' : (curentLang === 'ar' ? '  رقم مبني الشركة' : 'מספר בניין החברה ')}}
                                 </label> 
                                  <input style="color: grey;" class="form-control " type="number" formControlName ="company_Building_number"   [(ngModel)]="CompanyBuildingNumber"/>
                                </div>
          
                              </div>
                              <div class="bg-gradient">
                                <svg class="stroke-icon svg-fill">
                                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!---->
                        <div class="col-xxl-4 col-sm-4 box-col-4 " >
                          <div class="card small-widget">
                            <div class="card-body {{ newOrders.colorClass }}" >
                              <div class="d-flex align-items-end gap-1">
                                <div class="mb-3 w-75">
                                  <label class="form-label" style="color: grey;"> 
                                    {{ curentLang === 'en' ? ' Post Number    ' : (curentLang === 'ar' ? ' الرقم البريدي  ' : ' מספר פוסט  ')}}
                                 </label> 
                                  <input style="color: grey;" class="form-control " type="text" formControlName ="company_address_zip_code"   [(ngModel)]="CompanyAddressZipCode"/>
                                </div>
          
                              </div>
                              <div class="bg-gradient">
                                <svg class="stroke-icon svg-fill">
                                  <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>



                           <!--back-->
          <div class=" d-flex justify-content-between"> 

            <button type="button" [disabled]="!updateCompanyInfo.valid"
            class="btn   m-2" 
           style="background-color: #141414; color: #fff;"
            (click)="updateCompanyById()"
            > 
             {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? '     تحديث ' : '     עדכן  ') }}
             <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
         </button>

            <a routerLink="/dashboard/companies">
              <button class="btn btn-danger m-2 " >
                {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
                <i class="fa fa-rotate-left mx-1"></i>
              </button>
            </a>

          </div>

             
            </div>
        </form>
            </div>
          </div>
       

      </div>


      <div class="col-xl-8 "  *ngIf="user_type == 200 || user_type == 201">
        <div class="card ">
            <div class="card-header mx-4">
                <h5 style="color: grey;"> 
                  {{curentLang === 'en' ? 'Company Info' : (curentLang === 'ar' ? ' معلومات الشركة  ' : '  מידע על החברה   ')}}
                </h5>
              </div>
            <div class="card-body">
                <form  [formGroup]="updateCompanyInfoByAdmin" novalidate [ngClass]="{ 'was-validated': validate }">
        <div class="row">

          <div class="col-xxl-6 col-sm-12 box-col-6 " >
            <div class="card small-widget">
               <div class="card-body {{ newOrders.colorClass }}" >
                <div class="d-flex align-items-end gap-1">
                  <div class="mb-3 w-75">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Company Name Ar' : (curentLang === 'ar' ? '  اسم الشركة عربي   ' : '  שמו של הסוחר הוא ערבי  ')}}

                   </label>
                    <input style="color: grey;" class="form-control" 
                    type="text"  [(ngModel)]="companyNameAr"
                    formControlName="company_name_ar"/>
                  </div>

                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div> 
            </div>
          </div>
          <!---->

          <div class="col-xxl-6 col-sm-12 box-col-6 " >
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass }}" >
                <div class="d-flex align-items-end gap-1">
                  <div class="mb-3 w-75">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Company Name En' : (curentLang === 'ar' ? '  اسم الشركة انجليزي   ' : ' שם החברה באנגלית')}}
                   </label>
                    <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="companyNameEn"
                    formControlName="company_name_en"/>
                  </div>

                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div> 
            </div>
          </div>
          <!---->

          <div class="col-xxl-6 col-sm-12 box-col-6 " >
            <div class="card small-widget">
              <div class="card-body {{ newOrders.colorClass }}" >
                <div class="d-flex align-items-end gap-1">
                  <div class="mb-3 w-75">
                    <label class="form-label" style="color: grey;"> 
                      {{ curentLang === 'en' ? 'Company Name He ' : (curentLang === 'ar' ? '  اسم الشركة عبري    ' : 'שם החברה הוא עברי ')}}    
                      <span class="text-danger">*</span>  
                   </label> 
                    <input style="color: grey;" class="form-control" type="text"  [(ngModel)]="companyNamehE" onlyAlphabets required=""
                    formControlName="company_name_he"/>
                    <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company name' : (curentLang === 'ar' ? '   الرجاء ادخال اسم الشركة ' : ' נא להזין את שם החברה  ')}}  </div>

                  </div>

                </div>
                <div class="bg-gradient">
                  <svg class="stroke-icon svg-fill">
                    <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                  </svg>
                </div>
              </div>
            </div>
          </div>

                    <!---->
       
                    <div class="col-xxl-6 col-sm-12 box-col-6 " >
                      <div class="card small-widget">
                        <div class="card-body {{ newOrders.colorClass }}" >
                          <div class="d-flex align-items-end gap-1">
                            <div class="mb-3 w-75">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Company City' : (curentLang === 'ar' ? ' مدينة الشركة' : 'עיר חברה ')}}
                                <span class="text-danger">*</span>
                             </label> 
                              <input style="color: grey;" class="form-control " type="text" formControlName ="company_address_city"  
                              onlyAlphabets required=""  [(ngModel)]="CompanyAddressCity"/>
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company city' : (curentLang === 'ar' ? '   الرجاء ادخال مدينة الشركة ' : ' נא להזין את עיר החברה ')}}  </div>

                            </div>
      
                          </div>
                          <div class="bg-gradient">
                            <svg class="stroke-icon svg-fill">
                              <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!---->

                    <div class="col-xxl-6 col-sm-12 box-col-4 " >
                      <div class="card small-widget">
                        <div class="card-body {{ newOrders.colorClass }}" >
                          <div class="d-flex align-items-end gap-1">
                            <div class="mb-3 w-75">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Company Street Name' : (curentLang === 'ar' ? '  اسم شارع الشركة' : 'שם רחוב של החברה')}}
                                <span class="text-danger">*</span>
                             </label> 
                              <input style="color: grey;" class="form-control " type="text" 
                              onlyAlphabets required=""
                              formControlName ="company_address_str"  
                               [(ngModel)]="CompanyAddressSTR"/>
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company address street' : (curentLang === 'ar' ? '   الرجاء ادخال أسم شارع الشركة ' : '  נא להזין את שם הרחוב של החברה')}}  </div>

                            </div>
      
                          </div>
                          <div class="bg-gradient">
                            <svg class="stroke-icon svg-fill">
                              <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!---->

                    <div class="col-xxl-6 col-sm-12 box-col-4 " >
                      <div class="card small-widget">
                        <div class="card-body {{ newOrders.colorClass }}" >
                          <div class="d-flex align-items-end gap-1">
                            <div class="mb-3 w-75">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? 'Company Building Number' : (curentLang === 'ar' ? '  رقم مبني الشركة' : 'מספר בניין החברה ')}}
                                <span class="text-danger">*</span>
                             </label> 
                              <input style="color: grey;" class="form-control " min="0" type="number" 
                              onlyAlphabets required="" formControlName ="company_Building_number"   [(ngModel)]="CompanyBuildingNumber"/>
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company building number' : (curentLang === 'ar' ? '   الرجاء ادخال  رقم مبني الشركة ' : '   נא להזין מספר בניין החברה    ')}}  </div>

                            </div>
      
                          </div>
                          <div class="bg-gradient">
                            <svg class="stroke-icon svg-fill">
                              <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!---->
                    <div class="col-xxl-6 col-sm-12 box-col-4 " >
                      <div class="card small-widget">
                        <div class="card-body {{ newOrders.colorClass }}" >
                          <div class="d-flex align-items-end gap-1">
                            <div class="mb-3 w-75">
                              <label class="form-label" style="color: grey;"> 
                                {{ curentLang === 'en' ? ' Post Number    ' : (curentLang === 'ar' ? ' الرقم البريدي ' : '  מספר דירה ')}}    
                                <span class="text-danger">*</span>
                             </label> 
                              <input style="color: grey;" class="form-control " type="number" min="0"
                              onlyAlphabets required="" formControlName ="company_address_zip_code"   [(ngModel)]="CompanyAddressZipCode"/>
                              <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company Post number' : (curentLang === 'ar' ? '   الرجاء ادخال الرقم البريدي للشركة ' : '  נא להזין את המיקוד של החברה    ')}}  </div>

                            </div>
      
                          </div>
                          <div class="bg-gradient">
                            <svg class="stroke-icon svg-fill">
                              <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
           
            <div class="col-xxl-6 col-sm-12 box-col-6 " >
                <div class="card small-widget">
                  <div class="card-body {{ newOrders.colorClass }}" >
                    <div class="d-flex align-items-end gap-1">
                      <div class="mb-3 w-75">
                        <label class="form-label" style="color: grey;"> 
                          {{ curentLang === 'en' ? 'Tax Register' : (curentLang === 'ar' ? '  رقم السجل الضريبي  ' : '  מספר רישום מס ')}}
                          <span class="text-danger">*</span>
                       </label> 
                       <input style="color: grey;" 
                       [(ngModel)]="TaxRegister"
                       class="form-control" 
                       type="text"
                       (keypress)="isNumber($event)"
                       maxlength="9"
                       max="999999999"
                       min="000000000"
                       inputmode="numeric" 
                       formControlName="tax_register" 
                       onlyAlphabets required=""
                       pattern="^[0-9]{9}$"
                       title="{{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}" />
                <div *ngIf="updateCompanyInfoByAdmin?.get('tax_register').invalid && (updateCompanyInfoByAdmin?.get('tax_register').dirty || updateCompanyInfoByAdmin?.get('tax_register').touched)" class="text-danger">
                  {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                </div>
                      </div>

                    </div>
                    <div class="bg-gradient">
                      <svg class="stroke-icon svg-fill">
                        <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
                   <!---->
           
                   <div class="col-xxl-6 col-sm-6 box-col-6 " >
                    <div class="card small-widget">
                      <div class="card-body {{ newOrders.colorClass }}" >
                        <div class="d-flex align-items-end gap-1">
                          <div class="mb-3 w-75">
                            <label class="form-label" style="color: grey;"> 
                              {{ curentLang === 'en' ? 'Commercial Register' : (curentLang === 'ar' ? '  رقم السجل التجاري  ' : '    מספר רישום מסחרי ')}}
                              <span class="text-danger">*</span>
                           </label> 
                            <input style="color: grey;" class="form-control" 
                            type="text"
                            (keypress)="isNumber($event)"
                            onlyAlphabets required=""
                             min="0"
                             inputmode="numeric" 
                             pattern="^[0-9]"
                           [(ngModel)]="CommercialRegister"
                            formControlName="commercial_register"/>
                            <div *ngIf="addCompanyByAdmin?.get('commercial_register').invalid && (addCompanyByAdmin?.get('commercial_register').dirty || addCompanyByAdmin?.get('commercial_register').touched)" class="text-danger">
                              {{ curentLang === 'en' ? 'Please enter exactly 9 digits' : (curentLang === 'ar' ? 'يرجى إدخال 9 أرقام فقط' : 'אנא הזן 9 ספרות בדיוק') }}
                            </div>
                            <div class="invalid-feedback text-danger">{{ curentLang === 'en' ? 'please enter company commercial register' : (curentLang === 'ar' ? '   الرجاء ادخال رقم السجل التجاري للشركة ' : '  נא להזין את מספר הרישום המסחרי של החברה  ')}}  </div>

                          </div>
    
                        </div>
                        <div class="bg-gradient">
                          <svg class="stroke-icon svg-fill">
                            <use attr.xlink:href="assets/svg/icon-sprite.svg#{{ newOrders.icon }}"></use>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>

                       <!--back-->
      <div class=" d-flex justify-content-between"> 

        <button type="button"  
        class="btn   m-2" 
       style="background-color: #141414; color: #fff;"
        (click)="updateCompanyByAdminByCompanyId()"
        > 
         {{ curentLang === 'en' ? 'Update' : (curentLang === 'ar' ? '     تحديث ' : '     עדכן  ') }}
         <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
     </button>

        <a routerLink="/dashboard/companies" >
          <button class="btn btn-danger m-2 " >
            {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
            <i class="fa fa-rotate-left mx-1"></i>
          </button>
        </a>

      </div>

         
        </div>
    </form>
        </div>
      </div>
   

  </div>
      
    </div>
    
  </div>

</div>




  <!-- upload image-->

  <ng-template #uploadData let-modal>
	<div class="modal-header">
	<h6 class="modal-title"style="color: grey;">
        {{ curentLang === 'en' ? 'Update Logo ' : (curentLang === 'ar' ? ' تعديل اللوجو ' : '    עריכת הלוגו')}} 
    </h6>
	<button type="button" class="close btn-close" style="color: grey;" aria-label="Close" (click)="modal.dismiss('Cross click')">
	  <span aria-hidden="true">&times;</span>
	</button>

	</div>
	<div class="modal-body my-2">
   
	  <div class="product-image text-center">
        <img *ngIf="currentImageUrl" [src]="currentImageUrl" alt="" class="my-2">
        <input type="file" class="form-control form-control-sm"  (change)="onFileChanged($event)" [accept]="'image/*'">
      </div>
      
      			
	</div>
    <div class="modal-footer">

        <button type="button" 
         class="btn btn-sm m-auto mt-2" 
        style="background-color: #141414; color: #fff;"
        (click)="uploadFile()"
         > 
          {{ curentLang === 'en' ? 'Confirm' : (curentLang === 'ar' ? '  تاكيد ' : '  אישור  ') }}
          <i class="fa fa-check-circle mx-1" style="color: #fff;"></i>
      </button>
  
          <button type="button" class="btn btn-sm m-auto mt-2 btn-danger" (click)="modal.close('Close click')">
              {{ curentLang === 'en' ? 'Back' : (curentLang === 'ar' ? '  رجوع ' : '  חזרה  ') }}
              <i class="fa fa-rotate-left mx-1"></i>
          </button>
  
        
  
      </div>
  </ng-template>