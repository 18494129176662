
  <div class="container-fluid p-0"> 
    <div class="row ">
      <div class="col-xl-5" [ngStyle]="{'background-image': 'url(../../../assets/bg/download.jpg)',
                                        'background-size': 'cover',
                                        'background-position': 'center center',
                                        'display': 'block'}">
        <img class="bg-img-cover bg-center d-none" src="" alt="looginpage">
      </div>
      <div class="col-xl-7 p-0 mt-3"> 
        <div class="login-card">
          <div>
            <div>
                <!-- <a class="logo"  routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/login.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="looginpage">
              </a>   -->
            </div>
            <div class="login-main w-100"> 
              <form class="theme-form" [formGroup]="RegisterForm" (ngSubmit)="submitRegisterForm(RegisterForm)">
                <h4 style="color: #141414;">{{'Register.Createyouraccount'|translate}}</h4>
                <p>{{'Register.enter'|translate}}</p>
                <div class="form-group ">

                  <div class="row">

                    <div class="col-6 ">
                      <label class="col-form-label pt-0" style="color: #141414;">{{'Register.name'|translate}}</label>
                      <input class="form-control" type="text" maxlength="20" required="" placeholder="{{'Register.name'|translate}}" formControlName="user_name">
                      <div *ngIf="RegisterForm.get('user_name')?.errors != null && RegisterForm.get('user_name')?.touched" class=" alert text-danger  my-1">
                        <p  *ngIf="RegisterForm.get('user_name')?.errors?.required" class=" my-1 text-danger"> {{'Register.nameuserRequierd'|translate}}</p>
                        <p *ngIf="RegisterForm.get('user_name')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                        <p *ngIf="RegisterForm.get('user_name')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                       </div>
                    </div>

                    <div class="col-6">
                      <label class="col-form-label pt-0" style="color: #141414;">{{'Register.phone'|translate}}</label>
                      <div class="input-group">
                        <span style="color: #141414;" class="input-group-text">+972</span>
                        <input class="form-control" type="text" maxlength="10" required=""   formControlName="phone_number">
                      </div>
                      <div *ngIf="RegisterForm.get('phone_number')?.errors != null && RegisterForm.get('phone_number')?.touched" class="alert text-danger my-1">
                        <p *ngIf="RegisterForm.get('phone_number')?.errors?.required" class="my-1 text-danger">{{'Register.phoneRequierd'|translate}}</p>
                        <p *ngIf="RegisterForm.get('phone_number')?.errors?.invalidMobilePrefix" class="my-1 text-danger">{{RegisterForm.get('phone_number')?.errors?.invalidMobilePrefix.message}}</p>
                        <p *ngIf="RegisterForm.get('phone_number')?.errors?.invalidPhoneNumber" class="my-1 text-danger">{{RegisterForm.get('phone_number')?.errors?.invalidPhoneNumber.message}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!---->

                <div class=" row">
                 <!-- <div class="col-md-6">
                <div class="form-group">
                  <label class="col-form-label"style="color: #141414;">{{'Register.merchant_nameAr'|translate}}</label>
                  <input class="form-control" type="text" maxlength="80" required="" placeholder="{{'Register.merchant_nameAr'|translate}}" formControlName="merchant_name_ar">
                  <div *ngIf="RegisterForm.get('merchant_name_ar')?.errors != null && RegisterForm.get('merchant_name_ar')?.touched" class=" alert text-danger  my-1">
                    <p  *ngIf="RegisterForm.get('merchant_name_ar')?.errors?.required" class=" my-1 text-danger"> {{'Register.merchant_name_ar'|translate}}</p>
                    <p *ngIf="RegisterForm.get('merchant_name_ar')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                    <p *ngIf="RegisterForm.get('merchant_name_ar')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
                   </div>
                </div>
                </div> -->
                    <!---->
                <!-- <div class="col-md-6">
      <div class="form-group">
        <label class="col-form-label"style="color: #141414;">{{'Register.merchant_nameEn'|translate}}</label>
        <input class="form-control" type="text" required="" maxlength="80" placeholder="{{'Register.merchant_nameEn'|translate}}" formControlName="merchant_name_en">
        <div *ngIf="RegisterForm.get('merchant_name_en')?.errors != null && RegisterForm.get('merchant_name_en')?.touched" class=" alert text-danger  my-1">
          <p  *ngIf="RegisterForm.get('merchant_name_en')?.errors?.required" class=" my-1 text-danger"> {{'Register.merchant_name_ar'|translate}}</p>
          <p *ngIf="RegisterForm.get('merchant_name_en')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
          <p *ngIf="RegisterForm.get('merchant_name_en')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
         </div>
      </div>
                </div> -->
            <!---->
            <div class="col-md-6">

            <div class="form-group">
              <label class="col-form-label"style="color: #141414;">
                {{ curentLang === 'en' ? 'Merchant Name' : (curentLang === 'ar' ? '  أسم التاجر ' : '   שם סוחר    ') }}

              </label>
              <input class="form-control" type="text" maxlength="80" required="" placeholder="  {{ curentLang === 'en' ? 'Merchant Name' : (curentLang === 'ar' ? '  أسم التاجر ' : '   שם סוחר    ') }}" formControlName="merchant_name_he">
              <div *ngIf="RegisterForm.get('merchant_name_he')?.errors != null && RegisterForm.get('merchant_name_he')?.touched" class=" alert text-danger  my-1">
                <p  *ngIf="RegisterForm.get('merchant_name_he')?.errors?.required" class=" my-1 text-danger"> {{'Register.merchant_name_ar'|translate}}</p>
                <p *ngIf="RegisterForm.get('merchant_name_he')?.errors?.minlength"  class="my-1 text-danger">{{'Register.error1'|translate}}</p>
                <p *ngIf="RegisterForm.get('merchant_name_he')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error2'|translate}}</p>
               </div>
            </div>
            </div>

                 <!---->
                 <div class="col-md-6">
                  <div class="form-group">
                    <label class="col-form-label"style="color: #141414;">{{'Register.merchantadress'|translate}} </label>
                   <input class="form-control" type="text" required="" maxlength="400" placeholder="{{'Register.merchantadress'|translate}}" formControlName="merchant_address">
                   <div *ngIf="RegisterForm.get('merchant_address')?.errors != null && RegisterForm.get('merchant_address')?.touched" class=" alert text-danger  my-1">
                    <p  *ngIf="RegisterForm.get('merchant_address')?.errors?.required" class=" my-1 text-danger"> {{'Register.merchant_address'|translate}}</p>
                    <p *ngIf="RegisterForm.get('merchant_name_he')?.errors?.maxlength"  class="my-1 text-danger"> {{'Register.error3'|translate}}</p>

                   </div>
                 </div>
                 </div>
                      <!---->
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="col-form-label"style="color: #141414;">{{'Register.id_number'|translate}} </label>
                         <input class="form-control" type="number" maxlength="20" required="" placeholder="{{'Register.id_number'|translate}}" formControlName="id_number">
                         <div *ngIf="RegisterForm.get('id_number')?.errors != null && RegisterForm.get('id_number')?.touched" class=" alert text-danger  my-1">
                          <p  *ngIf="RegisterForm.get('id_number')?.errors?.required" class=" my-1 text-danger"> {{'Register.id_numberreq'|translate}}</p>
                         </div>
                       </div>
                       </div>

                         <!---->
                      <div class="col-md-6">
                       <div class="form-group">
                        <label class="col-form-label"style="color: #141414;">{{'Register.password'|translate}}</label>
                        <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"
                         required=""
                         minlength="8"
                         maxlength="20"
                         placeholder="*********" 
                        formControlName="password">
                        <div *ngIf="RegisterForm.get('password')?.errors != null && RegisterForm.get('password')?.touched" class=" alert text-danger my-1">
                          <p  *ngIf="RegisterForm.get('password')?.errors?.required" class=" "> {{'Login.error18'|translate}}</p>
                                  <ul  *ngIf="RegisterForm.get('password')?.errors?.pattern && LoginForm.get('password')?.touched" class="text-start"> 
                                      <li>{{'Login.error19'|translate}}</li>
                                      <li>{{'Login.error20'|translate}}</li>
                                      <li>{{'Login.error21'|translate}}</li>
                                      <li>{{ curentLang === 'en' ? 'Password does not match the criteria' : (curentLang === 'ar' ? 'كلمة المرور غير مطابقة للمعايير' : 'הסיסמה אינה תואמת את הקריטריונים') }}

                                  </ul>
                           </div>
                              
                           
                            <div class="show-hideAr"  (click)="showPassword()" *ngIf="!show"><span class="show" style="color: #141414;"></span></div>
                            <div class="show-hideAr"  (click)="showPassword()" *ngIf="show"><span class="Hide" style="color: #141414;"></span></div>
                     
                            <!-- <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                            <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div> -->
                        
                       

                      </div>
                      </div>

                </div>
             
              
           
            
            

               
                <div class="form-group mb-0">
                  <div class=" p-0">
                  
                    <label  style="color: #141414;"><a class="ms-2" routerLink='/terms-and-conditions' style="color: #f90404;">
                      {{'Register.Agree'|translate}}</a></label>
                  </div>
                  <button [disabled]="RegisterForm.invalid"  
                  [disabled]="RegisterForm.get('password').invalid"
                  class="btn d-block w-50 m-auto" type="submit" 
                  style="background-color: #141414; color: #fff;">
                  {{'Register.createacount'|translate}}
                </button>
                </div>
                <div class="social mt-4">
                  <div class="btn-showcase">
          
                  </div>
                </div>
                <p class="mt-4 mb-0">{{'Register.Already'|translate}} <a class="ms-2"  [routerLink]="'/login'" style="color: #f90404;"> {{'Register.login'|translate}} </a></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>