<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper col-auto p-0">
      <div class="logo-wrapper">
        <a >
          <!-- <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="" />
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="" /> -->
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>
 
    <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto">
      <ul class="nav-menus">

        <li class="cart-nav onhover-dropdown" *ngIf="user_type === 100">
          <app-cart></app-cart>
        </li>



        <div ngbDropdown class="lang">
          <div class="language">
            <button class="mx-3" id="dropdownConfig" ngbDropdownToggle style="padding: 5px 12px;" style="color: grey;">{{'brand.Language'|translate}}</button>
          </div>
          <div ngbDropdownMenu aria-labelledby="dropdownConfig" >
            <a class="dropdown-item" (click)="changeLang('ar')" style="color: #fe0000;">Arabic</a>
            <a class="dropdown-item" (click)="changeLang('en')"style="color: #fe0000;">English </a>
            <a class="dropdown-item" (click)="changeLang('he')"style="color: #fe0000;">עִברִית</a>
         
      </div>
    </div>
   


       
        <!-- <li>
          <div class="mode" (click)="layoutToggle()">
            <svg>
              <use href="assets/svg/icon-sprite.svg#moon"></use>
            </svg>
          </div>
        </li> -->
     
    

        <li class="onhover-dropdown" *ngIf="user_type === 300 && officeIdNumber !== null">
          <app-notification></app-notification>
        </li>

        
        
        
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
